import { CreditCard } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Box,
    Container,
    Divider,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../shared/AuthRoles';
import { BillingAccountForm } from '../../components/Billing/AccountDetails/BillingAccountForm';
import BillingReport from '../../components/Billing/Reports/BillingReport';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
    return (
        <div hidden={value !== index}>
            {value === index && <Box pt={3}>{children}</Box>}
        </div>
    );
};

const Billing: React.FC = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (
            loggedInUser.role !== 'admin' &&
            loggedInUser.role !== 'guest_admin'
        ) {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'billingAccountPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    const searchParams = new URLSearchParams(location.search);
    const initialTab = parseInt(searchParams.get('page') || '0', 10);

    const [value, setValue] = useState<number>(initialTab);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        navigate(`/billing?page=${newValue}`, { replace: true });
    };

    if (loggedInUser?.role === 'admin') {
        return (
            <Container
                fixed
                maxWidth='xl'
                sx={{
                    minWidth: '95%',
                    paddingTop: isMobile ? '100px' : '125px',
                    height: isMobile || isIpad ? 'auto' : '100%',
                }}
            >
                <Box width='100%'>
                    <Stack
                        direction='row'
                        alignItems='center'
                        gap={1}
                        pb='30px'
                    >
                        <Typography
                            variant='h5'
                            color='#787878'
                            mt={2}
                            sx={{
                                textTransform: 'uppercase',
                                textDecoration: 'underline rgb(92, 181, 179)',
                                textUnderlineOffset: '8px',
                            }}
                        >
                            BILLING REPORTS
                        </Typography>
                    </Stack>
                    <BillingReport />
                </Box>
            </Container>
        );
    }

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Box width='100%'>
                <Stack direction='row' alignItems='center' gap={1} pb='30px'>
                    <CreditCard color='primary' />
                    <Typography variant='h5' color='#787878'>
                        BILLING
                    </Typography>
                </Stack>
                <Grid sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        sx={{
                            '& .MuiTabs-indicator': {
                                width: 'auto',
                                left: 0,
                            },
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Tab
                            label='Account Details'
                            sx={{ textAlign: 'left', paddingX: '16px' }}
                        />
                        <Tab
                            label='Invoices'
                            sx={{ textAlign: 'left', paddingX: '16px' }}
                        />
                    </Tabs>
                    <Box
                        sx={{
                            height: 'auto',
                            width: '100%',
                        }}
                    >
                        {' '}
                        <Divider />
                        {loggedInUser?.role !== 'admin' && (
                            <TabPanel value={value} index={0}>
                                <BillingAccountForm />
                            </TabPanel>
                        )}
                        <TabPanel value={value} index={1}>
                            <BillingReport />
                        </TabPanel>{' '}
                    </Box>
                    <Grid sx={{ pb: 2 }}>
                        {' '}
                        <CustomButton
                            onClick={() => navigate(`/adminCenter/guest`)}
                            color='green'
                            sx={{
                                marginLeft: !isMobile ? '-8px' : '-18px',
                                '&:hover': {
                                    cursor: 'pointer',
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <ArrowBackIcon />{' '}
                            {isMobile ? null : (
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        paddingLeft: '5px',
                                    }}
                                >
                                    Back
                                </Typography>
                            )}
                        </CustomButton>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default Billing;
