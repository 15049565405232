import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import BookingCapList from '../../components/BookingCaps/BookingCapList';
import BookingCapToolbar from '../../components/BookingCaps/BookingCapToolbar';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router';
import UserRole from '../../../shared/AuthRoles';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BookingCapPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'bookingCapPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    const onClickGoBack = () => {
        navigate(`/adminCenter/host`);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Booking Caps
                    </Typography>
                </Grid>
                <Grid sx={{ height: isMobile ? '5%' : '10%' }}>
                    <BookingCapToolbar />
                </Grid>
                <Grid sx={{ height: isMobile ? '90%' : '85%' }}>
                    {' '}
                    <BookingCapList />
                </Grid>
                <Grid sx={{ height: isMobile ? '5%' : '5%' }}>
                    {' '}
                    <CustomButton
                        onClick={onClickGoBack}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                }}
                            >
                                Back
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BookingCapPage;
