import React from 'react';
import { NavigateNext } from '@mui/icons-material';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import { formatTime } from '../../../../shared/utils';
import UserRole from '../../../../shared/AuthRoles';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

interface ReservationDetailsProps {
    booking: any;
}

const ReservationDetails = ({ booking }: ReservationDetailsProps) => {
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    return (
        <>
            {booking && loggedInUser?.role.includes('owner') && (
                <Box sx={{ p: 1 }}>
                    <Typography variant='overline' sx={{ fontWeight: 'bold' }}>
                        Guest Details
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <Avatar src={booking.booker.profilePicture?.src} />
                        <div>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {booking.booker.firstName}{' '}
                                {booking.booker.lastName} - {booking.guest.name}
                            </Typography>
                            <Typography>{booking.booker.email}</Typography>
                        </div>
                    </Box>
                </Box>
            )}
            {booking && (
                <>
                    <div>
                        <Typography
                            variant='overline'
                            sx={{ fontWeight: 'bold', ml: 1 }}
                        >
                            Reservation Details
                        </Typography>
                        <Stack divider={<Divider />} spacing={1}>
                            <Box
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    pr: 2,
                                    transition:
                                        'padding-right 0.3s ease-in-out',
                                    '&:hover': {
                                        pr: 1,
                                    },
                                }}
                                onClick={() =>
                                    navigate(
                                        `/reservations/${booking.module.name
                                            .replace(/\s/g, '')
                                            .toLowerCase()}/${booking.id}`
                                    )
                                }
                            >
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Reservation #{booking?.id}
                                </Typography>
                                <NavigateNext />
                            </Box>
                            <Box
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Check-in
                                </Typography>
                                <Typography>
                                    {format(
                                        new Date(booking.startDate),
                                        'dd/MM/yyyy'
                                    )}
                                    {' @ '}
                                    {formatTime(
                                        booking.bookingInfo?.checkinTime
                                    )}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Checkout
                                </Typography>
                                <Typography>
                                    {format(
                                        new Date(booking.endDate),
                                        'dd/MM/yyyy'
                                    )}
                                    {' @ '}
                                    {formatTime(
                                        booking.bookingInfo?.checkoutTime
                                    )}
                                </Typography>
                            </Box>
                            {booking.module?.type !== 'vehicle' && booking.module?.type !== 'commercial' && (
                                <Box
                                    sx={{
                                        p: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        Guests
                                    </Typography>
                                    <Typography>
                                        {booking.bookingInfo?.guests}
                                    </Typography>
                                </Box>
                            )}
                        </Stack>
                    </div>
                </>
            )}
        </>
    );
};

export default ReservationDetails;
