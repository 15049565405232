import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, InputLabel, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as z from 'zod';

import {
    useGetBillingAccountQuery,
    useGetCompanyGuestsInfoQuery,
    useGetProfileDetailsQuery,
    useSetBillingAccountMutation,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../../shared/AuthRoles';
import AddressAutocomplete from '../../../shared/AddressAutocomplete';
import FormAutocomplete from '../../../shared/FormAutocomplete';
import FormTextField from '../../../shared/FormTextField';
import { Address } from '../../../types/experience';
import toast from '../../../shared/Toast';
import { handleHttpError } from '../../../../shared/utils';

export const BillingAccountForm = () => {
    const loggedInUser = UserRole();

    const { data: guestCompanyInfo } = useGetCompanyGuestsInfoQuery(
        `/guests/${loggedInUser?.company}`
    );
    const { data: profileDetails } = useGetProfileDetailsQuery('/profile');

    const [setBillingAccount] = useSetBillingAccountMutation();
    const { data: billingAccount, isFetching } = useGetBillingAccountQuery(
        `guests/${guestCompanyInfo?.id}/billing-account`
    );

    const schema = z.object({
        firstName: z.string().min(1, 'Required'),
        lastName: z.string().min(1, 'Required'),
        email: z.string().email().min(1, 'Required'),
        phone: z.string().min(1, 'Required'),
        country: z.string().min(1, 'Required'),
        state: z.string().min(1, 'Required'),
        suburb: z.string().min(1, 'Required'),
        streetAddress: z.string().min(1, 'Required'),
        postcode: z.string().min(1, 'Required'),
    });

    type BillingAccountFormValues = z.infer<typeof schema>;
    const {
        reset,
        control,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { isSubmitting, errors, isDirty },
    } = useForm<BillingAccountFormValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: '',
            state: '',
            suburb: '',
            streetAddress: '',
            postcode: '',
        },
    });

    useEffect(() => {
        if (billingAccount) {
            reset(billingAccount);
        }
    }, [billingAccount, reset]);

    const handleAddressChange = (address: Address) => {
        setValue(
            'streetAddress',
            `${address.streetAddress1} ${address.streetAddress2}`,
            { shouldDirty: true }
        );
        setValue('suburb', address.suburb || '', { shouldDirty: true });
        setValue('state', address.state || '', { shouldDirty: true });
        setValue('postcode', address.postcode || '', { shouldDirty: true });
        setValue('country', address.country === 'AU' ? 'Australia' : '', {
            shouldDirty: true,
        });
    };

    const onSubmit = async (data: BillingAccountFormValues) => {
        const requestData = {
            guestId: guestCompanyInfo?.id,
            billingAccount: data,
        };

        setBillingAccount(requestData).then((data: any) => {
            if (data) {
                if (data?.error) {
                    const errorMessage = handleHttpError(data?.error);
                    toast.error(errorMessage);
                } else {
                    toast.success('Billing account was successfully updated.');
                }
            } else {
                toast.error(`Failed to save billing account`);
            }
        });
    };

    const handleCopyBillingContact = () => {
        setValue('firstName', profileDetails?.firstName || '', {
            shouldDirty: true,
        });
        setValue('lastName', profileDetails?.lastName || '', {
            shouldDirty: true,
        });
    };

    const handleCopyBillingAddress = () => {
        clearErrors([
            'country',
            'state',
            'suburb',
            'streetAddress',
            'postcode',
        ]);
        setValue(
            'country',
            guestCompanyInfo?.countryCode === 'AU' ? 'Australia' : '',
            { shouldDirty: true }
        );
        setValue('state', guestCompanyInfo?.state || '', {
            shouldDirty: true,
        });
        setValue('suburb', guestCompanyInfo?.suburb || '', {
            shouldDirty: true,
        });
        setValue(
            'streetAddress',
            `${guestCompanyInfo?.streetAddress1 || ''} ${
                guestCompanyInfo?.streetAddress2 || ''
            }`.trim(),
            { shouldDirty: true }
        );
        setValue('postcode', guestCompanyInfo?.postcode || '', {
            shouldDirty: true,
        });
    };

    if (isFetching) {
        return <LoadingComponent color='green' />;
    }

    return (
        <Stack spacing={3} sx={{ pb: '100px', minHeight: 890 }}>
            <Grid
                sx={{
                    display: 'flex',
                    gap: 1,
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    sx={{
                        color: 'text.primary',
                        fontWeight: 900,
                    }}
                >
                    Billing Contact
                </Typography>
                <CustomButton
                    //We cannot use reset() here because it wouldn't set isDirty to true
                    type='button'
                    variant='contained'
                    color='neutral'
                    onClick={handleCopyBillingContact}
                >
                    Copy Details from Profile
                </CustomButton>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={{ xs: 2, lg: 4 }}>
                    <Grid container item columnSpacing={5} rowSpacing={2}>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>First Name</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormTextField
                                    name='firstName'
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>Last Name</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormTextField
                                    name='lastName'
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        columnSpacing={5}
                        rowSpacing={2}
                        sx={{ pb: '30px' }}
                    >
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>Email</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormTextField name='email' control={control} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>Phone</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormTextField name='phone' control={control} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: {
                                xs: 'column',
                                sm: 'row',
                            },
                            justifyContent: 'space-between',
                            pb: '10px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'text.primary',
                                fontWeight: 900,
                            }}
                        >
                            Billing Address
                        </Typography>

                        <CustomButton
                            //We cannot use reset() here because it wouldn't set isDirty to true
                            type='button'
                            variant='contained'
                            color='neutral'
                            onClick={handleCopyBillingAddress}
                        >
                            Copy Details from Company
                        </CustomButton>
                    </Grid>

                    <Grid container item columnSpacing={5} rowSpacing={2}>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>Address</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <AddressAutocomplete
                                    onChange={handleAddressChange}
                                    height='8px'
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item columnSpacing={5} rowSpacing={2}>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>Country</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormAutocomplete
                                    name='country'
                                    control={control}
                                    options={[
                                        {
                                            value: 'Australia',
                                            label: 'Australia',
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>State</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormAutocomplete
                                    name='state'
                                    control={control}
                                    options={[
                                        { value: 'NSW', label: 'NSW' },
                                        { value: 'QLD', label: 'QLD' },
                                        { value: 'VIC', label: 'VIC' },
                                        { value: 'SA', label: 'SA' },
                                        { value: 'WA', label: 'WA' },
                                        { value: 'NT', label: 'NT' },
                                        { value: 'TAS', label: 'TAS' },
                                        { value: 'ACT', label: 'ACT' },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item columnSpacing={5} rowSpacing={2}>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>Suburb</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormTextField
                                    name='suburb'
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>Street Address</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormTextField
                                    name='streetAddress'
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item columnSpacing={5} rowSpacing={2}>
                        <Grid container item xs={12} lg={6} spacing={0.5}>
                            <Grid item lg={3}>
                                <InputLabel required>Postcode</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <FormTextField
                                    name='postcode'
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item spacing={1}>
                        <Grid
                            item
                            xs={12}
                            lg={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px',
                            }}
                        >
                            <CustomButton
                                type='button'
                                variant='contained'
                                color='warning'
                                onClick={() => reset(billingAccount)}
                                disabled={!isDirty}
                            >
                                Cancel
                            </CustomButton>
                            <CustomButton
                                type='submit'
                                variant='contained'
                                color='green'
                                loading={isSubmitting}
                                disabled={isSubmitting || !isDirty}
                            >
                                Save
                            </CustomButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Stack>
    );
};
