import { FormValues } from '@/src/feature/types/experience';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CalendarMonth, LocationOn, NavigateNext } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import ExperiencePhotos from './ExperiencePhotos';
import ReserveModal from './ReserveModal';
import UserRole from '../../../../shared/AuthRoles';

interface ExperienceDetailsProps {
    experience: FormValues;
    booking?: any;
}

const ExperienceDetails = ({ experience, booking }: ExperienceDetailsProps) => {
    const navigate = useNavigate();
    const loggedInUser = UserRole();

    const [open, setOpen] = useState<boolean>(false);

    const onReserve = () => {
        setOpen(true);
    };

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    pr: 2,
                    transition: 'padding-right 0.3s ease-in-out',
                    '&:hover': {
                        pr: 1,
                    },
                }}
                onClick={() =>
                    navigate(
                        `/viewExperiences/${experience.type}/${experience.id}`
                    )
                }
            >
                <div>
                    <Typography
                        variant='h6'
                        sx={{ fontWeight: 'bold' }}
                    >
                        {experience?.name}
                    </Typography>
                    <Typography variant='body2'>
                        Hosted by {experience?.owner?.name}
                    </Typography>
                </div>
                <NavigateNext />
            </Box>
            <ExperiencePhotos photos={experience?.photos} />
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    p: 1,
                }}
            >
                <LocationOn />
                <div>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {experience.streetAddress1}{' '}
                        {experience.streetAddress2}
                    </Typography>
                    <Typography variant='body2'>
                        {experience.suburb}, {experience.state}
                    </Typography>
                </div>
            </Box>
            {!booking && loggedInUser?.role.includes('guest') && (
                <>
                    <Divider />
                    <Button
                        variant='outlined'
                        startIcon={<CalendarMonth />}
                        onClick={onReserve}
                    >
                        Reserve
                    </Button>
                </>
            )}
            
            <ReserveModal 
                open={open}
                onClose={() => setOpen(false)}
                experience={experience}
            />
        </>
    );
};

export default ExperienceDetails;
