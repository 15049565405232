import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Grid,
    List,
    ListItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    useEditClientMutation,
    useGetUserListQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomCheckbox } from '../../../../layout/styledcomponents/CustomCheckbox';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../../layout/styledcomponents/filters/Dropdown';
import UserRole from '../../../../shared/AuthRoles';
import { useExitPrompt } from '../../../../shared/LeavingPageWarning';
import toast from '../../../shared/Toast';
import { handleHttpError } from '../../../../shared/utils';

export default function EditClientForm(clientInformation: any) {
    const navigate = useNavigate();
    const loggedInUser = UserRole();
    const [editClient] = useEditClientMutation();
    const [url, setUrl] = useState('');
    const { data: getUsers } = useGetUserListQuery(url);
    const [clientDetails, setClientDetails] = useState(
        clientInformation.client
    );
    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isDesktop = useMediaQuery('(min-width: 1200px)');
    const [companyName, setCompanyName] = useState(clientDetails?.name);
    const [primaryContact, setPrimaryContact] = useState(
        clientDetails?.primaryContact
    );
    const [streetNo, setStreetNo] = useState(clientDetails?.streetAddress1);
    const [street, setStreet] = useState(clientDetails?.streetAddress2);
    const [suburb, setSuburb] = useState(clientDetails?.suburb);
    const [state, setState] = useState(clientDetails?.state);
    const [postCode, setPostCode] = useState(clientDetails?.postcode);
    const [country, setCountry] = useState(clientDetails?.countryCode);
    const [status, setStatus] = useState(
        clientDetails?.isActive ? 'enabled' : 'disabled'
    );
    const [emailAddress, setEmailAddress] = useState(
        clientDetails?.account.email
    );
    const [notificationEmail, setNotificationEmail] = useState(
        clientDetails?.notificationsEmail
    );
    const [allowMultipleDomains, setAllowMultpleDomains] = useState(
        clientDetails?.allowsMultipleDomains
    );
    const [phone, setPhone] = useState(clientDetails?.phone);
    const [password, setPassword] = useState('');
    const [isValidInput, setIsValidInput] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidNotificationEmail, setIsValidNotificationEmail] =
        useState(false);

    const [isValidPhone, setIsValidPhone] = useState(false);
    const [lowerCaseValidation, setLowercaseValidation] = useState(false);
    const [upperCaseValidation, setUppercaseValidation] = useState(false);
    const [pwLengthValidation, setPWLengthValidation] = useState(false);
    const [numberValidation, setNumberValidation] = useState(false);
    const [characterValidation, setCharacterValidation] = useState(false);
    const [isDuplicateEmail, setDuplicateEmail] = useState(false);

    useEffect(() => {
        setClientDetails(clientInformation.client);
    }, [clientInformation.client]);

    useEffect(() => {
        return () => {
            setShowExitPrompt(false);
        };
    }, []);

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setUrl(`/owners/${loggedInUser?.company}/users`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setUrl(`/guests/${loggedInUser?.company}/users`);
        } else {
            setUrl(`/7-day/users`);
        }
    }, [loggedInUser]);

    const formDetailsEditClient = {
        name: companyName,
        primaryContact: primaryContact,
        isActive: status === 'enabled' ? true : false,
        phone: phone,
        countryCode: country,
        state: state,
        suburb: suburb,
        streetAddress1: streetNo,
        streetAddress2: street,
        postcode: postCode,
        notificationsEmail: notificationEmail,
        allowsMultipleDomains: allowMultipleDomains,
        // account: {
        //     email: emailAddress,
        //     // password: 'password',
        // },
    };

    const errors: any = [];

    if (companyName?.length === 0) {
        errors.push('Company Name cannot be empty');
    }
    if (streetNo?.length === 0) {
        errors.push('Surname cannot be empty');
    }
    if (street?.length === 0) {
        errors.push('Role cannot be empty');
    }
    if (suburb?.length === 0) {
        errors.push('Role cannot be empty');
    }
    if (state?.length === 0) {
        errors.push('Role cannot be empty');
    }
    if (postCode?.length === 0) {
        errors.push('Role cannot be empty');
    }
    if (status?.length === 0) {
        errors.push('Status cannot be empty');
    }
    if (emailAddress?.length === 0) {
        errors.push('Email Address cannot be empty');
    }
    if (notificationEmail?.length === 0) {
        errors.push('Notification Email Address cannot be empty');
    }
    if (phone?.length === 0) {
        errors.push('Phone cannot be empty');
    }
    // if (password?.length === 0) {
    //     errors.push('Password cannot be empty');
    // }

    const handleCompanyName = (event: any) => {
        setCompanyName(
            event?.target?.value.charAt(0)?.toUpperCase() +
                event?.target?.value?.slice(1)
        );
        setShowExitPrompt(true);
    };

    const handlePriamryContact = (event: any) => {
        setPrimaryContact(
            event?.target?.value.charAt(0)?.toUpperCase() +
                event?.target?.value?.slice(1)
        );
        setShowExitPrompt(true);
    };
    const handleStreetNo = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setStreetNo(event.target.value);
        setShowExitPrompt(true);
    };
    const handleStreet = (event: any) => {
        setStreet(
            event?.target?.value.charAt(0)?.toUpperCase() +
                event?.target?.value?.slice(1)
        );
        setShowExitPrompt(true);
    };
    const handleSuburb = (event: any) => {
        setSuburb(
            event?.target?.value.charAt(0)?.toUpperCase() +
                event?.target?.value?.slice(1)
        );
        setShowExitPrompt(true);
    };
    const handlePostCode = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPostCode(event.target.value);
        setShowExitPrompt(true);
    };
    const handleState = (value: SetStateAction<string>) => {
        setState(value);
        setShowExitPrompt(true);
    };
    const handleCountry = (value: SetStateAction<string>) => {
        setCountry(value);
        setShowExitPrompt(true);
    };

    const handleStatus = (value: any) => {
        setStatus(value);
        setShowExitPrompt(true);
    };

    //Retrieve users and compare account.email with the new user email
    const mapOverUsers = getUsers?.map(
        (account: { email: any }) => account?.email
    );

    const handleEmail = (event: any) => {
        // setEmailAddress(event.target.value);
        setShowExitPrompt(true);
        if (validatEmail(event?.target?.value) === false) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
        //Find Duplicate User
        const findDuplicate = mapOverUsers?.find(
            (i: any) => i === event?.target?.value
        );
        if (
            findDuplicate !== undefined &&
            findDuplicate !== getUsers?.account?.email
        ) {
            setDuplicateEmail(true);
            setEmailAddress('');
        } else if (findDuplicate === getUsers?.account?.email) {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value);
        } else {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value);
        }
    };

    const handleNotificationEmail = (event: any) => {
        setShowExitPrompt(true);
        if (validatEmail(event?.target?.value) === false) {
            setIsValidNotificationEmail(true);
        } else {
            setIsValidNotificationEmail(false);
        }

        setNotificationEmail(event?.target?.value);
    };

    const handleMultipleDomains = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAllowMultpleDomains(event?.target?.checked);
    };

    const handlePhone = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPhone(event.target.value);
        setShowExitPrompt(true);
        if (validatePhone(event.target.value) === false) {
            setIsValidPhone(true);
        } else {
            setIsValidPhone(false);
        }
    };

    const handlePassword = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPassword(event.target.value);
        setShowExitPrompt(true);
        if (event.target.value.length === 0) {
            // if the password is blank, validate as true and don't update PW
            setIsValidInput(false);
        } else {
            if (validatePassword(event.target.value) === false) {
                setIsValidInput(true);
            } else {
                setIsValidInput(false);
            }
        }
    };

    const validatePassword = (pw: any) => {
        if (/(?=.*[A-Z])/.test(pw) === true) {
            setUppercaseValidation(true);
        } else {
            setUppercaseValidation(false);
        }
        if (pw.length >= 8 === true) {
            setPWLengthValidation(true);
        } else {
            setPWLengthValidation(false);
        }
        if (/[a-z]/.test(pw) === true) {
            setLowercaseValidation(true);
        } else {
            setLowercaseValidation(false);
        }
        if (/[#?!@$%^&*-]/.test(pw) === true) {
            setCharacterValidation(true);
        } else {
            setCharacterValidation(false);
        }
        if (/[0-9]/.test(pw) === true) {
            setNumberValidation(true);
        } else {
            setNumberValidation(false);
        }

        return (
            /[A-Z]/.test(pw) &&
            pw.length >= 8 &&
            /[a-z]/.test(pw) &&
            /[0-9]/.test(pw) &&
            /[#?!@$%^&*-]/.test(pw)
        );
    };

    const validatEmail = (email: any) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };

    const validatePhone = (ph: any) => {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(
            ph
        );
    };

    const dispatch = useDispatch();

    const saveEditClient = () => {
        const requestBody = formDetailsEditClient;
        let url = `/owners/${clientDetails?.id}`;
        let reqInfo = [url, requestBody];
        editClient(reqInfo).then((data: any) => {
            if (data) {
                if (data?.error) {
                    const errorMessage = handleHttpError(data?.error);
                    toast.error(errorMessage);
                } else {
                    toast.success(
                        `${formDetailsEditClient.name} has been successfully edited`
                    );
                    navigate(
                        `/clients/${clientDetails?.name
                            .replace(/ /g, '')
                            .toLowerCase()}/${clientDetails?.id}`
                    );
                }
            } else {
                toast.error(
                    `Failed to edit client: ${formDetailsEditClient.name}`
                );
            }
        });
    };
    return (
        <Grid sx={{ width: '100%', height: '100%' }}>
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: isIpad ? '0px' : '30px',
                    height: 'auto',
                }}
            >
                <Typography
                    component='h4'
                    // variant='h6'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        fontWeight: 900,
                        paddingTop: isMobile ? '30px' : isIpad ? '0px' : '0px',
                        paddingBottom: '30px',
                    }}
                >
                    Client
                </Typography>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Company
                            {companyName === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            placeholder='ABC Pty Ltd'
                            value={companyName}
                            onChange={handleCompanyName}
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Primary Contact
                            {primaryContact === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            value={primaryContact}
                            placeholder='John Smith'
                            onChange={handlePriamryContact}
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Phone{' '}
                            {phone === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            value={phone}
                            placeholder='0414789456'
                            type='tel'
                            onChange={handlePhone}
                            fullWidth={isMobile || isIpad}
                            color={isValidPhone ? 'red' : 'green'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            No. Users{' '}
                            {/* {role === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null} */}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            value={clientDetails?.activeUsers}
                            fullWidth={isMobile || isIpad}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Street No.{' '}
                            {streetNo === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            value={streetNo}
                            placeholder=''
                            onChange={handleStreetNo}
                            fullWidth={isMobile || isIpad}
                            color={'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Street{' '}
                            {street === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            value={street}
                            placeholder=''
                            onChange={handleStreet}
                            fullWidth={isMobile || isIpad}
                            color={'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Surbub{' '}
                            {suburb === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            value={suburb}
                            placeholder=''
                            onChange={handleSuburb}
                            fullWidth={isMobile || isIpad}
                            color={'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Post Code{' '}
                            {postCode === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            value={postCode}
                            placeholder=''
                            onChange={handlePostCode}
                            fullWidth={isMobile || isIpad}
                            color={'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            State{' '}
                            {state === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <DropdownFilter
                            id={'state'}
                            options={[
                                { value: 'NSW', label: 'NSW' },
                                { value: 'QLD', label: 'QLD' },
                                { value: 'VIC', label: 'VIC' },
                                { value: 'SA', label: 'SA' },
                                { value: 'WA', label: 'WA' },
                                { value: 'NT', label: 'NT' },
                                { value: 'TAS', label: 'TAS' },
                                { value: 'ACT', label: 'ACT' },
                            ]}
                            handleChange={handleState}
                            selectedValue={state}
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Country{' '}
                            {country === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <DropdownFilter
                            id={'country'}
                            options={[{ value: 'AU', label: 'Australia' }]}
                            handleChange={handleCountry}
                            selectedValue={country}
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ marginRight: '5px' }}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Status
                                {status === '' ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}{' '}
                            </Typography>
                        </Grid>

                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        // color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                {' '}
                                                Enabled: Clients can access the
                                                site and add/edit users
                                            </ListItem>
                                            <ListItem>
                                                {' '}
                                                Disabled: Clients will not be
                                                able to access the site
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <DropdownFilter
                            id={'clientStatus'}
                            options={[
                                { value: 'Enabled', label: 'Enabled' },
                                {
                                    value: 'Disabled',
                                    label: 'Disabled',
                                    isDefault: true,
                                },
                            ]}
                            handleChange={handleStatus}
                            selectedValue={status}
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Notification Email
                            {notificationEmail === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}{' '}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            value={notificationEmail}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleNotificationEmail}
                            fullWidth={isMobile || isIpad}
                            color={
                                isValidNotificationEmail ? 'green' : 'neutral'
                            }
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ marginRight: '5px' }}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Allow multiple domains
                            </Typography>
                        </Grid>

                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        // color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                {' '}
                                                If checked, users of this
                                                company will be allowed to add
                                                email addresses that don't
                                                belong to their organsation to
                                                access 7DAY, such as personal
                                                emails. If unchecked, users
                                                cannot add an email address that
                                                is outside their organisation.
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomCheckbox
                            checked={allowMultipleDomains}
                            onChange={handleMultipleDomains}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    ></Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    ></Grid>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                }}
            >
                <Typography
                    component='h4'
                    // variant='h6'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        fontWeight: 900,
                        paddingTop: isMobile || isIpad ? '10px' : '30px',
                        height: '10%',
                    }}
                >
                    Client Login
                </Typography>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                        paddingTop: isMobile ? '10px' : isIpad ? '0px' : '30px',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Email
                            {emailAddress === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    {isValidEmail === true && (isMobile || isIpad) ? (
                        <Typography
                            sx={{
                                fontSize: '10px',
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            <CloseIcon
                                sx={{
                                    fontSize: '12px',
                                    paddingRight: '5px',
                                    color: 'red',
                                }}
                            />{' '}
                            Invalid email
                        </Typography>
                    ) : null}
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            error={isDuplicateEmail}
                            label={
                                isDuplicateEmail === true
                                    ? 'Error: User already exists'
                                    : ''
                            }
                            value={!isDuplicateEmail ? emailAddress : null}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleEmail}
                            fullWidth={isMobile || isIpad}
                            color={isValidEmail ? 'green' : 'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        {/* <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Password
                            {password === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography> */}
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        {/* <CustomTextField
                            placeholder='*******'
                            onChange={handlePassword}
                            fullWidth={isMobile || isIpad}
                            color={isValidInput ? 'green' : 'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        /> */}
                    </Grid>
                </Grid>

                {isValidInput ? (
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection:
                                isMobile || isIpad ? 'column' : 'row',
                            width: '100%',
                            height:
                                password === '' && isMobile
                                    ? '0px'
                                    : isIpad
                                    ? '260px'
                                    : '200px',
                        }}
                    >
                        <>
                            {' '}
                            {password !== '' && isMobile ? (
                                <Grid
                                    sx={{
                                        width:
                                            password !== '' && isMobile
                                                ? '100%'
                                                : '20%',
                                        height: '50px',
                                    }}
                                >
                                    {' '}
                                    <Grid
                                        sx={{
                                            width:
                                                isMobile || isIpad
                                                    ? '100%'
                                                    : '30%',
                                            height: '50px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '10px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                            }}
                                        >
                                            {' '}
                                            Password must:
                                            <List>
                                                <ListItem>
                                                    {pwLengthValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Be a minimum of 8 characters
                                                </ListItem>
                                                <ListItem>
                                                    {lowerCaseValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Include at least one
                                                    lowercase letter (a-z)
                                                </ListItem>
                                                <ListItem>
                                                    {upperCaseValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Include at least one
                                                    uppercase letter (A-Z)
                                                </ListItem>
                                                <ListItem>
                                                    {numberValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Include at least one number
                                                    (0-9)
                                                </ListItem>
                                                <ListItem>
                                                    {characterValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Include at least one special
                                                    character (!@#?&)
                                                </ListItem>
                                            </List>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ) : null}
                            {isDesktop && isValidEmail ? (
                                <Grid
                                    sx={{
                                        width: '30%',
                                        height: '50px',
                                        marginRight:
                                            !isMobile || !isIpad
                                                ? '50px'
                                                : '0px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '10px',
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />{' '}
                                                Invalid email
                                            </ListItem>{' '}
                                        </List>{' '}
                                    </Typography>
                                </Grid>
                            ) : null}
                            <Grid
                                sx={{
                                    width: '20%',
                                    height: '50px',
                                }}
                            ></Grid>
                        </>

                        {password !== '' && !isMobile ? (
                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '30%',
                                    height: '50px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '10px',
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    {' '}
                                    Password must:
                                    <List>
                                        <ListItem>
                                            {pwLengthValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Be a minimum of 8 characters
                                        </ListItem>
                                        <ListItem>
                                            {lowerCaseValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Include at least one lowercase
                                            letter (a-z)
                                        </ListItem>
                                        <ListItem>
                                            {upperCaseValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Include at least one uppercase
                                            letter (A-Z)
                                        </ListItem>
                                        <ListItem>
                                            {numberValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Include at least one number (0-9)
                                        </ListItem>
                                        <ListItem>
                                            {characterValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Include at least one special
                                            character (!@#?&)
                                        </ListItem>
                                    </List>
                                </Typography>
                            </Grid>
                        ) : null}
                    </Grid>
                ) : null}

                <Grid
                    sx={{
                        height: isMobile || isIpad ? '80px' : '150px',
                        textAlign: ' right',
                        paddingBottom: isMobile || isIpad ? '10px' : '0px',
                    }}
                >
                    <CustomButton
                        variant='contained'
                        color='green'
                       
                        onClick={saveEditClient}
                        disabled={
                            isValidInput ||
                            isValidEmail ||
                            isValidPhone ||
                            errors.length > 0
                        }
                        sx={{
                            top: isMobile || isIpad ? '10%' : '50%',
                        }}
                    >
                        Save
                    </CustomButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
