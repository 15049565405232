import {
    Alert,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    useMediaQuery,
} from '@mui/material';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import React, { useEffect, useState } from 'react';
import {
    useGetAssetCalendarAvailabilityQuery,
    useGetIndividualReservationQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { useSelector } from 'react-redux';
import { selectCalendarError } from '../../../../app/Slices/CalendarError';
import FormCalendar, { CheckinDetails } from '../../../shared/FormCalendar';
import { FormProvider, useForm } from 'react-hook-form';
import { DateRange } from 'react-day-picker';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormValues } from '../../../types/experience';
import { format } from 'date-fns';
import { formatTime } from '../../../../shared/utils';
import UserRole from '../../../../shared/AuthRoles';

const Schema = z.object({
    date: z.object(
        {
            from: z.date(),
            to: z.date(),
        },
        { message: 'Selection required' }
    ),
    checkin: z.string().optional(),
    checkout: z.string().optional(),
});

export default function CalendarModal(props: any) {
    const { onClose, open, onSubmit, data } = props;
    const dateSelectionError = useSelector(selectCalendarError);
    const isMobile = useMediaQuery('(max-width: 770px)');
    const urlRef = window.location.href;

    const loggedInUser = UserRole();
    const { data: getReservation, isLoading } =
        useGetIndividualReservationQuery(`bookings/${urlRef.split('/')[5]}`);

    const experience: FormValues = data;

    const methods = useForm({
        resolver: zodResolver(Schema),
        mode: 'onChange',
        shouldUnregister: true,
    });

    const { control, setValue, handleSubmit, watch } = methods;

    // const date = useWatch({ control, name: 'date' });
    const date = watch('date');

    const handleClose = () => {
        onClose();
    };

    const { data: availability, isFetching } =
        useGetAssetCalendarAvailabilityQuery({
            url: `/modules/${data?.id}/calendar`,
            bookingId: getReservation?.id,
        });

    const [limitedDates, setLimitedDates] = useState<
        Record<string, CheckinDetails>
    >({});

    useEffect(() => {
        // map so that checkin/checkout data is available in O(n)
        if (availability?.limited) {
            const mappedLimitedDates = availability.limited.reduce(
                (acc: Record<string, CheckinDetails>, day: any) => {
                    acc[day.date] = {
                        checkinFrom: day.checkinFrom,
                        checkoutBefore: day.checkoutBefore,
                    };
                    return acc;
                },
                {}
            );

            setLimitedDates(mappedLimitedDates);
        }
    }, [availability, date]);

    const details = {
        date: {
            from: new Date(getReservation?.startDate),
            to: new Date(getReservation?.endDate),
        },
        checkin: getReservation?.checkinTime,
        checkout: getReservation?.checkoutTime,
    };

    const [alert, setAlert] = useState<string>('');

    const handleSelect = (date?: DateRange) => {
        setAlert('');

        if (experience?.minHireHours <= 24 && !date?.to) {
            setValue('date', date ? {
                from: date?.from,
                to: date?.from,
            } : null);
        } else {
            setValue('date', date || null);
        }

        setValue('checkin', '');
        setValue('checkout', '');

        if (!date?.from || !date?.to) return;

        const checkin = format(date.from, 'yyyy-MM-dd');
        const checkout = format(date.to, 'yyyy-MM-dd');

        const checkinDetails = limitedDates[checkin];
        const checkoutDetails = limitedDates[checkout];

        if (checkinDetails?.checkinFrom) {
            setAlert(
                `Check-in available from ${formatTime(
                    checkinDetails.checkinFrom
                )}`
            );
            setValue('checkin', checkinDetails.checkinFrom);
        }

        if (
            (checkoutDetails?.checkoutBefore && !checkoutDetails.checkinFrom) ||
            (checkoutDetails?.checkoutBefore &&
                checkoutDetails.checkinFrom &&
                checkin !== checkout)
        ) {
            setAlert(
                `Checkout before ${formatTime(checkoutDetails.checkoutBefore)}`
            );
            setValue('checkout', checkoutDetails.checkoutBefore);
        }
    };

    const endDate = new Date(
        new Date().setFullYear(
            new Date().getFullYear() +
                (loggedInUser?.role.includes('owner') ? 2 : 1)
        )
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Select new dates</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                            <Grid
                                sx={{
                                    overflowY: isMobile ? 'scroll' : 'hidden',
                                }}
                            >
                                <FormCalendar
                                    name='date'
                                    control={control}
                                    availability={availability}
                                    experience={experience}
                                    selected={date}
                                    onSelect={handleSelect}
                                    defaultMonth={
                                        new Date(getReservation?.startDate)
                                    }
                                    startMonth={new Date()}
                                    endMonth={endDate}
                                    limitedDates={limitedDates}
                                />
                                <Collapse in={alert !== ''}>
                                    <Alert severity='info' sx={{ mt: 1 }}>
                                        {alert}
                                    </Alert>
                                </Collapse>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>{' '}
                    <DialogActions
                        sx={{
                            borderTop: ' #d8d8d8 solid 1px',
                            // minHeight: '60px',
                            background: ' #eeeeee',
                        }}
                    >
                        <Grid>
                            {' '}
                            <CustomButton
                                variant='contained'
                                color='neutral'
                                // autoFocus
                                onClick={handleClose}
                            >
                                Cancel
                            </CustomButton>
                        </Grid>
                        <Grid>
                            {' '}
                            <CustomButton
                                variant='contained'
                                color='green'
                                type='submit'
                                disabled={dateSelectionError}
                            >
                                Save
                            </CustomButton>
                        </Grid>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}
