import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        custom: {
            columnHeader: string;
            columnBorder: string;
            cardActionBackground: string;
            modalActionBackground: string;
            modalActionBorder: string;
        };
    }
    interface PaletteOptions {
        custom?: {
            columnHeader?: string;
            columnBorder?: string;
            cardActionBackground?: string;
            modalActionBackground?: string;
            modalActionBorder?: string;
        };
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: '#5cb5b3',
            dark: '#5cb5b3',
            contrastText: '#fff',
        },
        info: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        text: {
            primary: '#787878',
        },
        custom: {
            columnHeader: '#000',
            columnBorder: '#787878',
            cardActionBackground: '#f6f8f9',
            modalActionBackground: '#eeeeee',
            modalActionBorder: '#d8d8d8',
        },
    },

    typography: {
        allVariants: {
            fontFamily: 'Open Sans',
        },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    color: '#787878',
                    fontFamily: 'Open Sans',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#5cb5b3',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
        },
        MuiDialogContent: {
            defaultProps: {
                dividers: true,
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    backgroundColor: '#eeeeee',
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    paddingBottom: 2,
                    color: '#787878',
                },
                asterisk: {
                    color: 'red',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#787878',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: '#787878',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        color: '#787878',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontFamily: 'Open Sans',
                },
            },
        },
    },
});
