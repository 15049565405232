import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import {
    useGetIndividualAssetQuery,
    useGetIndividualReservationQuery,
    useGetRecurringReservationQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import ConfirmationModal from '../../shared/ConfirmationModal';
import EditReservationForm from '../../components/Reservations/EditReservation/EditReservationForm';
import ViewIndividualReservation from '../../components/Reservations/ViewReservations/ViewIndividualReservation';
import React from 'react';
import ViewRecurringReservation from '../../components/Reservations/ViewReservations/ViewRecurringReservation';

export default function ExistingReservationsFormPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const urlRef = window.location.href.split('/')[5];
    const { data: getIndividualReservation, isFetching } =
        useGetIndividualReservationQuery(`/bookings/${urlRef}`);
    const { data: getRecurringReservation } = useGetRecurringReservationQuery(
        `/recurring-bookings/${urlRef}`
    );
    let moduleId = getRecurringReservation?.map((i: any) => i?.module.id);
    const { data: getIndividualAsset } = useGetIndividualAssetQuery(
        `modules/${moduleId?.[0]}`
    );
    const [open, setOpen] = useState(false);

    const onClickOpenModal = () => {
        setOpen(true);
    };
    const handleChangeModal = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '5%',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        {window.location.href?.includes('reservations') ? (
                            <>
                                <Typography
                                    component='h1'
                                    variant='h5'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        textAlign: 'left',
                                        textTransform: 'uppercase',
                                        fontWeight:
                                            window.location.href?.includes(
                                                'reservations'
                                            )
                                                ? '550'
                                                : 'none',
                                        paddingRight: '15px',
                                        textDecoration: 'underline',
                                        textDecorationColor: '#5cb5b3',
                                        textUnderlineOffset: '8px',
                                    }}
                                >
                                    {window.location.href?.includes(
                                        'ai-assistant'
                                    )
                                        ? `${getIndividualAsset?.name}`
                                        : getIndividualReservation?.module
                                              ?.name}
                                </Typography>
                            </>
                        ) : (
                            <Typography
                                component='h1'
                                variant='h5'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'left',
                                    textTransform: 'uppercase',
                                    textDecoration: 'underline',
                                    textDecorationColor: '#5cb5b3',
                                    textUnderlineOffset: '8px',
                                }}
                            >
                                Edit Reservation
                            </Typography>
                        )}
                    </Grid>

                    {window.location.href?.includes('editReservation') ? (
                        <EditReservationForm
                            reservation={getIndividualReservation}
                        />
                    ) : window.location.href?.includes('ai-assistant') ? (
                        <ViewRecurringReservation
                            reservationDetails={getRecurringReservation}
                            individualAsset={getIndividualAsset}
                        />
                    ) : (
                        <ViewIndividualReservation
                            reservation={getIndividualReservation}
                        />
                    )}
                </Grid>
            )}
            <ConfirmationModal
                open={open}
                onClose={handleCloseModal}
                onChange={handleChangeModal}
                message={'cancel this reservation'}
                component={'reservation'}
            />
        </Container>
    );
}
