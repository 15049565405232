import { Grid, useMediaQuery } from '@mui/material';
import { getAmenitiesIcon, getReadableAmenitiesName } from './AmenitiesMapping';
import React from 'react';

export default function AmenitiesComponent(assetInfo: any) {
    let value = assetInfo?.amenities;

    const motorbikeSpecificProperties = ['adjustableSeats', 'helmet', 'gloves'];
    const carSpecificProperties = [
        'ac',
        'keylessEntry',
        'sunroof',
        'laneAssist',
        'autoPark',
        'powerSeats',
        'remoteStart',
        'wirelessPhoneCharger',
        'rearEntertainmentSystem',
    ];

    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    return (
        <Grid
        container
        direction="row"
        spacing={1}
        sx={{
            width: isIpad ? '%' : '100%',
        }}
    >
        {(Object.keys(value) as string[])
            ?.filter((key) => value[key] === false || value[key] === true)
            .filter(
                (key) =>
                    key !== 'dropoffLocationSameAsPickup' &&
                    key !== 'dropoffUsesNirovision' &&
                    key !== 'petsAllowed' &&
                    key !== 'smokingAllowed'
            )
            .filter((key) => {
                if (value.subType === 'car') {
                    return !motorbikeSpecificProperties.includes(key);
                } else if (value.subType === 'motorbike') {
                    return !carSpecificProperties.includes(key);
                }
                return true;
            })
            .map((key: any) => (
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'row' : 'row', // Ensure it aligns horizontally for both
                        height: '50px',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Align items to the start
                    }}
                    item
                    xs={6} // 2 columns for mobile view
                    sm={4} // 3 columns for larger screens
                    key={key}
                >
                    <Grid
                        sx={{
                            width: '20%', // Increased width to give more space to the icon
                            display: 'flex',
                            justifyContent: 'center', // Center the icon
                            alignItems: 'center', // Ensure vertical centering of the icon
                        }}
                    >
                        {getAmenitiesIcon('icon', key)}
                    </Grid>
                    <Grid
                        sx={{
                            width: '80%', // The text area takes the remaining space
                            paddingLeft: isMobile
                                ? '10px'
                                : isIpad
                                ? '20px'
                                : '10px',
                            textDecoration: value[key] === true ? null : 'line-through',
                            color: '#787878',
                            display: 'flex',
                            alignItems: 'center', // Ensure vertical centering of the text
                        }}
                    >
                        {getReadableAmenitiesName('type', key)}
                    </Grid>
                </Grid>
            ))}
    </Grid>
    
    
    );
}
