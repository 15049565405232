import React, { useEffect, useState } from 'react';
import { ModalProps } from '../../../types/modal';

import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { User as MessagingUser } from '../../../types/messaging';
import { AddCircleRounded, RemoveModerator } from '@mui/icons-material';
import UserRole from '../../../../shared/AuthRoles';
import { useGetUserListQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { User } from '../../../types/user';
import { Channel, ChannelMemberResponse } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react';
import toast from '../../../shared/Toast';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';

interface MembersModalProps extends ModalProps {
    channel: Channel;
    members: Record<string, ChannelMemberResponse<DefaultStreamChatGenerics>>;
}

const MembersModal = ({
    channel,
    members,
    open,
    onClose,
}: MembersModalProps) => {
    const loggedInUser = UserRole();
    const URL = `/owners/${loggedInUser?.company}`;

    const [users, setUsers] = useState<MessagingUser[]>([]);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState<boolean>(false);

    const [pendingAddition, setPendingAddition] = useState<MessagingUser[]>([]);
    const [pendingRemoval, setPendingRemoval] = useState<MessagingUser[]>([]);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { data: companyUsers } = useGetUserListQuery(`${URL}/users`);

    // Filter out existing messaging users
    const availableUsers =
        companyUsers?.filter(
            (companyUser: User) =>
                !Object.entries(members).some(
                    ([id, _]) => +id === companyUser.id
                )
        ) || [];

    const handleSelect = (user: User) => {
        handleAdd(user);
        setMenuOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleSave = async () => {
        try {
            setSaving(true);

            if (pendingAddition.length > 0)
                await channel.addMembers(
                    pendingAddition.map((user) => ({
                        user_id: user.id,
                        channel_role: user.role,
                    }))
                );

            if (pendingRemoval.length > 0)
                await channel.removeMembers(
                    pendingRemoval.map((user) => user.id)
                );

            // cleanup
            setPendingAddition([]);
            setPendingRemoval([]);

            onClose();
            setSaving(false);

            toast.success(`Changes saved successfully.`);
        } catch {
            toast.error(`Error saving changes.`);
        }
    };

    const handleRole = (e: SelectChangeEvent, user: MessagingUser) => {
        const role = e.target.value;

        // Update the user's role in the state
        const updatedUsers = users.map((u) =>
            u.id === user.id ? { ...u, role } : u
        );

        // If the user was pending addition, update their role in pending addition as well
        const updatedPendingAddition = pendingAddition.map((u) =>
            u.id === user.id ? { ...u, role } : u
        );

        setUsers(updatedUsers);
        setPendingAddition(updatedPendingAddition);
    };

    const handleAdd = (user: User) => {
        const newUser: MessagingUser = {
            id: user.id.toString(),
            name: `${user.firstName} ${user.lastName}`,
            image: user.profilePicture?.src,
            role: 'channel_read_only',
            pending: true,
        };

        setUsers([...users, newUser]);
        setPendingAddition([...pendingAddition, newUser]);
    };

    const handleDelete = async (user: MessagingUser) => {
        setUsers(users.filter((u) => u !== user));
        setPendingAddition(pendingAddition.filter((u) => u !== user));

        if (!user.pending) setPendingRemoval([...pendingRemoval, user]);
    };

    useEffect(() => {
        const users: MessagingUser[] = Object.entries(members).map(
            ([id, member]) => {
                return {
                    id,
                    name: member.user?.name,
                    role: member.channel_role,
                    image: member.user?.image,
                    original_member: member.original_member as
                        | boolean
                        | undefined,
                    pending: false,
                };
            }
        );

        setUsers(users);

        setLoading(false);
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle variant='h6' fontWeight='bold'>
                Manage Users
            </DialogTitle>
            <DialogContent>
                <Typography variant='body2'>
                    Manage users associated with this channel. Add additional
                    users to view/respond to messages in this channel. Muted
                    users cannot respond to messages.
                </Typography>
                <Box display='flex' justifyContent='flex-end' py={2}>
                    <Button
                        size='small'
                        variant='contained'
                        onClick={handleClick}
                        startIcon={<AddCircleRounded />}
                    >
                        Member
                    </Button>
                    <Popover
                        open={menuOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <List disablePadding>
                            {availableUsers.length > 0 ? (
                                availableUsers.map((user: User) => (
                                    <ListItem
                                        key={user.id}
                                        disableGutters
                                        sx={{ p: 1 }}
                                    >
                                        <ListItemButton
                                            onClick={() => handleSelect(user)}
                                        >
                                            <ListItemText
                                                primary={`${user.firstName} ${user.lastName}`}
                                                secondary={user.email}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            ) : (
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='center'
                                    p={4}
                                >
                                    <Typography color='textSecondary'>
                                        No users available.
                                    </Typography>
                                </Box>
                            )}
                        </List>
                    </Popover>
                </Box>
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <>
                                    {[...Array(3)].map(() => (
                                        <TableRow>
                                            <TableCell>
                                                <Box
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <Box display='flex' gap={1}>
                                                        <Skeleton
                                                            variant='circular'
                                                            height={40}
                                                            width={40}
                                                        />
                                                        <Skeleton
                                                            height={24}
                                                            width={200}
                                                        />
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {users.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell>
                                                <Stack direction='row' gap={1}>
                                                    <Avatar
                                                        variant='rounded'
                                                        src={user.image}
                                                    >
                                                        {user.name?.charAt(0)}
                                                    </Avatar>
                                                    <Typography variant='body2'>
                                                        {user.name}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    size='small'
                                                    value={user.role}
                                                    onChange={(e) =>
                                                        handleRole(e, user)
                                                    }
                                                    disabled={
                                                        user.original_member
                                                    }
                                                    fullWidth
                                                >
                                                    <MenuItem value='channel_member'>
                                                        Member
                                                    </MenuItem>
                                                    <MenuItem value='channel_read_only'>
                                                        Read Only
                                                    </MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell padding='checkbox'>
                                                <IconButton
                                                    color='error'
                                                    disabled={
                                                        user.original_member
                                                    }
                                                    size='small'
                                                    onClick={() =>
                                                        handleDelete(user)
                                                    }
                                                >
                                                    <RemoveModerator />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    onClick={onClose}
                    variant='contained'
                    color='neutral'
                >
                    Close
                </CustomButton>
                <Button variant='contained' onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MembersModal;
