import { HostPayment } from '../app/Slices/SevenDayApiTypes';

export const capitalizeName = (name: any): string => {
    return name?.charAt(0).toUpperCase() + name?.slice(1);
};

export const filterItemsBySearch = (items: any, search: any) => {
    if (!search) return items;
    const searchLower = search.toLowerCase();

    const matchesSearch = (value: any, key?: string): boolean => {
        if (key === 'photos') {
            return false;
        }
        if (typeof value === 'string') {
            return value.toLowerCase().includes(searchLower);
        }
        if (typeof value === 'object' && value !== null) {
            return Object.entries(value).some(([k, v]) => matchesSearch(v, k));
        }
        return false;
    };

    return items.filter((item: any) => matchesSearch(item));
};

export const validatEmail = (email: any) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const validatePhone = (ph: any) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(
        ph
    );
};

export const validateName = (name: string) => {
    const nameRegex = /^[a-zA-Zà-ÿÀ-ÿ'-\s]+$/;
    return (
        nameRegex.test(name.trim()) &&
        name.trim().length >= 2 &&
        name.trim().length <= 50
    );
};

export const validateLicenceNo = (licenceNo: string) => {
    const minLength = 5;
    const maxLength = 15;
    const licenceRegex = /^[A-Za-z0-9]+$/;

    return (
        licenceNo.length >= minLength &&
        licenceNo.length <= maxLength &&
        licenceRegex.test(licenceNo)
    );
};
export const camelCaseToTitleCase = (input: string): string =>
    input
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, (str) => str.toUpperCase());

export const camelCaseToSentenceCase = (input: string): string => {
    const result = input.replace(/([A-Z])/g, ' $1').trim();
    return result?.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const camelCaseToLowerCase = (input: string): string => {
    const result = input.replace(/([A-Z])/g, ' $1').trim();
    return result?.charAt(0).toLowerCase() + result.slice(1).toLowerCase();
};

export const sum = (numbers: number[]): number =>
    numbers?.reduce((acc, curr) => acc + curr);

export const formatCurrency = (amount: number, long = true): string => {
    return `$${amount?.toLocaleString('en-AU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}${long ? ' AUD' : ''}`;
};

export const formatCurrencyShort = (amount: number): string => {
    return formatCurrency(amount, false);
};

export const getPaymentStatus = (
    payment: HostPayment
): 'Paid' | 'Unpaid' | '' => {
    return payment.amountDue ? 'Unpaid' : payment.amount ? 'Paid' : '';
};

export const formatTime = (time: string) => {
    return new Date('1970-01-01T' + time + 'Z').toLocaleTimeString('en-US', {
        timeZone: 'UTC',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
    });
};

export const handleHttpError = (error: any) => {
    if (!error?.status) {
        return 'Network error. Please check your connection.';
    }

    switch (error.status) {
        case 400:
            return error?.data?.message || 'Bad Request.';
        case 401:
            return 'Unauthorized. Please log in again.';
        case 403:
            return 'You do not have permission to perform this action.';
        case 404:
            return (
                error?.data?.message || 'The requested resource was not found.'
            );
        case 500:
            return 'Internal Server Error. Please try again later.';
        default:
            return 'An unexpected error occurred. Please tr again';
    }
};
