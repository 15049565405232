import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

interface FormMultiSelectProps {
    name: string;
    control: any;
    options: any[];
    label: string;
    getOptionLabel: (option: any) => string;
    isOptionEqualToValue: (option: any, value: any) => boolean;
}

const FormMultiSelect: React.FC<FormMultiSelectProps> = ({
    name,
    control,
    options = [],
    label,
    getOptionLabel,
    isOptionEqualToValue,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                    size='small'
                    multiple
                    filterSelectedOptions
                    options={options}
                    value={(Array.isArray(value) ? value : [])
                        .map((id: any) =>
                            options.find((option) => option.id === id)
                        )
                        .filter(Boolean)}
                    onChange={(_, selectedOptions) => {
                        onChange(selectedOptions.map((option) => option.id));
                    }}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            error={!!error}
                            helperText={error?.message}
                        />
                    )}
                    popupIcon={<KeyboardArrowDown />}
                />
            )}
        />
    );
};

export default FormMultiSelect;
