import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useEffect } from 'react';
import FiltersModal from '../../../shared/FiltersModal';
import ExperiencesListOwnerComponent from '../../../components/Experiences/Owner/ExperiencesListOwnerComponent';
import React from 'react';
import DateFilter from '../../../shared/DateFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectEndDate,
    selectStartDate,
} from '../../../../app/Slices/DateFilterSlice';
import {
    selectExperienceFilters,
    setExperienceFilters,
} from '../../../../app/Slices/ExperienceFiltersSlice';
import moment from 'moment';
import SearchFilterGeneric from '../../../shared/SearchFilterGeneric';
import {
    selectExperienceSearchFilter,
    setExperienceSearchFilter,
} from '../../../../app/Slices/ExperienceSearchSlice';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import UserRole from '../../../../shared/AuthRoles';
import { useGetIndividualOwnerQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';

export default function ViewOwnersExperiencesPage() {
    const role = UserRole();
    const experienceFilters = useSelector(selectExperienceFilters);
    const isMobile = useMediaQuery('(max-width: 900px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const searchValue = useSelector(selectExperienceSearchFilter);
    const dispatch = useDispatch();
    const urlRef = window.location.href.split('/')[4];
    const navigate = useNavigate();

    const {
        data: getIndividualCompany,
        isFetching,
        error,
    } = useGetIndividualOwnerQuery(`owners/${role?.company}`);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (startDate && endDate && startDate <= endDate) {
            dispatch(
                setExperienceFilters({
                    ...experienceFilters,
                    checkinDate: moment(startDate).format('YYYY-MM-DD'),
                    checkoutDate: moment(endDate).format('YYYY-MM-DD'),
                })
            );
        }
        if (startDate === undefined && endDate === undefined) {
            dispatch(
                setExperienceFilters({
                    ...experienceFilters,
                    checkinDate: '',
                    checkoutDate: '',
                })
            );
        }
    }, [startDate, endDate]);

    const handleSearchValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newSearchValue = event.target.value;
        dispatch(setExperienceSearchFilter(newSearchValue));
    };

    const handleVisitType = (event: any) => {
        navigate(
            `/viewExperiences/all/${getIndividualCompany?.name
                .replace(/ /g, '')
                .toLowerCase()}`
        );
        // if (event === 'business') {
        //     navigate(
        //         `/viewExperiences/business/${getIndividualCompany?.name
        //             .replace(/ /g, '')
        //             .toLowerCase()}`
        //     );
        // } else if (event === 'personal') {
        //     navigate(
        //         `/viewExperiences/personal/${getIndividualCompany?.name
        //             .replace(/ /g, '')
        //             .toLowerCase()}`
        //     );
        // } else {
        //     navigate(
        //         `/viewExperiences/all/${getIndividualCompany?.name
        //             .replace(/ /g, '')
        //             .toLowerCase()}`
        //     );
        // }
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        justifyContent: isMobile ? 'center' : 'left',
                        display: 'flex',
                        paddingBottom: '5px',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: isMobile ? 'center' : 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Manage Your Listings
                    </Typography>
                </Grid>{' '}
                {/* <Grid
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        paddingBottom: '20px',
                    }}
                >
                    <Grid
                        sx={{
                            padding: '0px 20px 0px 10px',
                        }}
                    >
                        <CustomButton
                            color='gray'
                            onClick={() => {
                                handleVisitType('personal');
                            }}
                            sx={{
                                fontWeight:
                                    urlRef === 'personal' ? 'bold' : 'none',
                                '&:hover': {
                                    color:
                                        urlRef === 'personal'
                                            ? '#787878'
                                            : '#fff',
                                    backgroundColor:
                                        urlRef === 'personal'
                                            ? '#fff'
                                            : '#5cb5b3',
                                    opacity: urlRef === 'personal' ? 1 : 0.8,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textTransform: 'capitalize',
                                    fontWeight:
                                        urlRef === 'personal' ? 'bold' : 'none',
                                    '&:hover': {
                                        color:
                                            urlRef === 'personal'
                                                ? '#787878'
                                                : '#fff',
                                    },
                                }}
                            >
                                Personal
                            </Typography>
                        </CustomButton>
                    </Grid>
                    <Grid
                        sx={{
                            padding: '0px 10px 0px 20px',
                            '&:hover': {
                                color: '#fff',
                            },
                        }}
                    >
                        <CustomButton
                            color='gray'
                            onClick={() => {
                                handleVisitType('business');
                            }}
                            sx={{
                                fontWeight:
                                    urlRef === 'business' ? 'bold' : 'none',
                                // color: '#fff',
                                '&:hover': {
                                    color:
                                        urlRef === 'business'
                                            ? '#787878'
                                            : '#fff',
                                    backgroundColor:
                                        urlRef === 'business'
                                            ? '#fff'
                                            : '#5cb5b3',
                                    opacity: urlRef === 'business' ? 1 : 0.8,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textTransform: 'capitalize',
                                    fontWeight:
                                        urlRef === 'business' ? 'bold' : 'none',
                                    '&:hover': {
                                        color:
                                            urlRef === 'business'
                                                ? '#787878'
                                                : '#fff',
                                    },
                                }}
                            >
                                corporate
                            </Typography>
                        </CustomButton>
                    </Grid>
                </Grid> */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: '5px',
                        justifyContent: 'center',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            borderStyle: 'solid',
                            borderWidth: 'thin',
                            borderColor: '#E0E0E0',
                            borderRadius: 4,
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: '15px',
                            padding: '15px 10px 15px 10px',
                            width: 'auto',
                            boxShadow: 1,
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                width: isMobile ? 'auto' : '300px',
                                paddingLeft: isMobile ? '0px' : '20px',
                            }}
                        >
                            <SearchFilterGeneric
                                handleSearchValueChange={
                                    handleSearchValueChange
                                }
                                value={searchValue}
                            />
                        </Grid>
                        <DateFilter />
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                width: 'auto',
                                paddingRight: '10px',
                            }}
                        >
                            <FiltersModal management={false} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        width: '100%',
                        minHeight: isMobile || isIpad ? '65%' : '95%',
                        // justifyContent: 'left',
                    }}
                >
                    {urlRef === 'business' ? (
                        <ExperiencesListOwnerComponent visitType='business' />
                    ) : urlRef === 'personal' ? (
                        <ExperiencesListOwnerComponent visitType='personal' />
                    ) : (
                        <ExperiencesListOwnerComponent />
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}
