import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import BookingCapForm from '../../components/BookingCaps/BookingCapForm';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import UserRole from '../../../shared/AuthRoles';

const AddBookingCapPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate(`/bookingCaps`);
    };

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'feesPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <BookingCapForm />
                <CustomButton
                    onClick={handleBackButton}
                    color='green'
                    sx={{
                        marginLeft: !isMobile ? '-8px' : '-18px',
                        '&:hover': {
                            cursor: 'pointer',
                            color: '#5cb5b3',
                            backgroundColor: 'transparent',
                            background: 'none',
                        },
                    }}
                >
                    <ArrowBackIcon />{' '}
                    {isMobile ? null : (
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back to list
                        </Typography>
                    )}
                </CustomButton>
            </Grid>
        </Container>
    );
};

export default AddBookingCapPage;
