import React from 'react'
import StreamMessaging from '../../components/Messages/Messaging';

const Messages = () => {
    return (
        <StreamMessaging />
    )
}

export default Messages;
