import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function BillingExplanation() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Invoices are issued on the first of each month for
                        bookings completed in the previous month. Guest
                        companies must set up their billing account to receive
                        invoices for completed bookings.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Payments can be made online upon receiving the invoice.
                        No credit card information is stored in 7DAY’s database.
                        All transactions are securely processed through our
                        integrated payment system.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
