import React, { useEffect, useState } from 'react';

import {
    Channel,
    Chat,
    MessageInput,
    MessageList,
    Thread,
    Window,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';

import MessagingSidebar from './Sidebar/MessagingSidebar';
import { StreamChat } from 'stream-chat';
import {
    Box,
    Toolbar,
    useMediaQuery,
} from '@mui/material';
import ChannelDetails from './ChannelDetails/ChannelDetails';
import ChannelHeader from './ChannelHeader/ChannelHeader';
import { useParams } from 'react-router-dom';
import ThreadHeader from './Threads/ThreadHeader';

const css = `
    .str-chat {
        --str-chat__primary-color: #5cb5b3;
        --str-chat__active-primary-color: #1c3636;
        --str-chat__surface-color: #ececec;
        --str-chat__secondary-surface-color: #eff8f7;
        --str-chat__primary-surface-color: #cee9e8;
        --str-chat__primary-surface-color-low-emphasis: #689797;
        --str-chat__border-radius-circle: 6px;
        --str-chat__avatar-background-color: #5cb5b3;
        --str-chat__message-bubble-color: #407f7d;
        --str-chat__card-attachment-color: #407f7d;
        --str-chat__font-family: 'Open Sans';
        --str-chat__own-message-reaction-background-color: #cee9e8;
    }

    .str-chat__message--pinned .str-chat__message-bubble::before {
        content: '📌';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: papayawhip;
        font-size: 0.6rem;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 9999px;
        z-index: 1;
        top: -10px;
    }
    
    .str-chat__message--other.str-chat__message--pinned .str-chat__message-bubble::before {
        right: -10px;
    }
    
    .str-chat__message--me.str-chat__message--pinned .str-chat__message-bubble::before {
        left: -10px;
    }

    .str-chat.messaging.light.str-chat__channel-list.str-chat__channel-list-react {
        position: absolute;
        z-index: 1;
    }
`;

const StreamMessaging = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const { id } = useParams();

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(
        isMobile ? !id : true
    );
    const [detailsOpen, setDetailsOpen] = useState<boolean>(
        !(isMobile || isIpad)
    );

    const handleSidebar = () => {
        if (isMobile) setSidebarOpen(false);
    };

    const handleDetails = () => {
        setDetailsOpen(!detailsOpen);

        // on Ipad close the sidebar when details is opened
        if (isIpad && !isMobile) {
            setSidebarOpen(!sidebarOpen);
        }
    };

    const client = StreamChat.getInstance(
        process.env.REACT_APP_STREAM_API_KEY as string
    );

    useEffect(() => setSidebarOpen(sidebarOpen), [sidebarOpen]);

    if (!client) return <div>Loading... </div>;

    return (
        <Box width='100%'>
            <style>{css}</style>
            <Chat client={client}>
                <MessagingSidebar open={sidebarOpen} onSelect={handleSidebar} />
                <Box
                    height='calc(100vh - 65px)'
                    ml={isMobile || (isIpad && detailsOpen) ? 0 : '400px'}
                    mr={detailsOpen ? '400px' : 0}
                    sx={{
                        transition: `margin-right 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                            margin-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
                    }}
                >
                    <Toolbar />
                    <Channel ThreadHeader={ThreadHeader}>
                        <Window>
                            <ChannelHeader
                                handleBack={() => setSidebarOpen(true)}
                                handleOpen={handleDetails}
                            />
                            <MessageList />
                            <MessageInput />
                        </Window>
                        <Thread />
                        <ChannelDetails
                            open={detailsOpen}
                            handleClose={handleDetails}
                        />
                    </Channel>
                </Box>
            </Chat>
        </Box>
    );
};

export default StreamMessaging;
