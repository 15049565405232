import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardMedia,
    Collapse,
    Divider,
    Fade,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    Paper,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setBookerObject } from '../../../../app/Slices/BookerSlice';
import { setDriverSameAsBooker } from '../../../../app/Slices/DriverSameAsBookerSlice';
import {
    resetDrivers,
    setMinimumLicenceRequired,
} from '../../../../app/Slices/DriversSlice';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useDispatch, useSelector } from 'react-redux';
import {
    useCalculateFeesMutation,
    useGetAllGuestAttendeesQuery,
    useGetAvailabilityQuery,
    useGetBookingCapAvailabilityMutation,
    useGetIndividualClientQuery,
    useGetProfileDetailsQuery,
    useSubmitReservationMutation,
} from '../../../../app/Slices/SevenDayApiSlice';

import { capitalizeName } from '../../../../shared/utils';

import { zodResolver } from '@hookform/resolvers/zod';
import { Add, KeyboardArrowDown } from '@mui/icons-material';
import {
    Controller,
    FieldValues,
    FormProvider,
    useForm,
} from 'react-hook-form';
import * as z from 'zod';
import {
    selectReservationPolicies,
    setReservationPolicies,
} from '../../../../app/Slices/ReservationPoliciesSlice';
import { selectSnackBars } from '../../../../app/Slices/SnackBarSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../../shared/AuthRoles';
import FormAutocomplete from '../../../shared/FormAutocomplete';
import FormCounter from '../../../shared/FormCounter';
import FormSelect from '../../../shared/FormSelect';
import FormTextField from '../../../shared/FormTextField';
import toast from '../../../shared/Toast';
import { ExperiencePolicy, ReservationPolicy } from '../../../types/policy';
import { User } from '../../../types/user';
import CalculateCharges from '../../Bookings/Fees/CalculateCharges';
import { TimeArray } from '../../Experiences/Owner/Property/TimeArray';
import Drivers from './Drivers/RHFDrivers';
import ReservationPolicies from './ReservationPolicies';
import { getDefaultValues, getRequestSchema } from './schema';

export const getAvailableSpaces = (
    commercialAvailability: Record<string, number> | null,
    experienceDetails: any
) =>
    commercialAvailability
        ? Object.keys(commercialAvailability)
              .filter((key) => key !== 'conflictingBookingIds')
              .map((key) => {
                  const isUnavailable = commercialAvailability[key] <= 0;
                  const keyToLabelMap: { [key: string]: string } = {
                      desks: 'Dedicated Desk',
                      privateOffices:
                          experienceDetails?.subType === 'house'
                              ? 'Room'
                              : 'Private Office',
                      meetingRooms: 'Meeting Room',
                  };
                  return {
                      value: key?.slice(0, -1),
                      label: `${keyToLabelMap[key] || key?.slice(0, -1)}${
                          isUnavailable ? ' (unavailable)' : ''
                      }`,
                      disabled: isUnavailable,
                  };
              })
        : [];

export default function ReservationRequestContent(params: any) {
    const toasts = useSelector(selectSnackBars);
    const loggedInUser = UserRole();

    const location = useLocation();

    let experienceDetails = params?.reservationDetails;
    let startDate = moment(location?.state?.[1]?.startDate).format(
        'DD/MM/YYYY'
    );
    let endDate = moment(location?.state?.[1]?.endDate).format('DD/MM/YYYY');

    const selectedSchema = getRequestSchema(
        experienceDetails,
        loggedInUser?.role
    );

    type Schema = z.infer<typeof selectedSchema>;

    const methods = useForm<Schema>({
        resolver: zodResolver(selectedSchema),
        mode: 'onChange',
        defaultValues: getDefaultValues(
            experienceDetails,
            startDate !== endDate,
            loggedInUser
        ),
        shouldUnregister: false,
        shouldFocusError: true,
    });

    const {
        control,
        watch,
        handleSubmit,
        setValue,
        getValues,
        formState: { isSubmitting, isDirty },
    } = methods;

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [warnings, setWarnings] = useState<Record<string, string>>({});

    const [calculateFees, { isLoading: isCalculating }] =
        useCalculateFeesMutation();
    const [fees, setFees] = useState<any>();
    const [requestReservation, { isLoading }] = useSubmitReservationMutation();

    const { data: guestUsers, isFetching: isFetchingGuestUsers } =
        useGetIndividualClientQuery(`/guests/${loggedInUser?.company}/users`);

    const { data: guestAttendees } = useGetAllGuestAttendeesQuery(
        loggedInUser?.company
    );

    let hasMassages = experienceDetails?.details?.massages;
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const bookerId = watch('booker.id');
    const spaceType = watch('bookingInfo.spaceType');
    const isMeetingRoom = watch('bookingInfo.reqMeetingRoom');
    const checkinTime = watch('bookingInfo.checkinTime');
    const checkoutTime = watch('bookingInfo.checkoutTime');
    const isWorkTrip = watch('bookingInfo.visitType');
    const isCatering = watch('bookingInfo.requiresCatering');
    const isMassage = watch('bookingInfo.requiresMassages');
    const isAlcohol = watch('bookingInfo.requiresBeverages');
    const cateringDetails = watch('bookingInfo.cateringNotes');
    const beverageDetails = watch('bookingInfo.beverageNotes');
    const massageDetails = watch('bookingInfo.massageNotes');
    const additionalNotes = watch('bookingInfo.notes');
    const adultCount = watch('bookingInfo.adults');
    const childCount = watch('bookingInfo.children');
    const overnightGuestCount = watch('bookingInfo.overnightGuests');
    const isOnBehalf = watch('onBehalf');
    const { data: profileDetails } = useGetProfileDetailsQuery('/profile');

    let beckFamilyCheck = profileDetails?.companyName
        ?.toLowerCase()
        .includes('beck');

    //check booking cap availability
    const [getBookingCapAvailability] = useGetBookingCapAvailabilityMutation();

    const [conflictError, setConflictError] = useState('');
    /* POLICY LOGIC */
    const policies = useSelector(selectReservationPolicies);

    useEffect(() => {
        //Reservation Policy
        const reservationPolicies: ReservationPolicy[] =
            experienceDetails?.policies?.map((policy: ExperiencePolicy) => ({
                policyId: policy.policyId,
                checked: false,
                checkedAt: undefined,
                name: policy.name,
                description: policy.description,
                requiresConsent: policy.requiresConsent,
            }));

        dispatch(setReservationPolicies(reservationPolicies));
        if (loggedInUser?.role === 'guest_user') {
            checkAvailability(loggedInUser?.sub);
        }
    }, []);

    let checkinT =
        location?.state?.[2]?.checkinFrom || experienceDetails?.checkinTime;
    let checkoutT =
        location?.state?.[2]?.checkoutBefore || experienceDetails?.checkoutTime;

    useEffect(() => {
        if (location?.state?.[2]) {
            setValue('bookingInfo.checkinTime', location.state[2].checkin);
            setValue('bookingInfo.checkoutTime', location.state[2].checkout);
        }

        if (location.state[3]) {
            setValue('bookingInfo.spaceType', location.state[3]);
        }
    }, [location]);

    useEffect(() => {
        if (experienceDetails?.visitType === 'personal') {
            setValue('bookingInfo.overnightGuests', adultCount + childCount);
        }
    }, [adultCount, childCount]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [maxGuestCountReached, setMaxGuestReached] = useState(false);

    // TODO: Change varaible name
    const MAX_GUEST_COUNT_TEST =
        adultCount + childCount >= experienceDetails?.details.maxGuests;
    const MAX_OVERNIGHT =
        overnightGuestCount >= experienceDetails?.details.overnightGuests;

    const [maxOvernightGuestCountReached, setMaxOvernightGuestReached] =
        useState(false);

    let min = experienceDetails.minHireHours;
    let max = experienceDetails.maxHireHours;
    let timeToAdd = moment(min, 'HH').format('HH:mm');
    let timeToSubtract = moment(max, 'HH').format('HH:mm');

    const {
        data: availability,
        error,
        isLoading: isLoadingAvailability,
    } = useGetAvailabilityQuery({
        url: `/modules/commercial/${experienceDetails.id}/get-availability`,
        body: {
            startDate: moment(location?.state?.[1]?.startDate).format(
                'YYYY-MM-DD'
            ),
            endDate: moment(location?.state?.[1]?.endDate).format('YYYY-MM-DD'),
            bookerId: watch('booker.id'),
        },
    });

    const availableSpaces = getAvailableSpaces(availability, experienceDetails);

    let compareCheckinSettings =
        startDate === endDate && checkoutT > checkinT
            ? TimeArray?.filter((element: any) => {
                  if (
                      experienceDetails?.visitType === 'business' &&
                      experienceDetails?.type === 'property'
                  ) {
                      return (
                          element.value >= checkinT &&
                          element.value <= checkoutT
                      );
                  } else if (
                      experienceDetails?.visitType === 'personal' &&
                      experienceDetails?.type === 'property'
                  ) {
                      return (
                          element.value >= checkinT &&
                          element.value <= checkoutT
                      );
                  } else if (
                      experienceDetails?.type === 'activity' ||
                      experienceDetails?.type === 'boat'
                  ) {
                      if (max >= 24) {
                          return (
                              element.value >= checkinT &&
                              element.value <= checkoutT
                          );
                      } else {
                          return (
                              element.value >= checkinT &&
                              element.value <= checkoutT &&
                              element.value <= subHours(checkoutT, timeToAdd)
                          );
                      }
                  } else {
                      return element.value >= checkinT;
                  }
              })
            : TimeArray?.filter((element: any) => {
                  return element.value >= checkinT;
              });

    const checkAvailability = (value: any) => {
        getBookingCapAvailability({
            moduleId: experienceDetails?.id,
            startDate: moment(location?.state?.[1]?.startDate).format(
                'YYYY-MM-DD'
            ),
            bookerId: value ?? loggedInUser.sub,
        }).then((data: any) => {
            if (data?.error?.status === 400) {
                const errorMessage =
                    data?.error?.data?.message +
                    (loggedInUser.role === 'guest_admin' ||
                    loggedInUser.role === 'guest_department_admin'
                        ? ' Please change the dates or the primary guest.'
                        : ' Please change the dates.');
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bookingCaps: errorMessage,
                }));
            } else {
                //Note: using delete errors.bookingCaps would not trigger a re-render
                setErrors((prevErrors) => {
                    const { bookingCaps, ...rest } = prevErrors;
                    return rest;
                });
            }
        });
    };

    function addHours(start: any, end: any) {
        var mins =
            moment.duration(start).asMinutes() +
            moment.duration(end).asMinutes();
        function z(n: any) {
            return (n < 10 ? '0' : '') + n;
        }
        var h = ((mins / 60) | 0) % 24;
        var m = mins % 60;
        return z(h) + ':' + z(m);
    }

    function subHours(start: any, end: any) {
        var mins =
            moment.duration(start).asMinutes() -
            moment.duration(end).asMinutes();
        function z(n: any) {
            return (n < 10 ? '0' : '') + n;
        }
        var h = ((mins / 60) | 0) % 24;
        var m = mins % 60;
        return z(h) + ':' + z(m);
    }

    let compareCheckoutSettings =
        startDate === endDate
            ? TimeArray?.filter((element: any) => {
                  let checkinTimeSelected =
                      checkinTime === '' ? checkinT : checkinTime;
                  if (
                      experienceDetails?.visitType === 'business' &&
                      experienceDetails?.type === 'property'
                  ) {
                      return (
                          element.value > checkinTimeSelected &&
                          element.value <= checkoutT
                      );
                  } else if (
                      experienceDetails?.visitType === 'personal' &&
                      experienceDetails?.type === 'property'
                  ) {
                      return (
                          element.value > checkinTimeSelected &&
                          element.value <= checkoutT
                      );
                  } else if (
                      experienceDetails?.type === 'activity' ||
                      experienceDetails?.type === 'boat'
                  ) {
                      if (max >= 24) {
                          //

                          return (
                              element.value > checkinTimeSelected &&
                              element.value <= checkoutT
                          );
                      } else {
                          return (
                              element.value > checkinTimeSelected &&
                              element.value <= checkoutT &&
                              element.value >=
                                  addHours(
                                      checkinTime ? checkinTime : checkinT,
                                      timeToAdd
                                  )
                          );
                      }
                  } else {
                      return (
                          element.value > checkinTimeSelected &&
                          element.value <= checkoutT
                      );
                  }
              })
            : TimeArray?.filter((element: any) => {
                  return element.value <= checkoutT;
              });

    const onClickGoBack = () => {
        navigate(
            `/viewExperiences/${experienceDetails?.type}/${experienceDetails?.id}`
        );
    };

    let newTimeSelection = subHours(checkoutTime, checkinTime);

    /* ---- CUSTOM VALIDATION ERRORS ---- */

    useEffect(() => {
        let errorMessage = '';

        if (!checkinTime || !checkoutTime) return;

        if (
            newTimeSelection < timeToAdd &&
            startDate === endDate &&
            checkinTime < checkoutTime
        ) {
            errorMessage = `Your selection is below the allowed hire time for this experience ${min} hrs`;
        } else if (
            newTimeSelection > timeToSubtract &&
            startDate === endDate &&
            checkinTime <= checkoutTime &&
            timeToSubtract !== '00:00'
        ) {
            errorMessage = `Your selection exceeds the allowed hire time for this experience ${min}-${max} hrs`;
        } else if (startDate === endDate && checkinTime > checkoutTime) {
            errorMessage = 'Checkin time cannot be greater than checkout time';
        }

        if (errorMessage) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                checkoutTime: errorMessage,
            }));
        } else {
            // remove checkoutTime from errors
            const { checkoutTime, ...rest } = errors;
            setErrors(rest);
        }
    }, [
        newTimeSelection,
        timeToAdd,
        timeToSubtract,
        startDate,
        endDate,
        checkinTime,
        checkoutTime,
        min,
        max,
    ]);

    useEffect(() => {
        checkAvailability(bookerId);
        const obj = guestUsers?.find((o: any) => o.id === bookerId);

        dispatch(setBookerObject(obj));

        if (
            obj &&
            !obj.profilePicture &&
            (experienceDetails?.usesNirovision ||
                experienceDetails?.details?.dropoffUsesNirovision)
        ) {
            setWarnings((prevWarnings) => ({
                ...prevWarnings,
                booker: `This experience uses facial recognition. The selected user does not have a profile picture, which may affect the check-in process.`,
            }));
        } else if (
            (experienceDetails?.usesNirovision ||
                experienceDetails?.details?.dropoffUsesNirovision) &&
            loggedInUser?.role === 'guest_user' &&
            !profileDetails?.profilePicture
        ) {
            setWarnings((prevWarnings) => ({
                ...prevWarnings,
                booker: `This experience uses facial recognition. You don't have a profile picture, which may affect the check-in process.`,
            }));
        } else {
            const { booker, ...rest } = warnings;
            setWarnings(rest);
        }
    }, [bookerId, guestUsers]);

    useEffect(() => {
        if (Object.keys(errors).includes('bookingCaps')) {
            toast.error(errors.bookingCaps, {
                duration: Infinity,
                customId: 'bookingCapWarning',
            });
        } else {
            toast.remove('bookingCapWarning');
        }
    }, [errors]);

    useEffect(() => {
        if (Object.keys(warnings).includes('booker')) {
            toast.warning(warnings.booker, {
                duration: Infinity,
                customId: 'faceRecWarning',
            });
        } else {
            toast.remove('faceRecWarning');
        }
    }, [warnings]);

    useEffect(() => {
        if (conflictError) {
            toast.error(conflictError, {
                customId: 'conflictError',
                duration: Infinity,
            });
        } else {
            toast.remove('conflictError');
        }
    }, [conflictError]);

    const checkIfOvernight = () => {
        if (
            startDate !== endDate &&
            experienceDetails?.visitType === 'personal'
        ) {
            return maxOvernightGuestCountReached;
        } else if (
            startDate === endDate &&
            experienceDetails?.visitType === 'personal'
        ) {
            return maxGuestCountReached;
        } else if (
            experienceDetails?.visitType === 'corporate ' ||
            startDate === endDate
        ) {
            return maxGuestCountReached;
        } else {
            return maxGuestCountReached;
        }
    };

    useEffect(() => {
        if (experienceDetails?.type === 'commercial' && !spaceType) {
            return;
        }
        calculateFees({
            bookerType: loggedInUser?.role.includes('owner')
                ? 'owner'
                : 'guest',
            module: { id: experienceDetails?.id },
            startDate: moment(location?.state?.[1]?.startDate).format(
                'YYYY-MM-DD'
            ),
            endDate: moment(location?.state?.[1]?.endDate).format('YYYY-MM-DD'),
            onBehalf: isOnBehalf,
            bookingInfo: {
                moduleType: experienceDetails?.type,
                visitType: isWorkTrip,
                checkinTime: !!checkinTime ? checkinTime : '08:00',
                checkoutTime: !!checkoutTime ? checkoutTime : '17:00',
                adults: adultCount ?? 0,
                children: childCount ?? 0,
                overnightGuests: overnightGuestCount ?? 0,
                requiresCatering: isCatering,
                cateringNotes: cateringDetails,
                requiresBeverages: isAlcohol,
                beverageNotes: beverageDetails,
                requiresMassages: isMassage,
                massageNotes: massageDetails,
                notes: additionalNotes,
                desks: spaceType === 'desk' ? 1 : 0,
                privateOffices: spaceType === 'privateOffice' ? 1 : 0,
                meetingRooms:
                    spaceType === 'meetingRoom' ||
                    (isMeetingRoom && spaceType !== 'entireVenue')
                        ? 1
                        : 0,
                entireVenue: spaceType === 'entireVenue',
                guests: adultCount,
                drivers: getValues('bookingInfo.drivers'),
                driverSameAsBooker: getValues('bookingInfo.driverSameAsBooker'),
            },
            policies: policies,
            staffAttending: [],
        }).then((data: any) => {
            if (data) {
                if (data?.error) {
                    console.error('there was a problem calculating fees');
                } else {
                    setFees(data.data);
                }
            }
        });
    }, [
        adultCount,
        childCount,
        isAlcohol,
        isCatering,
        checkinTime,
        checkoutTime,
        isMeetingRoom,
        overnightGuestCount,
        spaceType,
        isMassage,
    ]);

    useEffect(() => {
        setConflictError('');

        if (
            (availability?.desks < experienceDetails?.details?.desks ||
                availability?.privateOffices <
                    experienceDetails?.details?.privateOffices ||
                availability?.meetingRooms <
                    experienceDetails?.details?.meetingRooms) &&
            loggedInUser?.role?.includes('owner') &&
            !isOnBehalf
        ) {
            setConflictError(`You cannot block the selected
                                                        period. There are existing bookings
                                                        during this timeframe. Please choose
                                                        a different period.`);
        } else if (
            (availability?.desks < experienceDetails?.details?.desks ||
                availability?.privateOffices <
                    experienceDetails?.details?.privateOffices ||
                availability?.meetingRooms <
                    experienceDetails?.details?.meetingRooms) &&
            spaceType === 'entireVenue'
        ) {
            setConflictError(`You cannot book the entire venue. There are existing bookings
                during this timeframe. Please choose
                a different period.`);
        } else if (
            availability?.conflictingBookingIds?.length > 0 &&
            (loggedInUser?.role === 'guest_admin' ||
                loggedInUser?.role === 'guest_department_admin')
        ) {
            setConflictError(
                `You already have a booking for the selected dates. Please change the dates or the booker.`
            );
        } else if (
            availability?.conflictingBookingIds?.length > 0 &&
            loggedInUser?.role !== 'guest_admin' &&
            loggedInUser?.role !== 'guest_department_admin'
        ) {
            setConflictError(
                `You already have a booking for the selected dates. Please change the dates`
            );
        }
    }, [
        availability,
        experienceDetails?.details,
        loggedInUser?.role,
        isOnBehalf,
        spaceType,
    ]);

    const isDisabled = (spaceType: string) => {
        const spaces = availableSpaces.find(
            (space) => space.value === spaceType
        );

        return spaces ? spaces.disabled : false;
    };

    const commercialHasExistingBooking =
        availability?.desks < experienceDetails?.details?.desks ||
        availability?.privateOffices <
            experienceDetails?.details?.privateOffices ||
        availability?.meetingRooms < experienceDetails?.details?.meetingRooms;

    const fullAddress = `${experienceDetails?.streetAddress1} ${experienceDetails?.streetAddress2} ${experienceDetails?.suburb} ${experienceDetails?.postcode} ${experienceDetails?.state} ${experienceDetails?.countryCode}`;

    const renderFees = () => {
        if (fees) {
            if (loggedInUser?.role.includes('owner')) {
                if (isOnBehalf) {
                    return true;
                } else return false;
            } else {
                return true;
            }
        }
        return false;
    };
    useEffect(() => {
        dispatch(resetDrivers());
        dispatch(setDriverSameAsBooker(false));
        dispatch(
            setMinimumLicenceRequired(
                experienceDetails?.details?.minimumLicenceRequired
            )
        );
        dispatch(setBookerObject(profileDetails));
    }, []);

    const onSubmit = async (data: FieldValues) => {
        try {
            if (Object.keys(errors).length > 0 || conflictError) return;

            let additionalBookingInfo = {};

            if (experienceDetails?.type === 'commercial' && data?.bookingInfo) {
                additionalBookingInfo = {
                    checkinTime: experienceDetails?.checkinTime,
                    checkoutTime: experienceDetails?.checkoutTime,
                    desks: data.bookingInfo.spaceType === 'desk' ? 1 : 0,
                    privateOffices:
                        data.bookingInfo.spaceType === 'privateOffice' ? 1 : 0,
                    meetingRooms:
                        data.bookingInfo.spaceType === 'meetingRoom' ||
                        (data.bookingInfo.reqMeetingRoom &&
                            data.bookingInfo.spaceType !== 'entireVenue')
                            ? 1
                            : 0,
                    entireVenue: data.bookingInfo.spaceType === 'entireVenue',
                };
            }

            if (experienceDetails?.type === 'activity' && data?.bookingInfo) {
                additionalBookingInfo = { guests: data.bookingInfo.adults };
            }

            const request = {
                ...data,
                bookingInfo: {
                    moduleType: experienceDetails?.type,
                    overnightGuests: 0,
                    ...data.bookingInfo,
                    ...additionalBookingInfo,
                },
                bookerType: loggedInUser?.role.includes('owner')
                    ? 'owner'
                    : 'guest',
                module: { id: experienceDetails?.id },
                startDate: moment(location?.state?.[1]?.startDate).format(
                    'YYYY-MM-DD'
                ),
                endDate: moment(location?.state?.[1]?.endDate).format(
                    'YYYY-MM-DD'
                ),
                policies: data.policies ?? [],
                channelId: location?.state?.[4]
            };

            const response = await requestReservation(request).unwrap();

            toast.success(
                experienceDetails?.requiresBookingApproval
                    ? `Reservation request submitted`
                    : `Reservation submitted`
            );

            navigate(`/reservationRequest/submitted/${response.id}`, {
                state: {
                    page: 'singleReservation',
                    id: response.id,
                },
            });
        } catch (error: any) {
            if (error.data?.error === 'booking_cap_exceeded') {
                toast.error(`${error.data?.message}`);
            } else {
                toast.error(`Failed to submit reservation`);
            }
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid sx={{ height: '100%' }}>
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            paddingTop: '30px',
                            height: '100%',
                        }}
                    >
                        <Grid
                            sx={{
                                width: isMobile ? '100%' : '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                height: isMobile ? 'auto' : '90%',
                            }}
                        >
                            <Grid
                                sx={{
                                    height:
                                        isMobile || isIpad ? '50px' : '50px',
                                    width: '100%',
                                }}
                            >
                                {' '}
                                {experienceDetails.type === 'property' ||
                                experienceDetails.type === 'boat' ? (
                                    <Typography
                                        variant='h5'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {loggedInUser?.role.includes('owner')
                                            ? 'Trip Details'
                                            : 'Your Trip Details'}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant='h5'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {loggedInUser?.role.includes('owner')
                                            ? 'Experience Details'
                                            : 'Your Experience Details'}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                sx={{
                                    height: 'auto',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // backgroundColor: 'yellow',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingTop: '30px',
                                        paddingBottom: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {startDate === endDate ? 'Date' : 'Dates'}
                                </Typography>
                                <Grid
                                    sx={{
                                        paddingBottom: '10px',
                                    }}
                                >
                                    {' '}
                                    <Typography
                                        sx={{
                                            color: 'text.primary',
                                        }}
                                    >
                                        {startDate === endDate
                                            ? `${startDate}`
                                            : `${startDate} - ${endDate}`}
                                    </Typography>
                                    {loggedInUser?.role.includes('owner') &&
                                        !isOnBehalf &&
                                        (location?.state?.[2]?.checkinFrom ||
                                            location?.state?.[2]
                                                ?.checkoutBefore) && (
                                            <Alert
                                                severity='warning'
                                                sx={{
                                                    mt: 1,
                                                    borderRadius: 2,
                                                }}
                                            >
                                                There is an existing reservation
                                                within the selected dates
                                            </Alert>
                                        )}
                                </Grid>

                                {experienceDetails?.type === 'commercial' &&
                                    !(
                                        experienceDetails?.details?.subType ===
                                            'house' &&
                                        spaceType === 'privateOffice'
                                    ) && (
                                        <>
                                            {' '}
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingBottom: '10px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Time
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingBottom: '10px',
                                                }}
                                            >
                                                {`${experienceDetails?.checkinTime} - ${experienceDetails?.checkoutTime}`}
                                            </Typography>
                                            {!availability && (
                                                <Skeleton
                                                    variant='text'
                                                    sx={{ fontSize: '1rem' }}
                                                />
                                            )}
                                            {availability && (
                                                <>
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Availability
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {availability?.desks >
                                                            0 && (
                                                            <>
                                                                {' '}
                                                                Dedicated Desks:{' '}
                                                                <strong>
                                                                    {
                                                                        availability?.desks
                                                                    }
                                                                </strong>{' '}
                                                                -{' '}
                                                            </>
                                                        )}
                                                        {availability?.privateOffices >
                                                            0 && (
                                                            <>
                                                                {' '}
                                                                {experienceDetails
                                                                    ?.details
                                                                    ?.subType ===
                                                                'house'
                                                                    ? `Rooms:`
                                                                    : `Private Offices:`}{' '}
                                                                <strong>
                                                                    {
                                                                        availability?.privateOffices
                                                                    }
                                                                </strong>{' '}
                                                                -{' '}
                                                            </>
                                                        )}
                                                        {availability?.meetingRooms >
                                                            0 && (
                                                            <>
                                                                {' '}
                                                                Meeting Rooms:{' '}
                                                                <strong>
                                                                    {
                                                                        availability?.meetingRooms
                                                                    }
                                                                </strong>
                                                            </>
                                                        )}
                                                    </Typography>
                                                </>
                                            )}
                                        </>
                                    )}

                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    {capitalizeName(experienceDetails.type)}
                                    <i
                                        style={{
                                            fontSize: '5px',
                                            paddingRight: '5px',
                                            paddingLeft: '5px',
                                        }}
                                        className='bi bi-circle-fill'
                                    />
                                    {experienceDetails?.name}
                                    <i
                                        style={{
                                            fontSize: '5px',
                                            paddingRight: '5px',
                                            paddingLeft: '5px',
                                        }}
                                        className='bi bi-circle-fill'
                                    />
                                    <Link
                                        style={{ color: '#787878' }}
                                        target='_blank'
                                        to={`https://www.google.com/maps/place/${fullAddress}`}
                                    >
                                        {' '}
                                        {fullAddress}
                                    </Link>
                                </Typography>
                            </Grid>
                            <Divider sx={{ mt: '30px' }} />
                            <Grid
                                height={isMobile ? 'auto' : '70%'}
                                width='100%'
                                pt='30px'
                            >
                                <Grid height='60px'>
                                    <Typography
                                        variant='h5'
                                        color='textPrimary'
                                    >
                                        Additional Details Required
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        paddingRight: '5px',
                                        height: isMobile ? 'auto' : '420px',
                                    }}
                                >
                                    {loggedInUser?.role.includes('owner') ? (
                                        <Grid>
                                            <Grid pb='20px'>
                                                <InputLabel>
                                                    Is this reservation on
                                                    behalf of someone?
                                                </InputLabel>
                                                <FormSelect
                                                    name='onBehalf'
                                                    control={control}
                                                    defaultValue={false}
                                                    options={[
                                                        {
                                                            value: true,
                                                            label: 'Yes',
                                                        },
                                                        {
                                                            value: false,
                                                            label: 'No',
                                                        },
                                                    ]}
                                                />
                                            </Grid>
                                            {isOnBehalf === false ? (
                                                <Grid>
                                                    <Grid pb='20px'>
                                                        <InputLabel>
                                                            Reservation Reason
                                                        </InputLabel>
                                                        <FormSelect
                                                            name='reason'
                                                            control={control}
                                                            defaultValue='other'
                                                            options={[
                                                                {
                                                                    value: 'cleaning',
                                                                    label: 'Cleaning',
                                                                },
                                                                {
                                                                    value: 'maintenance',
                                                                    label: 'Maintenance',
                                                                },
                                                                {
                                                                    value: 'publicHoliday',
                                                                    label: 'Public Holiday',
                                                                },
                                                                {
                                                                    value: 'staffAway',
                                                                    label: 'Staff Away',
                                                                },
                                                                {
                                                                    value: 'shutdownPeriod',
                                                                    label: 'Shutdown Period',
                                                                },
                                                                {
                                                                    value: 'other',
                                                                    label: 'Other',
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid pb='20px'>
                                                        <Box
                                                            display='flex'
                                                            justifyContent='space-between'
                                                        >
                                                            <InputLabel
                                                                required={
                                                                    watch(
                                                                        'reason'
                                                                    ) ===
                                                                        'other' &&
                                                                    watch(
                                                                        'notes'
                                                                    ).length ===
                                                                        0
                                                                }
                                                            >
                                                                Additional Notes
                                                            </InputLabel>
                                                            {watch('notes')
                                                                ?.length >
                                                                0 && (
                                                                <Typography
                                                                    color='primary'
                                                                    variant='subtitle2'
                                                                >
                                                                    {`Characters left: ${
                                                                        300 -
                                                                        watch(
                                                                            'notes'
                                                                        ).length
                                                                    }`}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        <FormTextField
                                                            name='notes'
                                                            placeholder={
                                                                watch(
                                                                    'reason'
                                                                ) === 'other'
                                                                    ? "Please enter 'Other' reason"
                                                                    : ''
                                                            }
                                                            control={control}
                                                            multiline
                                                            rows={4}
                                                            inputProps={{
                                                                maxLength: 300,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <>
                                                    {experienceDetails.type ===
                                                        'commercial' && (
                                                        <>
                                                            {' '}
                                                            <Grid pb='20px'>
                                                                <InputLabel
                                                                    required={
                                                                        spaceType
                                                                            ? false
                                                                            : true
                                                                    }
                                                                >
                                                                    Workspace
                                                                    Type
                                                                </InputLabel>
                                                                <FormAutocomplete
                                                                    name='bookingInfo.spaceType'
                                                                    control={
                                                                        control
                                                                    }
                                                                    getOptionDisabled={(
                                                                        option: any
                                                                    ) =>
                                                                        (option.value ===
                                                                            'entireVenue' &&
                                                                            commercialHasExistingBooking) ||
                                                                        isDisabled(
                                                                            option.value
                                                                        )
                                                                    }
                                                                    options={[
                                                                        ...availableSpaces,
                                                                        {
                                                                            value: 'entireVenue',
                                                                            label: `Entire Venue ${
                                                                                commercialHasExistingBooking
                                                                                    ? `(unavailable)`
                                                                                    : ``
                                                                            }`,
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                            {spaceType !==
                                                                'meetingRoom' &&
                                                                spaceType !==
                                                                    'entireVenue' &&
                                                                spaceType !==
                                                                    '' &&
                                                                availability?.meetingRooms >
                                                                    0 && (
                                                                    <Grid pb='20px'>
                                                                        <InputLabel>
                                                                            Do
                                                                            you
                                                                            require
                                                                            a
                                                                            meeting
                                                                            room?
                                                                        </InputLabel>
                                                                        <FormSelect
                                                                            name='bookingInfo.reqMeetingRoom'
                                                                            control={
                                                                                control
                                                                            }
                                                                            defaultValue={
                                                                                false
                                                                            }
                                                                            options={[
                                                                                {
                                                                                    value: true,
                                                                                    label: 'Yes',
                                                                                },
                                                                                {
                                                                                    value: false,
                                                                                    label: 'No',
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                        </>
                                                    )}
                                                    {experienceDetails.type !==
                                                        'commercial' && (
                                                        <>
                                                            {experienceDetails?.type ===
                                                                'vehicle' && (
                                                                <Drivers />
                                                            )}
                                                            <Grid pb='20px'>
                                                                <Box
                                                                    display='flex'
                                                                    gap={0.5}
                                                                    alignItems='center'
                                                                >
                                                                    <InputLabel
                                                                        required={
                                                                            checkinTime
                                                                                ? false
                                                                                : true
                                                                        }
                                                                    >
                                                                        {experienceDetails?.type ===
                                                                        'vehicle'
                                                                            ? 'Expected Pickup Time'
                                                                            : 'Expected Arrival Time'}
                                                                    </InputLabel>
                                                                    <Typography
                                                                        color='textSecondary'
                                                                        fontSize='10px'
                                                                    >
                                                                        (From:{' '}
                                                                        {
                                                                            checkinT
                                                                        }
                                                                        )
                                                                    </Typography>
                                                                </Box>
                                                                <FormAutocomplete
                                                                    name='bookingInfo.checkinTime'
                                                                    control={
                                                                        control
                                                                    }
                                                                    options={
                                                                        compareCheckinSettings
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid pb='20px'>
                                                                <Box
                                                                    display='flex'
                                                                    gap={0.5}
                                                                    alignItems='center'
                                                                >
                                                                    <InputLabel
                                                                        required={
                                                                            checkoutTime
                                                                                ? false
                                                                                : true
                                                                        }
                                                                    >
                                                                        {experienceDetails?.type ===
                                                                        'vehicle'
                                                                            ? 'Expected Dropoff Time'
                                                                            : 'Expected Departure Time'}
                                                                    </InputLabel>
                                                                    <Typography
                                                                        color='textSecondary'
                                                                        fontSize='10px'
                                                                    >
                                                                        (Before:{' '}
                                                                        {
                                                                            checkoutT
                                                                        }
                                                                        )
                                                                    </Typography>
                                                                </Box>
                                                                <FormAutocomplete
                                                                    name='bookingInfo.checkoutTime'
                                                                    control={
                                                                        control
                                                                    }
                                                                    options={
                                                                        compareCheckoutSettings
                                                                    }
                                                                    customError={
                                                                        errors.checkoutTime
                                                                    }
                                                                />
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <>
                                                        {loggedInUser?.role.includes(
                                                            'guest'
                                                        ) ? null : (
                                                            <Grid pb='20px'>
                                                                <Box
                                                                    display='flex'
                                                                    gap={1}
                                                                    alignItems={
                                                                        'center'
                                                                    }
                                                                >
                                                                    <InputLabel>
                                                                        Type of
                                                                        Visit
                                                                    </InputLabel>
                                                                    {!loggedInUser?.role.includes(
                                                                        'owner'
                                                                    ) &&
                                                                        !beckFamilyCheck && (
                                                                            <Typography
                                                                                fontSize='10px'
                                                                                color='textSecondary'
                                                                            >
                                                                                (Note:
                                                                                You
                                                                                have
                                                                                selected
                                                                                a{' '}
                                                                                {isWorkTrip
                                                                                    ?.charAt(
                                                                                        0
                                                                                    )
                                                                                    ?.toUpperCase() +
                                                                                    isWorkTrip.slice(
                                                                                        1
                                                                                    )}{' '}
                                                                                experience
                                                                                and
                                                                                cannot
                                                                                change)
                                                                            </Typography>
                                                                        )}
                                                                </Box>
                                                                <FormAutocomplete
                                                                    name='bookingInfo.visitType'
                                                                    control={
                                                                        control
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: 'personal',
                                                                            label: 'Personal',
                                                                        },
                                                                        {
                                                                            value: 'business',
                                                                            label: 'Business',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </>

                                                    {experienceDetails?.type !==
                                                        'vehicle' &&
                                                        experienceDetails?.type !==
                                                            'commercial' && (
                                                            <Grid>
                                                                <Typography
                                                                    color='textPrimary'
                                                                    pb='10px'
                                                                >
                                                                    {experienceDetails?.type ===
                                                                        'activity' ||
                                                                    experienceDetails
                                                                        ?.details
                                                                        ?.subType ===
                                                                        'commercial' ||
                                                                    experienceDetails
                                                                        ?.details
                                                                        ?.subType ===
                                                                        'office space'
                                                                        ? 'How many guests will be attending?'
                                                                        : startDate !==
                                                                              endDate &&
                                                                          experienceDetails?.visitType ===
                                                                              'personal'
                                                                        ? 'How many guests will stay?'
                                                                        : startDate ===
                                                                              endDate &&
                                                                          experienceDetails?.visitType ===
                                                                              'personal'
                                                                        ? 'How many guests will attend for the day?'
                                                                        : experienceDetails?.visitType ===
                                                                              'corporate ' ||
                                                                          startDate ===
                                                                              endDate
                                                                        ? 'How many guests will attend for the day?'
                                                                        : 'How many day guests will attend?'}{' '}
                                                                    {adultCount !== 0|| childCount !==
                                                                    0 ? null : (
                                                                        <span
                                                                            style={{
                                                                                color: 'red',
                                                                            }}
                                                                        >
                                                                            *{' '}
                                                                        </span>
                                                                    )}{' '}
                                                                    {experienceDetails?.type ===
                                                                    'activity' ? null : (
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    '10px',
                                                                                color: checkIfOvernight()
                                                                                    ? 'red'
                                                                                    : '#787878',
                                                                            }}
                                                                        >
                                                                            (
                                                                            {checkIfOvernight()
                                                                                ? `Max ${
                                                                                      startDate !==
                                                                                          endDate &&
                                                                                      experienceDetails?.visitType ===
                                                                                          'personal'
                                                                                          ? `overnight guests reached`
                                                                                          : startDate ===
                                                                                                endDate &&
                                                                                            experienceDetails?.visitType ===
                                                                                                'personal'
                                                                                          ? `guests reached`
                                                                                          : experienceDetails?.visitType ===
                                                                                                'corporate ' ||
                                                                                            startDate ===
                                                                                                endDate
                                                                                          ? `guests reached`
                                                                                          : `guests reached`
                                                                                  }`
                                                                                : `Max ${
                                                                                      experienceDetails?.type ===
                                                                                      'activity'
                                                                                          ? `guests: ${experienceDetails?.details.maxGuests}`
                                                                                          : startDate !==
                                                                                                endDate &&
                                                                                            experienceDetails?.visitType ===
                                                                                                'personal'
                                                                                          ? `${experienceDetails?.details.overnightGuests} overnight guests`
                                                                                          : startDate ===
                                                                                                endDate &&
                                                                                            experienceDetails?.visitType ===
                                                                                                'personal'
                                                                                          ? `${experienceDetails?.details.maxGuests} guests`
                                                                                          : experienceDetails?.visitType ===
                                                                                                'corporate ' ||
                                                                                            startDate ===
                                                                                                endDate
                                                                                          ? `${experienceDetails?.details.maxGuests} guests`
                                                                                          : `${experienceDetails?.details.maxGuests} guests`
                                                                                  }`}
                                                                            )
                                                                        </span>
                                                                    )}
                                                                </Typography>
                                                                <Grid pb='20px'>
                                                                    <Stack
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Box
                                                                            display='flex'
                                                                            justifyContent='space-between'
                                                                        >
                                                                            <InputLabel>
                                                                                {experienceDetails?.type ===
                                                                                'activity'
                                                                                    ? 'Guests'
                                                                                    : 'Adults'}
                                                                            </InputLabel>
                                                                            <FormCounter
                                                                                name={
                                                                                    'bookingInfo.adults'
                                                                                }
                                                                                disabled={
                                                                                    MAX_GUEST_COUNT_TEST
                                                                                }
                                                                            />
                                                                        </Box>
                                                                        {experienceDetails?.type !==
                                                                            'activity' && (
                                                                            <Box
                                                                                display='flex'
                                                                                justifyContent='space-between'
                                                                            >
                                                                                <InputLabel>
                                                                                    Children
                                                                                </InputLabel>
                                                                                <FormCounter
                                                                                    name='bookingInfo.children'
                                                                                    disabled={
                                                                                        MAX_GUEST_COUNT_TEST
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    {experienceDetails?.type ===
                                                        'commercial' ||
                                                    experienceDetails?.type ===
                                                        'activity' ||
                                                    experienceDetails?.type ===
                                                        'vehicle' ||
                                                    experienceDetails?.visitType ===
                                                        'personal' ||
                                                    startDate ===
                                                        endDate ? null : (
                                                        <Grid pb='20px'>
                                                            <Typography
                                                                color='textPrimary'
                                                                pb='10px'
                                                            >
                                                                {startDate !==
                                                                    endDate && overnightGuestCount=== 0 &&(
                                                                    <span
                                                                        style={{
                                                                            color: 'red',
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                )}{' '}
                                                                How many guests
                                                                will sleep
                                                                overnight?{' '}
                                                                {experienceDetails?.type ===
                                                                'activity' ? null : (
                                                                    // {experienceDetails?.visitType === 'personal'}
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                '10px',
                                                                            color:
                                                                                maxOvernightGuestCountReached ===
                                                                                true
                                                                                    ? 'red'
                                                                                    : '#787878',
                                                                        }}
                                                                    >
                                                                        (
                                                                        {maxOvernightGuestCountReached ===
                                                                        true
                                                                            ? 'Max guests reached'
                                                                            : `Max ${experienceDetails?.details.overnightGuests} guests`}
                                                                        )
                                                                    </span>
                                                                )}
                                                            </Typography>
                                                            <Box
                                                                display='flex'
                                                                justifyContent='space-between'
                                                            >
                                                                <InputLabel>
                                                                    Overnight
                                                                    Guests
                                                                </InputLabel>
                                                                <FormCounter
                                                                    name='bookingInfo.overnightGuests'
                                                                    disabled={
                                                                        MAX_OVERNIGHT
                                                                    }
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {experienceDetails?.type ===
                                                        'activity' ||
                                                        (experienceDetails?.type ===
                                                            'vehicle' && (
                                                            <>
                                                                {experienceDetails
                                                                    ?.details
                                                                    ?.catering && (
                                                                    <>
                                                                        <Grid pb='20px'>
                                                                            <InputLabel>
                                                                                Do
                                                                                you
                                                                                require
                                                                                catering?
                                                                            </InputLabel>
                                                                            <FormSelect
                                                                                name='bookingInfo.requiresCatering'
                                                                                control={
                                                                                    control
                                                                                }
                                                                                options={[
                                                                                    {
                                                                                        value: true,
                                                                                        label: 'Yes',
                                                                                    },
                                                                                    {
                                                                                        value: false,
                                                                                        label: 'No',
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        </Grid>
                                                                        {watch(
                                                                            'bookingInfo.requiresCatering'
                                                                        ) && (
                                                                            <Grid pb='20px'>
                                                                                <Box
                                                                                    display='flex'
                                                                                    justifyContent='space-between'
                                                                                >
                                                                                    <InputLabel>
                                                                                        Catering
                                                                                        Details
                                                                                    </InputLabel>
                                                                                    {watch(
                                                                                        'bookingInfo.cateringNotes'
                                                                                    )
                                                                                        ?.length >
                                                                                        0 && (
                                                                                        <Typography
                                                                                            color='primary'
                                                                                            variant='subtitle2'
                                                                                        >
                                                                                            {`Characters left: ${
                                                                                                300 -
                                                                                                watch(
                                                                                                    'bookingInfo.cateringNotes'
                                                                                                )
                                                                                                    .length
                                                                                            }`}
                                                                                        </Typography>
                                                                                    )}
                                                                                </Box>
                                                                                <FormTextField
                                                                                    name='bookingInfo.cateringNotes'
                                                                                    placeholder='E.g. BBQ, salad and 1 person x vegan option'
                                                                                    control={
                                                                                        control
                                                                                    }
                                                                                    multiline
                                                                                    rows={
                                                                                        4
                                                                                    }
                                                                                    inputProps={{
                                                                                        maxLength: 300,
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {experienceDetails
                                                                    ?.details
                                                                    ?.alcohol && (
                                                                    <>
                                                                        <Grid pb='20px'>
                                                                            <InputLabel>
                                                                                Do
                                                                                you
                                                                                require
                                                                                alcohol?
                                                                            </InputLabel>
                                                                            <FormSelect
                                                                                name='bookingInfo.requiresBeverages'
                                                                                control={
                                                                                    control
                                                                                }
                                                                                options={[
                                                                                    {
                                                                                        value: true,
                                                                                        label: 'Yes',
                                                                                    },
                                                                                    {
                                                                                        value: false,
                                                                                        label: 'No',
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        </Grid>
                                                                        {watch(
                                                                            'bookingInfo.requiresBeverages'
                                                                        ) && (
                                                                            <Grid pb='20px'>
                                                                                <Box
                                                                                    display='flex'
                                                                                    justifyContent='space-between'
                                                                                >
                                                                                    <InputLabel>
                                                                                        Alcohol
                                                                                        Details
                                                                                    </InputLabel>
                                                                                    {watch(
                                                                                        'bookingInfo.beverageNotes'
                                                                                    )
                                                                                        ?.length >
                                                                                        0 && (
                                                                                        <Typography
                                                                                            color='primary'
                                                                                            variant='subtitle2'
                                                                                        >
                                                                                            {`Characters left: ${
                                                                                                200 -
                                                                                                watch(
                                                                                                    'bookingInfo.beverageNotes'
                                                                                                )
                                                                                                    .length
                                                                                            }`}
                                                                                        </Typography>
                                                                                    )}
                                                                                </Box>
                                                                                <FormTextField
                                                                                    name='bookingInfo.beverageNotes'
                                                                                    placeholder='E.g. Case of SuperDry, 4 White, 2 Reds'
                                                                                    control={
                                                                                        control
                                                                                    }
                                                                                    multiline
                                                                                    rows={
                                                                                        4
                                                                                    }
                                                                                    inputProps={{
                                                                                        maxLength: 200,
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ))}
                                                    {hasMassages && (
                                                        <>
                                                            <Grid pb='20px'>
                                                                <InputLabel>
                                                                    Do you
                                                                    require
                                                                    massages at
                                                                    our on-site
                                                                    spa?
                                                                </InputLabel>
                                                                <FormSelect
                                                                    name='bookingInfo.requiresMassages'
                                                                    control={
                                                                        control
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: true,
                                                                            label: 'Yes',
                                                                        },
                                                                        {
                                                                            value: false,
                                                                            label: 'No',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                            {watch(
                                                                'bookingInfo.requiresMassages'
                                                            ) && (
                                                                <Grid pb='20px'>
                                                                    <Box
                                                                        display='flex'
                                                                        justifyContent='space-between'
                                                                    >
                                                                        <InputLabel>
                                                                            Massage
                                                                            Details
                                                                        </InputLabel>
                                                                        {watch(
                                                                            'bookingInfo.massageNotes'
                                                                        )
                                                                            ?.length >
                                                                            0 && (
                                                                            <Typography
                                                                                color='primary'
                                                                                variant='subtitle2'
                                                                            >
                                                                                {`Characters left: ${
                                                                                    200 -
                                                                                    watch(
                                                                                        'bookingInfo.massageNotes'
                                                                                    )
                                                                                        .length
                                                                                }`}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                    <FormTextField
                                                                        name='bookingInfo.massageNotes'
                                                                        placeholder='3 x full body massages and 1 x facial starting at 12pm'
                                                                        control={
                                                                            control
                                                                        }
                                                                        multiline
                                                                        rows={4}
                                                                        inputProps={{
                                                                            maxLength: 200,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}
                                                    <Box
                                                        display='flex'
                                                        justifyContent='space-between'
                                                    >
                                                        <InputLabel>
                                                            Additional Notes
                                                        </InputLabel>
                                                        {watch(
                                                            'bookingInfo.notes'
                                                        )?.length > 0 && (
                                                            <Typography
                                                                color='primary'
                                                                variant='subtitle2'
                                                            >
                                                                {`Characters left: ${
                                                                    300 -
                                                                    watch(
                                                                        'bookingInfo.notes'
                                                                    ).length
                                                                }`}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                    <FormTextField
                                                        name='bookingInfo.notes'
                                                        control={control}
                                                        multiline
                                                        rows={4}
                                                        inputProps={{
                                                            maxLength: 300,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                    ) : (
                                        <>
                                            <>
                                                {(loggedInUser.role ===
                                                    'guest_admin' ||
                                                    loggedInUser.role ===
                                                        'guest_department_admin') && (
                                                    <Grid pb='20px'>
                                                        <InputLabel
                                                            required={
                                                                bookerId
                                                                    ? false
                                                                    : true
                                                            }
                                                        >
                                                            Who is this
                                                            reservation for?{' '}
                                                            <Tooltip
                                                                title={
                                                                    <Typography>
                                                                        <List>
                                                                            <ListItem>
                                                                                If
                                                                                you
                                                                                make
                                                                                a
                                                                                reservation
                                                                                on
                                                                                behalf
                                                                                of
                                                                                someone
                                                                                else,
                                                                                they
                                                                                will
                                                                                appear
                                                                                as
                                                                                the
                                                                                booker
                                                                                and
                                                                                you
                                                                                will
                                                                                both
                                                                                receive
                                                                                notifications
                                                                                for
                                                                                the
                                                                                reservation.
                                                                            </ListItem>
                                                                        </List>
                                                                    </Typography>
                                                                }
                                                                placement='right'
                                                            >
                                                                <HelpOutlineIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            '16px',
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </InputLabel>
                                                        <FormAutocomplete
                                                            name='booker.id'
                                                            control={control}
                                                            loading={
                                                                isFetchingGuestUsers
                                                            }
                                                            PaperComponent={({
                                                                children,
                                                            }) => {
                                                                return (
                                                                    <Paper>
                                                                        <Button
                                                                            fullWidth
                                                                            size='large'
                                                                            sx={{
                                                                                justifyContent:
                                                                                    'flex-start',
                                                                                pl: 2,
                                                                                mt: 1,
                                                                            }}
                                                                            startIcon={
                                                                                <Add />
                                                                            }
                                                                            onMouseDown={() =>
                                                                                navigate(
                                                                                    `/newUser`,
                                                                                    {
                                                                                        state: {
                                                                                            name: 'newUser',
                                                                                        },
                                                                                    }
                                                                                )
                                                                            }
                                                                        >
                                                                            Create
                                                                            New
                                                                            User
                                                                        </Button>
                                                                        {
                                                                            children
                                                                        }
                                                                    </Paper>
                                                                );
                                                            }}
                                                            options={
                                                                isFetchingGuestUsers
                                                                    ? []
                                                                    : guestUsers.map(
                                                                          (
                                                                              user: User
                                                                          ) => {
                                                                              return {
                                                                                  value: user.id,
                                                                                  label: `${user.firstName} ${user.lastName} (${user.email})`,
                                                                              };
                                                                          }
                                                                      )
                                                            }
                                                        />
                                                    </Grid>
                                                )}

                                                {experienceDetails.type ===
                                                    'commercial' &&
                                                    (!loggedInUser?.role?.includes(
                                                        'owner'
                                                    ) ||
                                                        isOnBehalf) && (
                                                        <>
                                                            <Grid pb='20px'>
                                                                <InputLabel
                                                                    required={
                                                                        spaceType
                                                                            ? false
                                                                            : true
                                                                    }
                                                                >
                                                                    Workspace
                                                                    Type
                                                                </InputLabel>
                                                                <FormAutocomplete
                                                                    name='bookingInfo.spaceType'
                                                                    control={
                                                                        control
                                                                    }
                                                                    getOptionDisabled={(
                                                                        option: any
                                                                    ) =>
                                                                        (option.value ===
                                                                            'entireVenue' &&
                                                                            commercialHasExistingBooking) ||
                                                                        isDisabled(
                                                                            option.value
                                                                        )
                                                                    }
                                                                    options={[
                                                                        ...availableSpaces,
                                                                        {
                                                                            value: 'entireVenue',
                                                                            label: `Entire Venue${
                                                                                commercialHasExistingBooking
                                                                                    ? ` (unavailable)`
                                                                                    : ``
                                                                            }`,
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                            <Collapse
                                                                in={
                                                                    spaceType &&
                                                                    spaceType !==
                                                                        'meetingRoom' &&
                                                                    spaceType !==
                                                                        'entireVenue' &&
                                                                    availability?.meetingRooms >
                                                                        0
                                                                }
                                                            >
                                                                {' '}
                                                                <Grid pb='20px'>
                                                                    <InputLabel>
                                                                        Do you
                                                                        require
                                                                        a
                                                                        meeting
                                                                        room?
                                                                    </InputLabel>
                                                                    <FormSelect
                                                                        name='bookingInfo.reqMeetingRoom'
                                                                        control={
                                                                            control
                                                                        }
                                                                        defaultValue={
                                                                            false
                                                                        }
                                                                        options={[
                                                                            {
                                                                                value: true,
                                                                                label: 'Yes',
                                                                            },
                                                                            {
                                                                                value: false,
                                                                                label: 'No',
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Grid>
                                                            </Collapse>
                                                        </>
                                                    )}
                                                {experienceDetails?.type ===
                                                    'vehicle' && <Drivers />}

                                                {experienceDetails.type !==
                                                    'commercial' && (
                                                    <>
                                                        <Grid pb='20px'>
                                                            <Box
                                                                display='flex'
                                                                gap={0.5}
                                                                alignItems='center'
                                                            >
                                                                <InputLabel
                                                                    required={
                                                                        checkinTime
                                                                            ? false
                                                                            : true
                                                                    }
                                                                >
                                                                    {experienceDetails?.type ===
                                                                    'vehicle'
                                                                        ? 'Expected Pickup Time'
                                                                        : 'Expected Arrival Time'}
                                                                </InputLabel>
                                                                <Typography
                                                                    color='textSecondary'
                                                                    fontSize='10px'
                                                                >
                                                                    (From:{' '}
                                                                    {checkinT})
                                                                </Typography>
                                                            </Box>
                                                            <FormAutocomplete
                                                                name='bookingInfo.checkinTime'
                                                                control={
                                                                    control
                                                                }
                                                                options={
                                                                    compareCheckinSettings
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid pb='20px'>
                                                            <Box
                                                                display='flex'
                                                                gap={0.5}
                                                                alignItems='center'
                                                            >
                                                                <InputLabel
                                                                    required={
                                                                        checkoutTime
                                                                            ? false
                                                                            : true
                                                                    }
                                                                >
                                                                    {experienceDetails?.type ===
                                                                    'vehicle'
                                                                        ? 'Expected Dropoff Time'
                                                                        : 'Expected Departure Time'}
                                                                </InputLabel>
                                                                <Typography
                                                                    color='textSecondary'
                                                                    fontSize='10px'
                                                                >
                                                                    (Before:{' '}
                                                                    {checkoutT})
                                                                </Typography>
                                                            </Box>
                                                            <FormAutocomplete
                                                                name='bookingInfo.checkoutTime'
                                                                control={
                                                                    control
                                                                }
                                                                options={
                                                                    compareCheckoutSettings
                                                                }
                                                                customError={
                                                                    errors.checkoutTime
                                                                }
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                            <>
                                                <Grid pb='20px'>
                                                    <Box
                                                        display='flex'
                                                        gap={1}
                                                        alignItems={'center'}
                                                    >
                                                        <InputLabel>
                                                            Type of Visit
                                                        </InputLabel>
                                                        {!loggedInUser?.role.includes(
                                                            'owner'
                                                        ) &&
                                                            !beckFamilyCheck && (
                                                                <Typography
                                                                    fontSize='10px'
                                                                    color='textSecondary'
                                                                >
                                                                    (Note: You
                                                                    have
                                                                    selected a{' '}
                                                                    {capitalizeName(
                                                                        isWorkTrip
                                                                    )}{' '}
                                                                    experience
                                                                    and cannot
                                                                    change)
                                                                </Typography>
                                                            )}
                                                    </Box>
                                                    <FormAutocomplete
                                                        name='bookingInfo.visitType'
                                                        control={control}
                                                        disabled={
                                                            !loggedInUser?.role.includes(
                                                                'owner'
                                                            ) &&
                                                            !beckFamilyCheck
                                                        }
                                                        options={[
                                                            {
                                                                value: 'personal',
                                                                label: 'Personal',
                                                            },
                                                            {
                                                                value: 'business',
                                                                label: 'Business',
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                            </>
                                            {experienceDetails?.type !==
                                                'vehicle' &&
                                                experienceDetails?.type !==
                                                    'commercial' && (
                                                    <Grid pb='20px'>
                                                        <Typography
                                                            color='textPrimary'
                                                            pb='10px'
                                                        >
                                                            {adultCount ||
                                                            childCount ? null : (
                                                                <span
                                                                    style={{
                                                                        color: 'red',
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            )}
                                                            {experienceDetails?.type ===
                                                                'activity' ||
                                                            experienceDetails
                                                                ?.details
                                                                ?.subType ===
                                                                'commercial' ||
                                                            experienceDetails
                                                                ?.details
                                                                ?.subType ===
                                                                'office space'
                                                                ? 'How many guests will be attending?'
                                                                : startDate !==
                                                                      endDate &&
                                                                  experienceDetails?.visitType ===
                                                                      'personal'
                                                                ? 'How many guests will stay?'
                                                                : startDate ===
                                                                      endDate &&
                                                                  experienceDetails?.visitType ===
                                                                      'personal'
                                                                ? 'How many guests will attend for the day?'
                                                                : experienceDetails?.visitType ===
                                                                      'corporate ' ||
                                                                  startDate ===
                                                                      endDate
                                                                ? 'How many guests will attend for the day?'
                                                                : 'How many day guests will attend?'}{' '}
                                                            {experienceDetails?.type ===
                                                            'activity' ? null : (
                                                                // {experienceDetails?.visitType === 'personal'}
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '10px',
                                                                        color: checkIfOvernight()
                                                                            ? 'red'
                                                                            : '#787878',
                                                                    }}
                                                                >
                                                                    (
                                                                    {checkIfOvernight()
                                                                        ? `Max ${
                                                                              startDate !==
                                                                                  endDate &&
                                                                              experienceDetails?.visitType ===
                                                                                  'personal'
                                                                                  ? `overnight guests reached`
                                                                                  : startDate ===
                                                                                        endDate &&
                                                                                    experienceDetails?.visitType ===
                                                                                        'personal'
                                                                                  ? `guests reached`
                                                                                  : experienceDetails?.visitType ===
                                                                                        'corporate ' ||
                                                                                    startDate ===
                                                                                        endDate
                                                                                  ? `guests reached`
                                                                                  : `guests reached`
                                                                          }`
                                                                        : `Max ${
                                                                              experienceDetails?.type ===
                                                                              'activity'
                                                                                  ? `guests: ${experienceDetails?.details.maxGuests}`
                                                                                  : startDate !==
                                                                                        endDate &&
                                                                                    experienceDetails?.visitType ===
                                                                                        'personal'
                                                                                  ? `${experienceDetails?.details.overnightGuests} overnight guests`
                                                                                  : startDate ===
                                                                                        endDate &&
                                                                                    experienceDetails?.visitType ===
                                                                                        'personal'
                                                                                  ? `${experienceDetails?.details.maxGuests} guests`
                                                                                  : experienceDetails?.visitType ===
                                                                                        'corporate ' ||
                                                                                    startDate ===
                                                                                        endDate
                                                                                  ? `${experienceDetails?.details.maxGuests} guests`
                                                                                  : `${experienceDetails?.details.maxGuests} guests`
                                                                          }`}
                                                                    )
                                                                </span>
                                                            )}
                                                        </Typography>
                                                        <Stack spacing={1}>
                                                            <Box
                                                                display='flex'
                                                                justifyContent='space-between'
                                                            >
                                                                <InputLabel>
                                                                    {experienceDetails?.type ===
                                                                    'activity'
                                                                        ? 'Guests'
                                                                        : 'Adults'}
                                                                </InputLabel>
                                                                <FormCounter
                                                                    name='bookingInfo.adults'
                                                                    disabled={
                                                                        MAX_GUEST_COUNT_TEST
                                                                    }
                                                                />
                                                            </Box>
                                                            {experienceDetails?.type !==
                                                                'activity' && (
                                                                <Box
                                                                    display='flex'
                                                                    justifyContent='space-between'
                                                                >
                                                                    <InputLabel>
                                                                        Children
                                                                    </InputLabel>
                                                                    <FormCounter
                                                                        name='bookingInfo.children'
                                                                        disabled={
                                                                            MAX_GUEST_COUNT_TEST
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Stack>
                                                    </Grid>
                                                )}
                                            {experienceDetails?.details
                                                ?.subType === 'commercial' ||
                                            experienceDetails?.details
                                                ?.subType === 'office space' ||
                                            experienceDetails?.type ===
                                                'activity' ||
                                            experienceDetails?.type ===
                                                'vehicle' ||
                                            experienceDetails?.type ===
                                                'commercial' ||
                                            experienceDetails?.visitType ===
                                                'personal' ||
                                            startDate === endDate ? null : (
                                                <Grid pb='20px'>
                                                    <Typography
                                                        color='textPrimary'
                                                        pb='10px'
                                                    >
                                                        {startDate !==
                                                            endDate &&
                                                            overnightGuestCount ===
                                                                0 && (
                                                                <span
                                                                    style={{
                                                                        color: 'red',
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            )}{' '}
                                                        How many guests will
                                                        sleep overnight?{' '}
                                                        {experienceDetails?.type ===
                                                        'activity' ? null : (
                                                            // {experienceDetails?.visitType === 'personal'}
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '10px',
                                                                    color:
                                                                    overnightGuestCount ===
                                                                        0
                                                                            ? 'red'
                                                                            : '#787878',
                                                                }}
                                                            >
                                                                (
                                                                {MAX_OVERNIGHT ===
                                                                true
                                                                    ? 'Max guests reached'
                                                                    : `Max ${experienceDetails?.details.overnightGuests} guests`}
                                                                )
                                                            </span>
                                                        )}
                                                    </Typography>
                                                    <Box
                                                        display='flex'
                                                        justifyContent='space-between'
                                                    >
                                                        <InputLabel>
                                                            Overnight Guests
                                                        </InputLabel>
                                                        <FormCounter
                                                            name='bookingInfo.overnightGuests'
                                                            disabled={
                                                                MAX_OVERNIGHT
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                            {experienceDetails?.visitType !==
                                                'personal' &&
                                                experienceDetails?.type !==
                                                    'vehicle' &&
                                                experienceDetails?.type !==
                                                    'commercial' && (
                                                    <Grid pb='20px'>
                                                        <InputLabel>
                                                            Staff Attending{' '}
                                                            <Tooltip
                                                                title={
                                                                    <Typography>
                                                                        <List>
                                                                            <ListItem>
                                                                                Which
                                                                                staff
                                                                                members
                                                                                will
                                                                                be
                                                                                attending
                                                                                this
                                                                                booking?
                                                                            </ListItem>
                                                                        </List>
                                                                    </Typography>
                                                                }
                                                                placement='right'
                                                            >
                                                                <HelpOutlineIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            '16px',
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </InputLabel>
                                                        <Controller
                                                            name='staffAttending'
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                                fieldState: {
                                                                    error,
                                                                },
                                                            }) => (
                                                                <Autocomplete
                                                                    size='small'
                                                                    disabled={
                                                                        watch(
                                                                            'bookingInfo.adults'
                                                                        ) === 0
                                                                    }
                                                                    onChange={(
                                                                        _,
                                                                        data
                                                                    ) =>
                                                                        onChange(
                                                                            data.map(
                                                                                (
                                                                                    d
                                                                                ) =>
                                                                                    d.value
                                                                            )
                                                                        )
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    multiple
                                                                    disableCloseOnSelect
                                                                    defaultValue={[]}
                                                                    filterSelectedOptions
                                                                    getOptionDisabled={(
                                                                        _
                                                                    ) =>
                                                                        value?.length >=
                                                                        watch(
                                                                            'bookingInfo.adults'
                                                                        )
                                                                    }
                                                                    isOptionEqualToValue={(
                                                                        option,
                                                                        value
                                                                    ) =>
                                                                        option
                                                                            .value
                                                                            .id ===
                                                                        value
                                                                            .value
                                                                            .id
                                                                    }
                                                                    options={
                                                                        guestAttendees?.map(
                                                                            (
                                                                                user: User
                                                                            ) => ({
                                                                                value: user,
                                                                                label: `${user.firstName} ${user.lastName} (${user.email})`,
                                                                            })
                                                                        ) || []
                                                                    }
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder={
                                                                                watch(
                                                                                    'bookingInfo.adults'
                                                                                ) ===
                                                                                0
                                                                                    ? 'No guests attending'
                                                                                    : ''
                                                                            }
                                                                            error={
                                                                                !!error
                                                                            }
                                                                            helperText={
                                                                                error &&
                                                                                error.message
                                                                            }
                                                                        />
                                                                    )}
                                                                    popupIcon={
                                                                        <KeyboardArrowDown />
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        {watch('staffAttending')
                                                            ?.length ===
                                                            watch(
                                                                'bookingInfo.adults'
                                                            ) && (
                                                            <FormHelperText>
                                                                Max occupancy
                                                                reached.
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                )}
                                            {experienceDetails?.type ===
                                                'activity' ||
                                            experienceDetails?.type ===
                                                'vehicle' ? null : (
                                                <>
                                                    {experienceDetails?.details
                                                        ?.catering && (
                                                        <>
                                                            <Grid pb='20px'>
                                                                <InputLabel>
                                                                    Do you
                                                                    require
                                                                    catering?
                                                                </InputLabel>
                                                                <FormSelect
                                                                    name='bookingInfo.requiresCatering'
                                                                    control={
                                                                        control
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: true,
                                                                            label: 'Yes',
                                                                        },
                                                                        {
                                                                            value: false,
                                                                            label: 'No',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                            {watch(
                                                                'bookingInfo.requiresCatering'
                                                            ) && (
                                                                <Grid pb='20px'>
                                                                    <Box
                                                                        display='flex'
                                                                        justifyContent='space-between'
                                                                    >
                                                                        <InputLabel>
                                                                            Catering
                                                                            Details
                                                                        </InputLabel>
                                                                        {watch(
                                                                            'bookingInfo.cateringNotes'
                                                                        )
                                                                            ?.length >
                                                                            0 && (
                                                                            <Typography
                                                                                color='primary'
                                                                                variant='subtitle2'
                                                                            >
                                                                                {`Characters left: ${
                                                                                    300 -
                                                                                    watch(
                                                                                        'bookingInfo.cateringNotes'
                                                                                    )
                                                                                        .length
                                                                                }`}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                    <FormTextField
                                                                        name='bookingInfo.cateringNotes'
                                                                        placeholder='E.g. BBQ, salad and 1 person x vegan option'
                                                                        control={
                                                                            control
                                                                        }
                                                                        multiline
                                                                        rows={4}
                                                                        inputProps={{
                                                                            maxLength: 300,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}
                                                    {experienceDetails?.details
                                                        ?.alcohol && (
                                                        <>
                                                            <Grid pb='20px'>
                                                                <InputLabel>
                                                                    Do you
                                                                    require
                                                                    alcohol?
                                                                </InputLabel>
                                                                <FormSelect
                                                                    name='bookingInfo.requiresBeverages'
                                                                    control={
                                                                        control
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: true,
                                                                            label: 'Yes',
                                                                        },
                                                                        {
                                                                            value: false,
                                                                            label: 'No',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                            {watch(
                                                                'bookingInfo.requiresBeverages'
                                                            ) && (
                                                                <Grid pb='20px'>
                                                                    <Box
                                                                        display='flex'
                                                                        justifyContent='space-between'
                                                                    >
                                                                        <InputLabel>
                                                                            Alcohol
                                                                            Details
                                                                        </InputLabel>
                                                                        {watch(
                                                                            'bookingInfo.beverageNotes'
                                                                        )
                                                                            ?.length >
                                                                            0 && (
                                                                            <Typography
                                                                                color='primary'
                                                                                variant='subtitle2'
                                                                            >
                                                                                {`Characters left: ${
                                                                                    200 -
                                                                                    watch(
                                                                                        'bookingInfo.beverageNotes'
                                                                                    )
                                                                                        .length
                                                                                }`}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                    <FormTextField
                                                                        name='bookingInfo.beverageNotes'
                                                                        placeholder='E.g. Case of SuperDry, 4 White, 2 Reds'
                                                                        control={
                                                                            control
                                                                        }
                                                                        multiline
                                                                        rows={4}
                                                                        inputProps={{
                                                                            maxLength: 200,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}
                                                    {hasMassages && (
                                                        <>
                                                            <Grid pb='20px'>
                                                                <InputLabel>
                                                                    Do you
                                                                    require
                                                                    massages at
                                                                    our on-site
                                                                    spa?
                                                                </InputLabel>
                                                                <FormSelect
                                                                    name='bookingInfo.requiresMassages'
                                                                    control={
                                                                        control
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: true,
                                                                            label: 'Yes',
                                                                        },
                                                                        {
                                                                            value: false,
                                                                            label: 'No',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                            {watch(
                                                                'bookingInfo.requiresMassages'
                                                            ) && (
                                                                <Grid pb='20px'>
                                                                    <Box
                                                                        display='flex'
                                                                        justifyContent='space-between'
                                                                    >
                                                                        <InputLabel>
                                                                            Massage
                                                                            Details
                                                                        </InputLabel>
                                                                        {watch(
                                                                            'bookingInfo.massageNotes'
                                                                        )
                                                                            ?.length >
                                                                            0 && (
                                                                            <Typography
                                                                                color='primary'
                                                                                variant='subtitle2'
                                                                            >
                                                                                {`Characters left: ${
                                                                                    200 -
                                                                                    watch(
                                                                                        'bookingInfo.massageNotes'
                                                                                    )
                                                                                        .length
                                                                                }`}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                    <FormTextField
                                                                        name='bookingInfo.massageNotes'
                                                                        placeholder='3 x full body massages and 1 x facial starting at 12pm'
                                                                        control={
                                                                            control
                                                                        }
                                                                        multiline
                                                                        rows={4}
                                                                        inputProps={{
                                                                            maxLength: 200,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            <Box
                                                display='flex'
                                                justifyContent='space-between'
                                            >
                                                <InputLabel>
                                                    Additional Notes
                                                </InputLabel>
                                                {watch('bookingInfo.notes')
                                                    ?.length > 0 && (
                                                    <Typography
                                                        color='primary'
                                                        variant='subtitle2'
                                                    >
                                                        {`Characters left: ${
                                                            300 -
                                                            watch(
                                                                'bookingInfo.notes'
                                                            ).length
                                                        }`}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <FormTextField
                                                name='bookingInfo.notes'
                                                control={control}
                                                multiline
                                                rows={4}
                                                inputProps={{
                                                    maxLength: 300,
                                                }}
                                            />
                                        </>
                                    )}
                                    {policies.length > 0 &&
                                        (!loggedInUser?.role?.includes(
                                            'owner'
                                        ) ||
                                            isOnBehalf) && (
                                            <ReservationPolicies />
                                        )}
                                    <Grid sx={{ paddingTop: '20px' }}>
                                        <Fade in={renderFees()} timeout={300}>
                                            <Grid>
                                                {renderFees() && (
                                                    <CalculateCharges
                                                        fees={fees}
                                                        moduleId={
                                                            experienceDetails?.id
                                                        }
                                                        moduleType={
                                                            experienceDetails?.type
                                                        }
                                                        overnightGuestCount={
                                                            overnightGuestCount
                                                        }
                                                    />
                                                )}{' '}
                                            </Grid>
                                        </Fade>
                                    </Grid>
                                    <Grid
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: 'auto',
                                            paddingTop: '20px',
                                            paddingBottom: isMobile
                                                ? '20px'
                                                : '50px',
                                        }}
                                    >
                                        <Grid sx={{ width: '50%' }}>
                                            {' '}
                                            <CustomButton
                                                onClick={onClickGoBack}
                                                color='green'
                                                sx={{
                                                    color: '#5cb5b3',
                                                    marginLeft: !isMobile
                                                        ? '-8px'
                                                        : '-18px',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        color: '#5cb5b3',
                                                        backgroundColor:
                                                            '#f6f8f9',
                                                    },
                                                }}
                                            >
                                                <ArrowBackIcon fontSize='small' />{' '}
                                                {isMobile ? null : (
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontSize: '14px',
                                                            paddingLeft: '5px',
                                                        }}
                                                    >
                                                        Back to experience
                                                    </Typography>
                                                )}
                                            </CustomButton>
                                        </Grid>

                                        <Grid
                                            sx={{
                                                width: '50%',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <CustomButton
                                                type='submit'
                                                fullWidth={isMobile}
                                                variant='contained'
                                                color='green'
                                                loading={isSubmitting}
                                                disabled={isSubmitting}
                                            >
                                                {loggedInUser?.role.includes(
                                                    'owner'
                                                ) ||
                                                !experienceDetails.requiresBookingApproval
                                                    ? 'Reserve'
                                                    : 'Request Reservation'}
                                            </CustomButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>{' '}
                        </Grid>

                        {isMobile ? null : (
                            <Grid
                                sx={{
                                    width: isIpad ? '50%' : '50%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                    }}
                                >
                                    <Card
                                        sx={{
                                            width: isIpad ? '400px' : '700px',
                                            boxShadow: 0,
                                            background: 'none',
                                            // backgroundColor: 'red',
                                            color: 'none',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#fff',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                            '&:focus': {
                                                cursor: 'pointer',
                                                color: '#fff',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                        }}
                                    >
                                        <CardMedia
                                            component='img'
                                            height={isIpad ? '300' : '500'}
                                            width='500'
                                            // image={'/experiences/placeholder.jpg'}
                                            image={
                                                experienceDetails.photos[0].src
                                            }
                                            alt='green iguana'
                                            sx={{ borderRadius: '8px' }}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}
