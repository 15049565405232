import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function ViewInvoices() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        How to view your invoices and make payments
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <br></br>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, select
                                "Admin Center" from the cog wheel icon and then
                                select "Billing".
                            </li>
                            <li>
                                Click on 'Invoices' to view your existing
                                invoices{' '}
                            </li>
                            <li>
                                To make a payment, click on each invoice and
                                then click the 'Pay Now' button. You will be
                                redirected to the payment page
                            </li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
