import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import {
    Box,
    Card,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setBookerObject } from '../../../../app/Slices/BookerSlice';
import {
    selectDriverSameAsBooker,
    setBookerIsSelected,
    setDriverSameAsBooker,
} from '../../../../app/Slices/DriverSameAsBookerSlice';
import {
    selectDrivers,
    selectDriversFormErrors,
    setDrivers,
    setMinimumLicenceRequired,
} from '../../../../app/Slices/DriversSlice';

import { selectFilteredReservationDateData } from '../../../../app/Slices/ReservationDateSlice';
import {
    useCalculateFeesMutation,
    useEditReservationMutation,
    useGetAllGuestAttendeesQuery,
    useGetAvailabilityQuery,
    useGetBookingCapAvailabilityMutation,
    useGetIndividualAssetQuery,
    useGetIndividualClientQuery,
    useGetProfileCompanyInfoQuery,
    useGetProfileDetailsQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../../layout/styledcomponents/filters/Dropdown';
import MultiSelectDropdownFilter from '../../../../layout/styledcomponents/filters/MultiSelectDropdown';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../../shared/AuthRoles';
import { useExitPrompt } from '../../../../shared/LeavingPageWarning';
import { capitalizeName, handleHttpError } from '../../../../shared/utils';
import toast from '../../../shared/Toast';
import { User } from '../../../types/user';
import BookingCharges from '../../Bookings/Fees/BookingCharges';
import { TimeArray } from '../../Experiences/Owner/Property/TimeArray';
import Drivers from '../ReservationRequest/Drivers/Drivers';
import CalendarModal from './CalendarModal';
import { FieldValues } from 'react-hook-form';

export default function EditReservationForm(params: any) {
    const [profilePictureRequired, setProfilePictureRequired] = useState(false);
    const [conflictError, setConflictError] = useState('');
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const [calculateFees, { isLoading: isCalculating }] =
        useCalculateFeesMutation();
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [fees, setFees] = useState<any>();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const updateDates: any = useSelector(selectFilteredReservationDateData);
    const [open, setOpen] = useState(false);

    const [editReservation] = useEditReservationMutation();

    const [reservationDetails, setReservationDetails] = useState(
        params?.reservation
    );
    const { data: getIndividualAsset, isFetching } = useGetIndividualAssetQuery(
        `modules/${reservationDetails?.module.id}`
    );

    useEffect(() => {
        // Artificial slight delay to ensure the loading skeleton displays for a short period.
        // This prevents the UI from appearing to lag or flicker by showing the skeleton even if the data loads quickly.
        let timer: any;
        if (isCalculating) {
            setShowSkeleton(true);
            timer = setTimeout(() => {
                setShowSkeleton(false);
            }, 500);
        } else {
            timer = setTimeout(() => {
                setShowSkeleton(false);
            }, 500);
        }

        return () => clearTimeout(timer);
    }, [isCalculating]);

    useEffect(() => {
        let timer: any;

        timer = setTimeout(() => {
            setIsFirstRender(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    if (reservationDetails === undefined) {
        navigate(`/permissionDenied`, {
            state: {
                name: 'editReservation',
            },
        });
        ReactGA.send({
            hitType: 'pageview',
            page: '/permissionDenied',
            title: 'Permission Denied',
        });
    }
    const { data: guestUsers, isFetching: isFetchingGuestUsers } =
        useGetIndividualClientQuery(`/guests/${loggedInUser?.company}/users`);

    const { data: guestAttendees } = useGetAllGuestAttendeesQuery(
        loggedInUser?.company
    );
    const { data: profileDetails } = useGetProfileDetailsQuery('/profile');

    const [booker, setBooker] = useState(
        `${reservationDetails?.booker.firstName} ${reservationDetails?.booker.lastName} (${reservationDetails?.booker.email})`
    );
    //check booking cap availability
    const [getBookingCapAvailability] = useGetBookingCapAvailabilityMutation();
    const [bookingCapError, setBookingCapError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(undefined);
    const [expandedPanel1, setExpandedPanel1] = useState<any>(true);
    const [expandedPanel2, setExpandedPanel2] = useState<any>(true);

    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [startDate, setStartDate] = useState(reservationDetails?.startDate);

    const [endDate, setEndDate] = useState(reservationDetails?.endDate);

    const [bookerType, setBookerType] = useState(
        reservationDetails?.bookerType
    );

    const [moduleType, setModuleType] = useState(
        reservationDetails?.bookingInfo?.moduleType
    );

    let [adultCount, setAdultCount] = useState(
        reservationDetails?.bookingInfo?.adults
    );
    let [guestCount, setGuestCount] = useState(
        reservationDetails?.bookingInfo?.guests
    );
    let [childCount, setChildCount] = useState(
        reservationDetails?.bookingInfo?.children
    );
    let [overnightGuestCount, setOvernightGuestCount] = useState(
        reservationDetails?.bookingInfo?.overnightGuests
    );

    const [maxGuestCountReached, setMaxGuestReached] = useState(
        adultCount + childCount >= getIndividualAsset?.details.maxGuests
    );
    const [maxOvernightGuestReached, setMaxOvernightGuestReached] = useState(
        overnightGuestCount >= getIndividualAsset?.details.overnightGuests
    );

    const [checkinTime, setCheckinTime] = useState(
        reservationDetails?.bookingInfo?.checkinTime
    );
    const [checkoutTime, setCheckoutTime] = useState(
        reservationDetails?.bookingInfo?.checkoutTime
    );
    const [isWorkTrip, setWorkTrip] = useState(
        reservationDetails?.bookingInfo?.visitType
    );
    const [requiresCatering, setRequiresCatering] = useState(
        reservationDetails?.bookingInfo?.requiresCatering
    );
    const [cateringDetails, setCateringDetails] = useState(
        reservationDetails?.bookingInfo?.cateringNotes
    );
    const [requiresBeverages, setRequiresBeverages] = useState(
        reservationDetails?.bookingInfo?.requiresBeverages
    );
    const [beverageDetails, setBeverageDetails] = useState(
        reservationDetails?.bookingInfo?.beverageNotes
    );
    const [massageDetails, setMassageDetails] = useState(
        reservationDetails?.bookingInfo?.massageNotes
    );
    const [additionalNotes, setAdditionalNotes] = useState(
        reservationDetails?.onBehalf === false
            ? reservationDetails?.notes
            : reservationDetails?.bookingInfo?.notes
    );
    const [isOnBehalf, setIsOnBehalf] = useState(reservationDetails?.onBehalf);
    const [reservationReason, setReservationReason] = useState(
        reservationDetails?.reason
    );

    const [bookerId, setBookerId] = useState(reservationDetails?.booker?.id);

    const driverSameAsBooker = useSelector(selectDriverSameAsBooker);
    const drivers = useSelector(selectDrivers);
    const driversFormErrors = useSelector(selectDriversFormErrors);
    const [spaceType, setSpaceType] = useState(
        reservationDetails?.bookingInfo?.entireVenue
            ? 'entireVenue'
            : !!reservationDetails?.bookingInfo?.desks
            ? 'desk'
            : !!reservationDetails?.bookingInfo?.privateOffices
            ? 'privateOffice'
            : 'meetingRoom'
    );
    const [isMeetingRoom, setIsMeetingRoom] = useState(
        !!reservationDetails?.bookingInfo?.meetingRooms
    );
    const [meetingRooms, setMeetingRooms] = useState(0);
    const [staffAttending, setStaffAttending] = useState<string[]>(
        reservationDetails?.staffAttending?.map((user: User) => user.id)
    );

    const [checkinTimeChanged, setCheckinTimeChanged] =
        useState<boolean>(false);
    const [checkoutTimeChanged, setCheckoutTimeChanged] =
        useState<boolean>(false);

    const [checkinFrom, setCheckinFrom] = useState<string>('');
    const [checkoutBefore, setCheckoutBefore] = useState<string>('');

    const fullAddress = `${getIndividualAsset?.streetAddress1} ${getIndividualAsset?.streetAddress2} ${getIndividualAsset?.suburb} ${getIndividualAsset?.postcode} ${getIndividualAsset?.state} ${getIndividualAsset?.countryCode}`;

    const keyToLabelMap: { [key: string]: string } = {
        desks: 'Dedicated Desk',
        privateOffices:
            getIndividualAsset?.details?.subType === 'house'
                ? 'Room'
                : 'Private Office',
        meetingRooms: 'Meeting Room',
    };

    const {
        data: availability,
        error,
        isLoading: isLoadingAvailability,
    } = useGetAvailabilityQuery({
        url: `/modules/commercial/${getIndividualAsset?.id}/get-availability`,
        body: {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            bookingId: reservationDetails?.id,
            bookerId,
        },
    });

    const availableSpaces = availability
        ? Object.keys(availability)
              .filter((key: any) => key !== 'conflictingBookingIds')
              .map((key: any) => {
                  const isUnavailable = availability[key] <= 0;
                  return {
                      value: key?.slice(0, -1),
                      label: `${keyToLabelMap[key] || key?.slice(0, -1)}${
                          isUnavailable ? ' (unavailable)' : ''
                      }`,
                      disabled: isUnavailable,
                  };
              })
        : [];

    let readableStartDate = moment(startDate).format('DD/MM/YYYY');

    let readableEndDate = moment(endDate).format('DD/MM/YYYY');

    let singleDate = moment(startDate).format('DD/MM/YYYY');

    let createdAtDate = moment(reservationDetails?.createdAtDate).format(
        'DD/MM/YYYY'
    );
    let approvedDate = moment(reservationDetails?.approvedAt).format(
        'DD/MM/YYYY'
    );

    useEffect(() => {
        setReservationDetails(params?.reservation);
        checkAvailability(startDate);
    }, [params?.reservation]);

    useEffect(() => {
        return () => {
            setShowExitPrompt(false);
        };
    }, []);

    let checkinT = getIndividualAsset?.checkinTime;
    let checkoutT = getIndividualAsset?.checkoutTime;
    let min = getIndividualAsset?.minHireHours;
    let max = getIndividualAsset?.maxHireHours;

    let timeToAdd = moment(min, 'HH').format('HH:mm');
    let timeToSubtract = moment(max, 'HH').format('HH:mm');

    let compareCheckinSettings =
        moment(startDate).format('YYYY-MM-DD') ===
            moment(endDate).format('YYYY-MM-DD') && checkoutT > checkinT
            ? TimeArray?.filter((element: any) => {
                  if (
                      getIndividualAsset?.visitType === 'business' &&
                      getIndividualAsset?.type === 'property'
                  ) {
                      return (
                          element.value >= checkinT &&
                          element.value <= checkoutT &&
                          element.value >= checkinFrom
                      );
                  } else if (
                      getIndividualAsset?.visitType === 'personal' &&
                      getIndividualAsset?.type === 'property'
                  ) {
                      return (
                          element.value >= checkinT &&
                          element.value <= checkoutT &&
                          element.value >= checkinFrom
                      );
                  } else if (
                      getIndividualAsset?.type === 'activity' ||
                      getIndividualAsset?.type === 'boat'
                  ) {
                      if (max >= 24) {
                          return (
                              element.value >= checkinT &&
                              element.value <= checkoutT &&
                              element.value >= checkinFrom
                          );
                      } else {
                          return (
                              element.value >= checkinT &&
                              element.value <= checkoutT &&
                              element.value <= subHours(checkoutT, timeToAdd) &&
                              element.value >= checkinFrom
                          );
                      }
                  } else {
                      return (
                          element.value >= checkinT &&
                          element.value >= checkinFrom
                      );
                  }
              })
            : TimeArray?.filter((element: any) => {
                  return (
                      element.value >= checkinT && element.value >= checkinFrom
                  );
              });

    const handleBooker = (value: Number | string) => {
        const obj = guestUsers.find((o: any) => o.id === value);
        dispatch(setBookerObject(obj));
        setBooker(`${obj.firstName} ${obj.lastName} (${obj.email})`);
        setBookerId(value);
        if (value === '') {
            navigate(`/newUser`, { state: { name: 'newUser' } });
            ReactGA.send({
                hitType: 'pageview',
                page: '/newUser',
                title: 'New User',
            });
        } else {
            checkAvailability(value);
            const obj = guestUsers.find((o: any) => o.id === value);
            if (
                !obj.profilePicture &&
                (getIndividualAsset?.usesNirovision ||
                    getIndividualAsset?.details?.dropoffUsesNirovision)
            ) {
                setProfilePictureRequired(true);
            } else {
                setProfilePictureRequired(false);
            }
            setBooker(`${obj.firstName} ${obj.lastName} (${obj.email})`);
            setBookerId(value);
        }
    };

    const handleSpaceType = (value: string) => {
        setSpaceType(value);
    };

    const checkAvailability = (value: any) => {
        let request = {};
        if (typeof value === 'number') {
            request = {
                moduleId: reservationDetails?.module?.id,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                bookerId: value,
                bookingId: reservationDetails?.id,
            };
        } else {
            request = {
                moduleId: reservationDetails?.module?.id,
                startDate: moment(value).format('YYYY-MM-DD'),
                bookerId: bookerId,
                bookingId: reservationDetails?.id,
            };
        }

        getBookingCapAvailability(request).then((data: any) => {
            if (data?.error?.status === 400) {
                setBookingCapError(true);
                setErrorMsg(data?.error?.data?.message);
            } else {
                setBookingCapError(false);
                setErrorMsg(undefined);
            }
        });
    };
    function addHours(start: any, end: any) {
        var mins =
            moment.duration(start).asMinutes() +
            moment.duration(end).asMinutes();
        function z(n: any) {
            return (n < 10 ? '0' : '') + n;
        }
        var h = ((mins / 60) | 0) % 24;
        var m = mins % 60;
        return z(h) + ':' + z(m);
    }

    function subHours(start: any, end: any) {
        var mins =
            moment.duration(start).asMinutes() -
            moment.duration(end).asMinutes();
        function z(n: any) {
            return (n < 10 ? '0' : '') + n;
        }
        var h = ((mins / 60) | 0) % 24;
        var m = mins % 60;
        return z(h) + ':' + z(m);
    }

    let compareCheckoutSettings =
        moment(startDate).format('YYYY-MM-DD') ===
        moment(endDate).format('YYYY-MM-DD')
            ? TimeArray?.filter((element: any) => {
                  let checkinTimeSelected =
                      checkinTime === '' ? checkinT : checkinTime;
                  if (
                      getIndividualAsset?.visitType === 'business' &&
                      getIndividualAsset?.type === 'property'
                  ) {
                      return (
                          element.value > checkinTimeSelected &&
                          element.value <= checkoutT &&
                          (!checkoutBefore || element.value <= checkoutBefore)
                      );
                  } else if (
                      getIndividualAsset?.visitType === 'personal' &&
                      getIndividualAsset?.type === 'property'
                  ) {
                      return (
                          element.value > checkinTimeSelected &&
                          element.value <= checkoutT &&
                          (!checkoutBefore || element.value <= checkoutBefore)
                      );
                  } else if (
                      getIndividualAsset?.type === 'activity' ||
                      getIndividualAsset?.type === 'boat'
                  ) {
                      if (max >= 24) {
                          return (
                              element.value > checkinTimeSelected &&
                              element.value <= checkoutT &&
                              (!checkoutBefore ||
                                  element.value <= checkoutBefore)
                          );
                      } else {
                          return (
                              element.value > checkinTimeSelected &&
                              element.value <= checkoutT &&
                              (!checkoutBefore ||
                                  element.value <= checkoutBefore) &&
                              //   element.value <=
                              //       addHours(
                              //           checkinTime ? checkinTime : checkinT,
                              //           timeToSubtract
                              //       )
                              //       &&
                              element.value >=
                                  addHours(
                                      checkinTime ? checkinTime : checkinT,
                                      timeToAdd
                                  )
                          );
                      }
                  } else {
                      return (
                          element.value > checkinTimeSelected &&
                          element.value <= checkoutT &&
                          (!checkoutBefore || element.value <= checkoutBefore)
                      );
                  }
              })
            : TimeArray?.filter((element: any) => {
                  return element.value <= checkoutT;
              });

    const onClickGoBack = () => {
        navigate(`/reservations`);
    };

    const onClickOpenModal = () => {
        setOpen(true);
    };
    const onSubmitDate = (data: FieldValues) => {
        setOpen(false);

        setStartDate(data.date?.from);
        checkAvailability(data.date?.from);
        setEndDate(data.date?.to);

        setCheckinFrom(data?.checkin);
        if (data?.checkin) {
            setCheckinTime(data?.checkin);
        }
        setCheckoutBefore(data?.checkout);
        if (data?.checkout) {
            setCheckoutTime(data?.checkout);
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleCheckinTime = (value: string) => {
        // formDetails.details['checkinTime'] = value;
        setCheckinTime(value);
        setCheckinTimeChanged(true);
    };
    const handleCheckoutTime = (value: string) => {
        // formDetails.details['checkoutTime'] = value;
        setCheckoutTime(value);
        setCheckoutTimeChanged(true);
    };

    const handleMaxGuestCount = () => {
        if (adultCount + childCount >= getIndividualAsset?.details.maxGuests) {
            setMaxGuestReached(true);
        } else {
            setMaxGuestReached(false);
        }
    };

    const handleMaxOvernightGuestCount = () => {
        if (
            overnightGuestCount >= getIndividualAsset?.details.overnightGuests
        ) {
            setMaxOvernightGuestReached(true);
        } else {
            setMaxOvernightGuestReached(false);
        }
    };

    function incrementAdultCount() {
        adultCount = adultCount + 1;
        setAdultCount(adultCount);
        if (getIndividualAsset?.type === 'activity') {
            //TBA
        } else {
            handleMaxGuestCount();
        }
    }
    function decrementAdultCount() {
        adultCount = adultCount - 1;
        setAdultCount(adultCount);
        if (getIndividualAsset?.type === 'activity') {
            //TBA
        } else {
            handleMaxGuestCount();
        }
    }

    function incrementGuestCount() {
        guestCount = guestCount + 1;
        setGuestCount(guestCount);
        if (getIndividualAsset?.type === 'activity') {
            //TBA
        } else {
            handleMaxGuestCount();
        }
    }
    function decrementGuestCount() {
        guestCount = guestCount - 1;
        setGuestCount(guestCount);
        if (reservationDetails?.type === 'activity') {
            //TBA
        } else {
            handleMaxGuestCount();
        }
    }

    function incrementChildCount() {
        childCount = childCount + 1;
        setChildCount(childCount);
        if (reservationDetails?.type === 'activity') {
            //TBA
        } else {
            handleMaxGuestCount();
        }
    }
    function decrementChildCount() {
        childCount = childCount - 1;
        setChildCount(childCount);
        if (reservationDetails?.type === 'activity') {
            //TBA
        } else {
            handleMaxGuestCount();
        }
    }

    function incrementOvernightGuestCount() {
        overnightGuestCount = overnightGuestCount + 1;
        setOvernightGuestCount(overnightGuestCount);

        handleMaxOvernightGuestCount();
    }
    function decrementOvernightGuestCount() {
        overnightGuestCount = overnightGuestCount - 1;
        setOvernightGuestCount(overnightGuestCount);

        handleMaxOvernightGuestCount();
    }

    const handleCateringDetails = (event: any) => {
        setCateringDetails(event?.target.value);
    };

    const handleBeverageDetails = (event: any) => {
        setBeverageDetails(event?.target.value);
    };
    const handleMassageDetails = (event: any) => {
        setMassageDetails(event?.target.value);
    };

    const handleAdditionalNotes = (event: any) => {
        setAdditionalNotes(event?.target.value);
    };

    const handleWorkTripStatus = (value: any) => {
        setWorkTrip(value);
    };

    const handleReservationReason = (value: string) => {
        setReservationReason(value);
    };

    const { data: getCompanyDetails } = useGetProfileCompanyInfoQuery(
        `/owners/${loggedInUser?.company}`
    );

    function getExperienceOwnedByLoggedInUser() {
        //disabled reserve button if the owner thats logged in clicks on another experience that belongs to another owner.Owners cannot book experiences from other companies
        if (loggedInUser?.role?.includes('owner')) {
            if (reservationDetails?.owner?.name === getCompanyDetails?.name) {
                return true;
            } else {
                return false;
            }
        } else {
            //THIS IS NOT AN OWNER
        }
    }

    const errors: any = [];

    let newTimeSelection = subHours(checkoutTime, checkinTime);

    if (staffAttending?.length > adultCount) {
        errors.push('Too many staff selected.');
    }

    if (
        reservationDetails?.module?.type === 'property' ||
        reservationDetails?.module?.type === 'boat'
    ) {
        if (checkinTime === '') {
            errors.push('Checkin Time cannot be empty');
        }
        if (checkoutTime === '') {
            errors.push('Checkin Time cannot be empty');
        }
        if (adultCount === 0) {
            errors.push(` Adults cannot be empty`);
        }
        if (
            overnightGuestCount === 0 &&
            moment(startDate).format('YYYY-MM-DD') !==
                moment(endDate).format('YYYY-MM-DD')
        ) {
            errors.push(
                'This reservation is an overnight stay, please specify how many people will be staying the night'
            );
        }
    } else {
        if (guestCount === 0) {
            errors.push(` Guests cannot be empty`);
        }
        if (checkoutTimeChanged || checkinTimeChanged === true) {
            if (
                moment(startDate).format('YYYY-MM-DD') ===
                    moment(endDate).format('YYYY-MM-DD') &&
                newTimeSelection < timeToAdd === true
            ) {
                errors.push(
                    'Checkin and Checkout under the min required hire time'
                );
            }
        }
    }

    if (
        newTimeSelection < timeToAdd &&
        moment(startDate).format('YYYY-MM-DD') ===
            moment(endDate).format('YYYY-MM-DD') &&
        timeToSubtract !== '00:00' &&
        (isOnBehalf || bookerType !== 'owner')
    ) {
        errors.push(
            `Your selection is below the allowed hire time for this experience ${min}hrs`
        );
    }

    if (
        newTimeSelection > timeToSubtract &&
        moment(startDate).format('YYYY-MM-DD') ===
            moment(endDate).format('YYYY-MM-DD') &&
        timeToSubtract !== '00:00'
    ) {
        errors.push(
            `Your selection exceeds the allowed hire time for this experience ${min}-${max} hrs`
        );
    }
    if (
        moment(startDate).format('YYYY-MM-DD') ===
            moment(endDate).format('YYYY-MM-DD') &&
        checkinTime > checkoutTime
    ) {
        errors.push(`Checkin time cannot be greater than checkout time`);
    }
    if (
        driversFormErrors.length > 0 &&
        getIndividualAsset?.type === 'vehicle' &&
        (isOnBehalf || !loggedInUser?.role?.includes('owner'))
    ) {
        errors.push('Please fill in all the required driver information');
    }

    useEffect(() => {
        if (isMeetingRoom) {
            setMeetingRooms(1);
        } else if (!isMeetingRoom) {
            setMeetingRooms(0);
        }
        if (spaceType === 'meetingRoom') {
            setMeetingRooms(1);
        }
    }, [spaceType, isMeetingRoom]);
    if (
        loggedInUser?.role?.includes('owner') &&
        !isOnBehalf &&
        reservationReason === 'other' &&
        additionalNotes === ''
    ) {
        errors.push("Enter 'Other' reason why");
    }

    const ownerRequestNotOnBehalf = {
        bookerType: bookerType,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        onBehalf: false,
        reason: reservationReason,
        notes: additionalNotes,
    };

    const ownerRequestOnBehalf = {
        bookerType: bookerType,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        onBehalf: true,
        bookingInfo:
            moduleType === 'property'
                ? {
                      moduleType: moduleType,
                      visitType: isWorkTrip,
                      checkinTime: checkinTime,
                      checkoutTime: checkoutTime,
                      adults: adultCount,
                      children: childCount,
                      overnightGuests: overnightGuestCount,
                      requiresBeverages,
                      requiresCatering,
                      cateringNotes: requiresCatering ? cateringDetails : '',
                      beverageNotes: requiresBeverages ? beverageDetails : '',
                      massageNotes: massageDetails,
                      notes: additionalNotes,
                  }
                : moduleType === 'boat'
                ? {
                      moduleType: moduleType,
                      visitType: isWorkTrip,
                      checkinTime: checkinTime,
                      checkoutTime: checkoutTime,
                      adults: adultCount,
                      children: childCount,
                      overnightGuests: overnightGuestCount,
                      requiresBeverages,
                      requiresCatering,
                      cateringNotes: requiresCatering ? cateringDetails : '',
                      beverageNotes: requiresBeverages ? beverageDetails : '',
                      notes: additionalNotes,
                  }
                : moduleType === 'activity'
                ? {
                      moduleType: moduleType,
                      visitType: isWorkTrip,
                      guests: guestCount,
                      checkinTime: checkinTime,
                      checkoutTime: checkoutTime,
                      notes: additionalNotes,
                  }
                : moduleType === 'commercial'
                ? {
                      moduleType: moduleType,
                      visitType: isWorkTrip,
                      checkinTime: getIndividualAsset?.details?.checkinTime,
                      checkoutTime: getIndividualAsset?.details?.checkoutTime,
                      notes: additionalNotes,
                      desks: spaceType === 'desk' ? 1 : 0,
                      privateOffices: spaceType === 'privateOffice' ? 1 : 0,
                      meetingRooms:
                          spaceType === 'entireVenue' ? 0 : meetingRooms,
                      entireVenue: spaceType === 'entireVenue',
                  }
                : moduleType === 'vehicle'
                ? {
                      moduleType: moduleType,
                      visitType: isWorkTrip,
                      driverSameAsBooker,
                      drivers,
                      checkinTime: checkinTime,
                      checkoutTime: checkoutTime,
                      notes: additionalNotes,
                  }
                : {
                      moduleType: moduleType,
                      visitType: isWorkTrip,
                      checkinTime: checkinTime,
                      checkoutTime: checkoutTime,
                      notes: additionalNotes,
                  },
    };

    const boatRequest = {
        bookerType: bookerType,
        booker: { id: bookerId },
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        bookingInfo: {
            moduleType: moduleType,
            visitType: isWorkTrip,
            checkinTime: checkinTime,
            checkoutTime: checkoutTime,
            adults: adultCount,
            children: childCount,
            overnightGuests: overnightGuestCount,
            requiresBeverages,
            requiresCatering,
            cateringNotes: requiresCatering ? cateringDetails : '',
            beverageNotes: requiresBeverages ? beverageDetails : '',
            notes: additionalNotes,
        },
        staffAttending: staffAttending?.map((userId) => ({ id: userId })),
    };

    const propertyRequest = {
        bookerType: bookerType,
        booker: { id: bookerId },
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        bookingInfo: {
            checkinTime: checkinTime,
            checkoutTime: checkoutTime,
            moduleType: moduleType,
            visitType: isWorkTrip,
            notes: additionalNotes,
            adults: adultCount,
            children: childCount,
            overnightGuests: overnightGuestCount,
            requiresBeverages,
            requiresCatering,
            cateringNotes: requiresCatering ? cateringDetails : '',
            beverageNotes: requiresBeverages ? beverageDetails : '',
            massageNotes: massageDetails,
        },
        staffAttending: staffAttending?.map((userId) => ({ id: userId })),
    };

    const activityRequest = {
        bookerType: bookerType,
        booker: { id: bookerId },
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        bookingInfo: {
            moduleType: moduleType,
            visitType: isWorkTrip,
            driverSameAsBooker,
            drivers,
            checkinTime: checkinTime,
            checkoutTime: checkoutTime,
            guests: guestCount,
            notes: additionalNotes,
        },
        staffAttending: staffAttending?.map((userId) => ({ id: userId })),
    };
    const vehicleRequest = {
        bookerType: bookerType,
        booker: { id: bookerId },
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        bookingInfo: {
            moduleType: moduleType,
            visitType: isWorkTrip,
            driverSameAsBooker,
            drivers,
            checkinTime: checkinTime,
            checkoutTime: checkoutTime,
            guests: guestCount,
            notes: additionalNotes,
        },
        staffAttending: staffAttending?.map((userId) => ({ id: userId })),
    };

    const commercialRequest = {
        bookerType: bookerType,
        booker: { id: bookerId },

        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        bookingInfo: {
            moduleType: moduleType,
            // visitType: isWorkTrip === true ? 'business' : 'personal',
            checkinTime: getIndividualAsset?.details?.checkinTime,
            checkoutTime: getIndividualAsset?.details?.checkoutTime,
            visitType: isWorkTrip,
            notes: additionalNotes,
            desks: spaceType === 'desk' ? 1 : 0,
            privateOffices: spaceType === 'privateOffice' ? 1 : 0,
            meetingRooms: spaceType === 'entireVenue' ? 0 : meetingRooms,
            entireVenue: spaceType === 'entireVenue',
        },
    };

    const dispatch = useDispatch();

    const updateReservation = async () => {
        let requestBody = {};
        if (bookerType === 'owner') {
            if (isOnBehalf === true) {
                requestBody = ownerRequestOnBehalf;
            } else {
                requestBody = ownerRequestNotOnBehalf;
            }
        } else {
            if (moduleType === 'boat') {
                requestBody = boatRequest;
            } else if (moduleType === 'property') {
                requestBody = propertyRequest;
            } else if (moduleType === 'commercial') {
                requestBody = commercialRequest;
            } else if (moduleType === 'activity') {
                requestBody = activityRequest;
            } else {
                requestBody = vehicleRequest;
            }
        }

        let url = `/bookings/${reservationDetails?.id}`;
        let reqInfo = [url, requestBody];
        editReservation(reqInfo).then((data: any) => {
            if (data) {
                if (data?.error) {
                    const errorMessage = handleHttpError(data?.error);
                    toast.error(errorMessage);
                } else {
                    toast.success(`Reservation has been edited`);

                    navigate(
                        `/reservations/${reservationDetails?.module?.name
                            .replace(/ /g, '')
                            .toLowerCase()}/${reservationDetails?.id}`
                    );
                }
            } else {
                toast.error(`Failed to edit reservation`);
            }
        });
    };

    useEffect(() => {
        if (
            getIndividualAsset?.type === 'vehicle' &&
            (isOnBehalf || bookerType !== 'owner')
        ) {
            dispatch(
                setDrivers(
                    reservationDetails?.bookingInfo?.drivers?.map(
                        (driver: any, index: any) => ({ ...driver, id: index })
                    )
                )
            );
            dispatch(
                setDriverSameAsBooker(
                    reservationDetails.bookingInfo.driverSameAsBooker
                )
            );
            dispatch(
                setMinimumLicenceRequired(
                    getIndividualAsset?.details?.minimumLicenceRequired
                )
            );
            if (loggedInUser.role.includes('admin') && !isFetchingGuestUsers) {
                dispatch(
                    setBookerObject(
                        guestUsers?.find(
                            (user: any) =>
                                user.id === reservationDetails?.booker?.id
                        )
                    )
                );
            } else {
                dispatch(setBookerObject(profileDetails));
            }

            dispatch(setBookerIsSelected(true));
        }
    }, [isFetchingGuestUsers]);

    useEffect(() => {
        setConflictError('');

        if (
            (availability?.desks < getIndividualAsset?.details?.desks ||
                availability?.privateOffices <
                    getIndividualAsset?.details?.privateOffices ||
                availability?.meetingRooms <
                    getIndividualAsset?.details?.meetingRooms) &&
            loggedInUser?.role?.includes('owner') &&
            !isOnBehalf
        ) {
            setConflictError(`You cannot block the selected
                                                        period. There are existing bookings
                                                        during this timeframe. Please choose
                                                        a different period.`);
        } else if (
            (availability?.desks < getIndividualAsset?.details?.desks ||
                availability?.privateOffices <
                    getIndividualAsset?.details?.privateOffices ||
                availability?.meetingRooms <
                    getIndividualAsset?.details?.meetingRooms) &&
            spaceType === 'entireVenue'
        ) {
            setConflictError(`You cannot book the entire venue. There are existing bookings
                during this timeframe. Please choose
                a different period.`);
        } else if (
            availability?.conflictingBookingIds?.length > 0 &&
            (loggedInUser?.role === 'guest_admin' ||
                loggedInUser?.role === 'guest_department_admin')
        ) {
            setConflictError(
                `You already have a booking for the selected dates. Please change the dates or the booker.`
            );
        } else if (availability?.conflictingBookingIds?.length > 0) {
            setConflictError(
                `You already have a booking for the selected dates. Please change the dates`
            );
        }
    }, [
        availability,
        getIndividualAsset?.details,
        loggedInUser?.role,
        isOnBehalf,
        spaceType,
    ]);

    const commercialHasExistingBooking =
        availability?.desks < getIndividualAsset?.details?.desks ||
        availability?.privateOffices <
            getIndividualAsset?.details?.privateOffices ||
        availability?.meetingRooms < getIndividualAsset?.details?.meetingRooms;

    const disableSaveOffice =
        ((availability?.desks < 1 && spaceType === 'desk') ||
            (availability?.privateOffices < 1 &&
                spaceType === 'privateOffice') ||
            (availability?.meetingRooms < 1 && spaceType === 'meetingRoom') ||
            (availability?.meetingRooms < 1 && isMeetingRoom) ||
            !!conflictError) &&
        getIndividualAsset?.type === 'commercial';

    const arrayOfDependencies = [spaceType, startDate, endDate, isMeetingRoom];
    if (
        getIndividualAsset?.charges
            ?.map((charge: any) => charge.type)
            .some((type: any) => ['perHour', 'perHourPerGues'].includes(type))
    ) {
        arrayOfDependencies.push([checkinTime, checkoutTime]);
    }
    if (
        getIndividualAsset?.charges
            ?.map((charge: any) => charge.type)
            .some((type: any) =>
                [
                    'perHourPerGuest',
                    'perDayPerGuest',
                    'perNightPerGuest',
                ].includes(type)
            )
    ) {
        arrayOfDependencies.push([adultCount, childCount, overnightGuestCount]);
    }

    useEffect(() => {
        calculateFees({
            ...ownerRequestOnBehalf,
            module: { id: reservationDetails?.module?.id },
            policies: reservationDetails?.policies,
            staffAttending: [],
            bookingInfo: {
                moduleType,
                visitType: isWorkTrip,
                checkinTime: !!checkinTime ? checkinTime : '08:00',
                checkoutTime: !!checkoutTime ? checkoutTime : '17:00',
                adults: adultCount,
                children: childCount,
                overnightGuests: overnightGuestCount,
                requiresCatering,
                cateringNotes: cateringDetails,
                requiresBeverages,
                requiresMassages:
                    reservationDetails?.bookingInfo?.requiresMassages,
                beverageNotes: beverageDetails,
                massageNotes: massageDetails,
                notes: additionalNotes,
                desks: spaceType === 'desk' ? 1 : 0,
                privateOffices: spaceType === 'privateOffice' ? 1 : 0,
                meetingRooms:
                    spaceType === 'meetingRoom' ||
                    (isMeetingRoom && spaceType !== 'entireVenue')
                        ? 1
                        : 0,
                entireVenue: spaceType === 'entireVenue',
                guests: adultCount || guestCount,
                drivers: [],
                driverSameAsBooker: false,
            },
        }).then((data: any) => {
            if (data) {
                if (data?.error) {
                    console.error('there was a problem calculating fees');
                } else {
                    setFees(data.data);
                }
            }
        });
    }, [
        spaceType,
        startDate,
        endDate,
        isMeetingRoom,
        checkinTime,
        checkoutTime,
        adultCount,
        childCount,
        overnightGuestCount,
    ]);

    const disableSave =
        errors?.length > 0 ||
        bookingCapError ||
        disableSaveOffice ||
        profilePictureRequired;

    return (
        <Grid sx={{ height: 'auto' }}>
            {isFetching === true ? (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}

                    sx={{
                        height: isIpad ? '50vh' : isMobile ? '5px' : '50vh',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <>
                    <Grid
                        sx={{
                            // backgroundColor: isMobile ? 'pink' : 'gray',
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            paddingTop: '30px',
                            height: 'auto',
                            // width: isIpad ? '80%' : isMobile ? null : '%',
                        }}
                    >
                        <Grid
                            sx={{
                                width: isMobile ? 'auto' : '100%',
                                // backgroundColor: 'brown',
                                display: 'flex',
                                flexDirection: 'column',
                                height: isMobile ? 'auto' : 'auto',
                            }}
                        >
                            <Grid
                                sx={{
                                    height: 'auto',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <List>
                                    <ListItem
                                        sx={{
                                            paddingBottom: '30px',
                                            textAlign: isMobile
                                                ? 'left'
                                                : 'right',
                                            marginLeft: isMobile
                                                ? '-15px'
                                                : '0px',
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingTop: '0px',
                                                        // paddingBottom: '30px',
                                                        fontWeight: 'bold',
                                                        textTransform:
                                                            'uppercase',
                                                    }}
                                                >
                                                    Reservation REFERENCE:{' '}
                                                    {reservationDetails?.id}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Created {createdAtDate}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>{' '}
                                {isMobile ? (
                                    <>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Date:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {reservationDetails?.status ===
                                                    'approved' &&
                                                !loggedInUser?.role.includes(
                                                    'owner'
                                                ) ? (
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {reservationDetails
                                                            ?.module?.type ===
                                                            'property' ||
                                                        reservationDetails
                                                            ?.module?.type ===
                                                            'vehicle' ||
                                                        reservationDetails
                                                            ?.module?.type ===
                                                            'boat' ||
                                                        reservationDetails
                                                            ?.module?.type ===
                                                            'activity' ||
                                                        reservationDetails
                                                            ?.module?.type ===
                                                            'commercial' ||
                                                        getExperienceOwnedByLoggedInUser() ===
                                                            true
                                                            ? `${readableStartDate} - ${readableEndDate}`
                                                            : singleDate}
                                                    </Typography>
                                                ) : (
                                                    <CustomTextField
                                                        disabled={
                                                            reservationDetails?.status ===
                                                                'approved' &&
                                                            !loggedInUser?.role.includes(
                                                                'owner'
                                                            )
                                                        }
                                                        fullWidth
                                                        value={
                                                            reservationDetails
                                                                ?.module
                                                                ?.type ===
                                                                'property' ||
                                                            reservationDetails
                                                                ?.module
                                                                ?.type ===
                                                                'vehicle' ||
                                                            reservationDetails
                                                                ?.module
                                                                ?.type ===
                                                                'boat' ||
                                                            reservationDetails
                                                                ?.module
                                                                ?.type ===
                                                                'activity' ||
                                                            reservationDetails
                                                                ?.module
                                                                ?.type ===
                                                                'commercial' ||
                                                            getExperienceOwnedByLoggedInUser() ===
                                                                true
                                                                ? `${readableStartDate} - ${readableEndDate}`
                                                                : singleDate
                                                        }
                                                        // fullWidth
                                                        onClick={
                                                            onClickOpenModal
                                                        }
                                                        color='neutral'
                                                        inputProps={{
                                                            style: {
                                                                height: '10px',

                                                                backgroundColor:
                                                                    reservationDetails?.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                        ? '#f6f8f9'
                                                                        : 'white',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {getIndividualAsset?.type ===
                                                    'commercial'
                                                        ? ' Access From:'
                                                        : 'Checkin:'}
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                    paddingTop: isMobile
                                                        ? '10px'
                                                        : '',
                                                }}
                                            >
                                                {' '}
                                                {loggedInUser?.role.includes(
                                                    'owner'
                                                ) &&
                                                reservationDetails?.onBehalf ===
                                                    false ? (
                                                    '-'
                                                ) : getIndividualAsset?.type ===
                                                  'commercial' ? (
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {checkinTime}
                                                    </Typography>
                                                ) : reservationDetails?.status ===
                                                      'approved' &&
                                                  !loggedInUser?.role.includes(
                                                      'owner'
                                                  ) ? (
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {checkinTime}
                                                    </Typography>
                                                ) : (
                                                    <DropdownFilter
                                                        id={'checkin'}
                                                        options={
                                                            compareCheckinSettings
                                                        }
                                                        handleChange={
                                                            handleCheckinTime
                                                        }
                                                        selectedValue={
                                                            checkinTime
                                                        }
                                                        backgroundColor={
                                                            reservationDetails?.status ===
                                                                'approved' &&
                                                            !loggedInUser?.role.includes(
                                                                'owner'
                                                            )
                                                                ? '#f6f8f9'
                                                                : 'white'
                                                        }
                                                        border={'#C3C3C3'}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {getIndividualAsset?.type ===
                                                    'commercial'
                                                        ? ' Access To:'
                                                        : 'Checkout:'}
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {loggedInUser?.role.includes(
                                                        'owner'
                                                    ) &&
                                                    reservationDetails?.onBehalf ===
                                                        false ? (
                                                        '-'
                                                    ) : getIndividualAsset?.type ===
                                                      'commercial' ? (
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {
                                                                getIndividualAsset
                                                                    ?.details
                                                                    ?.checkoutTime
                                                            }
                                                        </Typography>
                                                    ) : reservationDetails?.status ===
                                                          'approved' &&
                                                      !loggedInUser?.role.includes(
                                                          'owner'
                                                      ) ? (
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {checkoutTime}
                                                        </Typography>
                                                    ) : (
                                                        <DropdownFilter
                                                            disabled={
                                                                reservationDetails?.status ===
                                                                    'approved' &&
                                                                !loggedInUser?.role.includes(
                                                                    'owner'
                                                                )
                                                            }
                                                            id={'checkout'}
                                                            options={
                                                                compareCheckoutSettings
                                                            }
                                                            handleChange={
                                                                handleCheckoutTime
                                                            }
                                                            selectedValue={
                                                                checkoutTime
                                                            }
                                                            backgroundColor={
                                                                reservationDetails?.status ===
                                                                    'approved' &&
                                                                !loggedInUser?.role.includes(
                                                                    'owner'
                                                                )
                                                                    ? '#f6f8f9'
                                                                    : 'white'
                                                            }
                                                            border={'#C3C3C3'}
                                                        />
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Type:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {reservationDetails?.module.type
                                                        ?.charAt(0)
                                                        .toUpperCase() +
                                                        reservationDetails?.module.type?.slice(
                                                            1
                                                        )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Status:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {reservationDetails?.status
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        reservationDetails?.status?.slice(
                                                            1
                                                        )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label='simple table'
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        {' '}
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Date
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {getIndividualAsset?.type ===
                                                            'commercial'
                                                                ? ' Access From'
                                                                : 'Checkin'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {getIndividualAsset?.type ===
                                                            'commercial'
                                                                ? ' Access To'
                                                                : 'Checkout'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Type
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Status
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component='th'
                                                        scope='row'
                                                        sx={{
                                                            width: isIpad
                                                                ? 'auto'
                                                                : '15%',
                                                        }}
                                                    >
                                                        {reservationDetails?.status ===
                                                            'approved' &&
                                                        !loggedInUser?.role.includes(
                                                            'owner'
                                                        ) ? (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    padding:
                                                                        '5px',
                                                                    minWidth:
                                                                        '200px',
                                                                }}
                                                            >
                                                                {reservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                    'property' ||
                                                                reservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                    'vehicle' ||
                                                                reservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                    'boat' ||
                                                                reservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                    'activity' ||
                                                                reservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                    'commercial' ||
                                                                getExperienceOwnedByLoggedInUser() ===
                                                                    true
                                                                    ? `${readableStartDate} - ${readableEndDate}`
                                                                    : singleDate}
                                                            </Typography>
                                                        ) : (
                                                            <CustomTextField
                                                                disabled={
                                                                    reservationDetails?.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                }
                                                                value={
                                                                    reservationDetails
                                                                        ?.module
                                                                        ?.type ===
                                                                        'property' ||
                                                                    reservationDetails
                                                                        ?.module
                                                                        ?.type ===
                                                                        'vehicle' ||
                                                                    reservationDetails
                                                                        ?.module
                                                                        ?.type ===
                                                                        'boat' ||
                                                                    reservationDetails
                                                                        ?.module
                                                                        ?.type ===
                                                                        'activity' ||
                                                                    reservationDetails
                                                                        ?.module
                                                                        ?.type ===
                                                                        'commercial' ||
                                                                    getExperienceOwnedByLoggedInUser() ===
                                                                        true
                                                                        ? `${readableStartDate} - ${readableEndDate}`
                                                                        : singleDate
                                                                }
                                                                // fullWidth
                                                                onClick={
                                                                    onClickOpenModal
                                                                }
                                                                color='neutral'
                                                                inputProps={{
                                                                    style: {
                                                                        height: '10px',
                                                                        width: isMobile
                                                                            ? ''
                                                                            : isIpad
                                                                            ? '190px'
                                                                            : '190px',

                                                                        backgroundColor:
                                                                            reservationDetails?.status ===
                                                                                'approved' &&
                                                                            !loggedInUser?.role.includes(
                                                                                'owner'
                                                                            )
                                                                                ? '#f6f8f9'
                                                                                : 'white',
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        align='left'
                                                        sx={{
                                                            paddingBottom:
                                                                getIndividualAsset?.type ===
                                                                'commercial'
                                                                    ? '14px'
                                                                    : '6px',
                                                        }}
                                                    >
                                                        {loggedInUser?.role.includes(
                                                            'owner'
                                                        ) &&
                                                        reservationDetails?.onBehalf ===
                                                            false ? (
                                                            '-'
                                                        ) : getIndividualAsset?.type ===
                                                          'commercial' ? (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                {
                                                                    getIndividualAsset
                                                                        ?.details
                                                                        ?.checkinTime
                                                                }
                                                            </Typography>
                                                        ) : reservationDetails?.status ===
                                                              'approved' &&
                                                          !loggedInUser?.role.includes(
                                                              'owner'
                                                          ) ? (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {checkinTime}
                                                            </Typography>
                                                        ) : (
                                                            <DropdownFilter
                                                                disabled={
                                                                    reservationDetails?.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                }
                                                                id={'checkin'}
                                                                options={
                                                                    compareCheckinSettings
                                                                }
                                                                handleChange={
                                                                    handleCheckinTime
                                                                }
                                                                selectedValue={
                                                                    checkinTime
                                                                }
                                                                backgroundColor={
                                                                    reservationDetails?.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                        ? '#f6f8f9'
                                                                        : 'white'
                                                                }
                                                                border={
                                                                    '#C3C3C3'
                                                                }
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        align='left'
                                                        sx={{
                                                            paddingBottom:
                                                                getIndividualAsset?.type ===
                                                                'commercial'
                                                                    ? '14px'
                                                                    : '6px',
                                                        }}
                                                    >
                                                        {loggedInUser?.role.includes(
                                                            'owner'
                                                        ) &&
                                                        reservationDetails?.onBehalf ===
                                                            false ? (
                                                            '-'
                                                        ) : getIndividualAsset?.type ===
                                                          'commercial' ? (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                {
                                                                    getIndividualAsset
                                                                        ?.details
                                                                        ?.checkoutTime
                                                                }
                                                            </Typography>
                                                        ) : reservationDetails?.status ===
                                                              'approved' &&
                                                          !loggedInUser?.role.includes(
                                                              'owner'
                                                          ) ? (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {checkoutTime}
                                                            </Typography>
                                                        ) : (
                                                            <DropdownFilter
                                                                disabled={
                                                                    reservationDetails?.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                }
                                                                id={'checkout'}
                                                                options={
                                                                    compareCheckoutSettings
                                                                }
                                                                handleChange={
                                                                    handleCheckoutTime
                                                                }
                                                                selectedValue={
                                                                    checkoutTime
                                                                }
                                                                backgroundColor={
                                                                    reservationDetails?.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                        ? '#f6f8f9'
                                                                        : 'white'
                                                                }
                                                                border={
                                                                    '#C3C3C3'
                                                                }
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {capitalizeName(
                                                                reservationDetails
                                                                    ?.module
                                                                    ?.type
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {reservationDetails?.status
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                reservationDetails?.status?.slice(
                                                                    1
                                                                )}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                                {/* <Divider sx={{ marginTop: '20px' }} /> */}
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        paddingTop: '30px',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Grid
                                        sx={{ width: isMobile ? '30%' : '20%' }}
                                    >
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                paddingBottom: '10px',
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Experience:
                                        </Typography>{' '}
                                    </Grid>
                                    <Grid
                                        sx={{ width: isMobile ? '70%' : '80%' }}
                                    >
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                paddingBottom: '10px',
                                            }}
                                        >
                                            {reservationDetails?.module?.name} -
                                            hosted by{' '}
                                            {reservationDetails?.owner?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {getIndividualAsset?.type === 'commercial' && (
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Grid
                                            sx={{
                                                width: isMobile ? '30%' : '20%',
                                            }}
                                        >
                                            {' '}
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingBottom: '10px',
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Availability:
                                            </Typography>{' '}
                                        </Grid>
                                        <Grid
                                            sx={{
                                                width: isMobile ? '70%' : '80%',
                                            }}
                                        >
                                            {' '}
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingBottom: '10px',
                                                }}
                                            >
                                                {availability?.desks > 0 && (
                                                    <>
                                                        {' '}
                                                        Dedicated Desks:{' '}
                                                        <strong>
                                                            {
                                                                availability?.desks
                                                            }
                                                        </strong>{' '}
                                                        -{' '}
                                                    </>
                                                )}
                                                {availability?.privateOffices >
                                                    0 && (
                                                    <>
                                                        {getIndividualAsset
                                                            ?.details
                                                            ?.subType ===
                                                        `house`
                                                            ? `Rooms: `
                                                            : `Private Offices: `}{' '}
                                                        <strong>
                                                            {
                                                                availability?.privateOffices
                                                            }
                                                        </strong>{' '}
                                                        -{' '}
                                                    </>
                                                )}
                                                {availability?.meetingRooms >
                                                    0 && (
                                                    <>
                                                        {' '}
                                                        Meeting Rooms:{' '}
                                                        <strong>
                                                            {
                                                                availability?.meetingRooms
                                                            }
                                                        </strong>
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Grid
                                        sx={{ width: isMobile ? '30%' : '20%' }}
                                    >
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                paddingBottom: '10px',
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Location:
                                        </Typography>{' '}
                                    </Grid>
                                    <Grid
                                        sx={{ width: isMobile ? '70%' : '80%' }}
                                    >
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: !reservationDetails
                                                    ?.module?.isAvailable
                                                    ? 'red'
                                                    : '#787878',
                                                paddingBottom: '10px',
                                            }}
                                        >
                                            {!reservationDetails?.module
                                                ?.isAvailable ? (
                                                `This experience is no longer listed, please contact the host`
                                            ) : (
                                                <Link
                                                    style={{ color: '#787878' }}
                                                    target='_blank'
                                                    to={`https://www.google.com/maps/place/${fullAddress}`}
                                                >
                                                    {' '}
                                                    {fullAddress}
                                                </Link>
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {reservationDetails?.bookerType.includes(
                                    'owner'
                                ) && reservationDetails?.onBehalf === false ? (
                                    <>
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Reserved By:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {
                                                        reservationDetails
                                                            ?.booker?.firstName
                                                    }{' '}
                                                    {
                                                        reservationDetails
                                                            ?.booker?.lastName
                                                    }{' '}
                                                    -{' '}
                                                    {
                                                        reservationDetails
                                                            ?.booker?.email
                                                    }{' '}
                                                    (
                                                    {
                                                        reservationDetails
                                                            ?.owner.name
                                                    }
                                                    )
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {reservationDetails?.onBehalf ===
                                        false ? (
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Reservation Reason:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <DropdownFilter
                                                        id={'workTrip'}
                                                        options={[
                                                            {
                                                                value: 'maintenance',
                                                                label: 'Maintenance',
                                                            },
                                                            {
                                                                value: 'publicHoliday',
                                                                label: 'Public Holiday',
                                                            },
                                                            {
                                                                value: 'staffAway',
                                                                label: 'Staff Away',
                                                            },
                                                            {
                                                                value: 'shutdownPeriod',
                                                                label: 'Shutdown Period',
                                                            },
                                                            {
                                                                value: 'other',
                                                                label: 'Other',
                                                            },
                                                        ]}
                                                        handleChange={
                                                            handleReservationReason
                                                        }
                                                        selectedValue={reservationReason
                                                            .replace(
                                                                /([A-Z])/g,
                                                                ' $1'
                                                            )
                                                            .replace(
                                                                /^./,
                                                                function (
                                                                    str: string
                                                                ) {
                                                                    return str.toUpperCase();
                                                                }
                                                            )}
                                                        backgroundColor={
                                                            'white'
                                                        }
                                                        border={'#C3C3C3'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                paddingTop: '10px',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Notes:{' '}
                                                    {loggedInUser?.role?.includes(
                                                        'owner'
                                                    ) &&
                                                    reservationReason ===
                                                        'other' &&
                                                    additionalNotes === '' ? (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    ) : null}
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {' '}
                                                <CustomTextField
                                                    value={additionalNotes}
                                                    placeholder={
                                                        additionalNotes ===
                                                            '' &&
                                                        reservationReason ===
                                                            'other' &&
                                                        loggedInUser?.role?.includes(
                                                            'owner'
                                                        )
                                                            ? "Enter in 'other' reason"
                                                            : ''
                                                    }
                                                    fullWidth
                                                    color='neutral'
                                                    onChange={
                                                        handleAdditionalNotes
                                                    }
                                                    inputProps={{
                                                        maxLength: 500,
                                                        style: {
                                                            height: '60px',
                                                            // width: isMobile
                                                            //     ? '350px'
                                                            //     : isIpad
                                                            //     ? '200px'
                                                            //     : '40px',
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                        },
                                                    }}
                                                    multiline
                                                    rows={4}
                                                />
                                                {!!conflictError ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor:
                                                                '#FDD2D2',
                                                            width: isMobile
                                                                ? 'auto'
                                                                : '700px',
                                                            borderRadius: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'red',
                                                                padding: '5px',
                                                                paddingLeft:
                                                                    '10px',
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            <ErrorIcon />
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                    fontSize:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {conflictError}
                                                            </span>
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                            </Grid>
                                        </Grid>

                                        <Divider sx={{ marginTop: '20px' }} />
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                paddingTop: '30px',
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Approved:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {reservationDetails
                                                        ?.approvedBy?.firstName
                                                        ? `${approvedDate} - ${reservationDetails?.approvedBy?.firstName} ${reservationDetails?.approvedBy?.lastName} ${reservationDetails?.approvedBy?.email}`
                                                        : null}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Company:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {reservationDetails?.guest
                                                        ?.name
                                                        ? reservationDetails
                                                              ?.guest?.name
                                                        : reservationDetails
                                                              ?.bookingInfo
                                                              ?.visitType
                                                        ? `Reserved on behalf of ${reservationDetails?.owner?.name}`
                                                        : `Blocked by ${reservationDetails?.owner?.name}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Primary Guest:{' '}
                                                </Typography>{' '}
                                            </Grid>
                                            {loggedInUser.role ===
                                                'guest_admin' ||
                                            loggedInUser.role ===
                                                'guest_department_admin' ? (
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    <DropdownFilter
                                                        id={'booker'}
                                                        options={[
                                                            {
                                                                value: '',
                                                                label: '+ Create New User',
                                                            },
                                                        ].concat(
                                                            isFetchingGuestUsers
                                                                ? []
                                                                : guestUsers?.map(
                                                                      ({
                                                                          id,
                                                                          email,
                                                                          firstName,
                                                                          lastName,
                                                                          profilePicture,
                                                                      }: any) => {
                                                                          return {
                                                                              value: id,
                                                                              label: `${firstName} ${lastName} (${email})`,
                                                                          };
                                                                      }
                                                                  )
                                                        )}
                                                        handleChange={
                                                            handleBooker
                                                        }
                                                        selectedValue={
                                                            <Typography
                                                                sx={{
                                                                    textTransform:
                                                                        'none',
                                                                    fontSize:
                                                                        isMobile
                                                                            ? '10px'
                                                                            : '16px',
                                                                }}
                                                            >
                                                                {booker}
                                                            </Typography>
                                                        }
                                                        backgroundColor={
                                                            'white'
                                                        }
                                                        border={'#C3C3C3'}
                                                    />
                                                    {profilePictureRequired && (
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'red',
                                                                fontSize:
                                                                    '13px',
                                                                marginTop:
                                                                    '-10px',
                                                                paddingBottom:
                                                                    '10px',
                                                            }}
                                                        >
                                                            The selected user
                                                            does not have a
                                                            profile picture and
                                                            cannot book this
                                                            experience.
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontSize: isMobile
                                                                ? '10px'
                                                                : '14px',
                                                        }}
                                                    >
                                                        {
                                                            reservationDetails
                                                                ?.booker
                                                                ?.firstName
                                                        }{' '}
                                                        {
                                                            reservationDetails
                                                                ?.booker
                                                                ?.lastName
                                                        }{' '}
                                                        -{' '}
                                                        {
                                                            reservationDetails
                                                                ?.booker?.email
                                                        }
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                        {getIndividualAsset?.type ===
                                            'vehicle' && (
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Driver Information:{' '}
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    <Drivers />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {getIndividualAsset?.type ===
                                            'commercial' && (
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Workspace Type:
                                                    </Typography>
                                                </Grid>
                                                {
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '70%'
                                                                : '80%',
                                                        }}
                                                    >
                                                        {' '}
                                                        {reservationDetails.status ===
                                                            'approved' &&
                                                        !loggedInUser?.role.includes(
                                                            'owner'
                                                        ) ? (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {spaceType ===
                                                                    'privateOffice' &&
                                                                getIndividualAsset
                                                                    ?.details
                                                                    ?.subType !==
                                                                    'house'
                                                                    ? 'Private Office'
                                                                    : spaceType ===
                                                                          'privateOffice' &&
                                                                      getIndividualAsset
                                                                          ?.details
                                                                          ?.subType ===
                                                                          'house'
                                                                    ? 'Room'
                                                                    : spaceType ===
                                                                      'meetingRoom'
                                                                    ? 'Meeting Room'
                                                                    : spaceType ===
                                                                      'desk'
                                                                    ? 'Dedicated Desk'
                                                                    : spaceType ===
                                                                      'entireVenue'
                                                                    ? 'Entire Venue'
                                                                    : spaceType}
                                                            </Typography>
                                                        ) : (
                                                            <DropdownFilter
                                                                id={'booker'}
                                                                options={[
                                                                    ...availableSpaces,
                                                                    {
                                                                        value: 'entireVenue',
                                                                        label: `Entire Venue ${
                                                                            commercialHasExistingBooking
                                                                                ? `(unavailable)`
                                                                                : ``
                                                                        }`,
                                                                        disabled:
                                                                            commercialHasExistingBooking,
                                                                    },
                                                                ]}
                                                                handleChange={
                                                                    handleSpaceType
                                                                }
                                                                disabled={
                                                                    reservationDetails.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                }
                                                                selectedValue={
                                                                    <Typography
                                                                        sx={{
                                                                            textTransform:
                                                                                'none',
                                                                            fontSize:
                                                                                '16px',
                                                                        }}
                                                                    >
                                                                        {spaceType ===
                                                                            'privateOffice' &&
                                                                        getIndividualAsset
                                                                            ?.details
                                                                            ?.subType !==
                                                                            'house'
                                                                            ? 'Private Office'
                                                                            : spaceType ===
                                                                                  'privateOffice' &&
                                                                              getIndividualAsset
                                                                                  ?.details
                                                                                  ?.subType ===
                                                                                  'house'
                                                                            ? 'Room'
                                                                            : spaceType ===
                                                                              'meetingRoom'
                                                                            ? 'Meeting Room'
                                                                            : spaceType ===
                                                                              'desk'
                                                                            ? 'Dedicated Desk'
                                                                            : spaceType ===
                                                                              'entireVenue'
                                                                            ? 'Entire Venue'
                                                                            : spaceType}
                                                                    </Typography>
                                                                }
                                                                backgroundColor={
                                                                    reservationDetails.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                        ? '#f6f8f9'
                                                                        : 'white'
                                                                }
                                                                border={
                                                                    '#C3C3C3'
                                                                }
                                                            />
                                                        )}
                                                        {availability?.desks <
                                                            1 &&
                                                            spaceType ===
                                                                'desk' && (
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: 'red',
                                                                        paddingBottom:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    There are
                                                                    not enough
                                                                    desks
                                                                    available.
                                                                    Please
                                                                    change the
                                                                    date.
                                                                </Typography>
                                                            )}
                                                        {availability?.meetingRooms <
                                                            1 &&
                                                            spaceType ===
                                                                'meetingRoom' && (
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: 'red',
                                                                        paddingBottom:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    There are
                                                                    not enough
                                                                    meeting
                                                                    rooms
                                                                    available.
                                                                    Please
                                                                    change the
                                                                    date.
                                                                </Typography>
                                                            )}
                                                        {availability?.privateOffices <
                                                            1 &&
                                                            spaceType ===
                                                                'privateOffice' && (
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: 'red',
                                                                        paddingBottom:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    There are
                                                                    not enough
                                                                    private
                                                                    offices
                                                                    available.
                                                                    Please
                                                                    change the
                                                                    date.
                                                                </Typography>
                                                            )}
                                                    </Grid>
                                                }
                                            </Grid>
                                        )}
                                        {spaceType !== 'meetingRoom' &&
                                            spaceType !== 'entireVenue' && (
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '30%'
                                                                : '20%',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            Meeting Room
                                                            Required:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                        {reservationDetails.status ===
                                                            'approved' &&
                                                        !loggedInUser?.role.includes(
                                                            'owner'
                                                        ) ? (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {isMeetingRoom
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Typography>
                                                        ) : (
                                                            <>
                                                                {' '}
                                                                <Checkbox
                                                                    checked={
                                                                        isMeetingRoom
                                                                    }
                                                                    onChange={() => {
                                                                        setIsMeetingRoom(
                                                                            !isMeetingRoom
                                                                        );
                                                                    }}
                                                                    color='default'
                                                                    sx={{
                                                                        padding:
                                                                            '0',
                                                                    }}
                                                                />
                                                                {availability?.meetingRooms <
                                                                    1 &&
                                                                    isMeetingRoom && (
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: 'red',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                            }}
                                                                        >
                                                                            There
                                                                            are
                                                                            not
                                                                            enough
                                                                            meeting
                                                                            rooms
                                                                            available.
                                                                            Please
                                                                            change
                                                                            the
                                                                            date.
                                                                        </Typography>
                                                                    )}
                                                            </>
                                                        )}{' '}
                                                    </Grid>
                                                </Grid>
                                            )}
                                        {reservationDetails?.createdBy &&
                                        reservationDetails?.createdBy.email !==
                                            reservationDetails?.booker.email ? (
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Reservation made by:{' '}
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {
                                                            reservationDetails
                                                                ?.createdBy
                                                                ?.firstName
                                                        }{' '}
                                                        {
                                                            reservationDetails
                                                                ?.createdBy
                                                                ?.lastName
                                                        }{' '}
                                                        -{' '}
                                                        {
                                                            reservationDetails
                                                                ?.createdBy
                                                                ?.email
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Type of visit:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {isWorkTrip
                                                        ?.charAt(0)
                                                        ?.toUpperCase() +
                                                        isWorkTrip?.slice(1)}
                                                </Typography>
                                                {/* <DropdownFilter
                                                    id={'workTrip'}
                                                    options={[
                                                        {
                                                            value: 'business',
                                                            label: 'Business',
                                                        },
                                                        {
                                                            value: 'personal',
                                                            label: 'Personal',
                                                        },
                                                    ]}
                                                    handleChange={
                                                        handleWorkTripStatus
                                                    }
                                                    selectedValue={isWorkTrip}
                                                    backgroundColor={'white'}
                                                    border={'#C3C3C3'}
                                                /> */}
                                            </Grid>
                                        </Grid>
                                        {reservationDetails?.module.type ===
                                        'activity' ? (
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        No. Guests:{' '}
                                                        {guestCount === 0 ? (
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        ) : null}
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            width:
                                                                isMobile ||
                                                                isIpad
                                                                    ? '7%'
                                                                    : '5%',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            alignContent:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <CustomIconButton
                                                            onClick={
                                                                decrementGuestCount
                                                            }
                                                            disabled={
                                                                guestCount === 0
                                                            }
                                                            sx={{
                                                                borderRadius:
                                                                    '50%',
                                                                backgroundColor:
                                                                    '#fff',
                                                                border: 1,
                                                                width: '15px',
                                                                height: '15px',
                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                    color: '#5cb5b3',
                                                                    backgroundColor:
                                                                        'transparent',
                                                                    background:
                                                                        'none',
                                                                },
                                                            }}
                                                        >
                                                            <RemoveRoundedIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            />
                                                        </CustomIconButton>
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width:
                                                                isMobile ||
                                                                isIpad
                                                                    ? '7%'
                                                                    : '5%',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            alignContent:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                textAlign:
                                                                    'center',
                                                                color: '#787878',
                                                                marginLeft:
                                                                    isMobile
                                                                        ? '25px'
                                                                        : '',
                                                            }}
                                                        >
                                                            {guestCount}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width:
                                                                isMobile ||
                                                                isIpad
                                                                    ? '7%'
                                                                    : '5%',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            marginLeft: isMobile
                                                                ? '25px'
                                                                : '',
                                                        }}
                                                    >
                                                        <CustomIconButton
                                                            sx={{
                                                                borderRadius:
                                                                    '50%',
                                                                backgroundColor:
                                                                    '#fff',
                                                                border: 1,
                                                                width: '15px',
                                                                height: '15px',

                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                    color: '#5cb5b3',
                                                                    backgroundColor:
                                                                        'transparent',
                                                                    background:
                                                                        'none',
                                                                },
                                                            }}
                                                            onClick={
                                                                incrementGuestCount
                                                            }
                                                        >
                                                            <AddRoundedIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            />
                                                        </CustomIconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : reservationDetails?.module.type ===
                                          'vehicle' ? null : reservationDetails
                                              ?.module.type === 'commercial' ? (
                                            <Grid></Grid>
                                        ) : (
                                            <>
                                                <Grid sx={{ display: 'flex' }}>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '30%'
                                                                : '20%',
                                                        }}
                                                    >
                                                        {' '}
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            No. Adults:{' '}
                                                            {adultCount ===
                                                            0 ? (
                                                                <span
                                                                    style={{
                                                                        color: 'red',
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            ) : null}
                                                        </Typography>{' '}
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '70%'
                                                                : '80%',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width:
                                                                    isMobile ||
                                                                    isIpad
                                                                        ? '7%'
                                                                        : '5%',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                alignContent:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <CustomIconButton
                                                                onClick={
                                                                    decrementAdultCount
                                                                }
                                                                disabled={
                                                                    adultCount ===
                                                                    0
                                                                }
                                                                sx={{
                                                                    borderRadius:
                                                                        '50%',
                                                                    backgroundColor:
                                                                        '#fff',
                                                                    border: 1,
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        color: '#5cb5b3',
                                                                        backgroundColor:
                                                                            'transparent',
                                                                        background:
                                                                            'none',
                                                                    },
                                                                }}
                                                            >
                                                                <RemoveRoundedIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                />
                                                            </CustomIconButton>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width:
                                                                    isMobile ||
                                                                    isIpad
                                                                        ? '7%'
                                                                        : '5%',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                alignContent:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    textAlign:
                                                                        'center',
                                                                    color: '#787878',
                                                                    marginLeft:
                                                                        isMobile
                                                                            ? '25px'
                                                                            : '',
                                                                }}
                                                            >
                                                                {adultCount}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width:
                                                                    isMobile ||
                                                                    isIpad
                                                                        ? '7%'
                                                                        : '5%',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                marginLeft:
                                                                    isMobile
                                                                        ? '25px'
                                                                        : '',
                                                            }}
                                                        >
                                                            <CustomIconButton
                                                                sx={{
                                                                    borderRadius:
                                                                        '50%',
                                                                    backgroundColor:
                                                                        '#fff',
                                                                    border: 1,
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        color: '#5cb5b3',
                                                                        backgroundColor:
                                                                            'transparent',
                                                                        background:
                                                                            'none',
                                                                    },
                                                                }}
                                                                onClick={
                                                                    incrementAdultCount
                                                                }
                                                                disabled={
                                                                    maxGuestCountReached
                                                                }
                                                            >
                                                                <AddRoundedIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                />
                                                            </CustomIconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid sx={{ display: 'flex' }}>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '30%'
                                                                : '20%',
                                                        }}
                                                    >
                                                        {' '}
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            No. Children:{' '}
                                                        </Typography>{' '}
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '70%'
                                                                : '80%',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width:
                                                                    isMobile ||
                                                                    isIpad
                                                                        ? '7%'
                                                                        : '5%',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                alignContent:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <CustomIconButton
                                                                onClick={
                                                                    decrementChildCount
                                                                }
                                                                disabled={
                                                                    childCount ===
                                                                    0
                                                                }
                                                                sx={{
                                                                    borderRadius:
                                                                        '50%',
                                                                    backgroundColor:
                                                                        '#fff',
                                                                    border: 1,
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        color: '#5cb5b3',
                                                                        backgroundColor:
                                                                            'transparent',
                                                                        background:
                                                                            'none',
                                                                    },
                                                                }}
                                                            >
                                                                <RemoveRoundedIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                />
                                                            </CustomIconButton>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width:
                                                                    isMobile ||
                                                                    isIpad
                                                                        ? '7%'
                                                                        : '5%',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                alignContent:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    textAlign:
                                                                        'center',
                                                                    color: '#787878',
                                                                    marginLeft:
                                                                        isMobile
                                                                            ? '25px'
                                                                            : '',
                                                                }}
                                                            >
                                                                {childCount}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width:
                                                                    isMobile ||
                                                                    isIpad
                                                                        ? '7%'
                                                                        : '5%',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                marginLeft:
                                                                    isMobile
                                                                        ? '25px'
                                                                        : '',
                                                            }}
                                                        >
                                                            <CustomIconButton
                                                                sx={{
                                                                    borderRadius:
                                                                        '50%',
                                                                    backgroundColor:
                                                                        '#fff',
                                                                    border: 1,
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        color: '#5cb5b3',
                                                                        backgroundColor:
                                                                            'transparent',
                                                                        background:
                                                                            'none',
                                                                    },
                                                                }}
                                                                disabled={
                                                                    maxGuestCountReached
                                                                }
                                                                onClick={
                                                                    incrementChildCount
                                                                }
                                                            >
                                                                <AddRoundedIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                />
                                                            </CustomIconButton>
                                                        </Grid>
                                                        {getIndividualAsset?.type ===
                                                        'activity' ? null : (
                                                            <span
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-end',
                                                                    alignItems:
                                                                        'center',
                                                                    fontSize:
                                                                        '10px',
                                                                    paddingLeft:
                                                                        isMobile
                                                                            ? '10px'
                                                                            : '',
                                                                    color:
                                                                        maxGuestCountReached ===
                                                                        true
                                                                            ? 'red'
                                                                            : '#787878',
                                                                }}
                                                            >
                                                                (
                                                                {maxGuestCountReached ===
                                                                true
                                                                    ? 'max guests reached'
                                                                    : `max guests:${getIndividualAsset?.details.maxGuests}`}
                                                                )
                                                            </span>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                {reservationDetails?.type ===
                                                    'vehicle' ||
                                                reservationDetails?.visitType ===
                                                    'personal' ||
                                                readableStartDate ===
                                                    readableEndDate ? null : (
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            pb: '10px',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                No. Overnight
                                                                Guests:{' '}
                                                                {overnightGuestCount ===
                                                                0 ? (
                                                                    <span
                                                                        style={{
                                                                            color: 'red',
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                ) : null}{' '}
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width:
                                                                        isMobile ||
                                                                        isIpad
                                                                            ? '7%'
                                                                            : '5%',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    alignContent:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <CustomIconButton
                                                                    onClick={
                                                                        decrementOvernightGuestCount
                                                                    }
                                                                    disabled={
                                                                        overnightGuestCount ===
                                                                        0
                                                                    }
                                                                    sx={{
                                                                        borderRadius:
                                                                            '50%',
                                                                        backgroundColor:
                                                                            '#fff',
                                                                        border: 1,
                                                                        width: '15px',
                                                                        height: '15px',
                                                                        '&:hover':
                                                                            {
                                                                                cursor: 'pointer',
                                                                                color: '#5cb5b3',
                                                                                backgroundColor:
                                                                                    'transparent',
                                                                                background:
                                                                                    'none',
                                                                            },
                                                                    }}
                                                                >
                                                                    <RemoveRoundedIcon
                                                                        sx={{
                                                                            fontSize:
                                                                                '12px',
                                                                        }}
                                                                    />
                                                                </CustomIconButton>
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width:
                                                                        isMobile ||
                                                                        isIpad
                                                                            ? '7%'
                                                                            : '5%',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    alignContent:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        textAlign:
                                                                            'center',
                                                                        color: '#787878',
                                                                        marginLeft:
                                                                            isMobile
                                                                                ? '25px'
                                                                                : '',
                                                                    }}
                                                                >
                                                                    {
                                                                        overnightGuestCount
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width:
                                                                        isMobile ||
                                                                        isIpad
                                                                            ? '7%'
                                                                            : '5%',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                    marginLeft:
                                                                        isMobile
                                                                            ? '25px'
                                                                            : '',
                                                                }}
                                                            >
                                                                <CustomIconButton
                                                                    sx={{
                                                                        borderRadius:
                                                                            '50%',
                                                                        backgroundColor:
                                                                            '#fff',
                                                                        border: 1,
                                                                        width: '15px',
                                                                        height: '15px',
                                                                        '&:hover':
                                                                            {
                                                                                cursor: 'pointer',
                                                                                color: '#5cb5b3',
                                                                                backgroundColor:
                                                                                    'transparent',
                                                                                background:
                                                                                    'none',
                                                                            },
                                                                    }}
                                                                    disabled={
                                                                        maxOvernightGuestReached
                                                                    }
                                                                    onClick={
                                                                        incrementOvernightGuestCount
                                                                    }
                                                                >
                                                                    <AddRoundedIcon
                                                                        sx={{
                                                                            fontSize:
                                                                                '12px',
                                                                        }}
                                                                    />
                                                                </CustomIconButton>
                                                            </Grid>
                                                            {getIndividualAsset?.type ===
                                                            'activity' ? null : (
                                                                <span
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        justifyContent:
                                                                            'flex-end',
                                                                        alignItems:
                                                                            'center',
                                                                        fontSize:
                                                                            '10px',
                                                                        paddingLeft:
                                                                            isMobile
                                                                                ? '10px'
                                                                                : '',
                                                                        color:
                                                                            maxOvernightGuestReached ===
                                                                            true
                                                                                ? 'red'
                                                                                : '#787878',
                                                                    }}
                                                                >
                                                                    (
                                                                    {maxOvernightGuestReached ===
                                                                    true
                                                                        ? 'max guests reached'
                                                                        : `max guests:${getIndividualAsset?.details.overnightGuests}`}
                                                                    )
                                                                </span>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                        {getIndividualAsset?.visitType !==
                                            'personal' &&
                                            getIndividualAsset?.type !==
                                                'vehicle' &&
                                            getIndividualAsset?.type !==
                                                'commercial' && (
                                                <Grid display='flex' pb='10px'>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '30%'
                                                                : '20%',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            Staff Attending
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '70%'
                                                                : '80%',
                                                        }}
                                                    >
                                                        {loggedInUser?.role.includes(
                                                            'owner'
                                                        ) ? (
                                                            <>
                                                                {reservationDetails
                                                                    .staffAttending
                                                                    ?.length >
                                                                0 ? (
                                                                    reservationDetails?.staffAttending?.map(
                                                                        (
                                                                            user: User
                                                                        ) => (
                                                                            <Typography
                                                                                key={
                                                                                    user.id
                                                                                }
                                                                                fontSize='14px'
                                                                                color='#787878'
                                                                            >
                                                                                <li>
                                                                                    {`${user.firstName} ${user.lastName} - ${user.email}`}
                                                                                </li>
                                                                            </Typography>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <Typography
                                                                        fontSize='14px'
                                                                        color='#787878'
                                                                    >
                                                                        No staff
                                                                        selected
                                                                    </Typography>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <MultiSelectDropdownFilter
                                                                    id={
                                                                        'staffAttending'
                                                                    }
                                                                    options={guestAttendees?.map(
                                                                        (
                                                                            user: User
                                                                        ) => ({
                                                                            value: user.id,
                                                                            label: `${user.firstName} ${user.lastName} (${user.email})`,
                                                                        })
                                                                    )}
                                                                    handleChange={
                                                                        setStaffAttending
                                                                    }
                                                                    selectedValue={
                                                                        staffAttending
                                                                    }
                                                                    backgroundColor={
                                                                        'white'
                                                                    }
                                                                    border={
                                                                        '#C3C3C3'
                                                                    }
                                                                    disabled={
                                                                        !adultCount
                                                                    }
                                                                />
                                                                <Box mt='-10px'>
                                                                    {staffAttending?.length ===
                                                                        adultCount && (
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    '10px',
                                                                                color: 'red',
                                                                            }}
                                                                        >
                                                                            (max
                                                                            attendees
                                                                            reached)
                                                                        </span>
                                                                    )}
                                                                    {staffAttending?.length >
                                                                        adultCount && (
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    '10px',
                                                                                color: 'red',
                                                                            }}
                                                                        >
                                                                            (max
                                                                            guests
                                                                            exceeded)
                                                                        </span>
                                                                    )}
                                                                </Box>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            )}
                                        {reservationDetails?.module?.type ===
                                            'activity' ||
                                        reservationDetails?.module?.type ===
                                            'vehicle' ? null : (
                                            <>
                                                {getIndividualAsset?.details
                                                    ?.catering && (
                                                    <>
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Catering
                                                                    Required
                                                                </Typography>
                                                            </Grid>
                                                            <Grid>
                                                                {' '}
                                                                <Checkbox
                                                                    disabled={
                                                                        reservationDetails.status ===
                                                                            'approved' &&
                                                                        !loggedInUser?.role.includes(
                                                                            'owner'
                                                                        )
                                                                    }
                                                                    checked={
                                                                        requiresCatering
                                                                    }
                                                                    onChange={() => {
                                                                        setRequiresCatering(
                                                                            !requiresCatering
                                                                        );
                                                                    }}
                                                                    color='default'
                                                                    sx={{
                                                                        padding:
                                                                            '0',
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        {requiresCatering ===
                                                        false ? null : (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    paddingTop:
                                                                        '10px',
                                                                    // flexDirection:
                                                                    //     isMobile
                                                                    //         ? 'column'
                                                                    //         : 'row',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Grid
                                                                        sx={{
                                                                            paddingBottom:
                                                                                '10px',
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                        }}
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Catering
                                                                                Details{' '}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid>
                                                                            {cateringDetails?.length >
                                                                            0 ? (
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize:
                                                                                            '10px',
                                                                                        color: '#5cb5b3',
                                                                                    }}
                                                                                >
                                                                                    (Characters
                                                                                    left:{' '}
                                                                                    {300 -
                                                                                        cateringDetails?.length}

                                                                                    )
                                                                                </Typography>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    <CustomTextField
                                                                        disabled={
                                                                            reservationDetails.status ===
                                                                                'approved' &&
                                                                            !loggedInUser?.role.includes(
                                                                                'owner'
                                                                            )
                                                                        }
                                                                        placeholder=''
                                                                        fullWidth
                                                                        color='neutral'
                                                                        value={
                                                                            cateringDetails
                                                                        }
                                                                        onChange={
                                                                            handleCateringDetails
                                                                        }
                                                                        inputProps={{
                                                                            maxLength: 500,
                                                                            style: {
                                                                                height: '60px',
                                                                                // width: isMobile
                                                                                //     ? '350px'
                                                                                //     : isIpad
                                                                                //     ? '200px'
                                                                                //     : '40px',
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                            },
                                                                        }}
                                                                        multiline
                                                                        rows={4}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </>
                                                )}
                                                {getIndividualAsset?.details
                                                    ?.alcohol && (
                                                    <>
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Beverages
                                                                    Required
                                                                </Typography>
                                                            </Grid>
                                                            <Grid>
                                                                {' '}
                                                                <Checkbox
                                                                    disabled={
                                                                        reservationDetails.status ===
                                                                            'approved' &&
                                                                        !loggedInUser?.role.includes(
                                                                            'owner'
                                                                        )
                                                                    }
                                                                    checked={
                                                                        requiresBeverages
                                                                    }
                                                                    onChange={() => {
                                                                        setRequiresBeverages(
                                                                            !requiresBeverages
                                                                        );
                                                                    }}
                                                                    color='default'
                                                                    sx={{
                                                                        padding:
                                                                            '0',
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        {requiresBeverages ===
                                                        false ? null : (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    paddingTop:
                                                                        '10px',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Grid
                                                                        sx={{
                                                                            paddingBottom:
                                                                                '10px',
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                        }}
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Alcohol
                                                                                Details{' '}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid>
                                                                            {beverageDetails?.length >
                                                                            0 ? (
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize:
                                                                                            '10px',
                                                                                        color: '#5cb5b3',
                                                                                    }}
                                                                                >
                                                                                    (Characters
                                                                                    left:{' '}
                                                                                    {200 -
                                                                                        beverageDetails?.length}

                                                                                    )
                                                                                </Typography>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <CustomTextField
                                                                        disabled={
                                                                            reservationDetails.status ===
                                                                                'approved' &&
                                                                            !loggedInUser?.role.includes(
                                                                                'owner'
                                                                            )
                                                                        }
                                                                        value={
                                                                            beverageDetails
                                                                        }
                                                                        fullWidth
                                                                        color='neutral'
                                                                        onChange={
                                                                            handleBeverageDetails
                                                                        }
                                                                        inputProps={{
                                                                            maxLength: 500,
                                                                            style: {
                                                                                height: '60px',
                                                                                // width: isMobile
                                                                                //     ? '350px'
                                                                                //     : isIpad
                                                                                //     ? '200px'
                                                                                //     : '40px',
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                            },
                                                                        }}
                                                                        multiline
                                                                        rows={4}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </>
                                                )}
                                                {reservationDetails?.bookingInfo
                                                    ?.requiresMassages ===
                                                    false ||
                                                reservationDetails?.bookingInfo
                                                    ?.requiresMassages ===
                                                    undefined ? null : (
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Grid
                                                                sx={{
                                                                    paddingBottom:
                                                                        '10px',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <Grid>
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Massage
                                                                        Details:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid>
                                                                    {massageDetails?.length >
                                                                    0 ? (
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize:
                                                                                    '10px',
                                                                                color: '#5cb5b3',
                                                                            }}
                                                                        >
                                                                            (Characters
                                                                            left:{' '}
                                                                            {200 -
                                                                                massageDetails?.length}
                                                                            )
                                                                        </Typography>
                                                                    ) : null}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <CustomTextField
                                                                disabled={
                                                                    reservationDetails?.status ===
                                                                        'approved' &&
                                                                    !loggedInUser?.role.includes(
                                                                        'owner'
                                                                    )
                                                                }
                                                                value={
                                                                    massageDetails
                                                                }
                                                                fullWidth
                                                                color='neutral'
                                                                onChange={
                                                                    handleMassageDetails
                                                                }
                                                                inputProps={{
                                                                    maxLength: 500,
                                                                    style: {
                                                                        height: '60px',
                                                                        // width: isMobile
                                                                        //     ? '350px'
                                                                        //     : isIpad
                                                                        //     ? '200px'
                                                                        //     : '40px',
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                    },
                                                                }}
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Grid
                                                    sx={{
                                                        paddingBottom: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Grid>
                                                        {' '}
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            Notes:{' '}
                                                            {loggedInUser?.role?.includes(
                                                                'owner'
                                                            ) &&
                                                            reservationReason ===
                                                                'other' &&
                                                            additionalNotes ===
                                                                '' ? (
                                                                <span
                                                                    style={{
                                                                        color: 'red',
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            ) : null}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                        {additionalNotes?.length >
                                                        0 ? (
                                                            <Typography
                                                                sx={{
                                                                    fontSize:
                                                                        '10px',
                                                                    color: '#5cb5b3',
                                                                }}
                                                            >
                                                                (Characters
                                                                left:{' '}
                                                                {300 -
                                                                    additionalNotes?.length}
                                                                )
                                                            </Typography>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <CustomTextField
                                                    value={additionalNotes}
                                                    placeholder=''
                                                    fullWidth
                                                    color='neutral'
                                                    onChange={
                                                        handleAdditionalNotes
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            height: '60px',
                                                            // width: isMobile
                                                            //     ? '350px'
                                                            //     : isIpad
                                                            //     ? '200px'
                                                            //     : '40px',
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                        },
                                                    }}
                                                    multiline
                                                    rows={4}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Grid
                                                    sx={{
                                                        paddingBottom: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                ></Grid>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                {errorMsg !== undefined ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor:
                                                                '#FDD2D2',
                                                            width: isMobile
                                                                ? 'auto'
                                                                : '700px',
                                                            borderRadius: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'red',
                                                                padding: '5px',
                                                                paddingLeft:
                                                                    '10px',
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            <ErrorIcon />
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                    fontSize:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {' '}
                                                                {errorMsg}{' '}
                                                                {loggedInUser.role ===
                                                                    'guest_admin' ||
                                                                loggedInUser.role ===
                                                                    'guest_department_admin'
                                                                    ? 'Please change the dates or the primary guest.'
                                                                    : 'Please change the dates.'}
                                                            </span>
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                                {!!conflictError ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor:
                                                                '#FDD2D2',
                                                            width: isMobile
                                                                ? 'auto'
                                                                : '700px',
                                                            borderRadius: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'red',
                                                                padding: '5px',
                                                                paddingLeft:
                                                                    '10px',
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            <ErrorIcon />
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                    fontSize:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {conflictError}
                                                            </span>
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                                {moment(startDate).format(
                                                    'YYYY-MM-DD'
                                                ) ===
                                                    moment(endDate).format(
                                                        'YYYY-MM-DD'
                                                    ) &&
                                                newTimeSelection < timeToAdd ===
                                                    true ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor:
                                                                '#FDD2D2',
                                                            width: '700px',
                                                            borderRadius: 2,
                                                            marginTop:
                                                                errorMsg !==
                                                                undefined
                                                                    ? '10px'
                                                                    : '0px',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: 'red',
                                                                padding: '5px',
                                                                paddingLeft:
                                                                    '10px',
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            <ErrorIcon />
                                                            {newTimeSelection <
                                                                timeToAdd &&
                                                            moment(
                                                                startDate
                                                            ).format(
                                                                'DD/MM/YYYY'
                                                            ) ===
                                                                moment(
                                                                    endDate
                                                                ).format(
                                                                    'DD/MM/YYYY'
                                                                ) &&
                                                            checkinTime >
                                                                checkoutTime ===
                                                                false
                                                                ? `Your selection is below the allowed hire time for this experience ${min}hrs`
                                                                : newTimeSelection >
                                                                      timeToSubtract &&
                                                                  moment(
                                                                      startDate
                                                                  ).format(
                                                                      'DD/MM/YYYY'
                                                                  ) ===
                                                                      moment(
                                                                          endDate
                                                                      ).format(
                                                                          'DD/MM/YYYY'
                                                                      ) &&
                                                                  checkinTime >
                                                                      checkoutTime ===
                                                                      false &&
                                                                  timeToSubtract !==
                                                                      '00:00'
                                                                ? `Your selection exceeds the allowed hire time for this experience ${min}-${max} hrs`
                                                                : moment(
                                                                      startDate
                                                                  ).format(
                                                                      'DD/MM/YYYY'
                                                                  ) ===
                                                                      moment(
                                                                          endDate
                                                                      ).format(
                                                                          'DD/MM/YYYY'
                                                                      ) &&
                                                                  checkinTime >
                                                                      checkoutTime
                                                                ? `Checkin time cannot be greater than checkout time`
                                                                : null}
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingBottom: '10px',
                                    }}
                                ></Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {!disableSave &&
                        (bookerType !== 'owner' || isOnBehalf) &&
                        fees?.charges?.length > 0 && (
                            <Grid sx={{ paddingTop: '10px' }}>
                                <Card
                                    sx={{
                                        minHeight: '200px',
                                        padding: '20px 20px 20px 10px',
                                    }}
                                >
                                    <Typography
                                        component='h3'
                                        variant='h6'
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontWeight: 550,
                                            textTransform: 'uppercase',
                                            paddingBottom: '30px',
                                        }}
                                    >
                                        Pricing & Fees
                                    </Typography>
                                    {(isCalculating || showSkeleton) &&
                                    isFirstRender ? (
                                        <Grid sx={{ width: '310px' }}>
                                            <Skeleton />
                                            <Skeleton />
                                            <Skeleton />
                                            <Skeleton />
                                            <Skeleton />
                                            <Skeleton />
                                        </Grid>
                                    ) : (
                                        fees && (
                                            <BookingCharges
                                                charges={fees?.charges}
                                                bookingTotal={
                                                    fees?.bookingTotal
                                                }
                                                moduleType={moduleType}
                                                module={getIndividualAsset}
                                                overnightGuestCount={
                                                    reservationDetails
                                                        ?.bookingInfo
                                                        ?.overnightGuests
                                                }
                                            />
                                        )
                                    )}
                                </Card>
                            </Grid>
                        )}

                    <Grid
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            paddingTop: '50px',
                            paddingBottom: '50px',
                        }}
                    >
                        <Grid sx={{ width: '50%' }}>
                            <CustomButton
                                onClick={onClickGoBack}
                                color='green'
                                sx={{
                                    marginLeft: !isMobile ? '-8px' : '-18px',
                                    top: isMobile || isIpad ? '10%' : '20%',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                    // alignItems: 'flex-end'
                                }}
                            >
                                <ArrowBackIcon />{' '}
                                {isMobile ? null : (
                                    <Typography
                                        sx={{
                                            paddingLeft: '5px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        Back to reservations
                                    </Typography>
                                )}
                            </CustomButton>
                        </Grid>
                        <Grid
                            sx={{
                                width: '50%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <CustomButton
                                variant='contained'
                                color='green'
                               
                                disabled={disableSave}
                                onClick={updateReservation}
                                sx={{
                                    top: isMobile || isIpad ? '10%' : '20%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                    }}
                                >
                                    Save
                                </Typography>
                            </CustomButton>
                        </Grid>
                    </Grid>
                    <CalendarModal
                        open={open}
                        onClose={handleCloseModal}
                        onSubmit={onSubmitDate}
                        data={getIndividualAsset}
                        reservation={reservationDetails}
                    />
                </>
            )}
        </Grid>
    );
}
