import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import ReactGA from 'react-ga4';

const PolicyToolbar = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();

    const handleAdd = () => {
        navigate('/newPolicy');
        ReactGA.send({
            hitType: 'pageview',
            page: `/newPolicy`,
            title: `New Policy`,
        });
    };

    return (
        <Grid
            sx={{
                paddingBottom: '20px',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            }}
        >
            <Grid sx={{ width: '90%', paddingTop: '10px' }}>
                <Typography
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '12px',
                    }}
                >
                    {' '}
                    Create a custom policy for your experience, making your
                    terms clear and easy for users to understand!
                </Typography>
            </Grid>
            <Grid
                sx={{
                    textAlign: ' right',
                }}
            >
                {isMobile || isIpad ? (
                    <CustomIconButton
                        variant='contained'
                        color='green'
                        onClick={handleAdd}
                    >
                        <AddCircleIcon />
                    </CustomIconButton>
                ) : (
                    <CustomButton
                        variant='contained'
                        color='green'
                        startIcon={<AddCircleIcon />}
                        onClick={handleAdd}
                    >
                        Add New
                    </CustomButton>
                )}
            </Grid>
        </Grid>
    );
};

export default PolicyToolbar;
