import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { BasePolicy } from '../../types/policy'
import { capitalizeName } from '../../../shared/utils'
import { CustomButton } from '../../../layout/styledcomponents/CustomButton'

interface ModalProps {
    policy: BasePolicy
    open: boolean
    onClose: () => void
}

const PolicyModal = ({ policy, open, onClose }: ModalProps) => {

    console.log(policy)
    return (
        <Dialog open={open} onClose={onClose}>
    <DialogTitle
        fontFamily="Open Sans"
        color="#787878"
        sx={{ textAlign: 'left' }} // Left-align DialogTitle
    >
        {capitalizeName(policy.name)}
    </DialogTitle>
    <DialogContent dividers>
        <DialogContentText
            fontFamily="Open Sans"
            color="#787878"
            sx={{
                whiteSpace: 'pre-line',
                textAlign: 'left', // Left-align the content
                '& ul': {
                    paddingLeft: '20px', // Ensures proper indentation of bullet points
                },
                '& li': {
                    marginBottom: '5px', // Adds spacing between list items
                },
            }}
        >
            {policy.description}
        </DialogContentText>
    </DialogContent>
    <DialogActions
        sx={{
            borderTop: '#d8d8d8 solid 1px',
            bgcolor: '#eeeeee',
            justifyContent: 'flex-start', // Align button to the left
        }}
    >
        <CustomButton
            color="green"
            variant="contained"
            onClick={onClose}
            sx={{ textAlign: 'left' }} // Ensures button text is left-aligned
        >
            Close
        </CustomButton>
    </DialogActions>
</Dialog>

    )
}

export default PolicyModal