import {
    Button,
    Collapse,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useGetFeeListQuery } from '../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../shared/AuthRoles';
import {
    camelCaseToSentenceCase,
    camelCaseToTitleCase,
    capitalizeName,
    formatTime,
} from '../../../shared/utils';
import { ExperienceDiscount } from '../../types/experience';
import { findFeeById } from './Fees/ExperienceChargesForm';

const IndividualExperienceCharges = ({
    charges,
    experienceType,
    experienceSubType,
    discounts,
}: {
    charges: any;
    experienceType: any;
    experienceSubType: any;
    discounts: any;
}) => {
    const [showPricingDetails, setShowPricingDetails] = useState(false);
    const loggedInUser = UserRole();
    const { data: availableFees = [] } = useGetFeeListQuery(
        loggedInUser?.company
    );
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const activeDiscounts: ExperienceDiscount[] = discounts.filter(
        (discount: ExperienceDiscount) => !discount.disabled
    );

    const additionalFees = charges?.filter(
        (charge: any) => charge.chargeType === 'fee'
    );

    const basePrices = charges?.filter((charge: any) =>
        ['price', 'flexiblePrice'].includes(charge.chargeType)
    );

    const chargeWithWeekendPrice = charges?.find(
        (charge: any) => charge.weekendPrice
    );

    return (
        <Grid
            sx={{
                height: 'auto',
                paddingTop: isIpad ? '10px' : '30px',
                width: isMobile || isIpad ? '100%' : '88%',
            }}
        >
            <Divider
                sx={{
                    width: '100%',
                }}
            />
            <Grid>
                <Grid
                    sx={{
                        paddingTop: '30px',
                        paddingBottom: '10px',
                    }}
                >
                    <Typography
                        sx={{
                            color: 'text.primary',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            pb: 1.5,
                        }}
                        gutterBottom
                    >
                        Pricing & Fees
                    </Typography>
                    <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize: '14px',
                        }}
                    >
                        The amounts listed cover the cost of the experience,
                        including any additional services.{' '}
                        {showPricingDetails && (
                            <span> Note: All amounts are excl GST.</span>
                        )}
                        <Button
                            onClick={() =>
                                setShowPricingDetails(!showPricingDetails)
                            }
                            style={{ marginLeft: '8px', textTransform: 'none' }}
                            color='primary'
                        >
                            {showPricingDetails ? 'Show Less' : 'Show More'}
                        </Button>
                    </Typography>
                </Grid>

                <Collapse in={showPricingDetails}>
                    <Grid
                        sx={{
                            minWidth: isMobile || isIpad ? 0 : '700px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        {basePrices?.length > 0 && (
                            <Typography
                                sx={{
                                    color: 'text.primary',
                                    fontSize: '14px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Base Price
                                </Typography>
                                {basePrices?.flatMap(
                                    (charge: any, index: number) => {
                                        let priceItem;

                                        if (
                                            charge.chargeType ===
                                            'flexiblePrice'
                                        ) {
                                            priceItem = (
                                                <>
                                                    <li key={`${index}-day`}>
                                                        {`$${
                                                            charge.dayPrice
                                                        } - Per Day: ${formatTime(
                                                            charge.dayCheckin
                                                        )} - ${formatTime(
                                                            charge.dayCheckout
                                                        )}`}
                                                    </li>
                                                    <li key={`${index}-night`}>
                                                        {`$${
                                                            charge.nightPrice
                                                        } - Per Night: ${formatTime(
                                                            charge.nightCheckin
                                                        )} - ${formatTime(
                                                            charge.nightCheckout
                                                        )}`}
                                                    </li>
                                                    <li key={`${index}-hour`}>
                                                        {`$${charge.hourPrice} - Per Hour (outside of standard check-in/checkout)`}
                                                    </li>
                                                </>
                                            );
                                        } else {
                                            priceItem = (
                                                <li key={`${index}-regular`}>
                                                    {` $${charge?.unitPrice.toFixed(
                                                        2
                                                    )} - ${
                                                        charge?.chargeType ===
                                                        'price'
                                                            ? camelCaseToTitleCase(
                                                                  charge?.type ===
                                                                      'perOfficePerDay' &&
                                                                      experienceType ===
                                                                          'commercial' &&
                                                                      experienceSubType ===
                                                                          'house'
                                                                      ? 'perRoomPerDay'
                                                                      : charge?.type
                                                              )
                                                            : `${camelCaseToSentenceCase(
                                                                  charge?.name
                                                              )} (${
                                                                  charge.type
                                                              })`
                                                    }`}
                                                </li>
                                            );
                                        }

                                        return [priceItem];
                                    }
                                )}
                            </Typography>
                        )}

                        {chargeWithWeekendPrice && (
                            <Typography
                                sx={{
                                    color: 'text.primary',
                                    fontSize: '14px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Weekend Price
                                </Typography>
                                {chargeWithWeekendPrice.chargeType ===
                                'flexiblePrice' ? (
                                    <>
                                        <li>
                                            {`$${
                                                chargeWithWeekendPrice
                                                    .weekendPrice.dayPrice
                                            } - Per Day: ${formatTime(
                                                chargeWithWeekendPrice.dayCheckin
                                            )} - ${formatTime(
                                                chargeWithWeekendPrice.dayCheckout
                                            )}`}
                                        </li>
                                        <li>
                                            {`$${
                                                chargeWithWeekendPrice
                                                    .weekendPrice.nightPrice
                                            } - Per Night: ${formatTime(
                                                chargeWithWeekendPrice.nightCheckin
                                            )} - ${formatTime(
                                                chargeWithWeekendPrice.nightCheckout
                                            )}`}
                                        </li>
                                        <li>
                                            {`$${chargeWithWeekendPrice.weekendPrice.hourPrice} - Per Hour (outside of standard check-in/checkout)`}
                                        </li>
                                    </>
                                ) : (
                                    <li>
                                        {` $${chargeWithWeekendPrice?.weekendPrice?.unitPrice.toFixed(
                                            2
                                        )} - ${
                                            chargeWithWeekendPrice?.chargeType ===
                                            'price'
                                                ? camelCaseToTitleCase(
                                                      chargeWithWeekendPrice?.type
                                                  )
                                                : `${camelCaseToSentenceCase(
                                                      chargeWithWeekendPrice?.name
                                                  )} (${
                                                      findFeeById(
                                                          chargeWithWeekendPrice.feeId,
                                                          availableFees
                                                      )?.type
                                                  })`
                                        } `}

                                        {` (${chargeWithWeekendPrice?.weekendPrice?.days
                                            .map((day: string) =>
                                                capitalizeName(day)
                                            )
                                            .join(', ')})`}
                                    </li>
                                )}
                            </Typography>
                        )}

                        {additionalFees.length > 0 && (
                            <Typography
                                sx={{
                                    color: 'text.primary',
                                    fontSize: '14px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Additional Fees
                                </Typography>
                                {additionalFees.map(
                                    (charge: any, index: number) => (
                                        <li key={`${index}-regular`}>
                                            {` $${charge?.unitPrice.toFixed(
                                                2
                                            )} - ${
                                                charge?.chargeType === 'price'
                                                    ? camelCaseToTitleCase(
                                                          charge?.type ===
                                                              'perOfficePerDay' &&
                                                              experienceType ===
                                                                  'commercial' &&
                                                              experienceSubType ===
                                                                  'house'
                                                              ? 'perRoomPerDay'
                                                              : charge?.type
                                                      )
                                                    : `${camelCaseToSentenceCase(
                                                          charge?.name
                                                      )} (${charge.type})`
                                            }`}
                                        </li>
                                    )
                                )}
                            </Typography>
                        )}
                    </Grid>

                    {activeDiscounts.length > 0 && (
                        <Grid>
                            <Grid
                                sx={{
                                    paddingTop: '30px',
                                    paddingBottom: '10px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Discounts
                                </Typography>
                            </Grid>
                            <Typography
                                component='ul'
                                sx={{
                                    color: 'text.primary',
                                    fontSize: '14px',
                                    paddingLeft: '20px',
                                }}
                            >
                                {activeDiscounts?.map((discount, index) => (
                                    <li
                                        key={index}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'text.primary',
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {discount.name} -{' '}
                                            {discount.percentage}%
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'text.primary',
                                                fontSize: '12px',
                                            }}
                                        >
                                            Applicable Dates:
                                            <ul>
                                                {discount.dates.map(
                                                    (
                                                        date: any,
                                                        dateIndex: number
                                                    ) => (
                                                        <li key={dateIndex}>
                                                            {date.startDate} to{' '}
                                                            {date.endDate}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </Typography>
                                    </li>
                                ))}
                            </Typography>
                        </Grid>
                    )}
                </Collapse>
            </Grid>
        </Grid>
    );
};

export default IndividualExperienceCharges;
