import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import UserRole from '../../../shared/AuthRoles';
import { StreamChat } from 'stream-chat';
import { useGetChatTokenQuery } from '../../../app/Slices/SevenDayApiSlice';

const ConnectToChat = () => {
    const [connected, setConnected] = useState<boolean>(false);
    const { data, error: tokenError } = useGetChatTokenQuery({});

    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (connected || !data) {
            return;
        }

        const client = StreamChat.getInstance(
            process.env.REACT_APP_STREAM_API_KEY as string
        );

        let isInterrupted = false;

        const id = UserRole().sub.toString();
        const connectPromise = client
            .connectUser({ id }, data.token)
            .then(() => {
                if (isInterrupted) return;
                setConnected(true);
            }).catch(() => {
                setError(true);
                console.error('Error connecting to chat');
            })

        return () => {
            isInterrupted = true;
            setConnected(false);

            connectPromise.then(() => {
                client.disconnectUser();
            });
        };
    }, [data]);

    return !connected && !error && !tokenError ? <></> : <Outlet />
};
export default ConnectToChat;
