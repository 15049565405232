import { Grid, useMediaQuery } from '@mui/material';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { PhotoAlbum, RenderPhotoProps } from 'react-photo-album';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import React from 'react';

export default function IndividualExperiencePhotos(experience: any) {
    let photoArray = experience?.experience?.photos;
    const isMobile = useMediaQuery('(max-width: 770px)');

    const photosWithStyling = [
        { src: photoArray?.[0]?.src, width: 1080, height: 800 },
        { src: photoArray?.[1]?.src, width: 1080, height: 800 },
        { src: photoArray?.[2]?.src, width: 1080, height: 800 },
        { src: photoArray?.[3]?.src, width: 1080, height: 800 },
        { src: photoArray?.[4]?.src, width: 1080, height: 800 },
        { src: photoArray?.[5]?.src, width: 1080, height: 800 },
        { src: photoArray?.[6]?.src, width: 1080, height: 800 },
        { src: photoArray?.[7]?.src, width: 1080, height: 800 },
        { src: photoArray?.[8]?.src, width: 1080, height: 800 },
        { src: photoArray?.[9]?.src, width: 1080, height: 800 },
    ];
    const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];
    let removeUndefinedPhotos = photosWithStyling?.filter?.(
        (i: any) => i.src !== undefined
    );

    const photos: any[] = removeUndefinedPhotos.map((photo) => ({
        src: photo?.src,
        width: photo?.width,
        height: photo?.height,
        srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round(
                (photo?.height / photo?.width) * breakpoint
            );
            return {
                src: photo?.src,
                width: breakpoint,
                height,
            };
        }),
    }));

    const [open, setOpen] = React.useState(false);

    const renderPhoto = React.useCallback(
        ({ imageProps: { alt, style, ...rest } }: RenderPhotoProps) => (
            <img
                alt={alt}
                style={{
                    ...style,
                    width: style.width,
                    // height: 800,
                    borderRadius: '8px',
                    boxShadow: 'none',
                    objectFit: 'cover',
                    transition:
                        'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                }}
                {...rest}
            />
        ),
        []
    );

    return (
        <Grid
            sx={{
                width: '100%',
                height: '100%',
                paddingTop: isMobile ? '10px' : '50px',
            }}
        >
            {photoArray?.length === 0 ? null : (
                <>
                    <Grid>
                        {isMobile ? (
                            <Grid
                                // onClick={() => setOpen(true)}
                                sx={{ height: '300px', width: 'auto' }}
                            >
                                {' '}
                                <Slide autoplay={false}>
                                    {photoArray?.map(
                                        (pic: {
                                            id: string | undefined;
                                            src: string | undefined;
                                        }) => (
                                            <img
                                                alt={pic?.id}
                                                src={pic?.src}
                                                style={{
                                                    height: '300px',
                                                    width: 'auto',
                                                }}
                                            />
                                        )
                                    )}
                                </Slide>
                            </Grid>
                        ) : (
                            <>
                                {photoArray?.length === 5 ? (
                                    <PhotoAlbum
                                        photos={photos}
                                        layout={'columns'}
                                        columns={3}
                                        spacing={10}
                                        padding={0}
                                        targetRowHeight={0}
                                        renderPhoto={renderPhoto}
                                        onClick={() => setOpen(true)}
                                    />
                                ) : (
                                    <PhotoAlbum
                                        photos={photos}
                                        layout={'columns'}
                                        columns={(containerWidth) => {
                                            if (containerWidth < 400) return 2;
                                            if (containerWidth < 800) return 3;
                                            return 4;
                                        }}
                                        spacing={10}
                                        padding={0}
                                        targetRowHeight={0}
                                        renderPhoto={renderPhoto}
                                        onClick={() => setOpen(true)}
                                    />
                                )}
                            </>
                        )}
                    </Grid>

                    <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={photos}
                        plugins={[Fullscreen, Thumbnails, Zoom]}
                        zoom={{
                            maxZoomPixelRatio: 50,
                            zoomInMultiplier: 2,
                            doubleTapDelay: 0,
                            doubleClickDelay: 0,
                            keyboardMoveDistance: 0,
                            wheelZoomDistanceFactor: 0,
                            pinchZoomDistanceFactor: 0,
                            scrollToZoom: false,
                        }}
                        carousel={{ preload: 1, padding: 0, imageFit: 'cover' }}
                    />
                </>
            )}
        </Grid>
    );
}
