import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function AddBillingAccount() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To set up your billing account you will need to:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <br></br>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, select
                                "Admin Center" from the cog wheel icon and then
                                select "Billing".
                            </li>
                            <li>
                                On the 'Account Details' tab complete the
                                necessary fields and click 'Save'
                            </li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
