import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { ExperiencePolicy } from '../../../types/policy';
import PolicyModal from '../../Policies/PolicyModal';
import { capitalizeName } from '../../../../shared/utils';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';

interface Props {
    policy: ExperiencePolicy;
}

const ExperiencePolicyCard = ({ policy }: Props) => {
    const MAX_DESCRIPTION_LENGTH: number = 150;
    const isMobile = useMediaQuery('(max-width: 600px)');
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const truncateDescription = (description?: string) => {
        if (!description) return '';

        if (description.length > MAX_DESCRIPTION_LENGTH) {
            return description.substring(0, MAX_DESCRIPTION_LENGTH - 3) + '...';
        } else {
            return description;
        }
    };

    return (
        <>
            <Card
                sx={{
                    boxShadow: 1,
                    background: 'none',
                    minHeight: isMobile ? '50px' : '150px',
                    backgroundColor: 'transparent',
                    color: 'none',
                    cursor: 'pointer',
                    '&:hover': {
                        cursor: 'pointer',
                        color: '#fff',
                        backgroundColor: 'transparent',
                        background: 'none',
                    },
                    '&:focus': {
                        cursor: 'pointer',
                        color: '#fff',
                        backgroundColor: 'transparent',
                        background: 'none',
                    },
                }}
                // onClick={() => {
                //     navigate(`/bookingCap/${cap.id}/edit`);
                // }}
                style={{ flex: 1 }}
            >
                <CardActionArea
                    sx={{
                        background: 'none',
                        backgroundColor: 'transparent',
                        color: '#fff',
                        '&:hover': {
                            cursor: 'pointer',
                            color: '#fff',
                            backgroundColor: 'transparent',
                            background: 'none',
                        },
                        '&:focus': {
                            cursor: 'pointer',
                            color: '#fff',
                            backgroundColor: 'transparent',
                            background: 'none',
                        },
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#f6f8f9',
                        }}
                    >
                        <Grid>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '80px',
                                }}
                            >
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#5cb5b3',
                                        // fontSize: isIpad ? '16px':''
                                        alignSelf: 'left',
                                    }}
                                >
                                    {truncateDescription(
                                        capitalizeName(policy.name)
                                    )}
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'left',
                            gap: '15px',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    fontSize: '10px',
                                    paddingLeft: '2px',
                                    paddingBottom: '4px',
                                }}
                            >
                                {truncateDescription(policy.description)}
                            </Typography>
                            <CustomButton
                                color='green'
                                onClick={() => setModalOpen(true)}
                                sx={{
                                    fontSize: '10px',
                                    width: 'auto',
                                    textTransform: 'none', // Ensures text is in its normal case
                                    padding: '0px', // Removes padding
                                    border: 'none', // Removes the border outline
                                    backgroundColor: 'transparent', // No background color
                                    color: 'green', // Text color
                                    alignSelf: 'flex-start', // Left-aligns the button
                                    '&:hover': {
                                        backgroundColor: 'transparent', // No background on hover
                                    },
                                }}
                            >
                                See details
                            </CustomButton>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>

            <PolicyModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                policy={policy}
            />
        </>
    );
};

export default ExperiencePolicyCard;
