import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router';
import UserRole from '../../../shared/AuthRoles';
import TeamsToolbar from '../../components/Teams/TeamsToolbar';
import TeamList from '../../components/Teams/TeamList';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TeamsListPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedInUser.role !== 'guest_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'teamPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    const handleBackButton = () => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            navigate(`/adminCenter/host`);
        } else {
            navigate(`/adminCenter/guest`);
        }
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Teams
                    </Typography>
                </Grid>
                <TeamsToolbar />
                <Grid sx={{ height: isMobile ? '5%' : '10%' }}>
                    {/* <BookingCapToolbar /> */}
                </Grid>
                <Grid sx={{ height: isMobile ? '90%' : '85%' }}>
                    {' '}
                    <TeamList />
                </Grid>

                <CustomButton
                    onClick={handleBackButton}
                    color='green'
                    sx={{
                        marginLeft: !isMobile ? '-8px' : '-18px',
                        '&:hover': {
                            cursor: 'pointer',
                            color: '#5cb5b3',
                            backgroundColor: 'transparent',
                            background: 'none',
                        },
                    }}
                >
                    <ArrowBackIcon />{' '}
                    {isMobile ? null : (
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back
                        </Typography>
                    )}
                </CustomButton>
            </Grid>
        </Container>
    );
};

export default TeamsListPage;
