import React, { useEffect } from 'react';
import {
    Box,
    Container,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import UserRole from '../../../shared/AuthRoles';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Earnings } from '../../components/HostPayments/ReservationEarnings';

export const EarningsPage = () => {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, []);

    const navigate = useNavigate();

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Stack
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Typography
                        variant='h5'
                        color='textPrimary'
                        sx={{
                            textDecoration: 'underline',
                            textDecorationColor: (theme) =>
                                theme.palette.primary.main,
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Reservation Earnings
                    </Typography>
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            paddingTop: '10px',
                        }}
                    >
                      Get a clear overview of your earnings from all your listed experiences, right at your fingertips!
                    </Typography>
                </Box>
                <Earnings />
            </Stack>
        </Container>
    );
};
