import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRefreshMutation } from '../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../shared/AuthRoles';

const RequireAuth = () => {
    const [isLoading, setIsLoading] = useState(true);
    const loggedInUser = UserRole();
    const [refresh] = useRefreshMutation();

    useEffect(() => {
        const loadAccessToken = async () => {
            await refresh({});
            setIsLoading(false);
        };
        loadAccessToken();
    }, [refresh]);

    return isLoading ? (
        <></>
    ) : loggedInUser ? (
        <Outlet />
    ) : (
        <Navigate to={'/login'} replace />
    );
};
export default RequireAuth;
