import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Card,
    CardActionArea,
    CardContent,
    Fade,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectExperienceDiscounts } from '../../../../app/Slices/DiscountsSlice';
import {
    useGetDiscountsQuery,
    useGetFeeListQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../../shared/AuthRoles';
import {
    camelCaseToSentenceCase,
    camelCaseToTitleCase,
    capitalizeName,
    formatTime,
} from '../../../../shared/utils';
import GenericModal from '../../../shared/GenericModal';
import AIDiscountsAssistant from './Discounts/AIDiscountAssistant';
import AIEditDiscounts from './Discounts/AIEditDiscounts';
import { useFormContext, useWatch } from 'react-hook-form';
import BasePriceModal, { Pricing } from './BasePriceModal';
import {
    ExperienceCharge,
    ExperienceDiscount,
    FlexibleExperienceCharge,
} from '../../../types/experience';
import FeesModal from './FeesModal';
import {
    selectPriceRequired,
    setPriceRequired,
} from '../../../../app/Slices/PriceRequiredSlice';
import PricingModalTest from '../Owner/Pricing/PricingModal';
import { ArrowForward } from '@mui/icons-material';

export const findFeeById = (id: number, availableFees: any) => {
    return availableFees?.find((fee: any) => fee.id === id);
};

interface Props {
    experienceType: string;
    isEditing: boolean;
}

export const getFeeId = (type: string): string => {
    switch (type) {
        case 'perDeskPerDay':
            return 'priceDesk';
        case 'perOfficePerDay':
            return 'priceOffice';
        case 'perMeetingRoomPerDay':
            return 'priceMeetingRoom';
        case 'entireVenuePerDay':
        case 'entireVenuePerNight':
            return 'priceEntireVenue';
        default:
            return '';
    }
};

const ExperienceChargesForm = ({ experienceType, isEditing }: Props) => {
    const loggedInUser = UserRole();
    const dispatch = useDispatch();
    const urlRef = window.location.href.split('/')[4];

    const { setValue, getValues, control } = useFormContext();

    const priceRequired = useSelector(selectPriceRequired);

    let moduleId = parseInt(urlRef);
    const [showModalPrice, setShowModalPrice] = useState(false);
    const [showModalFee, setShowModalFee] = useState(false);
    const [showModalDiscounts, setShowModalDiscounts] = useState(false);
    const [showEditModalDiscounts, setShowEditModalDiscounts] = useState(false);
    const [showModalDiscountsOptions, setShowModalDiscountsOptions] =
        useState(false);
    const isMobile = useMediaQuery('(max-width: 700px)');
    const [spaceDetailsRequired, setSpaceDetailsRequired] = useState(false);

    const [availableSpaces, setAvailableSpaces] = useState<any[]>([]);

    const { data: availableFees = [] } = useGetFeeListQuery(
        loggedInUser?.company
    );

    //get discounts applied to NEW experience
    const newExperienceDiscounts = useSelector(selectExperienceDiscounts);
    const [displayExperienceIdDiscounts, setDisplayExperienceIdDiscounts] =
        useState<any[]>([]);

    //get discounts applied to existing experience
    const { data: getDiscounts } = useGetDiscountsQuery(
        `/owners/${loggedInUser?.company}/discounts`
    );

    useEffect(() => {
        setDisplayExperienceIdDiscounts(
            newExperienceDiscounts?.length !== 0
                ? newExperienceDiscounts
                : getDiscounts?.filter((i: any) => {
                      return i?.module?.id === moduleId;
                  })
        );
    }, [getDiscounts, newExperienceDiscounts]);

    const desks = useWatch({ control, name: 'details.desks' });
    const privateOffices = useWatch({
        control,
        name: 'details.privateOffices',
    });
    const meetingRooms = useWatch({ control, name: 'details.meetingRooms' });
    const subType = useWatch({ control, name: 'details.subType' });

    useEffect(() => {
        const commercialDetails = {
            desks: desks,
            privateOffices: privateOffices,
            meetingRooms: meetingRooms,
            entireVenue: 1,
        };

        setAvailableSpaces(
            Object.entries(commercialDetails)
                .filter(([_, value]) => parseInt(value) > 0)
                .map(([key, _]) => {
                    switch (key) {
                        case 'desks':
                            return {
                                feeId: 'priceDesk',
                                spaceType: 'desk',
                                unitPrice: '',
                                type: 'perDeskPerDay',
                                chargeType: 'price',
                            };
                        case 'privateOffices':
                            return {
                                feeId: 'priceOffice',
                                spaceType: 'privateOffice',
                                unitPrice: '',
                                type: 'perOfficePerDay',
                                chargeType: 'price',
                            };
                        case 'meetingRooms':
                            return {
                                feeId: 'priceMeetingRoom',
                                spaceType: 'meetingRoom',
                                unitPrice: '',
                                type: 'perMeetingRoomPerDay',
                                chargeType: 'price',
                            };
                        default:
                            return {
                                feeId: 'priceEntireVenue',
                                spaceType: 'entireVenue',
                                unitPrice: '',
                                type: '',
                                chargeType: 'price',
                            };
                    }
                })
        );

        setSpaceDetailsRequired(experienceType === 'commercial' && !subType);
    }, [desks, privateOffices, meetingRooms, subType, experienceType]);

    const charges: ExperienceCharge[] = useWatch({ control, name: 'charges' });
    const discounts: ExperienceDiscount[] = useWatch({
        control,
        name: 'discounts',
    });

    const handlePrice = (data: Pricing) => {
        const charges: ExperienceCharge[] = getValues('charges');

        setValue('charges', [
            ...charges.filter((charge) => charge.chargeType === 'fee'),
            ...data.prices,
        ]);

        setShowModalPrice(false);
    };

    const handleMultiPrice = (data: any) => {
        const charges: ExperienceCharge[] = getValues('charges');

        setValue('charges', [
            ...charges.filter((charge) => charge.chargeType === 'fee'),
            data,
        ]);

        setShowModalPrice(false);
    };

    const handleFees = (data: any) => {
        const charges: ExperienceCharge[] = getValues('charges');

        setValue('charges', [
            ...charges.filter((charge) => charge.chargeType !== 'fee'),
            ...data.fees,
        ]);

        setShowModalFee(false);
    };

    const flexiblePrice = charges.filter(
        (charge: any) => charge.chargeType === 'flexiblePrice'
    );

    const prices = charges.filter(
        (charge: any) =>
            charge.chargeType === 'price' ||
            charge.chargeType === 'flexiblePrice'
    );

    const additionalFees = charges.filter(
        (charge: any) => charge.chargeType === 'fee'
    );

    const weekendDays = prices.find((price: any) => price?.weekendPrice)
        ?.weekendPrice?.days;

    const handleCancel = () => {
        setShowModalPrice(false);
        setShowModalFee(false);
        setShowModalDiscounts(false);
        setShowEditModalDiscounts(false);
    };

    const handleOpenPriceModal = () => {
        if (experienceType === '' || spaceDetailsRequired) {
            return;
        }

        setShowModalPrice(true);
    };

    useEffect(() => {
        if (experienceType === 'commercial') {
            const shouldUpdateCharges =
                parseInt(desks) === 0 ||
                parseInt(privateOffices) === 0 ||
                parseInt(meetingRooms) === 0;

            if (shouldUpdateCharges) {
                const updatedCharges = [
                    ...additionalFees,
                    ...prices.filter(
                        (price) =>
                            price.chargeType !== 'flexiblePrice' &&
                            availableSpaces
                                .map((space) => space.feeId)
                                .includes(getFeeId(price.type))
                    ),
                ];

                if (
                    updatedCharges.filter(
                        (charge) => charge.chargeType === 'price'
                    ).length > 0
                ) {
                    setValue('charges', updatedCharges);
                }
            }
        }
    }, [desks, privateOffices, meetingRooms, subType, experienceType]);

    useEffect(() => {
        if (experienceType === 'commercial') {
            const missingSpaces = availableSpaces.filter(
                (space) =>
                    !prices
                        .map(
                            (price) =>
                                price.chargeType !== 'flexiblePrice' &&
                                getFeeId(price.type)
                        )
                        .includes(space.feeId)
            );
            dispatch(setPriceRequired(missingSpaces));
        }
    }, [availableSpaces, charges]);

    const handleOpenFeeModal = () => {
        if (experienceType === '') {
            return;
        }

        setShowModalFee(true);
    };

    const handleOpenDiscountModal = () => {
        if (experienceType === '') {
            return;
        } else if (discounts?.length === 0) {
            //if there are no discounts show AI modal

            setShowModalDiscounts(true);
        } else {
            //if there is an exisiting discount offer them the option to edit or create a new one\

            setShowModalDiscountsOptions(true);
        }
    };

    const handleOpenNewDiscountModal = () => {
        //if there are no discounts show AI modal
        setShowModalDiscounts(true);
        setShowModalDiscountsOptions(false);
    };

    const handleOpenEditDiscountModal = () => {
        //if there are  discounts show AI Edit modal
        setShowEditModalDiscounts(true);
        setShowModalDiscountsOptions(false);
    };

    return (
        <Grid>
            <Grid
           container columns={{ xs: 1, sm: 2, md: 3 }} spacing={4}
            >
                <Grid
                  item xs={1}
                >
                    <Card
                        onClick={() => {
                            handleOpenPriceModal();
                        }}
                        sx={{
                            boxShadow: 1,
                            background: 'none',
                            height:
                                experienceType === 'commercial'
                                    ? '270px'
                                    : '230px',
                                    width:'100%',

                            backgroundColor: 'transparent',
                            color: 'none',
                            cursor: spaceDetailsRequired
                                ? 'default'
                                : 'pointer',
                            '&:hover': {
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        // onClick={() => {
                        //     navigate(`/fees/${fee.id}/edit`);
                        // }}
                        // style={{ flex: 1 }}
                    >
                        <CardActionArea
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    color: '#fff',
                                    cursor: spaceDetailsRequired
                                        ? 'default'
                                        : 'pointer',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: '#f6f8f9',
                                }}
                            >
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        component='div'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: spaceDetailsRequired
                                                ? '#A9A9A9'
                                                : '#787878',
                                            '&:hover': {
                                                color: spaceDetailsRequired
                                                    ? '#A9A9A9'
                                                    : '#5cb5b3',
                                            },

                                            padding: '0px 5px 0px 0px',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <EditIcon
                                            fontSize='small'
                                            sx={{ fontSize: '16px' }}
                                        />
                                    </Typography>
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '80px',
                                            paddingTop: 0,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: spaceDetailsRequired
                                                    ? '#A9A9A9'
                                                    : '#5cb5b3',
                                                // fontSize: isIpad ? '16px':''
                                            }}
                                        >
                                            Base Price
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '12px',
                                                paddingLeft: '2px',
                                            }}
                                        >
                                            {prices[0]?.type &&
                                            experienceType !== 'commercial'
                                                ? camelCaseToSentenceCase(
                                                      prices[0]?.type
                                                  )
                                                : experienceType ===
                                                  'commercial'
                                                ? ''
                                                : `Per night/day`}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                {prices?.length > 0 &&
                                    prices?.map((price: ExperienceCharge) => (
                                        <>
                                            <Fade in={true} timeout={500}>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '4px',
                                                    }}
                                                >
                                                    {price.chargeType ===
                                                    'flexiblePrice' ? (
                                                        <Stack>
                                                            <Typography
                                                                sx={{
                                                                    color: 'text.primary',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                - $
                                                                {price.dayPrice}
                                                                /day
                                                                {price.weekendPrice &&
                                                                    ` ($${price.weekendPrice.dayPrice} w/e)`}
                                                                :{' '}
                                                                {formatTime(
                                                                    price.dayCheckin
                                                                )}
                                                                -
                                                                {formatTime(
                                                                    price.dayCheckout
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    color: 'text.primary',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                - $
                                                                {
                                                                    price.nightPrice
                                                                }
                                                                /night
                                                                {price.weekendPrice &&
                                                                    ` ($${price.weekendPrice.nightPrice} w/e)`}
                                                                :{' '}
                                                                {formatTime(
                                                                    price.nightCheckin
                                                                )}
                                                                -
                                                                {formatTime(
                                                                    price.nightCheckout
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    color: 'text.primary',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                - $
                                                                {
                                                                    price.hourPrice
                                                                }
                                                                /hour
                                                                {price.weekendPrice &&
                                                                    ` ($${price.weekendPrice.hourPrice} w/e)`}
                                                            </Typography>
                                                        </Stack>
                                                    ) : (
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                $
                                                                {Number(
                                                                    price.unitPrice
                                                                ).toFixed(
                                                                    2
                                                                )}{' '}
                                                                AUD{' '}
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            'normal',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    (ex GST)
                                                                </span>
                                                            </Typography>
                                                            {experienceType ===
                                                                'commercial' && (
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    -{' '}
                                                                    {`${camelCaseToSentenceCase(
                                                                        price?.type
                                                                    )}`}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    )}
                                                </Grid>
                                            </Fade>
                                            {price?.weekendPrice &&
                                                price.chargeType !==
                                                    'flexiblePrice' && (
                                                    <Fade
                                                        in={
                                                            !!price?.weekendPrice
                                                        }
                                                        timeout={500}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            $
                                                            {
                                                                price
                                                                    ?.weekendPrice
                                                                    ?.unitPrice
                                                            }{' '}
                                                            AUD (ex GST) -
                                                            Weekend Price (
                                                            {weekendDays?.map(
                                                                (
                                                                    day: any,
                                                                    index: number
                                                                ) => (
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {capitalizeName(
                                                                            day
                                                                        )}
                                                                        {index <
                                                                        weekendDays.length -
                                                                            1
                                                                            ? ', '
                                                                            : ''}
                                                                    </span>
                                                                )
                                                            )}
                                                            )
                                                        </Typography>
                                                    </Fade>
                                                )}
                                        </>
                                    ))}
                                {prices?.length === 0 &&
                                    !(experienceType === '') &&
                                    !spaceDetailsRequired && (
                                        <Fade
                                            in={
                                                prices?.length === 0 &&
                                                !(experienceType === '') &&
                                                !spaceDetailsRequired
                                            }
                                            timeout={500}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Click to enter the base price
                                                for the experience
                                            </Typography>
                                        </Fade>
                                    )}

                                {experienceType === '' && (
                                    <Fade
                                        in={prices?.length === 0}
                                        timeout={500}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: 'red',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Please Select Type of Experience
                                        </Typography>
                                    </Fade>
                                )}
                                {spaceDetailsRequired && (
                                    <Fade
                                        in={prices?.length === 0}
                                        timeout={500}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: 'red',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Please Fill Out the Space Details
                                        </Typography>
                                    </Fade>
                                )}
                                {priceRequired.length > 0 &&
                                    !spaceDetailsRequired &&
                                    experienceType === 'commercial' && (
                                        <Fade
                                            in={priceRequired.length > 0}
                                            timeout={500}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: 'red',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {`No Price Has Been Set for ${
                                                    priceRequired?.length > 1
                                                        ? priceRequired
                                                              .slice(0, -1)
                                                              .map((space) =>
                                                                  camelCaseToTitleCase(
                                                                      space.spaceType
                                                                  )
                                                              )
                                                              .join(', ') +
                                                          ' and ' +
                                                          camelCaseToTitleCase(
                                                              priceRequired[
                                                                  priceRequired?.length -
                                                                      1
                                                              ]?.spaceType
                                                          )
                                                        : priceRequired
                                                              ?.map((space) =>
                                                                  camelCaseToTitleCase(
                                                                      space?.spaceType ||
                                                                          ''
                                                                  )
                                                              )
                                                              .join('')
                                                }`}
                                            </Typography>
                                        </Fade>
                                    )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <PricingModalTest
                    open={experienceType === 'property' && showModalPrice}
                    onClose={() => setShowModalPrice(false)}
                    onSubmit={handleMultiPrice}
                    price={flexiblePrice[0] as FlexibleExperienceCharge}
                />
                <BasePriceModal
                    open={experienceType !== 'property' && showModalPrice}
                    onClose={() => setShowModalPrice(false)}
                    prices={prices}
                    experienceType={experienceType}
                    availableSpaces={availableSpaces}
                    onSubmit={handlePrice}
                    isEditing={isEditing}
                />
            
                <Grid item xs={1}>
                    <Card
                        onClick={() => {
                            handleOpenFeeModal();
                        }}
                        sx={{
                            boxShadow: 1,
                            background: 'none',
                            // minHeight: isMobile ? '50px' : '150px',
                            // minWidth: '250px',
                            // width: '330px',
                            height:
                                experienceType === 'commercial'
                                    ? '270px'
                                    : '230px',
                            backgroundColor: 'transparent',
                            color: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        // onClick={() => {
                        //     navigate(`/fees/${fee.id}/edit`);
                        // }}
                        style={{ flex: 1 }}
                    >
                        <CardActionArea
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: '#f6f8f9',
                                }}
                            >
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        component='div'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            color: '#787878',
                                            '&:hover': {
                                                color: '#5cb5b3',
                                            },

                                            padding: '0px 5px 0px 0px',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <EditIcon
                                            fontSize='small'
                                            sx={{ fontSize: '16px' }}
                                        />
                                    </Typography>
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '80px',
                                            paddingTop: 0,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#5cb5b3',
                                                // fontSize: isIpad ? '16px':''
                                            }}
                                        >
                                            Additional Charges
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '12px',
                                                paddingLeft: '2px',
                                            }}
                                        >
                                            Cleaning, pets, etc
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {additionalFees?.map(
                                    (fee: any, index: number) => (
                                        <Fade
                                            in={true}
                                            key={index}
                                            timeout={500}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {`- ${
                                                    typeof fee?.feeId !==
                                                    'number'
                                                        ? ''
                                                        : camelCaseToSentenceCase(
                                                              findFeeById(
                                                                  fee?.feeId,
                                                                  availableFees
                                                              )?.name || ''
                                                          )
                                                } $${Number(
                                                    fee?.unitPrice
                                                ).toFixed(2)} AUD (ex GST)`}
                                            </Typography>
                                        </Fade>
                                    )
                                )}
                                {!(experienceType === '') && (
                                    <Fade
                                        in={additionalFees.length === 0}
                                        timeout={500}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                            }}
                                        >
                                            No additional charges have been
                                            added
                                        </Typography>
                                    </Fade>
                                )}
                                <Fade in={experienceType === ''} timeout={500}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: 'red',
                                            fontSize: '14px',
                                        }}
                                    >
                                        Please Select Type of Experience
                                    </Typography>
                                </Fade>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
             
                <Grid item xs={1}>
                    <Card
                        onClick={() => {
                            handleOpenDiscountModal();
                        }}
                        sx={{
                            boxShadow: 1,
                            background: 'none',
                            // minHeight: isMobile ? '50px' : '150px',
                            // minWidth: '250px',
                            // width: '330px',
                            height:
                                experienceType === 'commercial'
                                    ? '270px'
                                    : '230px',
                            backgroundColor: 'transparent',
                            color: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        style={{ flex: 1 }}
                    >
                        <CardActionArea
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: '#f6f8f9',
                                }}
                            >
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        component='div'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            color: '#787878',
                                            '&:hover': {
                                                color: '#5cb5b3',
                                            },

                                            padding: '0px 5px 0px 0px',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <EditIcon
                                            fontSize='small'
                                            sx={{ fontSize: '16px' }}
                                        />
                                    </Typography>
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '80px',
                                            paddingTop: 0,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#5cb5b3',
                                                // fontSize: isIpad ? '16px':''
                                            }}
                                        >
                                            Discounts
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '12px',
                                                paddingLeft: '2px',
                                            }}
                                        >
                                            Apply discounts to your listed
                                            experience to attract more bookings.
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {discounts?.length > 0 && (
                                    <>
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                            }}
                                        >
                                            You have {discounts?.length}{' '}
                                            discounts for this experience.
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Active:{' '}
                                            {
                                                discounts?.filter(
                                                    (discounts: any) =>
                                                        discounts?.expired ===
                                                            false &&
                                                        discounts?.disabled ===
                                                            false
                                                ).length
                                            }
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Disabled:{' '}
                                            {
                                                discounts?.filter(
                                                    (discounts: any) =>
                                                        discounts?.disabled ===
                                                        true
                                                ).length
                                            }
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Expired:{' '}
                                            {
                                                discounts?.filter(
                                                    (discounts: any) =>
                                                        discounts?.expired ===
                                                        true
                                                ).length
                                            }
                                        </Typography>
                                    </>
                                )}

                                {discounts?.map(
                                    (discount: any, index: number) => (
                                        <Fade
                                            in={true}
                                            key={index}
                                            timeout={500}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {/* {' '}
                                                <li>
                                                    {discount?.name
                                                        .charAt(0)
                                                        ?.toUpperCase() +
                                                        discount?.name?.slice(
                                                            1
                                                        )}{' '}
                                                    - {discount?.percentage}%
                                                    discount for (
                                                    {discount?.dates?.length ===
                                                    1
                                                        ? `${moment(
                                                              discount
                                                                  ?.dates?.[0]
                                                                  .startDate
                                                          ).format(
                                                              'DD/MM/YYYY'
                                                          )} - ${moment(
                                                              discount
                                                                  ?.dates?.[0]
                                                                  .endDate
                                                          ).format(
                                                              'DD/MM/YYYY'
                                                          )}`
                                                        : discount?.dates?.map(
                                                              (i: any) => {
                                                                  return `${moment(
                                                                      i?.startDate
                                                                  ).format(
                                                                      'DD/MM/YYYY'
                                                                  )}
                                                                          -
                                                                          ${moment(
                                                                              i?.endDate
                                                                          ).format(
                                                                              'DD/MM/YYYY'
                                                                          )},`;
                                                              }
                                                          )}
                                                    )
                                                </li>{' '} */}
                                            </Typography>
                                        </Fade>
                                    )
                                )}
                                {!(experienceType === '') && (
                                    <Fade
                                        in={discounts.length === 0}
                                        timeout={500}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                            }}
                                        >
                                            No discounts have been added
                                        </Typography>
                                    </Fade>
                                )}
                                <Fade in={experienceType === ''} timeout={500}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: 'red',
                                            fontSize: '14px',
                                        }}
                                    >
                                        Please Select Type of Experience
                                    </Typography>
                                </Fade>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <FeesModal
                open={showModalFee}
                onClose={() => setShowModalFee(false)}
                onSubmit={handleFees}
                fees={additionalFees}
                experienceType={experienceType}
            />

            <GenericModal
                open={showModalDiscounts}
                onClose={() => setShowModalDiscounts(false)}
            >
                <Grid sx={{ padding: '30px' }}>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Grid
                            item
                            xs={25}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '3px',
                            }}
                        >
                            <Grid>
                                {' '}
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Discounts %
                                </Typography>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    via 7DAY AI Assistant
                                </Typography>
                            </Grid>
                            <AIDiscountsAssistant
                                newExpDiscount={newExperienceDiscounts}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '10px',
                        gap: '10px',
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color={
                                discounts?.length === 0 ? 'lightGrey' : 'green'
                            }
                            onClick={handleCancel}
                            autoFocus
                        >
                            {discounts?.length === 0 ? 'Close' : 'Done'}
                        </CustomButton>
                    </Grid>
                </Grid>
            </GenericModal>
            <GenericModal
                open={showModalDiscountsOptions}
                onClose={() => setShowModalDiscountsOptions(false)}
            >
                <Grid sx={{ padding: '30px' }}>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Grid
                            item
                            xs={25}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '3px',
                            }}
                        >
                            <Grid
                                sx={{
                                    width: '100%',
                                    minHeight: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Card
                                    onClick={() => handleOpenNewDiscountModal()}
                                    sx={{
                                        width: isMobile ? 'auto' : 400,
                                        minWidth: isMobile ? 150 : 200,
                                        maxWidth: 300,
                                        maxHeight: 100,
                                        textAlign: 'center',
                                        marginRight: isMobile ? '0px' : '10px',
                                        marginBottom: isMobile ? '10px' : '0px',
                                        boxShadow: 1,
                                        background: 'none',
                                        backgroundColor: 'transparent',
                                        color: 'none',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: '#5cb5b3',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                        '&:focus': {
                                            cursor: 'pointer',
                                            color: '#5cb5b3',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                    }}
                                >
                                    <CardActionArea
                                        // disableRipple
                                        // disableTouchRipple
                                        sx={{
                                            background: 'none',
                                            backgroundColor: 'transparent',
                                            color: '#fff',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                            '&:focus': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                        }}
                                    >
                                        <CardContent
                                            sx={{
                                                '&:hover': {
                                                    color: '#5cb5b3',
                                                },
                                            }}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant='h5'
                                                component='div'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                <AddCircleIcon />
                                            </Typography>
                                            <Typography
                                                variant='h6'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    '&:hover': {
                                                        color: '#5cb5b3',
                                                    },
                                                }}
                                            >
                                                {isMobile
                                                    ? 'New %'
                                                    : 'Create New Discount %'}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Card
                                    onClick={() =>
                                        handleOpenEditDiscountModal()
                                    }
                                    sx={{
                                        width: isMobile ? 'auto' : 400,
                                        minWidth: isMobile ? 150 : 200,
                                        maxWidth: 300,
                                        maxHeight: 100,
                                        textAlign: 'center',
                                        marginRight: isMobile ? '0px' : '10px',
                                        marginBottom: isMobile ? '10px' : '0px',
                                        boxShadow: 1,
                                        background: 'none',
                                        backgroundColor: 'transparent',
                                        color: 'none',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: '#fff',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                        '&:focus': {
                                            cursor: 'pointer',
                                            color: '#fff',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                    }}
                                >
                                    <CardActionArea
                                        // disableRipple
                                        // disableTouchRipple
                                        sx={{
                                            background: 'none',
                                            backgroundColor: 'transparent',
                                            color: '#fff',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                            '&:focus': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant='h5'
                                                component='div'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                <VisibilityIcon />
                                            </Typography>
                                            <Typography
                                                variant='h6'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    '&:hover': {
                                                        color: '#5cb5b3',
                                                    },
                                                }}
                                            >
                                                {isMobile
                                                    ? 'View %'
                                                    : ' View Your Discounts %'}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </GenericModal>
            <GenericModal
                open={showEditModalDiscounts}
                onClose={() => setShowEditModalDiscounts(false)}
            >
                <Grid sx={{ padding: '30px' }}>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Grid
                            item
                            xs={25}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '3px',
                            }}
                        >
                            <Grid>
                                {' '}
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Your Discounts
                                    {discounts?.length !== 0
                                        ? ''
                                        : ` for ${
                                              discounts?.[0]?.module?.name
                                                  .charAt(0)
                                                  ?.toUpperCase() +
                                              displayExperienceIdDiscounts?.[0]?.module?.name?.slice(
                                                  1
                                              )
                                          }`}
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    width: '100%',
                                    minHeight: '100%',
                                    display: 'flex',
                                    justifyContent: isMobile
                                        ? 'flex-start'
                                        : 'center',
                                }}
                            >
                                <AIEditDiscounts discounts={discounts} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '10px',
                        gap: '10px',
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color={
                                displayExperienceIdDiscounts?.length === 0
                                    ? 'green'
                                    : 'lightGrey'
                            }
                            onClick={handleCancel}
                            autoFocus
                        >
                            {displayExperienceIdDiscounts?.length === 0
                                ? 'Done'
                                : 'Close'}
                        </CustomButton>
                    </Grid>
                </Grid>
            </GenericModal>
        </Grid>
    );
};

export default ExperienceChargesForm;
