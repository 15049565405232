import { Clear, Settings } from '@mui/icons-material';
import {
    Alert,
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useChannelStateContext } from 'stream-chat-react';
import {
    useGetIndividualAssetQuery,
    useGetIndividualReservationQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import MembersModal from './MembersModal';
import ExperienceDetails from './ExperienceDetails';
import ReservationDetails from './ReservationDetails';
import UserRole from '../../../../shared/AuthRoles';

interface ChannelDetailsProps {
    open: boolean;
    handleClose: () => void;
}

const ChannelDetails = ({ open, handleClose }: ChannelDetailsProps) => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();

    const { channel, members } = useChannelStateContext();

    const role = members && members[loggedInUser?.sub]?.channel_role;

    const { data: experience, isLoading: experienceLoading } = useGetIndividualAssetQuery(
        `/modules/${channel?.data?.experienceId}`,
        { skip: !channel?.data?.experienceId }
    );

    const { data: booking } = useGetIndividualReservationQuery(
        `bookings/${channel?.data?.bookingId}`,
        { skip: !channel?.data?.experienceId }
    );

    const [membersOpen, setMembersOpen] = useState<boolean>(false);

    return (
        <>
            <Drawer
                open={open}
                variant={'persistent'}
                anchor='right'
                sx={{ position: 'fixed' }}
                PaperProps={{
                    sx: { width: isMobile ? '100%' : '400px' },
                }}
            >
                <Toolbar />
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    px={2}
                    pt={2}
                >
                    <Typography
                        fontSize='18px'
                        fontWeight='bold'
                        color='#787878'
                    >
                        Details
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Clear />
                    </IconButton>
                </Box>
                <Stack
                    p={2}
                    spacing={2}
                    overflow='scroll'
                    flex={1}
                    divider={<Divider />}
                    // hide scrollbar
                    sx={{
                        '-ms-overflow-style': 'none',
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {members && (
                        <Stack gap={1} alignItems='flex-start'>
                            <AvatarGroup>
                                {Object.entries(members).map(([id, member]) => (
                                    <Tooltip title={member.user?.name}>
                                        <Avatar
                                            key={id}
                                            src={member.user?.image}
                                        >
                                            {member.user?.name?.charAt(0)}
                                        </Avatar>
                                    </Tooltip>
                                ))}
                            </AvatarGroup>
                            {loggedInUser?.role.includes('owner') && role === 'channel_member' && (
                                <Button
                                    onClick={() => setMembersOpen(true)}
                                    startIcon={<Settings />}
                                >
                                    Members
                                </Button>
                            )}
                            <MembersModal
                                open={membersOpen}
                                onClose={() => setMembersOpen(false)}
                                channel={channel}
                                members={members}
                            />
                        </Stack>
                    )}
                    {experience && (
                        <ExperienceDetails
                            experience={experience}
                            booking={booking}
                        />
                    )}
                    {!experienceLoading && !experience && (
                        <Alert severity='error'>This experience is no longer listed</Alert>
                    )}
                    {booking && (
                        <ReservationDetails
                            booking={booking}
                        />
                    )}
                </Stack>
            </Drawer>
        </>
    );
};

export default ChannelDetails;
