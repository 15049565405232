import {
    BaseQueryFn,
    createApi,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { getAccessToken, loadingAccessToken, logOut } from './AuthSlice';
import median from 'median-js-bridge';
import { Mutex } from 'async-mutex'; //https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
import {
    Channel,
    ChatEnquiry,
    Earnings,
    GuestInvoice,
    JoinChannel,
    ReservationChat,
    XeroAccount,
    XeroConnection,
    XeroConsentUrl,
    XeroSettings,
} from './SevenDayApiTypes';

const { REACT_APP_BASE_URL } = process.env;
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_BASE_URL,
    mode: 'cors',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getAccessToken(getState());
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

let refreshResult: any;
const refreshQuery = async (args: any, api: any, extraOptions: any) => {
    if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
            // send refresh token to get new access token
            refreshResult = await baseQuery(
                { url: '/refresh', method: 'POST' },
                api,
                extraOptions
            );
            if (refreshResult.data) {
                // store the new token
                api.dispatch(loadingAccessToken(refreshResult.data));
            } else {
                // Logout if refresh token is expired
                api.dispatch(logOut());
            }
            return refreshResult;
        } finally {
            release();
        }
    } else {
        await mutex.waitForUnlock();
        return refreshResult;
    }
};

const baseQueryWithReauth: BaseQueryFn = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    // Get a new access token if it is expired
    if (
        result?.error?.status === 401 &&
        (result.error.data as { message: string }).message === 'Unauthorized'
    ) {
        const refreshResult = await refreshQuery({}, api, extraOptions);
        if (refreshResult.data) {
            // retry the original query with new access token
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};
export const sevenDayApi = createApi({
    reducerPath: 'SevenDayApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Assets',
        'BookingCaps',
        'SignIn',
        'Profile',
        'Owners',
        'Guest',
        'Users',
        'Clients',
        'Reservations',
        'Reports',
        'Fees',
        'Teams',
        'Policies',
        'Chat',
        'AI',
        'Platform',
        'Billing',
        'Discounts',
        'XeroSettings',
    ],
    endpoints: (builder) => ({
        getAllAssets: builder.query<any[], any>({
            query: ({ url, ...params }) => ({ url, params }),
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Assets', 'SignIn'],
        }),
        getIndividualOwnerAssets: builder.query<any, any>({
            query: ({ url, ...params }) => ({ url, params }),
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Assets', 'SignIn'],
        }),
        getIndividualAsset: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Assets', 'SignIn'],
        }),
        addAsset: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/modules`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Assets'],
        }),
        addPhotos: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/modules/photos`,
                method: 'POST',
                body: data[1],
                // headers: {
                //     "Content-Type": "multipart/form-data",
                //   }
            }),
            // invalidatesTags: ['Assets', 'SignIn'],
        }),
        editPhotos: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/modules/photos`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Assets', 'SignIn'],
        }),
        editAsset: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/modules/${data[1]}`,
                method: 'PATCH',
                body: data[2],
            }),
            invalidatesTags: ['Assets'],
        }),

        deleteAsset: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/modules/${data[1]}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Assets'],
        }),
        calculatePrice: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/modules/calculate-price`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: [],
        }),
        getAssetCalendarAvailability: builder.query<any, any>({
            query: ({ url, ...params }) => ({ url, params }),
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Assets', 'Reservations', 'SignIn'],
        }),

        signIn: builder.mutation<any, any>({
            query: (data) => ({
                url: '/signin',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['SignIn'],
        }),

        refresh: builder.mutation<any, any>({
            queryFn: refreshQuery,
        }),

        signOut: builder.mutation<any, any>({
            queryFn: async (args, api, extraOptions: any) => {
                const result = await baseQueryWithReauth(
                    { url: '/signout', method: 'POST' },
                    api,
                    extraOptions
                );
                median.onesignal.externalUserId.remove();
                api.dispatch(logOut());
                return result;
            },
        }),

        getIndividualOwner: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Owners', 'SignIn'],
        }),
        getIndividualOwnerUsers: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Owners', 'SignIn'],
        }),
        addIndividualOwnerUsers: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/users`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Users'],
        }),
        addIndividualGuestUsers: builder.mutation<any, any>({
            query: (data) => ({
                url: `/guests/${data[0]}/users`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Users', 'Teams'],
        }),

        validateImportGuestUsers: builder.mutation<any, any>({
            query: (data) => ({
                url: `/guests/${data[0]}/users/validate-import`,
                method: 'POST',
                body: data[1],
            }),

            invalidatesTags: ['Users', 'Teams', 'Assets'],
        }),
        importGuestUsers: builder.mutation<any, any>({
            query: (data) => ({
                url: `/guests/${data[0]}/users/import`,
                method: 'POST',
                body: data[1],
            }),

            invalidatesTags: ['Users', 'Teams', 'Assets'],
        }),

        editProfileDetails: builder.mutation<any, any>({
            query: (data) => ({
                url: `/profile`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Profile', 'Teams', 'Users'],
        }),
        addIndividual7DayAdmin: builder.mutation<any, any>({
            query: (data) => ({
                url: `/7-day/users`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Users'],
        }),
        addNewClient: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Clients'],
        }),
        getAllClients: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Clients', 'SignIn'],
        }),
        getIndividualClient: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Clients', 'SignIn'],
        }),
        editClient: builder.mutation<any, any>({
            query: (data) => ({
                url: data[0],
                method: 'PATCH',
                body: data[1],
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Clients'],
        }),
        deleteClient: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Clients'],
        }),
        editCompany: builder.mutation<any, any>({
            query: (data) => ({
                url: data[0],
                method: 'PATCH',
                body: data[1],
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Profile'],
        }),
        getCompanyOwnerInfo: builder.query<any, any>({
            query: (id) => `/owners/${id}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Profile', 'SignIn'],
        }),

        getCompanyGuestsInfo: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Profile', 'SignIn'],
        }),
        getProfileCompanyInfo: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Profile', 'SignIn'],
        }),
        getProfileDetails: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Profile', 'SignIn'],
        }),
        deleteProfilePicture: builder.mutation<any, any>({
            query: () => ({
                url: '/profile/picture',
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile', 'SignIn'],
        }),
        createProfilePicture: builder.mutation<any, any>({
            query: (data) => ({
                url: 'profile/picture',
                method: 'POST',
                body: data,
                formData: true,
            }),
        }),

        createChatEnquiry: builder.mutation<Channel, ChatEnquiry>({
            query: (data) => ({
                url: 'chat/enquiry',
                method: 'POST',
                body: data,
            }),
        }),

        createReservationChat: builder.mutation<Channel, ReservationChat>({
            query: (data) => ({
                url: 'chat/booking-message',
                method: 'POST',
                body: data,
            }),
        }),

        joinChannel: builder.mutation<Channel, JoinChannel>({
            query: (data) => ({
                url: 'chat/join-channel',
                method: 'POST',
                body: data,
            }),
        }),

        getChatUsers: builder.query<any, any>({
            query: (userIds) => {
                const params = new URLSearchParams();
                userIds.forEach((id: string) => params.append('userIds', id));
                return `/chat/users?${params.toString()}`;
            },
            providesTags: ['Chat', 'SignIn'],
        }),

        getChatToken: builder.query({
            query: () => {
                return `/chat/auth`;
            },
            providesTags: ['SignIn'],
        }),
        getUserList: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Users', 'SignIn'],
        }),
        getBookingCapList: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['BookingCaps', 'SignIn'],
        }),
        addIndividualBookingCap: builder.mutation<any, any>({
            query: (data) => ({
                url: `/booking-caps`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['BookingCaps'],
        }),
        getIndividualBookingCap: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['BookingCaps', 'SignIn'],
        }),
        getBookingCapAvailability: builder.mutation<any, any>({
            query: (data) => ({
                url: `/bookings/checkAvailability`,
                method: 'POST',
                body: data,
            }),
            // invalidatesTags: ['BookingCaps', 'SignIn'],
        }),
        editBookingCap: builder.mutation<any, any>({
            query: (data) => ({
                url: `/booking-caps/${data[0]}`,
                method: 'PATCH',
                body: data[1],
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['BookingCaps'],
        }),
        deleteBookingCap: builder.mutation<any, any>({
            query: (data) => ({
                url: `/booking-caps/${data[0]}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['BookingCaps'],
        }),
        getFeeList: builder.query<any, any>({
            query: (company) => `/owners/${company}/fees`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Fees', 'SignIn'],
        }),
        addIndividualFee: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/fees`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Fees'],
        }),
        getIndividualFee: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Fees', 'SignIn'],
        }),
        editFee: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/fees/${data[1]}`,
                method: 'PATCH',
                body: data[2],
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Fees'],
        }),
        deleteFee: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/fees/${data[1]}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Fees'],
        }),
        getTeamList: builder.query<any, any>({
            query: (company) => `/departments`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Teams', 'SignIn'],
        }),
        addTeam: builder.mutation<any, any>({
            query: (data) => ({
                url: `/departments`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Teams'],
        }),
        getIndividualTeam: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Teams', 'SignIn'],
        }),
        editTeam: builder.mutation<any, any>({
            query: (data) => ({
                url: `/departments/${data[0]}`,
                method: 'PATCH',
                body: data[1],
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Teams'],
        }),
        deleteTeam: builder.mutation<any, any>({
            query: (data) => ({
                url: `/departments/${data[0]}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Teams'],
        }),
        getPolicyList: builder.query<any, any>({
            query: (company) => `/owners/${company}/policies`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Policies', 'SignIn'],
        }),
        getIndividualPolicy: builder.query<any, any>({
            query: (data) => `/owners/${data[0]}/policies/${data[1]}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Policies', 'SignIn'],
        }),
        addPolicy: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/policies`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Policies'],
        }),
        editPolicy: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/policies/${data[1]}`,
                method: 'PATCH',
                body: data[2],
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Policies'],
        }),
        deletePolicy: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/policies/${data[1]}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Policies'],
        }),

        getIndividualUserInfo: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Users', 'SignIn'],
        }),
        editUser: builder.mutation<any, any>({
            query: (data) => ({
                url: data[0],
                method: 'PATCH',
                body: data[1],
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Users', 'Teams'],
        }),
        forgotPassword: builder.mutation<any, any>({
            query: (data) => ({
                url: `/forgot-password`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['SignIn'],
        }),
        resetPassword: builder.mutation<any, any>({
            queryFn: async (args, api, extraOptions) => {
                return baseQuery(
                    { url: '/reset-password', method: 'POST', body: args },
                    api,
                    extraOptions
                );
            },
            invalidatesTags: ['SignIn'],
        }),
        verifyEmail: builder.mutation<any, any>({
            query: (data) => ({
                url: `/verify-email`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['SignIn'],
        }),
        sendVerificationEmail: builder.mutation<any, any>({
            query: (data) => ({
                url: `/verification-email`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['SignIn'],
        }),
        changePassword: builder.mutation<any, any>({
            query: (data) => ({
                url: `/change-password`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['SignIn', 'Profile'],
        }),
        sendInvite: builder.mutation<any, any>({
            query: (data) => ({
                url: `/send-invitation`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['SignIn', 'Profile'],
        }),
        submitReservation: builder.mutation<any, any>({
            query: (data) => ({
                url: `/bookings`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Reservations', 'Assets', 'SignIn', 'Reports'],
        }),
        calculateFees: builder.mutation<any, any>({
            query: (data) => ({
                url: `/bookings/calculate-charges`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [],
        }),

        getAllReservations: builder.query<any, any>({
            query: (args) => ({ url: '/bookings', params: { ...args } }),
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Reservations', 'SignIn'],
        }),

        getAvailability: builder.query<any, any>({
            query: (data) => ({
                url: `${data.url}`,
                method: 'POST',
                body: data.body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Assets', 'Reservations', 'SignIn'],
        }),

        getIndividualReservation: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Reservations', 'SignIn'],
        }),
        approveReservation: builder.mutation<any, any>({
            query: (id) => ({
                url: `/bookings/${id}/approve`,
                method: 'POST',
            }),
            invalidatesTags: ['Reservations', 'Reports'],
        }),
        addOwnerNotes: builder.mutation<any, any>({
            query: (data) => ({
                url: `/bookings/${data[0]}/addOwnerNotes`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Reservations'],
        }),
        addAttachments: builder.mutation<any, any>({
            query: (data) => ({
                url: `/bookings/${data[0]}/addAttachments`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Reservations'],
        }),
        deleteAttachment: builder.mutation<any, any>({
            query: (data) => ({
                url: `/bookings/${data[0]}/attachments/${data[1]}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Reservations'],
        }),
        declineReservation: builder.mutation<any, any>({
            query: (data) => ({
                url: `/bookings/${data[0]}/decline`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Reservations', 'Reports'],
        }),
        cancelReservation: builder.mutation<any, any>({
            query: (data) => ({
                url: `/bookings/${data[0]}/cancel`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['Reservations', 'Reports', 'AI'],
        }),
        editReservation: builder.mutation<any, any>({
            query: (data) => ({
                url: data[0],
                method: 'PATCH',
                body: data[1],
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Reservations', 'Reports'],
        }),
        getAllGuests: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Guest', 'SignIn', 'BookingCaps'],
        }),
        getAllGuestAttendees: builder.query<any, any>({
            query: (company) => `/guests/${company}/users/attendees`,
            providesTags: ['Guest', 'SignIn', 'BookingCaps'],
        }),
        getIndividualGuests: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Guest', 'SignIn'],
        }),
        getReport: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Reports', 'SignIn'],
        }),
        /* ---------- Billing ---------- */

        getBillingReport: builder.query<GuestInvoice[], any>({
            query: (arg) => ({
                url: 'invoices',
                params: arg,
            }),
            providesTags: ['SignIn'],
        }),

        getIndividualBill: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['SignIn'],
        }),
        setBillingAccount: builder.mutation<
            any,
            { guestId: number; billingAccount: any }
        >({
            query: ({ guestId, billingAccount }) => ({
                url: `/guests/${guestId}/billing-account`,
                method: 'PUT',
                body: billingAccount,
            }),
            invalidatesTags: ['SignIn'],
        }),

        getBillingAccount: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Assets', 'SignIn'],
        }),

        /* ---------- AI ---------- */
        getAIThreadId: builder.mutation<any, any>({
            query: () => ({
                url: `/ai/threads`,
                method: 'POST',
            }),
            invalidatesTags: ['AI'],
        }),
        aiReservationRequest: builder.mutation<any, any>({
            query: (data) => ({
                url: `/ai/bookings`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['AI'],
        }),

        submitRecurringReservation: builder.mutation<any, any>({
            query: (data) => ({
                url: `/recurring-bookings`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['AI'],
        }),
        getRecurringReservation: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['AI'],
        }),
        cancelRecurringReservation: builder.mutation<any, any>({
            query: (data) => ({
                url: `/recurring-bookings/${data[0]}/cancel`,
                method: 'POST',
                body: data[1],
            }),
            invalidatesTags: ['AI', 'Reservations', 'Reports'],
        }),
        aiDiscountsRequest: builder.mutation<any, any>({
            query: (data) => ({
                url: `/ai/discounts`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['AI', 'Discounts'],
        }),
        submitDiscount: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data?.[0]}/discounts`,
                method: 'POST',
                body: data?.[1],
            }),
            invalidatesTags: ['AI', 'Discounts'],
        }),
        getDiscounts: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['AI', 'Discounts'],
        }),
        getIndividualDiscount: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['AI', 'Discounts'],
        }),
        deleteDiscount: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/discounts/${data[1]}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AI', 'Discounts'],
        }),
        disableDiscount: builder.mutation<any, any>({
            query: (data) => ({
                url: `/owners/${data[0]}/discounts/${data[1]}/toggle-disable`,
                method: 'PATCH',
            }),
            invalidatesTags: ['AI', 'Discounts'],
        }),
        editPlatformSettings: builder.mutation<any, any>({
            query: (data) => ({
                url: `/platform-settings`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Platform'],
        }),
        getPlatformSettings: builder.query<any, any>({
            query: (url) => `${url}`,
            transformResponse: (response: any) => {
                return response;
            },
            providesTags: ['Platform', 'SignIn'],
        }),
        getXeroConsentUrl: builder.query<XeroConsentUrl, void>({
            query: () => 'xero/consent-url',
        }),
        getXeroConnection: builder.query<XeroConnection, void>({
            query: () => 'xero/connection',
        }),
        getXeroSettings: builder.query<XeroSettings, void>({
            query: () => 'xero/settings',
            providesTags: ['SignIn', 'XeroSettings'],
        }),
        editXeroSettings: builder.mutation<XeroSettings, XeroSettings>({
            query: (data) => ({
                url: `xero/settings`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['XeroSettings'],
        }),
        getXeroAccounts: builder.query<XeroAccount[], void>({
            query: () => 'xero/accounts',
            providesTags: ['SignIn'],
        }),
        getEarnings: builder.query<
            Earnings,
            { year: number; owner?: number; month?: number }
        >({
            query: (arg) => ({
                url: 'earnings',
                params: arg,
            }),
            providesTags: ['SignIn'],
        }),
    }),
});
export const {
    useGetAllAssetsQuery,
    useGetIndividualOwnerAssetsQuery,
    useGetIndividualAssetQuery,
    useAddAssetMutation,
    useAddPhotosMutation,
    useEditPhotosMutation,
    useEditAssetMutation,
    useDeleteAssetMutation,
    useCalculatePriceMutation,
    useSignInMutation,
    useRefreshMutation,
    useSignOutMutation,
    useGetIndividualOwnerQuery,
    useGetCompanyOwnerInfoQuery,
    useEditCompanyMutation,
    useGetCompanyGuestsInfoQuery,
    useGetProfileCompanyInfoQuery,
    useEditProfileDetailsMutation,
    useGetProfileDetailsQuery,
    useDeleteProfilePictureMutation,
    useCreateProfilePictureMutation,
    useAddIndividualOwnerUsersMutation,
    useAddIndividualGuestUsersMutation,
    useAddIndividual7DayAdminMutation,
    useAddNewClientMutation,
    useGetAllClientsQuery,
    useGetIndividualClientQuery,
    useEditClientMutation,
    useDeleteClientMutation,
    useCreateChatEnquiryMutation,
    useCreateReservationChatMutation,
    useJoinChannelMutation,
    useGetChatUsersQuery,
    useGetChatTokenQuery,
    useGetUserListQuery,
    useGetIndividualUserInfoQuery,
    useEditUserMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useVerifyEmailMutation,
    useSendVerificationEmailMutation,
    useChangePasswordMutation,
    useSubmitReservationMutation,
    useGetAllReservationsQuery,
    useGetIndividualReservationQuery,
    useGetAllGuestsQuery,
    useGetAllGuestAttendeesQuery,
    useGetIndividualGuestsQuery,
    useApproveReservationMutation,
    useCancelReservationMutation,
    useAddOwnerNotesMutation,
    useAddAttachmentsMutation,
    useDeleteAttachmentMutation,
    useDeclineReservationMutation,
    useGetAssetCalendarAvailabilityQuery,
    useEditReservationMutation,
    useGetReportQuery,
    useSendInviteMutation,
    useGetBookingCapListQuery,
    useAddIndividualBookingCapMutation,
    useGetIndividualBookingCapQuery,
    useGetBookingCapAvailabilityMutation,
    useEditBookingCapMutation,
    useDeleteBookingCapMutation,
    useGetFeeListQuery,
    useAddIndividualFeeMutation,
    useGetIndividualFeeQuery,
    useEditFeeMutation,
    useDeleteFeeMutation,
    useGetTeamListQuery,
    useAddTeamMutation,
    useGetIndividualTeamQuery,
    useEditTeamMutation,
    useDeleteTeamMutation,
    useGetPolicyListQuery,
    useGetIndividualPolicyQuery,
    useAddPolicyMutation,
    useEditPolicyMutation,
    useDeletePolicyMutation,
    useCalculateFeesMutation,
    useGetAvailabilityQuery,
    useGetAIThreadIdMutation,
    useAiReservationRequestMutation,
    useAiDiscountsRequestMutation,
    useSubmitRecurringReservationMutation,
    useGetRecurringReservationQuery,
    useCancelRecurringReservationMutation,
    useSubmitDiscountMutation,
    useGetDiscountsQuery,
    useGetIndividualDiscountQuery,
    useDeleteDiscountMutation,
    useDisableDiscountMutation,
    useGetBillingAccountQuery,
    useValidateImportGuestUsersMutation,
    useImportGuestUsersMutation,
    useEditPlatformSettingsMutation,
    useGetPlatformSettingsQuery,
    useGetBillingReportQuery,
    useGetIndividualBillQuery,
    useSetBillingAccountMutation,
    useLazyGetXeroConsentUrlQuery,
    useGetXeroConnectionQuery,
    useEditXeroSettingsMutation,
    useGetXeroSettingsQuery,
    useGetXeroAccountsQuery,
    useGetEarningsQuery,
} = sevenDayApi;
