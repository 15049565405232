import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Grid,
    List,
    ListItem,
    Paper,
    Typography,
    useMediaQuery,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { SetStateAction, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import toast from '../../shared/Toast';

export default function ResetPasswordPage() {
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [resetPassword] = useResetPasswordMutation();
    const queryParameters = new URLSearchParams(window.location.search);
    const emailParam = queryParameters.get('email');
    const sourceParam = queryParameters.get('source');

    const [emailAddress, setEmailAddress] = useState(
        emailParam ? emailParam : ''
    );
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidInput, setIsValidInput] = useState(false);
    const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
    const [lowerCaseValidation, setLowercaseValidation] = useState(false);
    const [upperCaseValidation, setUppercaseValidation] = useState(false);
    const [pwLengthValidation, setPWLengthValidation] = useState(false);
    const [numberValidation, setNumberValidation] = useState(false);
    const [characterValidation, setCharacterValidation] = useState(false);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    let path = window.location.href.split('?')[0];
    const token = path.split('/')[4];

    const btnstyle = {
        margin: '8px 0px  30px  0px',
        height: '50px',
        fontSize: '16px',
        borderRadius: '5px',
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const errors: any = [];

    if (emailAddress.length === 0) {
        errors.push('Email cannot be empty');
    }

    if (password.length === 0) {
        errors.push('New password cannot be empty');
    }
    if (confirmPassword.length === 0) {
        errors.push('Confirm password cannot be empty');
    }

    const handleSignInEmail = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setEmailAddress(event.target.value);
        if (validatEmail(event.target.value) === false) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
    };

    const validatEmail = (email: any) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };

    const handlePassword = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPassword(event.target.value);
        if (validatePassword(event.target.value) === false) {
            setIsValidInput(true);
        } else {
            setIsValidInput(false);
        }
    };

    const handleConfirmPassword = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setConfirmPassword(event.target.value);
        if (password === event.target.value) {
            setPasswordsDontMatch(false);
        } else {
            setPasswordsDontMatch(true);
        }
    };

    const validatePassword = (pw: any) => {
        if (/(?=.*[A-Z])/.test(pw) === true) {
            setUppercaseValidation(true);
        } else {
            setUppercaseValidation(false);
        }
        if (pw.length >= 8 === true) {
            setPWLengthValidation(true);
        } else {
            setPWLengthValidation(false);
        }
        if (/[a-z]/.test(pw) === true) {
            setLowercaseValidation(true);
        } else {
            setLowercaseValidation(false);
        }
        if (/[#?!@$%^&*-]/.test(pw) === true) {
            setCharacterValidation(true);
        } else {
            setCharacterValidation(false);
        }
        if (/[0-9]/.test(pw) === true) {
            setNumberValidation(true);
        } else {
            setNumberValidation(false);
        }

        return (
            /[A-Z]/.test(pw) &&
            pw.length >= 8 &&
            /[a-z]/.test(pw) &&
            /[0-9]/.test(pw) &&
            /[#?!@$%^&*-]/.test(pw)
        );
    };

    const navigate = useNavigate();

    const onClickResetPassword = () => {
        //Reset password and then redirect to login
        let requestBody = {
            email: emailAddress,
            token: token,
            newPassword: password,
            confirmNewPassword: confirmPassword,
        };

        resetPassword(requestBody).then((data: any) => {
            if (data) {
                if (data?.error?.data !== 'Password is reset.') {
                    if (data?.error?.data?.message === 'invalid token') {
                        toast.error(`Invalid Token/Token Expired`);
                    } else {
                        toast.error(`Failed to reset password`);
                    }
                } else {
                    toast.success(`Password has been reset`);
                    navigate(`/login`);
                }
            } else {
                toast.error(`Failed to reset password`);
            }
        });
    };

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: '#5cb5b3',
            }}
        >
            <Grid>
                <Paper
                    elevation={10}
                    sx={{
                        padding: '0px 0px 0px 0px',
                        minWidth: 380,
                        maxWidth: 480,
                        height: isMobile ? '700px' : 'auto',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            // backgroundColor: '#5cb5b3',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            padding: '35px',
                        }}
                    >
                        <img
                            alt='primarylogo'
                            src='/logos/GreenLogoNoBack.png'
                            style={{
                                // height: '50px',
                                width: '170px',
                                height: '60px',
                            }}
                        />{' '}
                    </Grid>

                    <Grid sx={{ padding: '0px 40px 40px 40px' }}>
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#787878'
                            sx={{
                                fontSize: '28px',
                                letterSpacing: '-0.5px',
                                padding: '20px 0px 20px 5px',
                            }}
                        >
                            {sourceParam === 'invite'
                                ? 'Set Password'
                                : 'Reset Password'}
                        </Typography>{' '}
                        <CustomTextField
                            error={isValidEmail}
                            label={
                                isValidEmail === true
                                    ? 'Invalid Email Address'
                                    : ''
                            }
                            type='email'
                            autocomplete='off'
                            data-lpignore={emailParam ? true : false}
                            disabled={emailParam ? true : false}
                            placeholder='Email'
                            onChange={handleSignInEmail}
                            value={emailAddress}
                            fullWidth
                            color={isValidEmail ? 'green' : 'neutral'}
                            sx={{ marginBottom: '10px' }}
                            InputProps={{
                                style: {
                                    height: '50px',
                                    backgroundColor: emailParam
                                        ? '#f6f8f9'
                                        : 'fff',
                                },
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <EmailIcon fontSize='small' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextField
                            error={isValidInput}
                            label={
                                isValidInput === true ? 'Invalid Password' : ''
                            }
                            placeholder='New Password'
                            type={showNewPassword ? 'text' : 'password'}
                            onChange={handlePassword}
                            sx={{ marginBottom: '10px' }}
                            color={isValidInput ? 'green' : 'neutral'}
                            fullWidth
                            InputProps={{
                                style: {
                                    height: '50px',
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                        sx={{ tabIndex: '-1' }}
                                    >
                                        <LockIcon
                                            fontSize='small'
                                            sx={{ tabIndex: '-1' }}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        sx={{ tabIndex: '-1' }}
                                    >
                                        {showNewPassword === false ? (
                                            <CustomIconButton
                                                tabIndex='-1'
                                                onClick={() =>
                                                    setShowNewPassword(
                                                        !showNewPassword
                                                    )
                                                }
                                                sx={{
                                                    width: '100%',
                                                    height: '25px',
                                                    display: 'flex',
                                                    flexDirection: 'row',

                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        color: '#5cb5b3',
                                                        backgroundColor:
                                                            'transparent',
                                                        background: 'none',
                                                    },
                                                }}
                                            >
                                                <VisibilityIcon fontSize='small' />
                                            </CustomIconButton>
                                        ) : (
                                            <CustomIconButton
                                                tabIndex='-1'
                                                onClick={() =>
                                                    setShowNewPassword(
                                                        !showNewPassword
                                                    )
                                                }
                                                sx={{
                                                    width: '100%',
                                                    height: '25px',
                                                    display: 'flex',
                                                    flexDirection: 'row',

                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        color: '#5cb5b3',
                                                        backgroundColor:
                                                            'transparent',
                                                        background: 'none',
                                                    },
                                                }}
                                            >
                                                <VisibilityOffIcon fontSize='small' />
                                            </CustomIconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextField
                            placeholder='Confirm Password'
                            error={passwordsDontMatch}
                            label={
                                passwordsDontMatch === true
                                    ? 'Passwords do not match'
                                    : ''
                            }
                            type={showConfirmPassword ? 'text' : 'password'}
                            onChange={handleConfirmPassword}
                            sx={{ marginBottom: '10px' }}
                            color={passwordsDontMatch ? 'green' : 'neutral'}
                            fullWidth
                            InputProps={{
                                style: {
                                    height: '50px',
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                        sx={{ tabIndex: '-1' }}
                                    >
                                        <LockIcon fontSize='small' />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        sx={{ tabIndex: '-1' }}
                                    >
                                        {showConfirmPassword === false ? (
                                            <CustomIconButton
                                                tabIndex='-1'
                                                onClick={() =>
                                                    setShowConfirmPassword(
                                                        !showConfirmPassword
                                                    )
                                                }
                                                sx={{
                                                    width: '100%',
                                                    height: '25px',
                                                    display: 'flex',
                                                    flexDirection: 'row',

                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        color: '#5cb5b3',
                                                        backgroundColor:
                                                            'transparent',
                                                        background: 'none',
                                                    },
                                                }}
                                            >
                                                <VisibilityIcon fontSize='small' />
                                            </CustomIconButton>
                                        ) : (
                                            <CustomIconButton
                                                onClick={() =>
                                                    setShowConfirmPassword(
                                                        !showConfirmPassword
                                                    )
                                                }
                                                sx={{
                                                    width: '100%',
                                                    height: '25px',
                                                    display: 'flex',
                                                    flexDirection: 'row',

                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        color: '#5cb5b3',
                                                        backgroundColor:
                                                            'transparent',
                                                        background: 'none',
                                                    },
                                                }}
                                            >
                                                <VisibilityOffIcon fontSize='small' />
                                            </CustomIconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Grid sx={{ marginTop: '30px' }}>
                            <CustomButton
                                type='submit'
                                color='green'
                                variant='contained'
                                style={btnstyle}
                                fullWidth
                                disabled={
                                    isValidEmail ||
                                    isValidInput ||
                                    passwordsDontMatch ||
                                    errors.length > 0
                                }
                                onClick={onClickResetPassword}
                            >
                                Save
                            </CustomButton>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection:
                                    isMobile || isIpad ? 'column' : 'row',
                                width: '100%',
                                height:
                                    password === '' && isMobile
                                        ? '0px'
                                        : isIpad
                                        ? '260px'
                                        : '200px',
                            }}
                        >
                            <Grid
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '10px',
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    {' '}
                                    Password must:
                                    <List sx={{ padding: '0!important' }}>
                                        <ListItem>
                                            {pwLengthValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Be a minimum of 8 characters
                                        </ListItem>
                                        <ListItem>
                                            {lowerCaseValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Include at least one lowercase
                                            letter (a-z)
                                        </ListItem>
                                        <ListItem>
                                            {upperCaseValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Include at least one uppercase
                                            letter (A-Z)
                                        </ListItem>
                                        <ListItem>
                                            {numberValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Include at least one number (0-9)
                                        </ListItem>
                                        <ListItem>
                                            {characterValidation === true ? (
                                                <DoneIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'green',
                                                    }}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: '12px',
                                                        paddingRight: '5px',
                                                        color: 'red',
                                                    }}
                                                />
                                            )}
                                            Include at least one special
                                            character (!@#?&)
                                        </ListItem>
                                    </List>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid sx={{ display: 'flex', width: '100%' }}>
                            <Grid
                                sx={{
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {' '}
                                <Link
                                    style={{ color: '#787878' }}
                                    target='_blank'
                                    to={`https://apps.apple.com/au/app/7day-app/id6470282265?itsct=apps_box_link&itscg=30200`}
                                >
                                    {' '}
                                    <img
                                        alt='primarylogo'
                                        src='/AppleWhite.png'
                                        style={{
                                            // padding: '0px 50px 0px 10px',
                                            width: 'auto',
                                            height: '40px',
                                        }}
                                    />
                                </Link>
                            </Grid>
                            <Grid
                                sx={{
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {' '}
                                <Link
                                    style={{ color: '#787878' }}
                                    target='_blank'
                                    to={`https://play.google.com/store/apps/details?id=com.sevenday.mobile&pcampaignid=web_share7DAY`}
                                >
                                    <img
                                        alt='primarylogo'
                                        src='/googleWhite.png'
                                        style={{
                                            padding: '0px 50px 0px 10px',
                                            width: 'auto',
                                            height: '40px',
                                            // backgroundColor: 'red',
                                        }}
                                    />
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Box>
    );
}
