import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import {
    getReadableReportDescription,
    getReadableReportName,
} from '../../components/Reports/ReportsMapping';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import ScheduledComponentPage from '../../components/Dashboard/Dashboard Reports/Scheduled Reservations/ScheduledComponentPage';
import CompletedComponentPage from '../../components/Dashboard/Dashboard Reports/Completed Reservations/CompletedComponentPage';
import UserReservationsComponentPage from '../../components/Dashboard/Dashboard Reports/User Reservations/UserReservationsComponentPage';
import PendingComponentPage from '../../components/Dashboard/Dashboard Reports/Pending Reservations/PendingComponentPage';
import NewReservationsComponentPage from '../../components/Dashboard/Dashboard Reports/New Reservations/NewReservationsComponentPage';
import GuestCountComponentPage from '../../components/Dashboard/Dashboard Reports/Guest Count/GuestCountComponentPage';

export default function DashboardReportPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    let reportUrl =
        window.location.href.split('/')[4] +
        '-' +
        window.location.href.split('/')[5];


    const navigate = useNavigate();

    const onClickGoBack = () => {
        navigate(`/dashboard`);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        {getReadableReportName('type', reportUrl)}
                    </Typography>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile ? '3%' : '5%',
                        justifyContent: 'left',
                        paddingTop: '10px'
                    }}
                >
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            paddingBottom: isMobile || isIpad ? '30px' : '0px',
               
                        }}
                    >
                        {getReadableReportDescription('type', reportUrl)}
                    </Typography>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        justifyContent: 'left',
                    }}
                >
                    {window.location.href.split('/')[4] === 'scheduled' ? (
                        <ScheduledComponentPage />
                    ) : window.location.href.split('/')[4] === 'completed' ? (
                        <CompletedComponentPage />
                    ) : window.location.href
                          .split('/')[4]
                          .includes('pending') ? (
                        <PendingComponentPage />
                    ) : window.location.href.split('/')[4] === 'new-bookings' ? (
                        <NewReservationsComponentPage/>
                    ): window.location.href.split('/')[4] === 'guests' ? (
                        <GuestCountComponentPage/>
                    ): (
                        <UserReservationsComponentPage />
                    )}
                </Grid>
                <Grid
                    sx={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    {' '}
                    <CustomButton
                        onClick={onClickGoBack}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon /> {/* {isMobile ? null : ( */}
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back to Dashboard
                        </Typography>
                        {/* )} */}
                    </CustomButton>
                </Grid>
            </Grid>
        </Container>
    );
}
