import { CreditCard, MailOutline } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
    Badge,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetDates as resetDatesCalendar } from '../app/Slices/CalendarDateFilterSlice';
import { openCalendarViewReservationAction } from '../app/Slices/CalendarViewSlice';
import { openFilterClientAction } from '../app/Slices/ClientFilterSlice';
import { resetDates } from '../app/Slices/DateFilterSlice';
import { resetExperienceFilters } from '../app/Slices/ExperienceFiltersSlice';
import { resetExperienceSearchFilter } from '../app/Slices/ExperienceSearchSlice';
import { resetReservationFilters } from '../app/Slices/ReservationFiltersSlice';
import { useGetProfileDetailsQuery } from '../app/Slices/SevenDayApiSlice';
import {
    ActionType,
    removeAllSnacks,
    selectSnackBars,
    Toast,
} from '../app/Slices/SnackBarSlice';
import { openFilterUserAction } from '../app/Slices/UserFilterSlice';
import { selectVisitTypeSliceData } from '../app/Slices/VisitTypeSlice';
import NotificationsCenter from '../feature/components/Notifications Center/NotificationsCenter';
import ProfilePicture from '../feature/components/Profile/ProfilePicture';
import toast from '../feature/shared/Toast';
import { resetDrivers } from '../app/Slices/DriversSlice';
import UserRole from '../shared/AuthRoles';
import { useLocationChange } from '../shared/custom-router';
import { CustomIconButton } from './styledcomponents/CustomIconButton';
import { StreamChat } from 'stream-chat';

const pagesWithIconsDesktop = [
    {
        menuIcon: (
            <LocationOnIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Experiences',
    },
    {
        menuIcon: (
            <CalendarMonthIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Reservations',
    },
    {
        menuIcon: (
            <BarChartIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Reports',
    },
];

const pagesWithIconsMobile = [
    {
        menuIcon: (
            <LocationOnIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Experiences',
    },
    {
        menuIcon: (
            <CalendarMonthIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Reservations',
    },
    {
        menuIcon: (
            <BarChartIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Reports',
    },
];
const subPagesWithIconsMobile = [
    {
        menuIcon: (
            <AccountCircleIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Profile',
    },
    {
        menuIcon: (
            <SecurityIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Settings',
    },
    {
        menuIcon: (
            <HelpOutlineIcon
                sx={{
                    color: '#5cb5b3',
                }}
            />
        ),
        menuName: 'Help',
    },
];

export default function CustomAppBar() {
    const toasts = useSelector(selectSnackBars);

    const [unreadCount, setUnreadCount] = useState<number>(0);

    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElProfile, setAnchorElProfile] =
        React.useState<null | HTMLElement>(null);
    const [anchorElHelp, setAnchorElHelp] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElProfile(event.currentTarget);
    };

    const handleOpenHelpMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElHelp(event.currentTarget);
    };

    const loggedInUser = UserRole();
    const location = useLocation();

    const API_KEY = process.env.REACT_APP_STREAM_API_KEY as string;

    const client = StreamChat.getInstance(API_KEY);

    client?.on((event) => {
        if (event.unread_channels !== undefined) {
            setUnreadCount(event.unread_channels);
        }

        if (event.me && unreadCount === 0 ) {
            setUnreadCount(client.user?.unread_channels as number)
        }
    });

    const { data: profileDetails } = useGetProfileDetailsQuery('/profile');

    const editProfile = () => {
        navigate('/editProfile');
        ReactGA.send({
            hitType: 'pageview',
            page: '/editProfile',
            title: 'Edit Profile',
        });
    };

    useEffect(() => {
        if (profileDetails) {
            if (
                !profileDetails?.profilePicture &&
                loggedInUser?.role.includes('guest') &&
                loggedInUser?.role !== 'guest_company'
            ) {
                if (
                    !toasts.find(
                        (toast: Toast) => toast.id === 'finishSettingUpProfile'
                    )
                ) {
                    toast.error('Action required!', {
                        closeButton: false,
                        actionText: 'Finish setting up your profile',
                        actionOnclick: editProfile,
                        actionType: ActionType.NAVIGATE,
                        customId: 'finishSettingUpProfile',
                        duration: Infinity,
                    });
                }
            } else {
                toast.remove('finishSettingUpProfile');
            }
        }
    }, [profileDetails, location]);

    const avatarStyles = {
        height: '30px',
        width: '30px',
        fontSize: '12px',
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(92,181,179,0.2)',
            transition: 'opacity 0.1s ease-in-out',
            opacity: 0,
        },
        '&:hover::after': {
            opacity: 1,
        },
        '&:hover': {
            transition: '0.1s ease-in-out',
            bgcolor: 'transparent',
            color: '#5cb5b3',
        },
    };

    const settingsWithIcons = [
        loggedInUser?.role === 'guest_user'
            ? {
                  menuIcon: <CreditCard color='primary' />,
                  menuName: 'Billing',
              }
            : null,
        loggedInUser?.role === 'owner_user' ||
        loggedInUser?.role === 'owner_admin' ||
        loggedInUser?.role === 'guest_department_admin'
            ? {
                  menuIcon: (
                      <GroupAddIcon
                          sx={{
                              color: '#5cb5b3',
                          }}
                      />
                  ),
                  menuName: 'Users',
              }
            : null,

        loggedInUser?.role === 'owner_admin' ||
        loggedInUser?.role === 'owner_company'
            ? {
                  menuIcon: (
                      <SecurityIcon
                          sx={{
                              color: '#5cb5b3',
                          }}
                      />
                  ),
                  menuName: 'Admin Center',
              }
            : null,
        loggedInUser?.role === 'guest_admin' ||
        loggedInUser?.role === 'guest_company' ||
        loggedInUser?.role === 'admin'
            ? {
                  menuIcon: (
                      <SecurityIcon
                          sx={{
                              color: '#5cb5b3',
                          }}
                      />
                  ),
                  menuName: 'Admin Center',
              }
            : null,

        loggedInUser?.role === 'admin'
            ? {
                  menuIcon: (
                      <BusinessIcon
                          sx={{
                              color: '#5cb5b3',
                          }}
                      />
                  ),
                  menuName: 'Clients',
              }
            : null,
        loggedInUser?.role === 'admin'
            ? {
                  menuIcon: (
                      <GroupsIcon
                          sx={{
                              color: '#5cb5b3',
                          }}
                      />
                  ),
                  menuName: 'Guests',
              }
            : null,
    ];

    const profileWithIcons = [
        {
            menuIcon: (
                <ManageAccountsIcon
                    sx={{
                        color: '#5cb5b3',
                    }}
                />
            ),
            menuName: 'Profile',
        },
        loggedInUser?.role === 'admin' ||
        loggedInUser?.role === 'owner_company' ||
        loggedInUser?.role === 'guest_company'
            ? null
            : {
                  menuIcon: (
                      <DashboardIcon
                          sx={{
                              color: '#5cb5b3',
                          }}
                      />
                  ),
                  menuName: 'Dashboard',
              },
        {
            menuIcon: (
                <LogoutIcon
                    sx={{
                        color: '#5cb5b3',
                    }}
                />
            ),
            menuName: 'Logout',
        },
    ];

    const helpWithIcons = [
        {
            menuIcon: (
                <GroupAddIcon
                    sx={{
                        color: '#5cb5b3',
                    }}
                />
            ),
            menuName: 'Help Documentation',
        },

        {
            menuIcon: (
                <GroupAddIcon
                    sx={{
                        color: '#5cb5b3',
                    }}
                />
            ),
            menuName: 'Privacy Policy',
        },
        {
            menuIcon: (
                <GroupAddIcon
                    sx={{
                        color: '#5cb5b3',
                    }}
                />
            ),
            menuName: 'Terms of Service',
        },
    ];

    const visitTypeSelected: any = useSelector(selectVisitTypeSliceData);

    const handleCloseNavMenu = (page: string) => {
        if (page === 'Experiences') {
            if (loggedInUser?.role === 'owner_admin') {
                navigate(`/experiences`);
                ReactGA.send({
                    hitType: 'pageview',
                    page: '/experiences',
                    title: 'Experiences',
                });
            } else {
                navigate(`/viewExperiences/all`);
                ReactGA.send({
                    hitType: 'pageview',
                    page: '/viewExperiences/all',
                    title: 'All Experiences',
                });
            }

            // navigate(`/viewExperiences`, { replace: true });
        } else if (page === 'Reservations') {
            navigate(`/reservations`);
            ReactGA.send({
                hitType: 'pageview',
                page: 'reservations',
                title: 'Reservations',
            });
        } else if (page === 'Reports') {
            navigate(`/reports`);
            ReactGA.send({
                hitType: 'pageview',
                page: '/reports',
                title: 'Reports',
            });
        } else if (page === 'Profile') {
            navigate(`/profile`);
            ReactGA.send({
                hitType: 'pageview',
                page: '/profile',
                title: 'Profile',
            });
        }
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (setting: string) => {
        if (setting === 'Admin Center') {
            if (
                loggedInUser?.role === 'owner_admin' ||
                loggedInUser?.role === 'owner_company' ||
                loggedInUser?.role === 'admin'
            ) {
                navigate(`/adminCenter/host`);
                ReactGA.send({
                    hitType: 'pageview',
                    page: '/adminCenter/host',
                    title: 'Admin Center Host',
                });
            } else {
                navigate(`/adminCenter/guest`);
                ReactGA.send({
                    hitType: 'pageview',
                    page: '/adminCenter/guest',
                    title: 'Admin Center Guest',
                });
            }
        } else if (setting === 'Billing') {
            navigate(`/billing`);
            ReactGA.send({
                hitType: 'pageview',
                page: '/billing',
                title: 'Billing',
            });
        } else if (setting === 'Policies') {
            navigate(`/policies`);
            ReactGA.send({
                hitType: 'pageview',
                page: '/policies',
                title: 'Policies',
            });
        } else if (setting === 'Users') {
            navigate(`/users`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/users',
                title: 'Users',
            });
        } else if (setting === 'Clients') {
            navigate(`/clients`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/clients',
                title: 'Clients',
            });
        } else if (setting === 'Guests') {
            navigate(`/guests`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/guests',
                title: 'Guests',
            });
        } else if (setting === 'Teams') {
            navigate(`/teams`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/teams',
                title: 'Teams',
            });
        }
        setAnchorElUser(null);
        handleCloseNavMenu('close');
    };
    const dispatch = useDispatch();

    const handleCloseHelpMenu = (helpItem: string) => {
        if (helpItem === 'Help Documentation') {
            navigate(`/documentation`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/documentation',
                title: 'Help Documentation',
            });
        } else if (helpItem === 'Support' || helpItem === 'Report') {
            navigate(`/support`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/support',
                title: 'Support',
            });
        } else if (helpItem === 'Give Feedback') {
            navigate(`/feedback`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/feedback',
                title: 'Feedback',
            });
        } else if (helpItem === 'Privacy Policy') {
            navigate(`/privacy`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/privacy',
                title: 'Privacy',
            });
        } else if (helpItem === 'Terms of Service') {
            navigate(`/terms`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/terms',
                title: 'Terms',
            });
        }
        setAnchorElHelp(null);
        handleCloseNavMenu('close');
    };

    useLocationChange((location: any, prevLocation: any) => {
        if (!location?.pathname.includes('/reservations')) {
            dispatch(resetReservationFilters());
            dispatch(
                openCalendarViewReservationAction({
                    view: false,
                })
            );
        }
        if (!location?.pathname.includes('/experiences/reservationRequest')) {
            toast.remove('faceRecWarning');
            toast.remove('bookingCapWarning');
        }

        if (!location?.pathname.includes('/viewExperiences')) {
            dispatch(resetExperienceFilters());
            dispatch(resetDates());
            dispatch(resetDatesCalendar());
            dispatch(resetExperienceSearchFilter());
        }

        if (!location?.pathname.includes('/users')) {
            dispatch(
                openFilterUserAction({
                    filteredData: {
                        status: 'All',
                        role: 'All',
                    },
                })
            );
        }

        if (!location?.pathname.includes('/clients')) {
            dispatch(
                openFilterClientAction({
                    filteredData: {
                        status: 'All',
                        // role: 'All',
                    },
                })
            );
        }
        if (!location?.pathname.includes('/guests')) {
            dispatch(
                openFilterClientAction({
                    filteredData: {
                        status: 'All',
                        // role: 'All',
                    },
                })
            );
        }

        if (prevLocation?.pathname !== location?.pathname) {
            openCalendarViewReservationAction({
                view: false,
            });
        }
    });

    const handleCloseProfileMenu = async (profileItem: string) => {
        if (profileItem === 'Profile') {
            navigate(`/profile`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/profile',
                title: 'Profile',
            });
        } else if (profileItem === 'Dashboard') {
            navigate(`/dashboard`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/dashboard',
                title: 'Dashboard',
            });
        } else if (profileItem === 'Logout') {
            dispatch(removeAllSnacks());

            navigate(`/login`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/login',
                title: 'Login',
            });
        }
        setAnchorElProfile(null);
        handleCloseNavMenu('close');
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position='fixed'
                elevation={2}
                sx={{ width: '100%', overflow: 'hidden' }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        backgroundColor: '#f6f8f9',
                        width: 'auto',
                        height: '20px',
                    }}
                >
                    <Box
                        sx={{
                            height: '40px',
                            // HIDE LOGO IF SCREEN XS
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        <Link
                            to={
                                loggedInUser?.role === 'owner_company' ||
                                loggedInUser?.role === 'guest_company'
                                    ? '/users'
                                    : '/dashboard'
                            }
                        >
                            <img
                                alt='primarylogo'
                                src='/logos/GreenLogoNoBack.png'
                                style={{
                                    padding: '0px 50px 0px 10px',
                                    width: '150px',
                                    height: '32px',
                                    // backgroundColor: 'red',
                                }}
                            />
                        </Link>
                    </Box>
                    {/* 
                    MOBILE RESPONSIVE */}
                    <Box
                        sx={{
                            // flexGrow:1,
                            width: '33.33%',
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <IconButton
                            size='large'
                            // aria-label='account of current user'
                            // aria-controls='menu-appbar'
                            // aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            color='inherit'
                        >
                            <MenuIcon sx={{ color: '#787878' }} />
                        </IconButton>

                        <Menu
                            anchorEl={anchorElNav}
                            // anchorOrigin={{
                            //     vertical: 'bottom',
                            //     horizontal: 'left',
                            // }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                sx: {
                                    marginTop: '-15px',
                                    marginLeft: isMobile ? '-15px' : '-15px',
                                    width: isMobile ? 'auto' : '50%',
                                    height: isMobile ? '100%' : '110%',
                                },
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            <MenuItem
                                // disableRipple
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'right',
                                    justifyContent: 'right',

                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#fff',
                                        backgroundColor: '#fff',
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    <IconButton
                                        aria-label='close'
                                        aria-haspopup='true'
                                        onClick={() =>
                                            handleCloseNavMenu('close')
                                        }
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor:
                                                    'rgba(92, 181, 179, 0.1) ',
                                            },
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Typography>
                            </MenuItem>
                            {loggedInUser?.role === 'owner_company' ||
                            loggedInUser?.role === 'guest_company' ? (
                                <>
                                    <Grid>
                                        <MenuItem
                                            onClick={handleOpenProfileMenu}
                                            key={'Profile'}
                                            sx={{
                                                width: 'auto',
                                                height: '60px',
                                                // backgroundColor: 'red',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                // display: { xs: 'block', md: 'none' },
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: '#5cb5b3',
                                                    backgroundColor:
                                                        'rgba(92, 181, 179, 0.1) ',
                                                    // transition: '1s all ease-in-out',
                                                },
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    // backgroundColor: 'yellow',
                                                    width: '10%',
                                                    // height: '100%',
                                                }}
                                            >
                                                <Typography
                                                    textAlign='left'
                                                    justifyContent='center'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        paddingTop: '5px',
                                                    }}
                                                >
                                                    <AccountCircleIcon
                                                        sx={{
                                                            color: '#5cb5b3',
                                                        }}
                                                    />
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                sx={{
                                                    width: '90%',
                                                    // backgroundColor: 'red',
                                                }}
                                            >
                                                <Typography
                                                    textAlign='left'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    Account
                                                </Typography>
                                            </Grid>
                                        </MenuItem>

                                        <Menu
                                            sx={{ mt: '45px' }}
                                            anchorEl={anchorElProfile}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    width: '200px',
                                                    height: 'auto',
                                                },
                                            }}
                                            open={Boolean(anchorElProfile)}
                                            onClose={handleCloseProfileMenu}
                                        >
                                            {profileWithIcons
                                                .filter?.(
                                                    (profileItem: any) =>
                                                        profileItem !== null
                                                )
                                                .map((profileItem: any) => (
                                                    <MenuItem
                                                        // disableRipple
                                                        key={
                                                            profileItem?.menuName
                                                        }
                                                        onClick={() =>
                                                            handleCloseProfileMenu(
                                                                profileItem?.menuName
                                                            )
                                                        }
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            width: '100%',
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                color: '#5cb5b3',
                                                                // transition: '1s all ease-in-out',
                                                                backgroundColor:
                                                                    'rgba(92, 181, 179, 0.1) ',
                                                            },
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                // backgroundColor: 'yellow',
                                                                width: '10%',
                                                                // height: '100%',
                                                            }}
                                                        >
                                                            <Typography
                                                                textAlign='left'
                                                                justifyContent='center'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    paddingTop:
                                                                        '5px',
                                                                }}
                                                            >
                                                                {
                                                                    profileItem?.menuIcon
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: '90%',
                                                                // backgroundColor: 'red',
                                                            }}
                                                        >
                                                            <Typography
                                                                textAlign='left'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    paddingLeft:
                                                                        '20px',
                                                                }}
                                                            >
                                                                {
                                                                    profileItem?.menuName
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </MenuItem>
                                                ))}
                                        </Menu>
                                        {loggedInUser?.role === 'guest_user' ||
                                        loggedInUser?.role === 'owner_admin' ||
                                        loggedInUser?.role === 'guest_admin' ||
                                        loggedInUser?.role ===
                                            'guest_department_admin' ||
                                        loggedInUser?.role === 'admin' ? (
                                            <>
                                                <MenuItem
                                                    onClick={handleOpenUserMenu}
                                                    key={'Settings'}
                                                    sx={{
                                                        width: 'auto',
                                                        height: '60px',
                                                        // backgroundColor: 'red',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        // display: { xs: 'block', md: 'none' },
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            backgroundColor:
                                                                'rgba(92, 181, 179, 0.1) ',
                                                            // transition: '1s all ease-in-out',
                                                        },
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            // backgroundColor: 'yellow',
                                                            width: '10%',
                                                            // height: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            justifyContent='center'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            <SettingsOutlinedIcon
                                                                sx={{
                                                                    color: '#5cb5b3',
                                                                }}
                                                            />
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        sx={{
                                                            width: '90%',
                                                            // backgroundColor: 'red',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                            }}
                                                        >
                                                            Settings
                                                        </Typography>
                                                    </Grid>
                                                </MenuItem>

                                                <Menu
                                                    sx={{ mt: '45px' }}
                                                    anchorEl={anchorElUser}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    keepMounted
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    PaperProps={{
                                                        sx: {
                                                            width: '200px',
                                                            height: 'auto',
                                                        },
                                                    }}
                                                    open={Boolean(anchorElUser)}
                                                    onClose={
                                                        handleCloseUserMenu
                                                    }
                                                >
                                                    {settingsWithIcons
                                                        .filter?.(
                                                            (setting: any) =>
                                                                setting !== null
                                                        )
                                                        .map((setting: any) => (
                                                            <MenuItem
                                                                key={
                                                                    setting?.menuName
                                                                }
                                                                onClick={() =>
                                                                    handleCloseUserMenu(
                                                                        setting?.menuName
                                                                    )
                                                                }
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    width: '100%',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        color: '#5cb5b3',
                                                                        // transition: '1s all ease-in-out',
                                                                        backgroundColor:
                                                                            'rgba(92, 181, 179, 0.1) ',
                                                                    },
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        // backgroundColor: 'yellow',
                                                                        width: '10%',
                                                                        // height: '100%',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        textAlign='left'
                                                                        justifyContent='center'
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            paddingTop:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            setting?.menuIcon
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: '90%',
                                                                        // backgroundColor: 'red',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        textAlign='left'
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            paddingLeft:
                                                                                '20px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            setting?.menuName
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        ))}
                                                </Menu>
                                            </>
                                        ) : null}

                                        {isMobile ? (
                                            <>
                                                <MenuItem
                                                    onClick={handleOpenHelpMenu}
                                                    key={'Help'}
                                                    sx={{
                                                        width: 'auto',
                                                        height: '60px',
                                                        // backgroundColor: 'red',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        // display: { xs: 'block', md: 'none' },
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            backgroundColor:
                                                                'rgba(92, 181, 179, 0.1) ',
                                                            // transition: '1s all ease-in-out',
                                                        },
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            // backgroundColor: 'yellow',
                                                            width: '10%',
                                                            // height: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            justifyContent='center'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            <HelpOutlineIcon
                                                                sx={{
                                                                    color: '#5cb5b3',
                                                                }}
                                                            />
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        sx={{
                                                            width: '90%',
                                                            // backgroundColor: 'red',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                            }}
                                                        >
                                                            Help
                                                        </Typography>
                                                    </Grid>
                                                </MenuItem>

                                                <Menu
                                                    sx={{ mt: '45px' }}
                                                    anchorEl={anchorElUser}
                                                    anchorOrigin={{
                                                        vertical: 355,
                                                        horizontal: 150,
                                                    }}
                                                    keepMounted
                                                    // transformOrigin={{
                                                    //     vertical: 350,
                                                    //     horizontal: 'right',
                                                    // }}
                                                    PaperProps={{
                                                        sx: {
                                                            width: '200px',
                                                            height: 'auto',
                                                        },
                                                    }}
                                                    open={Boolean(anchorElHelp)}
                                                    onClose={
                                                        handleCloseHelpMenu
                                                    }
                                                >
                                                    {helpWithIcons
                                                        .filter?.(
                                                            (menuItem: any) =>
                                                                menuItem !==
                                                                null
                                                        )
                                                        .map(
                                                            (menuItem: any) => (
                                                                <MenuItem
                                                                    // disableRipple
                                                                    key={
                                                                        menuItem?.menuName
                                                                    }
                                                                    onClick={() =>
                                                                        handleCloseHelpMenu(
                                                                            menuItem?.menuName
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                        width: '100%',
                                                                        '&:hover':
                                                                            {
                                                                                cursor: 'pointer',
                                                                                color: '#5cb5b3',
                                                                                // transition: '1s all ease-in-out',
                                                                                backgroundColor:
                                                                                    'rgba(92, 181, 179, 0.1) ',
                                                                            },
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: '100%',
                                                                            // backgroundColor: 'red',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            textAlign='left'
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                paddingLeft:
                                                                                    '0px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                menuItem?.menuName
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    <Divider />
                                                    <MenuItem
                                                        // disableRipple
                                                        key={'Report'}
                                                        onClick={() =>
                                                            handleCloseHelpMenu(
                                                                'Report'
                                                            )
                                                        }
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            width: '100%',
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                color: '#5cb5b3',
                                                                // transition: '1s all ease-in-out',
                                                                backgroundColor:
                                                                    'rgba(92, 181, 179, 0.1) ',
                                                            },
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: '100%',
                                                                // backgroundColor: 'red',
                                                            }}
                                                        >
                                                            <Typography
                                                                textAlign='left'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    paddingLeft:
                                                                        '0px',
                                                                }}
                                                            >
                                                                Report a problem
                                                            </Typography>
                                                        </Grid>
                                                    </MenuItem>
                                                </Menu>
                                            </>
                                        ) : null}
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid>
                                        {pagesWithIconsMobile.map(
                                            (page: any) => (
                                                <MenuItem
                                                    // disableRipple
                                                    key={page.menuName}
                                                    onClick={() =>
                                                        handleCloseNavMenu(
                                                            page.menuName
                                                        )
                                                    }
                                                    sx={{
                                                        width: 'auto',
                                                        height: '60px',
                                                        // backgroundColor: 'red',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        // display: { xs: 'block', md: 'none' },
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            backgroundColor:
                                                                'rgba(92, 181, 179, 0.1) ',
                                                            // transition: '1s all ease-in-out',
                                                        },
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            // backgroundColor: 'yellow',
                                                            width: '10%',
                                                            // height: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            justifyContent='center'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingTop:
                                                                    '5px',
                                                                // backgroundColor: 'red',
                                                                // color: '#fff',
                                                                // borderRadius: '80%'
                                                            }}
                                                        >
                                                            {page.menuIcon}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            // backgroundColor: 'green',
                                                            width: '90%',
                                                            // height: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                            }}
                                                        >
                                                            {page.menuName}
                                                        </Typography>
                                                    </Grid>
                                                </MenuItem>
                                            )
                                        )}
                                    </Grid>
                                    <Divider />

                                    <Grid>
                                        <MenuItem
                                            onClick={handleOpenProfileMenu}
                                            key={'Profile'}
                                            sx={{
                                                width: 'auto',
                                                height: '60px',
                                                // backgroundColor: 'red',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                // display: { xs: 'block', md: 'none' },
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: '#5cb5b3',
                                                    backgroundColor:
                                                        'rgba(92, 181, 179, 0.1) ',
                                                    // transition: '1s all ease-in-out',
                                                },
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    // backgroundColor: 'yellow',
                                                    width: '10%',
                                                    // height: '100%',
                                                }}
                                            >
                                                <Typography
                                                    textAlign='left'
                                                    justifyContent='center'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        paddingTop: '5px',
                                                    }}
                                                >
                                                    <AccountCircleIcon
                                                        sx={{
                                                            color: '#5cb5b3',
                                                        }}
                                                    />
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                sx={{
                                                    width: '90%',
                                                    // backgroundColor: 'red',
                                                }}
                                            >
                                                <Typography
                                                    textAlign='left'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    Account
                                                </Typography>
                                            </Grid>
                                        </MenuItem>

                                        <Menu
                                            sx={{ mt: '45px' }}
                                            anchorEl={anchorElProfile}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    width: '200px',
                                                    height: 'auto',
                                                },
                                            }}
                                            open={Boolean(anchorElProfile)}
                                            onClose={handleCloseProfileMenu}
                                        >
                                            {profileWithIcons
                                                .filter?.(
                                                    (profileItem: any) =>
                                                        profileItem !== null
                                                )
                                                .map((profileItem: any) => (
                                                    <MenuItem
                                                        // disableRipple
                                                        key={
                                                            profileItem?.menuName
                                                        }
                                                        onClick={() =>
                                                            handleCloseProfileMenu(
                                                                profileItem?.menuName
                                                            )
                                                        }
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            width: '100%',
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                color: '#5cb5b3',
                                                                // transition: '1s all ease-in-out',
                                                                backgroundColor:
                                                                    'rgba(92, 181, 179, 0.1) ',
                                                            },
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                // backgroundColor: 'yellow',
                                                                width: '10%',
                                                                // height: '100%',
                                                            }}
                                                        >
                                                            <Typography
                                                                textAlign='left'
                                                                justifyContent='center'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    paddingTop:
                                                                        '5px',
                                                                }}
                                                            >
                                                                {
                                                                    profileItem?.menuIcon
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: '90%',
                                                                // backgroundColor: 'red',
                                                            }}
                                                        >
                                                            <Typography
                                                                textAlign='left'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    paddingLeft:
                                                                        '20px',
                                                                }}
                                                            >
                                                                {
                                                                    profileItem?.menuName
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </MenuItem>
                                                ))}
                                        </Menu>
                                        {loggedInUser?.role === 'owner_admin' ||
                                        loggedInUser?.role === 'guest_admin' ||
                                        loggedInUser?.role ===
                                            'guest_department_admin' ||
                                        loggedInUser?.role === 'admin' ? (
                                            <>
                                                <MenuItem
                                                    onClick={handleOpenUserMenu}
                                                    key={'Settings'}
                                                    sx={{
                                                        width: 'auto',
                                                        height: '60px',
                                                        // backgroundColor: 'red',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        // display: { xs: 'block', md: 'none' },
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            backgroundColor:
                                                                'rgba(92, 181, 179, 0.1) ',
                                                            // transition: '1s all ease-in-out',
                                                        },
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            // backgroundColor: 'yellow',
                                                            width: '10%',
                                                            // height: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            justifyContent='center'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            <SettingsOutlinedIcon
                                                                sx={{
                                                                    color: '#5cb5b3',
                                                                }}
                                                            />
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        sx={{
                                                            width: '90%',
                                                            // backgroundColor: 'red',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                            }}
                                                        >
                                                            Settings
                                                        </Typography>
                                                    </Grid>
                                                </MenuItem>

                                                <Menu
                                                    sx={{ mt: '45px' }}
                                                    anchorEl={anchorElUser}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    keepMounted
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    PaperProps={{
                                                        sx: {
                                                            width: '200px',
                                                            height: 'auto',
                                                        },
                                                    }}
                                                    open={Boolean(anchorElUser)}
                                                    onClose={
                                                        handleCloseUserMenu
                                                    }
                                                >
                                                    {settingsWithIcons
                                                        .filter?.(
                                                            (setting: any) =>
                                                                setting !== null
                                                        )
                                                        .map((setting: any) => (
                                                            <MenuItem
                                                                // disableRipple
                                                                key={
                                                                    setting?.menuName
                                                                }
                                                                onClick={() =>
                                                                    handleCloseUserMenu(
                                                                        setting?.menuName
                                                                    )
                                                                }
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    width: '100%',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        color: '#5cb5b3',
                                                                        // transition: '1s all ease-in-out',
                                                                        backgroundColor:
                                                                            'rgba(92, 181, 179, 0.1) ',
                                                                    },
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        // backgroundColor: 'yellow',
                                                                        width: '10%',
                                                                        // height: '100%',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        textAlign='left'
                                                                        justifyContent='center'
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            paddingTop:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            setting?.menuIcon
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: '90%',
                                                                        // backgroundColor: 'red',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        textAlign='left'
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            paddingLeft:
                                                                                '20px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            setting?.menuName
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        ))}
                                                </Menu>
                                            </>
                                        ) : null}

                                        {isMobile ? (
                                            <>
                                                <MenuItem
                                                    onClick={handleOpenHelpMenu}
                                                    key={'Help'}
                                                    sx={{
                                                        width: 'auto',
                                                        height: '60px',
                                                        // backgroundColor: 'red',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        // display: { xs: 'block', md: 'none' },
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            backgroundColor:
                                                                'rgba(92, 181, 179, 0.1) ',
                                                            // transition: '1s all ease-in-out',
                                                        },
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            // backgroundColor: 'yellow',
                                                            width: '10%',
                                                            // height: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            justifyContent='center'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            <HelpOutlineIcon
                                                                sx={{
                                                                    color: '#5cb5b3',
                                                                }}
                                                            />
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        sx={{
                                                            width: '90%',
                                                            // backgroundColor: 'red',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                            }}
                                                        >
                                                            Help
                                                        </Typography>
                                                    </Grid>
                                                </MenuItem>

                                                <Menu
                                                    sx={{ mt: '45px' }}
                                                    anchorEl={anchorElUser}
                                                    anchorOrigin={{
                                                        vertical: 355,
                                                        horizontal: 150,
                                                    }}
                                                    keepMounted
                                                    // transformOrigin={{
                                                    //     vertical: 350,
                                                    //     horizontal: 'right',
                                                    // }}
                                                    PaperProps={{
                                                        sx: {
                                                            width: '200px',
                                                            height: 'auto',
                                                        },
                                                    }}
                                                    open={Boolean(anchorElHelp)}
                                                    onClose={
                                                        handleCloseHelpMenu
                                                    }
                                                >
                                                    {helpWithIcons
                                                        .filter?.(
                                                            (menuItem: any) =>
                                                                menuItem !==
                                                                null
                                                        )
                                                        .map(
                                                            (menuItem: any) => (
                                                                <MenuItem
                                                                    // disableRipple
                                                                    key={
                                                                        menuItem?.menuName
                                                                    }
                                                                    onClick={() =>
                                                                        handleCloseHelpMenu(
                                                                            menuItem?.menuName
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                        width: '100%',
                                                                        '&:hover':
                                                                            {
                                                                                cursor: 'pointer',
                                                                                color: '#5cb5b3',
                                                                                // transition: '1s all ease-in-out',
                                                                                backgroundColor:
                                                                                    'rgba(92, 181, 179, 0.1) ',
                                                                            },
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: '100%',
                                                                            // backgroundColor: 'red',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            textAlign='left'
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                paddingLeft:
                                                                                    '0px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                menuItem?.menuName
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    <Divider />
                                                    <MenuItem
                                                        // disableRipple
                                                        key={'Report'}
                                                        onClick={() =>
                                                            handleCloseHelpMenu(
                                                                'Report'
                                                            )
                                                        }
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            width: '100%',
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                color: '#5cb5b3',
                                                                // transition: '1s all ease-in-out',
                                                                backgroundColor:
                                                                    'rgba(92, 181, 179, 0.1) ',
                                                            },
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: '100%',
                                                                // backgroundColor: 'red',
                                                            }}
                                                        >
                                                            <Typography
                                                                textAlign='left'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    paddingLeft:
                                                                        '0px',
                                                                }}
                                                            >
                                                                Report a problem
                                                            </Typography>
                                                        </Grid>
                                                    </MenuItem>
                                                </Menu>
                                            </>
                                        ) : null}
                                    </Grid>
                                </>
                            )}

                            <Divider flexItem />
                            <Grid
                                sx={{
                                    width: 'auto',
                                    height: '100%',
                                    textAlign: 'center',
                                }}
                            >
                                <Grid sx={{ padding: '100px' }}>
                                    <img
                                        alt='primarylogo'
                                        src='/logos/GreenLogoNoBack.png'
                                        style={{
                                            // height: '50px',
                                            // width: '140px',
                                            height: '45px',
                                        }}
                                    />{' '}
                                </Grid>{' '}
                            </Grid>
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            height: '40px',
                            width: '33.33%',
                            // SHOW LOGO MIDDLE IF SCREEN XS
                            display: { xs: 'flex', md: 'none' },
                            // mr: 1,
                            justifyContent: ' center',
                        }}
                    >
                        {' '}
                        <Link
                            to={
                                loggedInUser?.role === 'owner_company' ||
                                loggedInUser?.role === 'guest_company'
                                    ? '/users'
                                    : '/dashboard'
                            }
                        >
                            <img
                                alt='primarylogo'
                                src='/logos/GreenLogoNoBack.png'
                                style={{
                                    // padding: '0px 50px 0px 10px',
                                    width: '100px',
                                    height: '32px',
                                    // backgroundColor: 'red',
                                }}
                            />
                        </Link>
                    </Box>

                    {/* 
                    DESKTOP RESPONSIVE */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        {loggedInUser?.role === 'owner_company' ||
                        loggedInUser?.role === 'guest_company' ? null : (
                            <Grid>
                                {' '}
                                {pagesWithIconsDesktop.map((page) => (
                                    <Button
                                        // disableRipple

                                        key={page.menuName}
                                        onClick={() =>
                                            handleCloseNavMenu(page.menuName)
                                        }
                                        sx={{
                                            my: 2,
                                            color: '#787878',
                                            // display: 'block',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor:
                                                    'rgba(92, 181, 179, 0.1) ',
                                                // textDecoration: 'underline',

                                                // transition: '1s all ease-in-out',
                                            },
                                        }}
                                    >
                                        {page.menuName}
                                    </Button>
                                ))}
                            </Grid>
                        )}
                    </Box>

                    {/* SETTINGS COG and PROFILE*/}
                    <Box
                        sx={{
                            display: 'flex',
                            width: '33.33%',
                            justifyContent: ' flex-end',
                            alignItems: 'flex-end',
                            alignContent: 'flex-end',
                        }}
                    >
                        {' '}
                        <Grid
                            sx={{
                                display: 'flex',
                                width: 'fit-content',
                                justifyContent: isMobile
                                    ? 'center'
                                    : 'flex-end',
                                paddingRight: isMobile ? '5px' : '15px',
                            }}
                        >
                            <IconButton
                                onClick={() => navigate('/messages')}
                                disableRipple
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                            >
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={unreadCount}
                                    color='primary'
                                >
                                    <MailOutline />
                                </Badge>
                            </IconButton>

                            {loggedInUser?.role.includes('company') ||
                            loggedInUser?.role === 'admin' ? null : (
                                <NotificationsCenter />
                            )}

                            {!isMobile &&
                            (loggedInUser?.role === 'owner_admin' ||
                                loggedInUser?.role === 'guest_admin' ||
                                loggedInUser?.role ===
                                    'guest_department_admin' ||
                                loggedInUser?.role === 'admin') ? (
                                <>
                                    <CustomIconButton
                                        disableRipple
                                        onClick={handleOpenUserMenu}
                                        color='dark'
                                        sx={{
                                            p: 0,
                                            paddingRight: isMobile
                                                ? '5px'
                                                : '0px',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                        }}
                                    >
                                        <SettingsOutlinedIcon
                                            sx={{
                                                margin: isMobile
                                                    ? '0px'
                                                    : '0px 10px 0px 0px',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: '#5cb5b3',
                                                    backgroundColor:
                                                        'rgba(92, 181, 179, 0.1) ',
                                                    // textDecoration: 'underline',

                                                    transition:
                                                        '1s all ease-in-out',
                                                },
                                            }}
                                        />
                                    </CustomIconButton>

                                    <Menu
                                        sx={{ mt: '45px' }}
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                width: '200px',
                                                height: 'auto',
                                            },
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {settingsWithIcons
                                            .filter?.(
                                                (setting: any) =>
                                                    setting !== null
                                            )
                                            .map((setting: any) => (
                                                <MenuItem
                                                    // disableRipple
                                                    key={setting?.menuName}
                                                    onClick={() =>
                                                        handleCloseUserMenu(
                                                            setting?.menuName
                                                        )
                                                    }
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            // transition: '1s all ease-in-out',
                                                            backgroundColor:
                                                                'rgba(92, 181, 179, 0.1) ',
                                                        },
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            // backgroundColor: 'yellow',
                                                            width: '10%',
                                                            // height: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            justifyContent='center'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            {setting?.menuIcon}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width: '90%',
                                                            // backgroundColor: 'red',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingLeft:
                                                                    '20px',
                                                            }}
                                                        >
                                                            {setting?.menuName}
                                                        </Typography>
                                                    </Grid>
                                                </MenuItem>
                                            ))}
                                    </Menu>
                                </>
                            ) : null}
                            {!isMobile ? (
                                <>
                                    <CustomIconButton
                                        disableRipple
                                        onClick={handleOpenHelpMenu}
                                        color='dark'
                                        sx={{
                                            p: 0,
                                            paddingRight: isMobile
                                                ? '5px'
                                                : '0px',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                        }}
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                margin: isMobile
                                                    ? '0px'
                                                    : '0px 10px 0px 0px',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: '#5cb5b3',
                                                    backgroundColor:
                                                        'rgba(92, 181, 179, 0.1) ',
                                                    // textDecoration: 'underline',

                                                    transition:
                                                        '1s all ease-in-out',
                                                },
                                            }}
                                        />
                                    </CustomIconButton>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        anchorEl={anchorElHelp}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                width: '200px',
                                                height: 'auto',
                                            },
                                        }}
                                        open={Boolean(anchorElHelp)}
                                        onClose={handleCloseHelpMenu}
                                    >
                                        {helpWithIcons
                                            .filter?.(
                                                (menuItem: any) =>
                                                    menuItem !== null
                                            )
                                            .map((menuItem: any) => (
                                                <MenuItem
                                                    // disableRipple
                                                    key={menuItem?.menuName}
                                                    onClick={() =>
                                                        handleCloseHelpMenu(
                                                            menuItem?.menuName
                                                        )
                                                    }
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            // transition: '1s all ease-in-out',
                                                            backgroundColor:
                                                                'rgba(92, 181, 179, 0.1) ',
                                                        },
                                                    }}
                                                >
                                                    {/* <Grid
                                                sx={{
                                                    // backgroundColor: 'yellow',
                                                    width: '10%',
                                                    // height: '100%',
                                                }}
                                            >
                                                <Typography
                                                    textAlign='left'
                                                    justifyContent='center'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        paddingTop: '5px',
                                                    }}
                                                >
                                                    {setting?.menuIcon}
                                                </Typography>
                                            </Grid> */}
                                                    <Grid
                                                        sx={{
                                                            width: '100%',
                                                            // backgroundColor: 'red',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingLeft:
                                                                    '0px',
                                                            }}
                                                        >
                                                            {menuItem?.menuName}
                                                        </Typography>
                                                    </Grid>
                                                </MenuItem>
                                            ))}
                                        <Divider />
                                        <MenuItem
                                            // disableRipple
                                            key={'Report'}
                                            onClick={() =>
                                                handleCloseHelpMenu('Report')
                                            }
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: '#5cb5b3',
                                                    // transition: '1s all ease-in-out',
                                                    backgroundColor:
                                                        'rgba(92, 181, 179, 0.1) ',
                                                },
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    width: '100%',
                                                    // backgroundColor: 'red',
                                                }}
                                            >
                                                <Typography
                                                    textAlign='left'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        paddingLeft: '0px',
                                                    }}
                                                >
                                                    Report a problem
                                                </Typography>
                                            </Grid>
                                        </MenuItem>
                                    </Menu>{' '}
                                    <Grid
                                        sx={{
                                            borderLeft: isMobile
                                                ? 'none'
                                                : '1px solid #e2e2e2',
                                            display: 'flex',
                                            justifyContent: isMobile
                                                ? 'center'
                                                : 'flex-end',
                                        }}
                                    >
                                        <CustomIconButton
                                            disableRipple
                                            onClick={handleOpenProfileMenu}
                                            sx={{
                                                p: 0,
                                                marginLeft: isMobile
                                                    ? '0px'
                                                    : '12px',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: '#5cb5b3',
                                                    backgroundColor:
                                                        'transparent',
                                                    background: 'none',
                                                },
                                            }}
                                        >
                                            {/* 
                                                Render profile picture for all except company accounts
                                            */}
                                            {profileDetails?.role?.includes(
                                                'company'
                                            ) ? (
                                                <AccountCircleIcon
                                                    sx={{
                                                        p: 0,
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            backgroundColor:
                                                                'transparent',
                                                            background: 'none',
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <ProfilePicture
                                                    profileDetails={
                                                        profileDetails
                                                    }
                                                    src={
                                                        profileDetails
                                                            ?.profilePicture
                                                            ?.src
                                                    }
                                                    sx={{ ...avatarStyles }}
                                                />
                                            )}
                                            {isMobile ? null : (
                                                <List>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        fontSize:
                                                                            '12px',
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                >
                                                                    {loggedInUser?.role ===
                                                                        'owner_company' ||
                                                                    loggedInUser?.role ===
                                                                        'guest_company' ? (
                                                                        'Company Profile'
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                profileDetails?.firstName
                                                                            }{' '}
                                                                            {
                                                                                profileDetails?.lastName
                                                                            }
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                >
                                                                    {
                                                                        loggedInUser?.email
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                </List>
                                            )}
                                        </CustomIconButton>{' '}
                                    </Grid>{' '}
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id='menu-appbar'
                                        anchorEl={anchorElProfile}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                width: '200px',
                                                height: 'auto',
                                            },
                                        }}
                                        open={Boolean(anchorElProfile)}
                                        onClose={handleCloseProfileMenu}
                                    >
                                        {profileWithIcons
                                            .filter?.(
                                                (profileItem: any) =>
                                                    profileItem !== null
                                            )
                                            .map((profileItem: any) => (
                                                <MenuItem
                                                    // disableRipple
                                                    key={profileItem?.menuName}
                                                    onClick={() =>
                                                        handleCloseProfileMenu(
                                                            profileItem?.menuName
                                                        )
                                                    }
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            // transition: '1s all ease-in-out',
                                                            backgroundColor:
                                                                'rgba(92, 181, 179, 0.1) ',
                                                        },
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            // backgroundColor: 'yellow',
                                                            width: '10%',
                                                            // height: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            justifyContent='center'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            {
                                                                profileItem?.menuIcon
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width: '90%',
                                                            // backgroundColor: 'red',
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign='left'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                paddingLeft:
                                                                    '20px',
                                                            }}
                                                        >
                                                            {
                                                                profileItem?.menuName
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </MenuItem>
                                            ))}
                                    </Menu>
                                </>
                            ) : null}
                        </Grid>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
