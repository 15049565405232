import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetReportQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function AdminDashboardSmallerStats() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isLaptop = useMediaQuery('(max-width: 1550px)');
    const [dashboardData, setDashboardData] = useState<any>([]);

    //GET REPORT
    const { data: getTodayStats } = useGetReportQuery(
        `/dashboard/scheduled-bookings?period=today`
    );
    const { data: getTomorrowStats } = useGetReportQuery(
        `/dashboard/scheduled-bookings?period=tomorrow`
    );
    const { data: getCurrentWeekStats } = useGetReportQuery(
        `/dashboard/scheduled-bookings?period=current-week`
    );
    const { data: getNextWeekStats } = useGetReportQuery(
        `/dashboard/scheduled-bookings?period=next-week`
    );

    useEffect(() => {
        let data = [
            {
                reportName: 'Scheduled Today',
                reportUrl: 'today',
                statNo: getTodayStats?.length,
            },
            {
                reportName: 'Scheduled Tomorrow',
                reportUrl: 'tomorrow',
                statNo: getTomorrowStats?.length,
            },
            {
                reportName: 'Scheduled This Week',
                reportUrl: 'current-week',
                statNo: getCurrentWeekStats?.length,
            },

            {
                reportName: 'Scheduled Next Week',
                reportUrl: 'next-week',
                statNo: getNextWeekStats?.length,
            },
        ];
        setDashboardData(data);
    }, [
        getTodayStats,
        getTomorrowStats,
        getCurrentWeekStats,
        getNextWeekStats,
    ]);

    const navigate = useNavigate();

    const handleOpen = (reportUrl: any) => {
        navigate(`/dashboard/scheduled/${reportUrl}`);
        ReactGA.send({
            hitType: 'pageview',
            page: `/dashboard/scheduled/${reportUrl}`,
            title: `Scheduled ${reportUrl} Report (Admin)`,
        });
    };

    return (
        <Grid
            container
            justifyContent='center'
            spacing={isMobile ? 6 : 6}
            rowSpacing={6}
            direction={isMobile || isIpad ? 'column' : 'row'}
            sx={{paddingTop:'10px'}}
        >
            {dashboardData.map((dashboardItem: any) => (
                <Grid key={dashboardItem?.reportName} xs={3} item>
                    <Paper
                        sx={{
                            height: 165,
                            flexGrow: 1,
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#1A2027'
                                    : '#fff',
                        }}
                        onClick={() => handleOpen(dashboardItem?.reportUrl)}
                    >
                        <Grid
                            sx={{
                                // backgroundColor: 'red',
                                height: '100%',
                                // display: 'flex',
                                flexDirection: 'column',
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                textAlignVertical: 'center',
                                alignContent: 'center',
                                alignSelf: 'center',
                            }}
                        >
                            <Grid
                                sx={{
                                    // backgroundColor: 'white',
                                    height: '70%',
                                }}
                            >
                                <Typography
                                    component='h1'
                                    variant='h5'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '70px',
                                    }}
                                >
                                    {' '}
                                    {dashboardItem?.statNo}
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    // backgroundColor: 'purple',
                                    height: '30%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    {dashboardItem?.reportName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
}
