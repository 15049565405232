import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { SnackBarReducer } from './SnackBarSlice';
import { sevenDayApi } from './SevenDayApiSlice';
import { AuthReducer } from './AuthSlice';
import { FilterUserReducer } from './UserFilterSlice';
import { FilterClientReducer } from './ClientFilterSlice';
import { FilterReservationDateReducer } from './ReservationDateSlice';
import { FilterCalendarReducer } from './CalendarViewSlice';
import { ReportAPIRefSliceReducer } from './ReportAPIRefSlice';
import { LoadingSliceReducer } from './LoadingSlice';
import { FilterVisitTypeSliceReducer } from './VisitTypeSlice';
import { FilterInviteSliceReducer } from './inviteSlice';
import { ReservationFiltersReducer } from './ReservationFiltersSlice';
import { ExperienceFiltersReducer } from './ExperienceFiltersSlice';
import { dateFilterReducer } from './DateFilterSlice';
import { experienceSearchReducer } from './ExperienceSearchSlice';
import { reservationPoliciesReducer } from './ReservationPoliciesSlice';
import { vehicleApi } from './VehicleApiSlice';
import { publicHolidaysApi } from './PublicHolidaysSlice';
import { calendarErrorReducer } from './CalendarError';
import { driversReducer, licenceReducer } from './DriversSlice';
import { driverSameAsBookerReducer } from './DriverSameAsBookerSlice';
import { bookerReducer } from './BookerSlice';
import { calendarDateFilterReducer } from './CalendarDateFilterSlice';
import { ExperienceDiscountsReducer } from './DiscountsSlice';
import { priceRequiredReducer } from './PriceRequiredSlice';


const appReducer = combineReducers({
    snackBar: SnackBarReducer,
    filterUsers: FilterUserReducer,
    reservationDate: FilterReservationDateReducer,
    reservationFilters: ReservationFiltersReducer,
    experienceDiscounts: ExperienceDiscountsReducer,
    experienceFilters: ExperienceFiltersReducer,
    filterClients: FilterClientReducer,
    authDetails: AuthReducer,
    calendarView: FilterCalendarReducer,
    reportAPIRefSlice: ReportAPIRefSliceReducer,
    loadingSlice: LoadingSliceReducer,
    visitTypeSlice: FilterVisitTypeSliceReducer,
    inviteSlice: FilterInviteSliceReducer,
    drivers: driversReducer,
    driverSameAsBooker: driverSameAsBookerReducer,
    priceRequired: priceRequiredReducer,
    booker: bookerReducer,
    licence: licenceReducer,
    calendarError: calendarErrorReducer,
    reservationPolicies: reservationPoliciesReducer,
    dateFilter: dateFilterReducer,
    calendarDateFilter: calendarDateFilterReducer,
    experienceSearch: experienceSearchReducer,
    [sevenDayApi.reducerPath]: sevenDayApi.reducer,
    [vehicleApi.reducerPath]: vehicleApi.reducer,
    [publicHolidaysApi.reducerPath]: publicHolidaysApi.reducer,
});

const rootReducer = (state: any, action: any) => {
    // Resetting to initial state upon logout
    if (action.type === 'authDetails/logOut') {
        // Do not reset snackbar state upon logout to enable showing snackbars after redirecting to login page (which triggers a logout), for example success message upon password reset
        return { ...appReducer(undefined, action), snackBar: state.snackBar };
    }
    return appReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            sevenDayApi.middleware,
            vehicleApi.middleware,
            publicHolidaysApi.middleware
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
