import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import {
    FieldArrayWithId,
    useFieldArray,
    useFormContext,
} from 'react-hook-form';
import { camelCaseToLowerCase } from '../../../../shared/utils';
import { FormValues, Space } from '../../../types/experience';
import IndividualSpace, { ModifiedSpace } from './IndividualSpace';
import SpaceModal from './SpaceModal';
import { sortSpacesByType } from '../IndividualExperienceSpacesModal';

export default function SpaceConfiguration() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    const { control } = useFormContext<FormValues>();
    const {
        fields: spaces,
        append,
        remove,
        update,
    } = useFieldArray({
        name: 'spaces',
        control,
    });

    const [showModal, setShowModal] = useState(false);

    const handleAddSpace = (newSpace: Space) => {
        const modifiedSpace = {
            ...newSpace,
            newSpace: true, // So that we only apply the animation to new spaces
        };

        append(modifiedSpace);
        setShowModal(false);
    };

    const handleUpdate = (index: number, updatedSpace: Space) => {
        const modifiedSpace = {
            ...updatedSpace,
            newSpace: false,
        };

        update(index, modifiedSpace);
    };

    const generateSpaceNames = (
        spaces: FieldArrayWithId<FormValues, 'spaces', 'id'>[]
    ): ModifiedSpace[] => {
        const typeCounts: Record<string, number> = {};

        const spacesWithNames = spaces.map((space) => {
            const type = space.spaceType;
            typeCounts[type] = (typeCounts[type] || 0) + 1;
            const titleCaseType = camelCaseToLowerCase(type);

            const name = `${titleCaseType} ${typeCounts[type]}`;
            return { ...space, name };
        });

        return spacesWithNames;
    };

    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 2,
                }}
            >
                <Typography color='textPrimary'>
                    Configure the spaces available in your experience.
                </Typography>
                <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    {' '}
                    <Button
                        variant='outlined'
                        onClick={() => setShowModal(true)}
                        fullWidth={isMobile}
                    >
                        Add Configuration
                    </Button>
                </Grid>
            </Grid>

            <SpaceModal
                open={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={(space) => handleAddSpace(space)}
                existingSpace={null}
            />

            <Grid container columns={{ xs: 1, sm: 2, md: 3 }} spacing={4}>
                {sortSpacesByType(
                    generateSpaceNames(spaces).map((space, index) => ({
                        ...space,
                        originalIndex: index,
                    }))
                ).map((space) => (
                    <IndividualSpace
                        key={space.id}
                        index={space.originalIndex}
                        space={space}
                        handleDelete={() => remove(space.originalIndex)}
                        handleUpdate={(updatedSpace) =>
                            handleUpdate(space.originalIndex, updatedSpace)
                        }
                    />
                ))}
            </Grid>
        </>
    );
}
