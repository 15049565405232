import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { CircularProgress } from '@mui/material';

const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#fff',
            contrastText: '#5cb5b3',
        },
        green: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        other: {
            main: '#5cb5b3',
            contrastText: '#5cb5b3',
        },
        roundButton: {
            main: '#addad9',
            contrastText: '#5cb5b3',
        },
        warning: {
            main: '#D0342C',
            contrastText: '#fff',
        },
        gray: {
            main: '#787878',
            contrastText: '#787878',
        },
        lightGrey: {
            main: '#E0E0E0',
            contrastText: '#787878',
        },
    },
});

export function CustomButton({
    children,
    loading,
    width,
    height,
    disabled,
    type = 'button',
    ...props
}: any) {
    return (
        <ThemeProvider theme={theme}>
            <Button
                disableRipple
                disabled={disabled}
                type={type}
                sx={{ fontFamily: 'Open Sans', width, height }}
                {...props}
            >
                {children}
                {loading && (
                    <CircularProgress
                        color='inherit'
                        size={16}
                        sx={{ ml: 1 }}
                    />
                )}
            </Button>
        </ThemeProvider>
    );
}
