import {
    Accordion,
    AccordionSummary,
    Avatar,
    Container,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useMediaQuery,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import UserRole from '../../../../shared/AuthRoles';
import { useGetIndividualOwnerQuery } from '../../../../app/Slices/SevenDayApiSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import ReactGA from 'react-ga4';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export default function OwnerExperiencesPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const role = UserRole();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        data: getIndividualCompany,
        isFetching,
        error,
    } = useGetIndividualOwnerQuery(`owners/${role?.company}`);

    const handleChange = (url: string) => (event: any) => {
        if (event?.type) {
            navigate(url);
            ReactGA.send({
                hitType: 'pageview',
                page: url,
                title: url,
            });
            return;
        }
    };

    const ownerSettingList = [
        {
            settingName: 'Create new experience',
            settingDescription:
                'List your property, boat, vehicle, or activity to start earning and hosting unforgettable experiences on your terms!',
            settingUrl: '/newExperience',
            settingIcon: <AddCircleIcon />,
        },
        {
            settingName: 'Manage your listings',
            settingDescription:
                'View and edit your listing anytime — update details, adjust pricing, and manage availability to keep it fresh and bookable!',
            settingUrl: `/viewExperiences/all/${getIndividualCompany?.name
                .replace(/ /g, '')
                .toLowerCase()}`,
            settingIcon: <EditIcon />,
        },
        {
            settingName: 'Your Experience Settings',
            settingDescription:
                'Customize your experience with ease — manage your account, preferences, and notifications all in one place',
            settingUrl: '/adminCenter/host',
            settingIcon: <SettingsOutlinedIcon />,
        },
        {
            settingName: 'View all Experiences',
            settingDescription:
                'View all available experiences on the platform to stay informed and optimize your own!',
            settingUrl: '/viewExperiences/all',
            settingIcon: <VisibilityIcon />,
        },
    ];

    useEffect(() => {
        if (role?.role === 'owner_admin') {
            navigate(`/experiences`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: `/experiences`,
                title: 'Experiences',
            });
        } else {
            navigate(`/viewExperiences/all`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: `/viewExperiences/all`,
                title: 'All Experiences',
            });
        }
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Experiences
                    </Typography>
                </Grid>
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile ? '65%' : '78%',
                        paddingTop: '30px',
                        // justifyContent: 'left',
                        // display: 'flex',
                        // flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    {ownerSettingList.map((item: any) => (
                        <Accordion
                            expanded={false}
                            onChange={handleChange(item?.settingUrl)}
                            key={item?.settingName}
                        >
                            <AccordionSummary
                                expandIcon={<ArrowRightIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                // sx={{
                                //     backgroundColor:
                                //         expanded === item.panel ? '#f6f8f9' : '',
                                // }}
                            >
                                <Typography
                                    component='h3'
                                    variant='h6'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 550,
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>{item?.settingIcon}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item?.settingName}
                                            secondary={item?.settingDescription}
                                        />
                                    </ListItem>
                                </Typography>
                            </AccordionSummary>
                            {/* <AccordionDetails>{item.settingDescription}</AccordionDetails> */}
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
}
