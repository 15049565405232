import { zodResolver } from '@hookform/resolvers/zod';
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
    useGetAllGuestsQuery,
    useGetBillingReportQuery,
    useGetCompanyGuestsInfoQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../../shared/AuthRoles';
import { formatCurrencyShort } from '../../../../shared/utils';
import FormMultiSelect from '../../../shared/FormMultiSelect';
import FormSelect from '../../../shared/FormSelect';
import LinkCell from '../../../shared/LinkCell';
import StripedDataGrid from '../../../shared/StripedDataGrid';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
    openReportAPIRefSliceAction,
    selectReportAPIRefSliceData,
} from '../../../../app/Slices/ReportAPIRefSlice';

const BillingReport = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const dispatch = useDispatch();
    const reportExportAPI: any = useSelector(selectReportAPIRefSliceData);
    const { data: guestCompanyInfo } = useGetCompanyGuestsInfoQuery(
        `/guests/${loggedInUser?.company}`
    );

    const schema = z.object({
        year: z.number().nullable().optional(),
        guestIds: z.array(z.number()).optional(),
    });

    type BillingReportFilters = z.infer<typeof schema>;
    const {
        reset,
        control,
        watch,
        formState: { isDirty, isLoading },
    } = useForm<BillingReportFilters>({
        resolver: zodResolver(schema),
        defaultValues: {
            year: null,
            guestIds: [],
        },
    });

    const { data: billingReportData } = useGetBillingReportQuery({
        ...(watch('year') ? { year: watch('year') } : {}),
        ...(watch('guestIds') ? { guestIds: watch('guestIds') } : {}),
    });

    const modifiedBillingReportData = billingReportData?.map((item) => ({
        ...item,
        daysFromDueDate: Math.ceil(
            (new Date().getTime() - new Date(item.dueDate).getTime()) /
                (1000 * 60 * 60 * 24)
        ),
    }));

    const { data: allGuests } = useGetAllGuestsQuery(`/guests`, {
        skip: loggedInUser?.role !== 'admin',
    });

    const now = new Date();
    const currentYear = now.getFullYear();

    const years: { value: number; label: string }[] = [];
    const startYear =
        loggedInUser?.role === 'admin'
            ? 2023
            : new Date(guestCompanyInfo?.createdAt).getFullYear();
    for (let year = currentYear; year >= startYear; year--) {
        years.push({
            value: year,
            label: `${year}`,
        });
    }

    const apiRef = useGridApiRef();

    const renderLinkCell = (
        params: any,
        align: 'left' | 'center' | 'right' = 'left',
        paddingLeft: string = '0px'
    ) => (
        <LinkCell
            params={params}
            href={`/invoices/${params.row.id}`}
            state={{
                name: 'viewReservation',
                component: 'reports',
                url: 'reports/reservationsReport',
                params: params.row,
            }}
            align={align}
            paddingLeft={paddingLeft}
        />
    );

    const columns: GridColDef[] = [
        {
            field: 'guestName',
            headerName: 'Guest Name',
            flex: 1,
            minWidth: 130,
            type: 'string',
            sortable: true,
            renderCell: renderLinkCell,
        },
        {
            flex: 1,
            field: 'period',
            headerName: 'Period',
            minWidth: 170,
            type: 'string',
            sortable: true,
            valueGetter: (params) => {
                const from = params.row.from
                    ? moment(params.row.from).format('D MMM')
                    : 'N/A';
                const to = params.row.to
                    ? moment(params.row.to).format('D MMM, YYYY')
                    : 'N/A';
                return `${from} - ${to}`;
            },
            renderCell: (params) =>
                renderLinkCell({ ...params, value: params.value }),
        },

        {
            flex: 0.7,
            field: 'id',
            headerName: 'Ref.',
            minWidth: 80,
            type: 'string',
            sortable: true,
            valueGetter: (params) => {
                return `#${params.row.id}`;
            },
            renderCell: renderLinkCell,
        },
        {
            flex: 1,
            field: 'billedTo',
            headerName: 'Billed To',
            minWidth: 130,
            type: 'string',
            sortable: true,
            valueGetter: (params) => {
                const { firstName, lastName, streetAddress } = params.row;
                return (
                    `${firstName || ''} ${lastName || ''}${
                        streetAddress ? `, ${streetAddress}` : ''
                    }`.trim() || 'N/A'
                );
            },
            renderCell: (params) =>
                renderLinkCell({ ...params, value: params.value }),
        },

        {
            flex: 1,
            field: 'dueDate',
            headerName: 'Due Date',
            minWidth: 130,
            type: 'date',
            headerClassName: 'custom-padding-header',
            sortable: true,
            valueGetter: (params) => {
                return params.row.dueDate ? new Date(params.row.dueDate) : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? moment(params.value).format('D MMM YYYY')
                    : 'N/A';
                return renderLinkCell({ ...params, value }, 'left', '30px');
            },
        },

        {
            flex: 1,
            field: 'total',
            headerName: 'Amount (inc GST)',
            minWidth: 120,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            renderCell: (params) => {
                const value = params?.value
                    ? formatCurrencyShort(params?.value)
                    : '$0.00';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },

        {
            flex: 1,
            field: 'lessThan7Days',
            headerName: '<7 days',
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            valueGetter: (params) => {
                if (params.row.amountDue === 0) {
                    return null;
                }
                return params.row.daysFromDueDate < 7 &&
                    params.row.daysFromDueDate > 0
                    ? params.row.total
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? formatCurrencyShort(params?.value)
                    : '';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },
        {
            flex: 1,
            field: 'lessThan14Days',
            headerName: '<14 days',
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            valueGetter: (params) => {
                if (params.row.amountDue === 0) {
                    return null;
                }
                return params.row.daysFromDueDate < 14 &&
                    params.row.daysFromDueDate >= 7
                    ? params.row.total
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? formatCurrencyShort(params?.value)
                    : '';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },
        {
            flex: 1,
            field: 'lessThan30Days',
            headerName: '<30 days',
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            valueGetter: (params) => {
                if (params.row.amountDue === 0) {
                    return null;
                }
                return params.row.daysFromDueDate < 30 &&
                    params.row.daysFromDueDate >= 14
                    ? params.row.total
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? formatCurrencyShort(params?.value)
                    : '';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },
        {
            flex: 1,
            field: 'moreThan30Days',
            headerName: '30+ days',
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            valueGetter: (params) => {
                if (params.row.amountDue === 0) {
                    return null;
                }
                return params.row.daysFromDueDate >= 30
                    ? params.row.total
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? formatCurrencyShort(params?.value)
                    : '';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },

        {
            flex: 1,
            field: 'fullyPaidAt',
            headerName: 'Paid Date',
            minWidth: 130,
            type: 'date',
            headerClassName: 'custom-padding-header',
            sortable: true,
            valueGetter: (params) => {
                return params.row.fullyPaidAt
                    ? new Date(params.row.fullyPaidAt)
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? moment(params.value).format('D MMM YYYY')
                    : 'N/A';
                return renderLinkCell({ ...params, value }, 'left', '30px');
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            sortable: true,
            valueGetter: (params) =>
                params.row.amountDue === 0 ? 'Paid' : 'Unpaid',
            renderCell: (params) =>
                renderLinkCell({ ...params, value: params.value }),
        },
    ];

    useEffect(() => {
        let csv = apiRef?.current.exportDataAsCsv;
        let print = apiRef?.current.exportDataAsPrint;
        dispatch(
            openReportAPIRefSliceAction({
                exportInfo: {
                    csvExport: csv,
                    printExport: print,
                    search: apiRef?.current.setQuickFilterValues,
                },
            })
        );
    }, [apiRef, dispatch, billingReportData]);

    return (
        <Stack spacing={3} paddingTop={3}>
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                }}
            >
                <Grid container item columnSpacing={2} rowSpacing={2}>
                    {' '}
                    <Grid item xs={12} md={4} lg={3}>
                        <FormSelect
                            name='year'
                            control={control}
                            options={years}
                            label='Select Year'
                        />
                    </Grid>
                    {loggedInUser?.role === 'admin' && (
                        <Grid item xs={12} md={4} lg={3}>
                            <FormMultiSelect
                                name='guestIds'
                                control={control}
                                options={allGuests}
                                label='Select Guests'
                                getOptionLabel={(option) => option.name || ''}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        md={loggedInUser?.role === 'admin' ? 4 : 8}
                        lg={loggedInUser?.role === 'admin' ? 6 : 9}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {' '}
                        <Grid>
                            {' '}
                            <CustomButton
                                type='button'
                                variant='contained'
                                color='neutral'
                                // fullWidth
                                onClick={() => {
                                    reset();
                                }}
                                disabled={!isDirty}
                                height='40px'
                            >
                                Clear Filters
                            </CustomButton>
                        </Grid>
                        <Grid>
                            <CustomButton
                                disableRipple
                                variant='contained'
                                color='neutral'
                                height='35px'
                                onClick={() =>
                                    reportExportAPI?.csvExport({
                                        fileName: 'Invoices Report',
                                        columnsStyles: {
                                            createdAt: {
                                                numFmt: 'dd/mm/yyyy',
                                            },
                                        },
                                    })
                                }
                            >
                                <FileDownloadIcon fontSize='small' />{' '}
                            </CustomButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box
                sx={{
                    height: 800,
                    width: 'auto',
                }}
            >
                {' '}
                {!billingReportData ? (
                    <Grid
                        sx={{
                            height: isIpad ? '50vh' : isMobile ? '5px' : '50vh',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <LoadingComponent color='green' />
                    </Grid>
                ) : (
                    <StripedDataGrid
                        apiRef={apiRef}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    guestName: loggedInUser?.role === 'admin',
                                },
                            },
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? 'even'
                                : 'odd'
                        }
                        sx={{
                            boxShadow: 0,
                            border: 0,

                            '& .MuiDataGrid-cell': {
                                padding: '0',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        rows={
                            modifiedBillingReportData !== undefined
                                ? modifiedBillingReportData
                                : []
                        }
                        columns={columns}
                        slots={{
                            noRowsOverlay: () => (
                                <Stack
                                    height='70%'
                                    alignItems='center'
                                    justifyContent='center'
                                >
                                    <List>
                                        <ListItem
                                            sx={{
                                                padding: '0!important',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography>
                                                        No Records
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography>
                                                        There are no invoices
                                                        based on your filter
                                                        selection
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Stack>
                            ),
                            noResultsOverlay: () => (
                                <Stack
                                    height='70%'
                                    alignItems='center'
                                    justifyContent='center'
                                >
                                    <List>
                                        <ListItem
                                            sx={{
                                                padding: '0!important',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography>
                                                        No Results
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        Please try again
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Stack>
                            ),
                        }}
                    />
                )}
            </Box>
        </Stack>
    );
};

export default BillingReport;
