import { ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Hotjar from '../Hotjar';
import ToastContainer from '../feature/shared/ToastContainer';
import CustomRouter from '../shared/custom-router';
import { theme } from '../shared/theme';
import './App.css';
import { useToastUpdater } from '../feature/shared/Toast';

function App() {
    useToastUpdater();
    useEffect(() => {
        window.addEventListener('error', (e) => {
            if (e.message.includes('ResizeObserver')) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );

                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <Hotjar />
            <BrowserRouter>
                <ToastContainer />
                <CustomRouter />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
