import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import MessagingSettings from '../../components/Messages/MessagingSettings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';

const MessagingSettingsPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const onClickGoBack = () => {
        navigate(`/adminCenter/host`);
    };
    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    // height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <MessagingSettings />
                <Grid sx={{ height: isMobile ? '5%' : '5%' }}>
                    {' '}
                    <CustomButton
                        onClick={onClickGoBack}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                }}
                            >
                                Back
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MessagingSettingsPage;
