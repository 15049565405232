import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Badge,
    Box,
    Drawer,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { NavigateBefore } from '@mui/icons-material';
import {
    ChannelList,
    ChannelPreviewUIComponentProps,
    ChannelSearch,
    useChatContext,
} from 'stream-chat-react';
import {
    ChannelFilters,
    ChannelOptions,
    ChannelSort,
    DefaultGenerics,
} from 'stream-chat';
import UserRole from '../../../../shared/AuthRoles';
import { useNavigate, useParams } from 'react-router-dom';

const ChannelListItem = ({
    channel,
    active,
    displayImage,
    displayTitle,
    latestMessage,
}: ChannelPreviewUIComponentProps<DefaultGenerics>) => {
    const { setActiveChannel } = useChatContext();

    const handleSelect = () => {
        setActiveChannel(channel);
    };

    const preview: string =
        typeof latestMessage === 'string'
            ? latestMessage
            : latestMessage?.props.children;

    return (
        <ListItemButton disableRipple selected={active} onClick={handleSelect}>
            <ListItemAvatar>
                <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={channel.countUnread()}
                    color='primary'
                    sx={{
                        '& .MuiBadge-badge': {
                            border: '1px solid white',
                        },
                    }}
                >
                    <Avatar variant='rounded' src={displayImage} />
                </Badge>
            </ListItemAvatar>
            <ListItemText
                primaryTypographyProps={{
                    fontWeight: 'bold',
                }}
                primary={displayTitle}
                secondary={preview}
            />
        </ListItemButton>
    );
};

const CustomExitIcon = () => {
    return <NavigateBefore sx={{ color: 'text.primary' }} />;
};

const CustomChannelList = ({
    open,
    onSelect,
    searchQuery,
    handleSearchChange,
    ...props
}: any) => {
    const isMobile = useMediaQuery('(max-width: 770px)');

    const [isSearching, setIsSearching] = useState<boolean>(false);

    return (
        <Drawer
            anchor='left'
            open={open}
            variant={'persistent'}
            sx={{ position: 'fixed' }}
            PaperProps={{
                sx: { width: isMobile ? '100%' : '400px' },
            }}
        >
            <Toolbar />
            <Box>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    px={2}
                    pt={2}
                    pb={1}
                >
                    <Typography variant='h6' fontWeight='bold'>
                        Messages
                    </Typography>
                </Box>
                <ChannelSearch
                    searchForChannels
                    onSearch={() => setIsSearching(true)}
                    onSearchExit={() => setIsSearching(false)}
                    ExitSearchIcon={CustomExitIcon}
                    searchQueryParams={{
                        channelFilters: {
                            filters: {
                                members: { $in: [UserRole().sub.toString()] },
                            },
                        },
                        userFilters: {
                            filters: { username: '' },
                        },
                    }}
                />
            </Box>
            {!isSearching && <List onClick={onSelect}>{props.children}</List>}
        </Drawer>
    );
};

interface SidebarProps {
    open: boolean;
    onSelect: () => void;
}

const MessagingSidebar = ({ open, onSelect }: SidebarProps) => {
    const loggedInUser = UserRole();
    const { id } = useParams();
    const { channel } = useChatContext();

    const navigate = useNavigate();

    const filters: ChannelFilters = {
        type: 'messaging',
        members: { $in: [loggedInUser.sub.toString()] },
    };
    const options: ChannelOptions = { state: true, presence: true };
    const sort: ChannelSort = { last_message_at: -1 };

    useEffect(() => {
        if (channel) {
            navigate(`/messages/${channel.id}`);
        }
    }, [channel]);

    return (
        <Box width='400px'>
            <ChannelList
                customActiveChannel={id}
                filters={filters}
                options={options}
                sort={sort}
                // sendChannelsToList
                List={(props: any) => (
                    <CustomChannelList
                        open={open}
                        onSelect={onSelect}
                        {...props}
                    />
                )}
                Preview={(props) => <ChannelListItem {...props} />}
            />
        </Box>
    );
};

export default MessagingSidebar;
