import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetIndividualBillQuery } from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import BillingReservationsList from '../../components/Billing/Reports/BillingReservationsList';
import moment from 'moment-timezone';
import UserRole from '../../../shared/AuthRoles';
import { formatCurrencyShort } from '../../../shared/utils';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const IndividualBillPage = () => {
    const loggedInUser = UserRole();
    const urlRef = window.location.href.split('/')[4];
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { data: invoice, isFetching } = useGetIndividualBillQuery(
        `invoices/${urlRef}`
    );

    const from = moment(invoice?.from).format('D MMM YYYY');
    const to = moment(invoice?.to).format('D MMM YYYY');
    const invoicePeriod = `${from} - ${to}`;

    const handleBackButton = () => {
        if (loggedInUser?.role === 'admin') {
            navigate(`/reports/guestInvoices`);
        } else {
            navigate(`/billing?page=1`);
        }
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            {isFetching ? (
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{ height: '100%' }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    container
                    spacing={3}
                    sx={{
                        backgroundColor: 'background.paper',
                        padding: 3,
                        borderRadius: 2,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant='h5'
                            sx={{
                                color: 'primary.main',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: 1,
                            }}
                        >
                            Invoice #{invoice?.id}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography
                            variant='h6'
                            sx={{
                                color: 'text.primary',
                                fontWeight: 'bold',
                                marginBottom: 2,
                            }}
                        >
                            Billing Details
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{ color: 'text.primary' }}
                        >
                            {`${invoice?.firstName} ${invoice?.lastName}`}
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{ color: 'text.primary' }}
                        >
                            {`${invoice?.streetAddress}, ${invoice?.suburb} ${invoice?.postcode}, ${invoice?.country}`}
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                color: 'text.primary',
                                marginTop: 2,
                                fontWeight: 'bold',
                            }}
                        >
                            Invoice Period
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{ color: 'text.primary' }}
                        >
                            {invoicePeriod}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography
                            variant='h6'
                            sx={{
                                color: 'text.primary',
                                fontWeight: 'bold',
                                marginBottom: 2,
                            }}
                        >
                            Payment Summary
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography
                                    variant='body2'
                                    sx={{ color: 'text.primary' }}
                                >
                                    Total (inc GST)
                                </Typography>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    {invoice?.total
                                        ? formatCurrencyShort(invoice.total)
                                        : 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant='body2'
                                    sx={{ color: 'text.primary' }}
                                >
                                    Amount Paid
                                </Typography>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {invoice?.amountPaid
                                        ? formatCurrencyShort(
                                              invoice.amountPaid
                                          )
                                        : '$0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant='body2'
                                    sx={{ color: 'text.primary' }}
                                >
                                    Amount Due
                                </Typography>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    {invoice?.amountDue
                                        ? formatCurrencyShort(invoice.amountDue)
                                        : '$0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant='body2'
                                    sx={{ color: 'text.primary' }}
                                >
                                    Due Date
                                </Typography>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {invoice?.dueDate
                                        ? new Date(
                                              invoice.dueDate
                                          ).toLocaleDateString()
                                        : 'N/A'}
                                </Typography>
                            </Grid>

                            {invoice?.amountDue === 0 && (
                                <Grid item xs={12}>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            color: 'text.primary',
                                            marginTop: 2,
                                        }}
                                    >
                                        Paid At
                                    </Typography>
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            color: 'text.primary',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {invoice?.fullyPaidAt
                                            ? new Date(
                                                  invoice.fullyPaidAt
                                              ).toLocaleDateString()
                                            : 'N/A'}
                                    </Typography>
                                </Grid>
                            )}

                            {loggedInUser?.role !== 'admin' && (
                                <Grid item xs={12}>
                                    {invoice?.amountDue > 0 && (
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                color: 'text.primary',
                                                marginTop: 2,
                                                marginBottom: 1,
                                            }}
                                        >
                                            You can pay your invoice by clicking
                                            the link below:
                                        </Typography>
                                    )}

                                    <Typography
                                        component='a'
                                        href={invoice.url}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        fontWeight='bold'
                                        sx={{
                                            color: 'primary.main',
                                            display: 'inline-block',
                                            transition:
                                                'transform 0.3s ease, color 0.3s ease',
                                            '&:hover': {
                                                color: 'primary.main',
                                                transform: 'scale(1.05)',
                                            },
                                        }}
                                    >
                                        {invoice?.amountDue > 0
                                            ? ' Pay Now'
                                            : 'View Invoice'}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                                marginBottom: 1,
                            }}
                        >
                            Reservations
                        </Typography>
                        <Typography variant='body2' color='text.primary'>
                            The following reservations are included in this
                            invoice.
                        </Typography>
                        <BillingReservationsList
                            data={invoice?.bookings}
                            invoiceId={invoice?.id}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid
                sx={{
                    justifyContent: 'flex-start',
                }}
            >
                {' '}
                <CustomButton
                    onClick={handleBackButton}
                    color='green'
                    sx={{
                        marginLeft: !isMobile ? '-8px' : '-18px',
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            background: 'none',
                        },
                    }}
                >
                    <ArrowBackIcon />
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            paddingLeft: '5px',
                        }}
                    >
                        Back to Invoices
                    </Typography>
                </CustomButton>
            </Grid>
        </Container>
    );
};

export default IndividualBillPage;
