import EditIcon from '@mui/icons-material/Edit';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import {
    useEditAssetMutation,
    useGetCompanyOwnerInfoQuery,
    useGetIndividualOwnerAssetsQuery,
} from '../../../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../../../shared/AuthRoles';
import ExperienceForm from '../../../../components/Experiences/Owner/ExperienceForm';
import { FieldValues } from 'react-hook-form';
import toast from '../../../../shared/Toast';

export default function ExperiencesEditFormPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const loggedInUser = UserRole();

    const urlRef = window.location.href.split('/')[4];

    const [editAsset] = useEditAssetMutation();

    const {
        data: getIndividualAsset,
        isFetching,
        error,
    } = useGetIndividualOwnerAssetsQuery({
        url: `owners/${loggedInUser?.company}/modules/${urlRef}`,
    });

    const {
        data: getCompanyDetails,
        isFetching: isFetchingOwner,
        error: ownerError,
    } = useGetCompanyOwnerInfoQuery(loggedInUser?.company);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (error || ownerError) {
        navigate(`/*`);
        ReactGA.send({
            hitType: 'pageview',
            page: '/*',
            title: 'Error 404',
        });
    }

    const onSubmit = async (data: FieldValues) => {
        try {
            const {
                discounts,
                instantBook,
                policies,
                hasPolicies,
                linkedModules,
                ...rest
            } = data;
            const request = {
                ...rest,
                requiresBookingApproval: !instantBook,
                policies: hasPolicies ? policies : [],
                linkedModules: linkedModules.length > 0 ? linkedModules : null,
            };
            const response = await editAsset([
                loggedInUser.company,
                urlRef,
                request,
            ]).unwrap();
            toast.success(`${data.name} ${data.type} has been updated`);

            navigate(
                `/viewExperiences/${loggedInUser?.company}/${data.type}/${response.id}`
            );
        } catch (error) {
            toast.error(`Failed to edit ${data.name} experience`);
        }
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            {isFetching || isFetchingOwner ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? 'auto' : isIpad ? 'auto' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                textDecoration: 'underline rgb(92, 181, 179)',
                                textUnderlineOffset: '8px',
                            }}
                        >
                            Edit Experience
                        </Typography>
                    </Grid>
                    <ExperienceForm
                        experience={getIndividualAsset}
                        owner={getCompanyDetails}
                        isEditing={true}
                        onSubmit={onSubmit}
                    />
                    {/* <EditExperienceForm
                        experience={getIndividualAsset}
                        owner={getCompanyDetails}
                    /> */}
                </Grid>
            )}
        </Container>
    );
}
