import AddCircleIcon from '@mui/icons-material/AddCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../shared/AuthRoles';
import { camelCaseToTitleCase, capitalizeName } from '../../../shared/utils';
import { ExperiencePolicy } from '../../types/policy';
import AmenitiesComponent from './Amenities/AmenitiesComponent';
import CalendarComponent from './Calendar/CalendarComponent';
import IndividualExperienceCharges from './IndividualExperienceCharges';
import IndividualExperienceSpaces from './IndividualExperienceSpaces';
import ExperiencePolicyCard from './Policies/ExperiencePolicyCard';
import ContactForm from './ContactForm';

export default function IndividualExperienceContent(experience: any) {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const loggedInUser = UserRole();

    const value = experience?.experience;
    let days = [
        { day: 'monday', value: value?.monday },
        { day: 'tuesday', value: value?.tuesday },
        { day: 'wednesday', value: value?.wednesday },
        { day: 'thursday', value: value?.thursday },
        { day: 'friday', value: value?.friday },
        { day: 'saturday', value: value?.saturday },
        { day: 'sunday', value: value?.sunday },
    ];

    // Helper function for determining the title text
    const getTitleText = (value: {
        owner: { name: any };
        details: { subType: string };
        type: any;
    }) => {
        if (!value?.owner?.name || !value?.details?.subType) return '';

        switch (value?.type) {
            case 'property':
                return `Entire ${value.details.subType} hosted by ${value?.owner.name}`;
            case 'boat':
                return `Entire boat hosted by ${value?.owner.name}`;
            case 'commercial':
                if (value?.details?.subType === 'office') {
                    return `Office space provided by ${value?.owner.name}`;
                } else if (value?.details?.subType === 'house') {
                    return `Home office provided by ${value?.owner.name}`;
                }
                break;
            default:
                return `${capitalizeName(
                    value?.details?.subType
                )} supplied by ${value?.owner.name}`;
        }
    };

    const capacity: any = {
        dedicatedDesks: value?.details?.desks,
        privateOffices: value?.details?.privateOffices,
        meetingRooms: value?.details?.meetingRooms,
    };

    let availableDays = days.filter?.((i: any) => i.value === true);

    const onClick = () => {
        navigate(`/reservations/ai-assistant`, {
            state: { page: 'experience', id: value?.id, name: value?.name },
        });
        ReactGA.send({
            hitType: 'pageview',
            page: `/reservations/ai-assistant`,
            title: `Ai Reservation Assistant`,
        });
    };
    const fullAddressPickup = `${value?.streetAddress1} ${value?.streetAddress2} ${value?.suburb} ${value?.postcode} ${value?.state} ${value?.countryCode}`;
    const fullAddressDropoff = `${value?.details?.dropoffStreetAddress1} ${value?.details?.dropoffStreetAddress2} ${value?.details?.dropoffSuburb} ${value?.details?.dropoffPostcode} ${value?.details?.dropoffState} ${value?.details?.dropoffCountryCode}`;

    const rules = [];

    if (value?.details?.minimumLicenceRequired) {
        rules.push(
            `Minimum licence required: ${capitalizeName(
                value.details.minimumLicenceRequired
            )}`
        );
    }
    if (value?.details?.minimumDriversAge) {
        rules.push(`Minimum driver's age: ${value.details.minimumDriversAge}`);
    }

    if (value?.details?.petsAllowed === false) {
        rules.push('Pets not allowed');
    }

    if (value?.details?.smokingAllowed === false) {
        rules.push('Smoking, vaping, e-cigarettes not allowed');
    }

    if (value?.details?.rules) {
        rules.push(value.details.rules);
    }

    // Helper functions for min/max hire period
    const formatHirePeriod = (hours: number) => {
        if (hours === 24) return `${hours / 24} day`;
        if (hours === 48) return `${(hours - 24) / 24} night`;
        if (hours > 24) return `${(hours - 24) / 24} nights`;
        return `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
    };

    const formatMaxHirePeriod = (hours: number) => {
        if (hours === 24) return `${hours / 24} day`;
        if (hours === 48) return `${(hours - 24) / 24} night`;
        if (hours > 24) return `${(hours - 24) / 24} nights`;
        return `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
    };

    // Reusable Typography component for displaying details
    const DetailTypography = ({ label, value }: any) => (
        <Grid>
            <Typography
                sx={{
                    fontFamily: 'Open Sans',
                    color: '#787878',
                    paddingRight: '5px',
                    fontSize: isMobile ? '14px' : '14px',
                }}
            >
                {label}: {value}{' '}
                <span style={{ paddingLeft: '5px' }}>
                    <FiberManualRecordIcon sx={{ fontSize: '4px' }} />
                </span>
            </Typography>
        </Grid>
    );

    const shouldShowButton = (
        loggedInUser: { role: string | string[]; viewOnly: any; company: any },
        value: { isAvailable: any },
        experience: { experience: { owner: { id: any } } }
    ) => {
        return (
            loggedInUser?.role.includes('owner') &&
            !loggedInUser?.viewOnly &&
            value?.isAvailable &&
            experience?.experience?.owner.id === loggedInUser?.company
        );
    };

    const CapacityTypography = ({
        label,
        value,
        index,
        totalCount,
        isIpad,
    }: any) => (
        <Typography
            sx={{
                fontFamily: 'Open Sans',
                color: '#787878',
                marginBottom: '8px',
                fontSize: isMobile ? '14px' : '14px',
            }}
        >
            {label}: {value}
            {index !== totalCount - 1 && !isIpad && (
                <span style={{ paddingLeft: '20px' }}>
                    <FiberManualRecordIcon sx={{ fontSize: '4px' }} />
                </span>
            )}
        </Typography>
    );

    return (
        <Grid>
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    paddingTop: '30px',
                    width: '100%',
                    // backgroundColor: 'blue',
                }}
            >
                <Grid
                    sx={{
                        width: isMobile ? '100%' : isIpad ? '80%' : '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingRight:isIpad?'10px': ''
                    }}
                >
                    <Grid
                        sx={{
                            height: isMobile || isIpad ? 'auto' : '50px',
                            width: isMobile || isIpad ? '100%' : '88%',

                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ width: '90%' }}>
                            <Typography
                             
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    fontWeight: 'bold',
                                    fontSize: isMobile ? '15px' : '24px',
                                }}
                            >
                                {getTitleText(value)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '88%',
                            // backgroundColor: 'pink',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value?.type === 'property' ||
                        value?.type === 'boat' ? (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                }}
                            >
                                {/* Conditional rendering for non-commercial properties */}
                                {!['commercial', 'office space'].includes(
                                    value?.details?.subType
                                ) && (
                                    <>
                                        <DetailTypography
                                            label='Sleeps'
                                            value={
                                                value?.details.overnightGuests
                                            }
                                        />
                                        <DetailTypography
                                            label='Bedrooms'
                                            value={value?.details.bedrooms}
                                        />
                                        <DetailTypography
                                            label='Beds'
                                            value={value?.details.beds}
                                        />
                                    </>
                                )}

                                {/* Always display Bathrooms and Max Guests */}
                                <DetailTypography
                                    label='Bathrooms'
                                    value={value?.details.baths}
                                />
                                <Grid>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '5px',
                                            fontSize: isMobile
                                                ? '14px'
                                                : '14px',
                                        }}
                                    >
                                        Max {value?.details.maxGuests} guests on
                                        the {value?.type}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : value?.type === 'vehicle' ? (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                }}
                            >
                                {/* General Vehicle Details */}
                                <DetailTypography
                                    label={`${value?.details.make} ${
                                        value?.details.model
                                    } ${
                                        value?.details.subType === 'car'
                                            ? capitalizeName(
                                                  value?.details?.bodyType
                                              )
                                            : ''
                                    } ${value?.details?.year || ''}`}
                                    value=''
                                />

                                <DetailTypography
                                    label='Transmission'
                                    value={capitalizeName(
                                        value?.details.transmission === 'cvt'
                                            ? 'CVT'
                                            : value?.details.transmission
                                    )}
                                />

                                <DetailTypography
                                    label='Fuel Type'
                                    value={capitalizeName(
                                        value?.details.fuelType
                                    )}
                                />

                                {/* Motorbike Specific Details */}
                                {value.details.subType === 'motorbike' && (
                                    <DetailTypography
                                        label='Engine CC'
                                        value={value?.details?.engineCC}
                                    />
                                )}

                                {/* Car Specific Details */}
                                {value.details.subType === 'car' && (
                                    <>
                                        <DetailTypography
                                            label='Seats'
                                            value={value?.details.numberOfSeats}
                                        />
                                        <DetailTypography
                                            label='Number of Doors'
                                            value={value?.details.numberOfDoors}
                                        />
                                    </>
                                )}
                            </Grid>
                        ) : value?.type === 'commercial' &&
                          ['office', 'house'].includes(
                              value.details.subType
                          ) ? (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: isIpad ? 'column' : 'row',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                    paddingTop: isIpad ? '10px' : '0px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {/* Title */}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingRight: '5px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Total Capacity
                                </Typography>

                                {/* Capacity Values */}
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        flexDirection: isIpad
                                            ? 'column'
                                            : 'row',
                                        flexWrap: 'wrap',
                                        gap: isIpad ? '0px' : '30px',
                                    }}
                                >
                                    {capacity ? (
                                        Object.keys(capacity)
                                            .filter((key) => capacity[key] > 0)
                                            .map((key, index) => {
                                                const label =
                                                    key === 'privateOffices' &&
                                                    value?.details?.subType ===
                                                        'house'
                                                        ? 'Rooms'
                                                        : camelCaseToTitleCase(
                                                              key
                                                          );
                                                const totalCount = Object.keys(
                                                    capacity
                                                ).filter(
                                                    (key) => capacity[key] > 0
                                                ).length;

                                                return (
                                                    <CapacityTypography
                                                        key={key}
                                                        label={label}
                                                        value={capacity[key]}
                                                        index={index}
                                                        totalCount={totalCount}
                                                        isIpad={isIpad}
                                                    />
                                                );
                                            })
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            Please select the dates
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                {value?.details.quantity} x {value?.name}
                            </Typography>
                        )}
                    </Grid>
                    {/* <Divider
                        sx={{ width: isMobile || isIpad ? '100%' : '88%' }}
                    /> */}
                    <Grid
                        sx={{
                            height: 'auto',
                            width: isMobile || isIpad ? '100%' : '88%',
                            paddingTop: '30px',
                            // backgroundColor: 'gray',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '10px',
                                fontWeight: 'bold',
                                fontSize: '14px',
                            }}
                        >
                            {value?.type === 'property'
                                ? 'Property'
                                : value?.type === 'boat'
                                ? 'Boat'
                                : value?.type === 'vehicle'
                                ? 'Vehicle'
                                : value?.type === 'commercial'
                                ? capitalizeName(value?.details?.subType)
                                : 'Activity'}{' '}
                            Description
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            {value?.description}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontWeight: 'bold',
                                fontSize: '14px',
                            }}
                        >
                            Reservation Information
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ul style={{ paddingLeft: '20px' }}>
                                <li>
                                    This experience accepts {value?.visitType}{' '}
                                    reservations only for{' '}
                                    {availableDays.map((name, i) => (
                                        <span key={i}>
                                            {i > 0 && ','}{' '}
                                            {name.day.charAt(0).toUpperCase() +
                                                name.day.slice(1)}
                                        </span>
                                    ))}
                                </li>

                                <li>
                                    This experience{' '}
                                    {value?.publicHolidays
                                        ? 'accepts'
                                        : "doesn't accept"}{' '}
                                    reservations for public holidays.
                                </li>

                                <li>
                                    {value?.type === 'commercial'
                                        ? `Access from ${value?.checkinTime} to ${value?.checkoutTime}`
                                        : `Checkin from ${value?.checkinTime} and checkout before ${value?.checkoutTime}`}
                                </li>

                                <li>
                                    The min/max hire period is{' '}
                                    {value?.type === 'commercial'
                                        ? `${value?.minHireHours / 24}`
                                        : formatHirePeriod(
                                              value?.minHireHours
                                          )}{' '}
                                    -{' '}
                                    {value?.type === 'commercial'
                                        ? `${value?.maxHireHours / 24} ${
                                              value?.maxHireHours === 24
                                                  ? 'day'
                                                  : 'days'
                                          }`
                                        : formatMaxHirePeriod(
                                              value?.maxHireHours
                                          )}
                                </li>
                            </ul>
                        </Typography>

                        {value?.type === 'vehicle' && rules?.length > 0 && (
                            <>
                                {' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingBottom: '10px',
                                        paddingTop: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Hiring Rules
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingBottom: '10px',
                                        fontSize: '14px',

                                        listStyle:
                                            rules.length === 1
                                                ? 'none'
                                                : 'default',
                                    }}
                                >
                                    {rules.map((rule: any, index: any) => (
                                        <li key={index}>{rule}</li>
                                    ))}
                                </Typography>
                            </>
                        )}
                    </Grid>{' '}
                    {value?.type === 'vehicle' && (
                        <Grid
                            sx={{
                                height: 'auto',
                                paddingTop: isIpad ? '10px' : '30px',
                                width: isMobile || isIpad ? '100%' : '88%',
                                // backgroundColor: 'blue',
                            }}
                        >
                            <Divider
                                sx={{
                                    width: '100%',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    paddingTop: '30px',
                                    paddingBottom: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}
                            >
                                Pick Up{' '}
                                {value.details.dropoffLocationSameAsPickup &&
                                    'and Drop Off '}
                                Location
                            </Typography>{' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    fontSize: '14px',
                                }}
                            >
                                {' '}
                                {fullAddressPickup}
                            </Typography>
                            {!value.details.dropoffLocationSameAsPickup && (
                                <>
                                    {' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingTop: '30px',
                                            paddingBottom: '10px',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                        }}
                                    >
                                        Drop Off Location
                                    </Typography>{' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {' '}
                                        {fullAddressDropoff}
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    )}
                    {value?.charges?.length > 0 && (
                        <IndividualExperienceCharges
                            charges={value?.charges}
                            experienceType={value?.type}
                            experienceSubType={value?.details?.subType}
                            discounts={value?.discounts}
                        />
                    )}
                    {value?.spaces?.length > 0 && (
                        <IndividualExperienceSpaces spaces={value?.spaces} />
                    )}
                    {value?.type === 'activity' ? null : (
                        <Divider
                            sx={{
                                marginTop: '30px',
                                width: isMobile || isIpad ? '100%' : '88%',
                            }}
                        />
                    )}
                    <Grid
                        sx={{
                            height: 'auto',
                            paddingTop: isIpad ? '10px' : '30px',
                            width: isMobile || isIpad ? '100%' : '88%',
                            // backgroundColor: 'blue',
                        }}
                    >
                        {value?.type === 'property' ||
                        value?.type === 'boat' ||
                        value?.type === 'vehicle' ? (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}
                            >
                                What this{' '}
                                {value?.type === 'property'
                                    ? 'place'
                                    : value?.type === 'vehicle' &&
                                      value?.details.subType === 'car'
                                    ? 'car'
                                    : value?.type === 'vehicle' &&
                                      value?.details.subType === 'motorbike'
                                    ? 'motorbike'
                                    : 'boat'}{' '}
                                offers
                            </Typography>
                        ) : null}
                    </Grid>
                    {value?.type === 'property' ||
                    value?.type === 'boat' ||
                    value?.type === 'vehicle' ||
                    value?.type === 'commercial' ? (
                        <Grid
                            sx={{
                                height: 'auto',
                                paddingTop: '30px',
                                // backgroundColor: 'green',
                                width: isIpad || isMobile ? '100%' : '88%',
                                paddingBottom: isMobile ? '20px' : '',
                            }}
                        >
                            <AmenitiesComponent amenities={value?.details} />
                        </Grid>
                    ) : value?.type === 'activity' ? null : null}
                    {value?.policies.length > 0 && (
                        <>
                            <Divider
                                sx={{
                                    marginTop: '30px',
                                    width: isMobile || isIpad ? '100%' : '88%',
                                }}
                            />
                            <Box
                                pt='30px'
                                width={isIpad || isMobile ? '100%' : '88%'}
                            >
                                <Typography
                                    fontFamily='Open Sans'
                                    color='#787878'
                                    fontWeight='bold'
                                    fontSize='14px'
                                    pb='30px'
                                >
                                    Reservations Policies
                                </Typography>
                                <Grid container spacing={2}>
                                    {value?.policies?.map(
                                        (policy: ExperiencePolicy) => (
                                            <Grid
                                                key={policy.policyId}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                lg={3}
                                                style={{ display: 'flex' }}
                                            >
                                                <ExperiencePolicyCard
                                                    policy={policy}
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </Box>
                        </>
                    )}
                    {loggedInUser?.role.includes('guest') && (
                        <Divider
                            sx={{
                                marginTop: '30px',
                                width: isMobile || isIpad ? '100%' : '88%',
                            }}
                        />
                    )}
                    {/* 
                        In-app messaging
                    */}
                    <ContactForm experience={value} />
                </Grid>
                <Divider
                    sx={{
                        marginTop: isMobile ? '30px' : '',
                        width: isMobile ? '100%' : '0%',
                    }}
                />
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 'auto',
                        paddingTop: isMobile ? '20px' : '',
                    }}
                >
                    <Grid
                      
                    >
                        <CalendarComponent experience={value} />
                    </Grid>

                    <Grid
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            marginTop: '20px',
                        }}
                        px='25px'
                    >
                        {shouldShowButton(loggedInUser, value, experience) && (
                            <CustomButton
                                fullWidth
                                id='recurringReservation'
                                variant='outlined'
                                color='green'
                                onClick={onClick}
                                startIcon={<AddCircleIcon />}
                                sx={{
                                    top: isMobile ? '50%' : '50%',
                                    mb: '10px',
                                }}
                            >
                                AI Reservation Assistant
                            </CustomButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
