import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormValues } from '../../../types/experience';
import UserRole from '../../../../shared/AuthRoles';
import {
    useCreateReservationChatMutation,
    useJoinChannelMutation,
} from '../../../../app/Slices/SevenDayApiSlice';
import {
    JoinChannel,
    ReservationChat,
} from '../../../../app/Slices/SevenDayApiTypes';
import FormTextField from '../../../shared/FormTextField';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import toast from '../../../shared/Toast';
import { NavigateNext } from '@mui/icons-material';

interface ContactFormProps {
    reservation: {
        id: number;
        channelId?: string;
        owner?: {
            messagingUsers?: Array<number>;
        };
    };
}

const Schema = z.object({
    message: z
        .string()
        .min(1, { message: 'Required' })
        .max(256, { message: 'Message cannot exceed 256 characters' }),
});

const ContactForm = ({ reservation }: ContactFormProps) => {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const [createChat] = useCreateReservationChatMutation();
    const [joinChat] = useJoinChannelMutation();

    type ContactForm = z.infer<typeof Schema>;

    const methods = useForm<ContactForm>({
        resolver: zodResolver(Schema),
        defaultValues: { message: '' },
    });

    const { control, handleSubmit } = methods;

    const [messageSending, setMessageSending] = useState<boolean>(false);
    const [joining, setJoining] = useState<boolean>(false);

    const navigate = useNavigate();

    const onSubmit = async (data: ContactForm) => {
        try {
            setMessageSending(true);

            const body: ReservationChat = {
                bookingId: reservation.id,
                message: data.message,
            };

            const response = await createChat(body).unwrap();

            navigate(`/messages/${response.channelId}`);
        } catch (error) {
            toast.error(
                `Error sending message to ${
                    loggedInUser?.role.includes('owner') ? 'guest' : 'host'
                }`
            );
        } finally {
            setMessageSending(false);
        }
    };

    const onNavigate = async () => {
        try {
            setJoining(true);

            const body: JoinChannel = {
                bookingId: reservation.id,
            };

            await joinChat(body).unwrap();

            navigate(`/messages/${reservation.channelId}`);
        } catch (error) {
            toast.error('Error joining channel');
        } finally {
            setJoining(false);
        }
    };

    const messagingUsers = reservation?.owner?.messagingUsers;

    return (
        <>
            {
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box
                            pt='30px'
                            width='100%'
                        >
                            {messagingUsers && messagingUsers.length > 0 && (
                                <>
                                    <Typography
                                      sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                    >
                                        {reservation.channelId
                                            ? 'Messages'
                                            : `Contact the ${
                                                  loggedInUser?.role.includes(
                                                      'owner'
                                                  )
                                                      ? 'Guest'
                                                      : 'Host'
                                              }`}
                                    </Typography>
                                    <>
                                        {/* {reservation.channelId ? (
                                        <Typography
                                            variant='body2'
                                            sx={{ color: 'text.primary' }}
                                            gutterBottom
                                        ></Typography>
                                    ) : (

                                    )} */}
                                        <Typography
                                            variant='body2'
                                            sx={{ color: 'text.primary' }}
                                            gutterBottom
                                        >
                                            {reservation.channelId
                                                ? 'Want to revisit your past conversations? View your chat history anytime.'
                                                : loggedInUser?.role.includes(
                                                      'owner'
                                                  )
                                                ? `Share key details or a friendly
                                                    message with your guest using the
                                                    field below.`
                                                : `Got a question about this reservation?
                                                Send a message to the host for more
                                                details. Whether it's about
                                                availability, specific details, or
                                                special requests, the host is here to
                                                help!`}
                                        </Typography>
                                        {reservation.channelId ? (
                                            <>
                                                <Button
                                                    variant='outlined'
                                                    onClick={onNavigate}
                                                    endIcon={<NavigateNext />}
                                                >
                                                    Go to Messages
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <FormTextField
                                                    name='message'
                                                    control={control}
                                                    rows={3}
                                                    multiline
                                                />
                                                <Box pt={1}>
                                                    <CustomButton
                                                        loading={messageSending}
                                                        disabled={
                                                            messageSending
                                                        }
                                                        variant='outlined'
                                                        color='green'
                                                        type='submit'
                                                    >
                                                        Send message
                                                    </CustomButton>
                                                </Box>
                                            </>
                                        )}
                                    </>
                                </>
                            )}
                        </Box>
                    </form>
                </FormProvider>
            }
        </>
    );
};

export default ContactForm;
