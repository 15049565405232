import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import UserRole from '../../../shared/AuthRoles';
import AdminCenterList from './AdminCenterList';

const AdminCenterPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            loggedInUser.role === 'owner_user' ||
            loggedInUser.role === 'guest_user'
        ) {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'adminCenterPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Admin Center
                    </Typography>
                </Grid>
                <AdminCenterList />
            </Grid>
        </Container>
    );
};

export default AdminCenterPage;
