import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import DocumentationComponentPage from '../../components/Help/Documentation/DocumentationComponent';
import SupportComponentPage from '../../components/Help/SupportComponent';
import PrivacyPolicyComponent from '../../components/Help/PrivacyPolicyComponent';
import TermsOfService from '../../components/Help/TermsOfService';

export default function HelpPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const urlRef = window.location.href.split('/')[3];
    const isIpad = useMediaQuery('(max-width: 1200px)');


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        {urlRef === 'privacy'
                            ? 'Privacy Policy'
                            : urlRef === 'terms'
                            ? 'Terms Of Service'
                            : urlRef}
                    </Typography>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        // minHeight: isMobile ? '65%' : '78%',
                        justifyContent: 'left',
                    }}
                >
                    {urlRef === 'documentation' ? (
                        <DocumentationComponentPage />
                    ) : urlRef === 'support' ? (
                        <SupportComponentPage />
                    ) : urlRef === 'privacy' ? (
                        <PrivacyPolicyComponent />
                    ) : urlRef === 'terms' ? (
                        <TermsOfService />
                    ) : null}
                </Grid>
            </Grid>
        </Container>
    );
}
