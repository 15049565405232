import { InfoOutlined, NavigateBefore } from '@mui/icons-material';
import {
    Alert,
    Avatar,
    Box,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MessageResponse } from 'stream-chat';
import { MessageList, useChannelStateContext } from 'stream-chat-react';

interface ChannelHeaderProps {
    handleBack: () => void;
    handleOpen: () => void;
}

const ChannelHeader = ({ handleBack, handleOpen }: ChannelHeaderProps) => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const { channel } = useChannelStateContext();

    const { name, member_count, image } = channel.data || {};
    const { watcher_count } = channel.state || {};

    const [pinnedMessages, setPinnedMessages] = useState<MessageResponse[]>([]);
    const [open] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await channel.getPinnedMessages({});

            setPinnedMessages(data.messages);
        };

        fetchData();
    }, [channel]);

    return (
        <>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                px={2}
                py={2}
                gap={2}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    gap={2}
                    overflow='hidden'
                >
                    {isMobile && (
                        <IconButton onClick={handleBack}>
                            <NavigateBefore />
                        </IconButton>
                    )}
                    {!isMobile && <Avatar variant='rounded' src={image} />}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                color: 'text.primary',
                                fontWeight: 'bold',
                                fontSize: '18px',
                                overflow: 'hidden', // Hide overflow text
                                textOverflow: 'ellipsis', // Show ellipsis when text overflows
                                whiteSpace: 'nowrap', // Prevent wrapping
                            }}
                        >
                            {name}
                        </Typography>
                        <Typography
                            sx={{ color: 'text.secondary', fontSize: '14px' }}
                        >
                            {`${member_count} members, ${watcher_count} online`}
                        </Typography>
                    </Box>
                </Box>
                <div>
                    {/* <IconButton>
                        <PushPinOutlined onClick={() => setOpen(!open)}/>
                    </IconButton> */}
                    <IconButton onClick={handleOpen}>
                        <InfoOutlined />
                    </IconButton>
                </div>
            </Box>
            {open && (
                <Box mx={4}>
                    <MessageList messages={pinnedMessages} />
                </Box>
            )}
        </>
    );
};

export default ChannelHeader;
