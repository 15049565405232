import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import {
    canAccessReport,
    getReadableReportDescription,
    getReadableReportName,
} from '../../components/Reports/ReportsMapping';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TopGuestsComponentPage from '../../components/Reports/Reports/TopGuestsReport/TopGuestsComponentPage';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import TopModulesComponentPage from '../../components/Reports/Reports/TopModulesReport/TopModulesComponentPage';
import TopBookersComponentPage from '../../components/Reports/Reports/TopBookersReport/TopBookersComponentPage';
import BookerDetailsComponentPage from '../../components/Reports/Reports/BookerDetailsReport/BookerDetailsComponentPage';
import CancellationComponentPage from '../../components/Reports/Reports/CancellationReport/CancellationComponentPage';
import MonthlyComponentPage from '../../components/Reports/Reports/MonthlyReport/MonthlyComponentPage';
import QuarterlyComponentPage from '../../components/Reports/Reports/QuarterlyReport/QuarterlyComponentPage';
import FYComponentPage from '../../components/Reports/Reports/FYReport/FYComponentPage';
import YTDComponentPage from '../../components/Reports/Reports/YTDReport/YTDComponentPage';
import CustomisedComponentPage from '../../components/Reports/Reports/CustomisedReport/CustomisedComponentPage';
import DeclinedComponentPage from '../../components/Reports/Reports/DeclinedReport/DeclinedComponentPage';
import GuestDetailsComponentPage from '../../components/Reports/Reports/GuestDetailsReport/GuestDetailsComponentPage';
import ReactGA from 'react-ga4';
import { HostPaymentsReportPage } from '../../components/Reports/Reports/HostPaymentsReport/HostPaymentReportPage';
import UserRole from '../../../shared/AuthRoles';

export default function IndividualReportPage() {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const urlRef = window.location.href.split('/')[4];
    const navigate = useNavigate();

    const onClickGoBack = () => {
        if (urlRef === 'guests') {
            navigate(`/reports/guestDetails`);
            ReactGA.send({
                hitType: 'pageview',
                page: '/reports/guestDetails',
                title: 'Guest Details',
            });
        } else {
            navigate(`/reports`);
            ReactGA.send({
                hitType: 'pageview',
                page: '/reports',
                title: 'Reports',
            });
        }
    };

    useEffect(() => {
        if (!canAccessReport(urlRef, loggedInUser.role)) {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'reports',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role, urlRef]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        {getReadableReportName('type', urlRef)}
                    </Typography>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile ? '3%' : '5%',
                        justifyContent: 'left',
                        paddingTop: '10px',
                    }}
                >
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                        }}
                    >
                        {' '}
                        {getReadableReportDescription('type', urlRef)}.
                        {/* {urlRef === 'cancellationReport'
                            ? ' Cancelled reservations are based on the reservation start date '
                            : null} */}
                    </Typography>
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            paddingBottom: isMobile || isIpad ? '30px' : '0px',
                        }}
                    >
                        {urlRef === 'cancellationReport'
                            ? 'NOTE: The following results are based on cancelled reservations within the date range selected. The date range is based on the start date of the reservation, not the cancelled date.'
                            : null}
                    </Typography>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        justifyContent: 'left',
                        paddingTop: '20px',
                    }}
                >
                    {urlRef === 'topGuests' ? (
                        <TopGuestsComponentPage />
                    ) : urlRef === 'topExperiences' ? (
                        <TopModulesComponentPage />
                    ) : urlRef === 'topBookers' ? (
                        <TopBookersComponentPage />
                    ) : urlRef === 'guestDetails' ? (
                        <GuestDetailsComponentPage />
                    ) : urlRef === 'bookerDetails' ? (
                        <BookerDetailsComponentPage />
                    ) : urlRef === 'cancellationReport' ? (
                        <CancellationComponentPage />
                    ) : urlRef === 'declinedReport' ? (
                        <DeclinedComponentPage />
                    ) : urlRef === 'monthlyReport' ? (
                        <MonthlyComponentPage />
                    ) : urlRef === 'quarterlyReport' ? (
                        <QuarterlyComponentPage />
                    ) : urlRef === 'ytdReport' ? (
                        <YTDComponentPage />
                    ) : urlRef === 'fyReport' ? (
                        <FYComponentPage />
                    ) : urlRef === 'reservationsReport' ? (
                        <CustomisedComponentPage />
                    ) : urlRef === 'guests' ? (
                        <GuestDetailsComponentPage />
                    ) : urlRef === 'hostPayments' ? (
                        <HostPaymentsReportPage />
                    ) : null}
                </Grid>
                <Grid
                    sx={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    {' '}
                    <CustomButton
                        onClick={onClickGoBack}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon /> {/* {isMobile ? null : ( */}
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back to Reports Menu
                        </Typography>
                        {/* )} */}
                    </CustomButton>
                </Grid>
            </Grid>
        </Container>
    );
}
