import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../shared/AuthRoles';
import { FormValues } from '../../types/experience';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import FormTextField from '../../shared/FormTextField';
import { useCreateChatEnquiryMutation } from '../../../app/Slices/SevenDayApiSlice';
import { ChatEnquiry } from '../../../app/Slices/SevenDayApiTypes';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from '../../shared/Toast';

interface ContactFormProps {
    experience: FormValues;
}

const Schema = z.object({
    message: z
        .string()
        .min(1, { message: 'Required' })
        .max(256, { message: 'Message cannot exceed 256 characters' }),
});

const ContactForm = ({ experience }: ContactFormProps) => {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const [createChatEnquiry] = useCreateChatEnquiryMutation();

    type ContactForm = z.infer<typeof Schema>;

    const methods = useForm<ContactForm>({
        resolver: zodResolver(Schema),
        defaultValues: { message: '' },
    });

    const { control, handleSubmit } = methods;

    const [messageSending, setMessageSending] = useState<boolean>(false);

    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        try {
            if (!experience.id) return;

            setMessageSending(true);

            const body: ChatEnquiry = {
                moduleId: experience.id,
                message: data.message,
            };

            const response = await createChatEnquiry(body).unwrap();

            navigate(`/messages/${response.channelId}`);
        } catch (error) {
            toast.error(`Error sending message to host`);
        } finally {
            setMessageSending(false);
        }
    };

    const messagingUsers = experience.owner?.messagingUsers;

    return (
        <>
            {loggedInUser?.role?.includes('guest') && (
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box
                            pt='30px'
                            width={isIpad || isMobile ? '100%' : '88%'}
                        >
                            <Typography
                                fontFamily='Open Sans'
                                color='#787878'
                                fontWeight='bold'
                                fontSize='14px'
                                pb='30px'
                            >
                                Contact the Host
                            </Typography>

                            {messagingUsers && messagingUsers.length > 0 ? (
                                <>
                                    <Typography
                                        variant='body2'
                                        sx={{ color: 'text.primary' }}
                                        gutterBottom
                                        pb='10px'
                                    >
                                        Got a question about this experience?
                                        Send a message to the host for more
                                        details. Whether it's about
                                        availability, specific details, or
                                        special requests, the host is here to
                                        help!
                                    </Typography>
                                    <FormTextField
                                        placeholder="Hi! I'll be visiting..."
                                        name='message'
                                        control={control}
                                        rows={3}
                                        multiline
                                    />
                                    <Box pt={1}>
                                        <CustomButton
                                            loading={messageSending}
                                            disabled={messageSending}
                                            variant='outlined'
                                            color='green'
                                            type='submit'
                                        >
                                            Send message
                                        </CustomButton>
                                    </Box>
                                </>
                            ) : (
                                <Typography
                                    variant='body2'
                                    sx={{ color: 'text.primary' }}
                                    gutterBottom
                                >
                                    This host does not allow for in-app
                                    messaging about this experience.
                                </Typography>
                            )}
                        </Box>
                    </form>
                </FormProvider>
            )}
        </>
    );
};

export default ContactForm;
