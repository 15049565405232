import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import ReactGA from 'react-ga4';

const BookingCapToolbar = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const handleAddClick = () => {
        navigate(`/newBookingCap`);
        ReactGA.send({
            hitType: 'pageview',
            page: `/newBookingCap`,
            title: `New Booking Cap`,
        });
    };

    return (
        <Grid
            sx={{
                paddingBottom: '20px',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            }}
        >
            <Grid sx={{     width: '90%', paddingTop:'10px' }}>
                <Typography
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        fontSize:'12px'
                    }}
                >
                    {' '}
                    Set limits on how many times a user or their organization
                    can book your experience within a timeframe you choose,
                    giving you full control over availability!
                </Typography>
            </Grid>
            <Grid
                sx={{
                    textAlign: ' right',
                }}
            >
                {isMobile || isIpad ? (
                    <CustomIconButton
                        variant='contained'
                        color='green'
                        onClick={handleAddClick}
                    >
                        <AddCircleIcon />
                    </CustomIconButton>
                ) : (
                    <CustomButton
                        variant='contained'
                        color='green'
                        startIcon={<AddCircleIcon />}
                        onClick={handleAddClick}
                    >
                      Add New
                    </CustomButton>
                )}
            </Grid>
        </Grid>
    );
};

export default BookingCapToolbar;
