import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import {
    useAddAttachmentsMutation,
    useDeleteAttachmentMutation,
} from '../../../../app/Slices/SevenDayApiSlice';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { openSnackAction } from '../../../../app/Slices/SnackBarSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { handleHttpError } from '../../../../shared/utils';
import toast from '../../../shared/Toast';

const { REACT_APP_BASE_URL } = process.env;

export default function ReservationAttachments(params: {
    bookingId: number;
    attachments: {
        src: string;
        id: string;
        uuid: string;
        name: string;
        createdAt: string;
        addedBy: { firstName: string; lastName: string };
    }[];
}) {
    const { bookingId, attachments } = params;
    const [open, setOpen] = useState(false);
    const [attachmentUuid, setAttachmentUuid] = useState('');
    const [attachmentName, setAttachmentName] = useState('');
    const [expandedPanel, setExpandedPanel] = useState<any>(false);
    const dispatch = useDispatch();
    const [addAttachments] = useAddAttachmentsMutation();
    const [deleteAttachment] = useDeleteAttachmentMutation();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const handleOpen = (uuid: string, name: string) => {
        setOpen(true);
        setAttachmentUuid(uuid);
        setAttachmentName(name);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleAddAttachments = (e: any) => {
        const formData = new FormData();

        for (const image of e.target.files) {
            formData.append('attachments', image);
        }

        const requestInfo = [bookingId, formData];

        addAttachments(requestInfo).then((data: any) => {
            if (!data || data?.error) {
                const errorMessage = data.error?.data?.message;

                let message: string;
                if (errorMessage.includes('expected size is less than')) {
                    message =
                        'One or more of the attachments exceeds the 10MB size limit.';
                } else if (errorMessage.includes('expected type is')) {
                    message =
                        'Invalid format. Expected formats are: image/*|.pdf|.doc|.docx|text/plain|.xlsx|.xls|.csv';
                } else {
                    message = 'Failed to add attachments.';
                }

                toast.error(message);
            }
        });
    };

    const handleDeleteAttachment = () => {
        const requestInfo = [bookingId, attachmentUuid];

        deleteAttachment(requestInfo).then((data: any) => {
            if (data) {
                if (data?.error) {
                    const errorMessage = handleHttpError(data?.error);
                    toast.error(errorMessage);
                } else {
                    toast.success(`Attachment removed`);
                }
            } else {
                toast.error(`Failed to remove attachment`);
            }
        });
    };
    return (
        <>
            <Accordion
                expanded={expandedPanel}
                onChange={() => setExpandedPanel(!expandedPanel)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    sx={{
                        backgroundColor:
                            expandedPanel === false ? '#f6f8f9' : '',
                    }}
                >
                    <Typography
                        component='h3'
                        variant='h6'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 550,
                            textTransform: 'uppercase',
                        }}
                    >
                        {`Attachments (${attachments.length}) `}
                        <VisibilityOffIcon
                            sx={{ opacity: 0.5 }}
                            fontSize='small'
                        />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: '10px',
                        }}
                    >
                        <Grid
                            sx={{
                                width: '100%',
                            }}
                        >
                            {attachments.map(
                                ({ src, uuid, name, createdAt, addedBy }) => (
                                    <Grid sx={{}}>
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '30%',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingTop: '5px',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        window.open(
                                                            src,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {name}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                <a
                                                    href={`${REACT_APP_BASE_URL}/bookings/${bookingId}/attachments/${uuid}`}
                                                >
                                                    <FileDownloadIcon
                                                        sx={{
                                                            fontSize: 'large',
                                                            color: '#5cb5b3',
                                                        }}
                                                    />
                                                </a>
                                                <CustomIconButton
                                                    onClick={() =>
                                                        handleOpen(uuid, name)
                                                    }
                                                    sx={{
                                                        paddingLeft: '10px',
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: 'red',
                                                            backgroundColor:
                                                                'transparent',
                                                        },
                                                    }}
                                                >
                                                    <ClearIcon
                                                        sx={{
                                                            fontSize: 'large',
                                                        }}
                                                    />
                                                </CustomIconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    width: '100%',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {moment(createdAt).format(
                                                        'DD/MM/YYYY, h:mm a'
                                                    )}{' '}
                                                    - {addedBy.firstName}{' '}
                                                    {addedBy.lastName}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            )}
                        </Grid>
                        <Grid
                            sx={{
                                marginTop: '20px',
                                width: '100%',
                            }}
                        >
                            <Button
                                component='label'
                                variant='outlined'
                                sx={{ height: '40px' }}
                                fullWidth={isMobile}
                            >
                                Upload
                                <input
                                    disabled={false}
                                    style={{
                                        display: 'none',
                                    }}
                                    onChange={(e) => {
                                        handleAddAttachments(e);
                                    }}
                                    type='file'
                                    accept='image/*,.pdf,.doc,.docx,.xlsx,.xls,.csv,text/plain'
                                    multiple
                                />
                            </Button>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontSize: '12px',
                                    paddingTop: '5px',
                                }}
                            >
                                Max size per attachment: 10 M
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Dialog open={open} onClose={handleClose}>
                <Grid
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            textAlign: 'left',
                            height: '50px',
                            width: '600px',
                            paddingLeft: '18px',
                            alignContent: 'center',
                            alignItems: 'center',
                            borderBottom: ' #f6f8f9 solid 1px',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                                variant='h6'
                            >
                                Are you sure you want to remove the following
                                attachment?
                            </Typography>
                        </Grid>
                    </Grid>
                    <DialogContent dividers>
                        <DialogContentText>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    paddingRight: '10px',
                                }}
                            >
                                {attachmentName}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            borderTop: ' #d8d8d8 solid 1px',
                            // minHeight: '60px',
                            background: ' #eeeeee',
                        }}
                    >
                        <Grid>
                            <CustomButton
                                variant='contained'
                                color='neutral'
                                // autoFocus
                                onClick={handleClose}
                            >
                                Cancel
                            </CustomButton>
                        </Grid>
                        <Grid>
                            <CustomButton
                                variant='contained'
                                color='warning'
                                onClick={handleDeleteAttachment}
                                // autoFocus
                                sx={{
                                    top: isMobile || isIpad ? '10%' : '50%',
                                }}
                            >
                                Delete
                            </CustomButton>
                        </Grid>
                    </DialogActions>
                </Grid>
            </Dialog>
        </>
    );
}
