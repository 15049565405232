import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { useAddPolicyMutation } from '../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../shared/AuthRoles';
import PolicyForm from '../../components/Policies/PolicyForm';
import toast from '../../shared/Toast';
import { Policy } from '../../types/policy';

const AddPolicyPage = () => {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const navigate = useNavigate();

    const [addPolicy] = useAddPolicyMutation();

    const onSubmit = (data: Policy) => {
        addPolicy([loggedInUser?.company, data]).then((data: any) => {
            if (data?.error) {
                toast.error(data.error.data.message);
            } else {
                toast.success(`New policy has been created`);
                navigate('/policies');
            }
        });
    };

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'policiesPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Stack width='100%' height='80%' gap='40px'>
                <Typography
                    variant='h5'
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        textDecorationColor: '#5cb5b3',
                        textUnderlineOffset: '8px',
                    }}
                    gutterBottom
                >
                    NEW POLICY
                </Typography>
                <PolicyForm onSubmit={onSubmit} />
            </Stack>
        </Container>
    );
};

export default AddPolicyPage;
