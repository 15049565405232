import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import React, { useEffect } from 'react';
import AllGuestsComponent from '../../components/GuestsManagement/AllGuestsComponent';
import { selectReportAPIRefSliceData } from '../../../app/Slices/ReportAPIRefSlice';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useGetAllClientsQuery } from '../../../app/Slices/SevenDayApiSlice';

export default function GuestListPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const reportExportAPI: any = useSelector(selectReportAPIRefSliceData);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //GET REPORT
    const { data: getReport } = useGetAllClientsQuery('/guests');

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <GroupsIcon
                        sx={{
                            color: '#5cb5b3',
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        View All Guests
                    </Typography>
                </Grid>
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'left' : 'right',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                        paddingTop: isMobile ? '10px' : '0px',
                    }}
                >
                    <Grid
                        sx={{
                            width: 'auto',
                            marginRight: isMobile ? '0px' : '15px',
                        }}
                    >
                        <>
                            {' '}
                            {/* <CustomButton
                                disableRipple
                                variant='contained'
                                color='neutral'
                                onClick={() => reportExportAPI?.printExport()}
                                sx={{ marginRight: '5px' }}
                            >
                                <PrintIcon fontSize='small' />{' '}
                            </CustomButton> */}
                            <CustomButton
                                disableRipple
                                variant='contained'
                                color='neutral'
                                onClick={() =>
                                    reportExportAPI?.csvExport({
                                        fileName: `View All Guests`,
                                        columnsStyles: {
                                            createdAt: {
                                                numFmt: 'dd/mm/yyyy',
                                            },
                                        },
                                        hideFooter: true,
                                        hideToolbar: true,
                                        // pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { fontSize: 50px}',
                                    })
                                }
                                sx={{ marginRight: '5px' }}
                            >
                                <FileDownloadIcon fontSize='small' />{' '}
                            </CustomButton>
                        </>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile ? '65%' : '78%',
                        justifyContent: 'left',
                    }}
                >
                    <AllGuestsComponent data={getReport} />
                </Grid>
            </Grid>
        </Container>
    );
}
