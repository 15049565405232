import { FileDownload } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { addDays } from 'date-fns';
import moment from 'moment';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    useAddOwnerNotesMutation,
    useApproveReservationMutation,
    useGetAvailabilityQuery,
    useGetIndividualAssetQuery,
    useGetProfileCompanyInfoQuery,
    useGetProfileDetailsQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../../shared/AuthRoles';
import { capitalizeName } from '../../../../shared/utils';
import { ReservationPolicy } from '../../../types/policy';
import { User } from '../../../types/user';
import BookingCharges from '../../Bookings/Fees/BookingCharges';
import ReservationAttachments from '../ReservationAttachments/ReservationAttachments ';
import CancelReservationModal from './CancelReservationModal';
import DeclineReservationModal from './DeclineReservationModal';
import DriverReservationDetails from './DriverReservationDetails';
import ShareReservation from './ShareReservation';
import toast from '../../../shared/Toast';
import PaymentHistory from '../../Bookings/Fees/PaymentHistory';
import ContactForm from './ContactForm';

export default function ViewIndividualReservation(params: any) {
    const [open, setOpen] = useState(false);
    const [openDeclineModal, setOpenDeclineModal] = useState(false);
    const [addOwnerNotes] = useAddOwnerNotesMutation();
    const loggedInUser = UserRole();
    let getReservationDetails = params?.reservation;
    const location = useLocation();
    // const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 900px)');
    const isLaptop = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const {
        data: getIndividualAsset,
        isFetching,
        isError,
    } = useGetIndividualAssetQuery(
        `modules/${getReservationDetails?.module.id}`
    );

    const { data: profileDetails } = useGetProfileDetailsQuery('/profile');
    const {
        data: availability,
        error,
        isLoading: isLoadingAvailability,
    } = useGetAvailabilityQuery({
        url: `/modules/commercial/${getIndividualAsset?.id}/get-availability`,
        body: {
            startDate: getReservationDetails?.startDate,
            endDate: getReservationDetails?.endDate,
        },
    });

    if (
        (isError && getReservationDetails?.module?.isAvailable) ||
        getReservationDetails?.module?.isAvailable === undefined
    ) {
        //API error i.e incorrect user accessing someone elses Reservation and the module is available or undefined
        navigate(`/permissionDenied`, {
            state: {
                name: 'individualReservation',
            },
        });
        ReactGA.send({
            hitType: 'pageview',
            page: '/permissionDenied',
            title: 'Permission Denied',
        });
    }

    let beckFamilyCheck = profileDetails?.companyName
        ?.toLowerCase()
        .includes('beck');

    let hasMassages = getIndividualAsset?.details?.massages;

    const [ownerNotes, setOwnerNotes] = useState('');
    const [approveReservation] = useApproveReservationMutation();
    const [expandedPanel1, setExpandedPanel1] = useState<any>(true);
    const [expandedPanel2, setExpandedPanel2] = useState<any>(true);
    const [expandedPanel3, setExpandedPanel3] = useState<any>(true);
    const [expandedPanel4, setExpandedPanel4] = useState<any>(true);
    const [expandedPanel5, setExpandedPanel5] = useState<any>(true);
    const [expandedPanel6, setExpandedPanel6] = useState<any>(false);

    const fullAddress = `${getIndividualAsset?.streetAddress1} ${getIndividualAsset?.streetAddress2} ${getIndividualAsset?.suburb} ${getIndividualAsset?.postcode} ${getIndividualAsset?.state} ${getIndividualAsset?.countryCode}`;

    const fullAddressDropoff = `${getIndividualAsset?.details.dropoffStreetAddress1} ${getIndividualAsset?.details.dropoffStreetAddress2} ${getIndividualAsset?.details.dropoffSuburb} ${getIndividualAsset?.details.dropoffPostcode} ${getIndividualAsset?.details.dropoffState} ${getIndividualAsset?.details.dropoffCountryCode}`;

    let startDate = moment(getReservationDetails?.startDate).format(
        'DD/MM/YYYY'
    );
    let calendarStart = moment(getReservationDetails?.startDate).format(
        'YYYY-MM-DD'
    );
    let calendarEnd = moment(getReservationDetails?.endDate).format(
        'YYYY-MM-DD'
    );

    let endDate = moment(getReservationDetails?.endDate).format('DD/MM/YYYY');

    let singleDate = moment(getReservationDetails?.startDate).format(
        'DD/MM/YYYY'
    );

    let createdAtDate = moment(getReservationDetails?.createdAt).format(
        'DD/MM/YYYY, h:mm a'
    );

    let editedAt = moment(getReservationDetails?.editedAt).format('DD/MM/YYYY');
    let approvedDate = moment(getReservationDetails?.approvedAt).format(
        'DD/MM/YYYY, h:mm a'
    );

    let declinedDate = moment(getReservationDetails?.declinedAt).format(
        'DD/MM/YYYY, h:mm a'
    );

    let completedDate = moment(getReservationDetails?.endDate).format(
        'DD/MM/YYYY'
    );

    let cancelledDate = moment(getReservationDetails?.cancelledAt).format(
        'DD/MM/YYYY, h:mm a'
    );

    let todaysDate = moment(new Date()).format('DD/MM/YYYY');
    let dayBeforeStartDate = addDays(
        new Date(getReservationDetails?.startDate),
        -1
    );

    const { data: getCompanyDetails } = useGetProfileCompanyInfoQuery(
        `/owners/${loggedInUser?.company}`
    );

    const calDescriptionNonVehicle = `${
        getReservationDetails?.bookerType === 'owner' &&
        getReservationDetails?.onBehalf === false
            ? `REF: #${getReservationDetails?.id} - ${window.location.href} [br] [li] Reservation at ${getReservationDetails?.module?.name} [br] [li] Date: ${startDate}-${endDate} [br] [li] Notes: ${getReservationDetails?.notes}`
            : `REF: #${getReservationDetails?.id} - ${window.location.href} [br] [li]  Reservation at ${getReservationDetails?.module?.name} [br] [li] Date: ${startDate}-${endDate} [br] [li] Checkin From: ${getReservationDetails?.bookingInfo?.checkinTime} [br] [li] Reserved By: ${getReservationDetails?.booker?.firstName}
      ${getReservationDetails?.booker?.lastName} -
      ${getReservationDetails?.booker?.email}  [br] [li] Max Guests Allowed: ${getIndividualAsset?.details.maxGuests}[br] ` +
              (getIndividualAsset?.details?.subType !== 'office space' &&
              getIndividualAsset?.details?.subType !== 'commercial'
                  ? `[li] Sleeps: ${getIndividualAsset?.details.overnightGuests} [br] `
                  : '') +
              `[li] No Adults Attending: ${getReservationDetails?.bookingInfo?.adults} [br] [li] No Children Attending: ${getReservationDetails?.bookingInfo?.children} [br] ` +
              (getIndividualAsset?.details?.subType !== 'office space' &&
              getIndividualAsset?.details?.subType !== 'commercial'
                  ? `[li] No. Overnight Guests: ${getReservationDetails?.bookingInfo?.overnightGuests}[br] `
                  : '') +
              ` [li] Notes: ${getReservationDetails?.bookingInfo?.notes}`
    } `;
    const calDescriptionCommercial = `${
        getReservationDetails?.bookerType === 'owner' &&
        getReservationDetails?.onBehalf === false
            ? `REF: #${getReservationDetails?.id} - ${window.location.href} [br] [li] Booking at ${getReservationDetails?.module?.name} [br] [li] Date: ${startDate}-${endDate} [br] [li] Notes: ${getReservationDetails?.notes}`
            : `REF: #${getReservationDetails?.id} - ${window.location.href} [br] [li] Booking at ${getReservationDetails?.module?.name} [br] [li] Date: ${startDate}-${endDate} [br] [li] Available From: ${getReservationDetails?.bookingInfo?.checkinTime} until ${getReservationDetails?.bookingInfo?.checkoutTime} [br] [li] Booked By: ${getReservationDetails?.booker?.firstName} ${getReservationDetails?.booker?.lastName} - ${getReservationDetails?.booker?.email} [br] ` +
              (getReservationDetails?.bookingInfo?.desks > 0
                  ? `[li] No. Dedicated Desks: ${getReservationDetails?.bookingInfo?.desks} [br] `
                  : '') +
              (getReservationDetails?.bookingInfo?.privateOffices > 0
                  ? `[li] No. Private Offices: ${getReservationDetails?.bookingInfo?.privateOffices} [br] `
                  : '') +
              (getReservationDetails?.bookingInfo?.meetingRooms > 0
                  ? `[li] No. Meeting Rooms: ${getReservationDetails?.bookingInfo?.meetingRooms} [br] `
                  : '') +
              (getReservationDetails?.bookingInfo?.notes
                  ? ` [li] Notes: ${getReservationDetails?.bookingInfo?.notes}`
                  : '')
    }`;

    const calDescriptionActivity = `REF: #${getReservationDetails?.id} - ${
        window.location.href
    } [br] [li]  Reservation at ${
        getReservationDetails?.module?.name
    } [br] [li] Date: ${startDate}  [br] [li] Reserved By: ${
        getReservationDetails?.booker?.firstName
    }
            ${getReservationDetails?.booker?.lastName} -
            ${getReservationDetails?.booker?.email}   ${
        getReservationDetails?.bookerType === 'owner' &&
        getReservationDetails?.onBehalf === false
            ? ''
            : `[br] [li] No Guests Attending: ${getReservationDetails?.bookingInfo?.guests}`
    }  [br] [li] Notes: ${
        getReservationDetails?.bookerType === 'owner' &&
        getReservationDetails?.onBehalf === false
            ? getReservationDetails?.notes
            : getReservationDetails?.bookingInfo?.notes
    }  `;

    const calDescriptionVehicle = ` REF: #${getReservationDetails?.id} - ${
        window.location.href
    } 
    [br] [li] Reserved ${
        getReservationDetails?.module?.name
    } [br] [li] Date: ${startDate}-${endDate}  [br] [li] Reserved By: ${
        getReservationDetails?.booker?.firstName
    }
          ${getReservationDetails?.booker?.lastName} -
          ${getReservationDetails?.booker?.email}  
            [br] [li] Notes:  ${
                getReservationDetails?.bookerType === 'owner' &&
                getReservationDetails?.onBehalf === false
                    ? getReservationDetails?.notes
                    : getReservationDetails?.bookingInfo?.notes
            }  ${
        !getIndividualAsset?.details?.dropoffLocationSameAsPickup
            ? `[br] [li] Pickup at : ${fullAddress} [br] [li] Dropoff at : ${fullAddressDropoff} `
            : ''
    }  `;

    const onClickGoBack = () => {
        if (location?.state?.component) {
            if (location?.state.type === 'notifications') {
                navigate(-1);
            } else {
                navigate(`/${location?.state?.url}`);
            }
        } else {
            navigate(-1);
        }
    };

    const handleOwnerNotes = (event: any) => {
        setOwnerNotes(event?.target.value);
    };

    const saveOwnerNotes = () => {
        //addOwnerNotes
        let requestBody = [
            getReservationDetails?.id,
            { ownerNotes: ownerNotes },
        ];
        addOwnerNotes(requestBody).then((data: any) => {
            if (data) {
                toast.success(`Notes added to reservation`);
            } else {
                toast.error(`Failed to save your notes`);
            }
        });
    };

    const editReservation = () => {
        ReactGA.event({
            category: 'UI',
            action: `Edit Reservation (View)`,
            label: `Edit Reservation Clicked (View) - ${loggedInUser.role}`,
        });
        navigate(
            `/editReservation/${getReservationDetails?.module?.name}/${getReservationDetails?.id}`,
            {
                state: {
                    name: 'editReservation',
                },
            }
        );
    };

    const onClickApprove = () => {
        ReactGA.event({
            category: 'UI',
            action: `Approve Reservation (View)`,
            label: `Approve Reservation Clicked (View)`,
        });
        let bookingId = getReservationDetails?.id;
        approveReservation(bookingId).then((data: any) => {
            if (data) {
                toast.success(`Reservation has been approved`);
            } else {
                toast.error(`Failed to approve reservation`);
            }
        });
    };

    const onClickOpenModal = () => {
        setOpen(true);
    };
    const handleChangeModal = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const onClickOpenDeclineModal = () => {
        ReactGA.event({
            category: 'UI',
            action: `Declined Reservation (View)`,
            label: `Declined Reservation Clicked (View)`,
        });
        setOpenDeclineModal(true);
    };
    const handleChangeDeclineModal = () => {
        setOpenDeclineModal(false);
    };

    const handleCloseDeclineModal = () => {
        setOpenDeclineModal(false);
    };

    const disableApprove =
        getReservationDetails?.bookingInfo?.desks > availability?.desks ||
        getReservationDetails?.bookingInfo?.privateOffices >
            availability?.privateOffices ||
        getReservationDetails?.bookingInfo?.meetingRooms >
            availability?.meetingRooms;
    const openRecurringReservation = () => {
        navigate(
            `/Reservation/ai-assistant/${getReservationDetails?.recurringId}`,
            {
                state: { name: 'viewAIReservation', type: 'reservations' },
            }
        );
    };

    return (
        <Grid sx={{ height: 'auto' }}>
            {isFetching === true ? (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}

                    sx={{
                        height: isLaptop ? '50vh' : isMobile ? '5px' : '50vh',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid
                        sx={{
                            // backgroundColor: isMobile ? 'pink' : 'gray',
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            paddingTop: '0px',
                            height: 'auto',
                            // width: isIpad ? '80%' : isMobile ? null : '%',
                        }}
                    >
                        <Grid
                            sx={{
                                width: isMobile ? '100%' : '100%',
                                // backgroundColor: 'brown',
                                display: 'flex',
                                flexDirection: 'column',
                                height: isMobile ? 'auto' : 'auto',
                            }}
                        >
                            <Grid>
                                <Grid
                                    sx={{
                                        height: 'auto',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // backgroundColor: 'yellow',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            flexDirection: isMobile
                                                ? 'column'
                                                : 'row',
                                            alignItems: isMobile
                                                ? ''
                                                : 'flex-end',
                                            paddingBottom: '30px',
                                        }}
                                    >
                                        {isMobile ? null : (
                                            <Grid
                                                sx={{
                                                    width: '50%',
                                                    marginLeft: '-5px',
                                                }}
                                            >
                                                {getReservationDetails?.status ===
                                                    'completed' ||
                                                getReservationDetails?.status ===
                                                    'cancelled' ||
                                                getReservationDetails?.status ===
                                                    'declined' ? null : (
                                                    <AddToCalendarButton
                                                        name={`${
                                                            loggedInUser?.role.includes(
                                                                'owner'
                                                            )
                                                                ? `${
                                                                      getReservationDetails
                                                                          ?.guest
                                                                          ?.name
                                                                          ? getReservationDetails
                                                                                ?.guest
                                                                                ?.name
                                                                          : getReservationDetails
                                                                                ?.owner
                                                                                ?.name
                                                                  } - ${
                                                                      getReservationDetails
                                                                          ?.module
                                                                          ?.name
                                                                  } REF: #${
                                                                      getReservationDetails?.id
                                                                  }`
                                                                : ` ${getReservationDetails?.module?.name} REF: #${getReservationDetails?.id}`
                                                        }`}
                                                        label='ADD TO CALENDAR'
                                                        iCalFileName={`${
                                                            loggedInUser?.role.includes(
                                                                'owner'
                                                            )
                                                                ? `${
                                                                      getReservationDetails
                                                                          ?.guest
                                                                          ?.name
                                                                          ? getReservationDetails
                                                                                ?.guest
                                                                                ?.name
                                                                          : getReservationDetails
                                                                                ?.owner
                                                                                ?.name
                                                                  } - ${
                                                                      getReservationDetails
                                                                          ?.module
                                                                          ?.name
                                                                  }-${
                                                                      getReservationDetails?.status
                                                                  } reservation REF: #${
                                                                      getReservationDetails?.id
                                                                  }`
                                                                : `${getReservationDetails?.module?.name}-${getReservationDetails?.status} reservation REF: #${getReservationDetails?.id}`
                                                        }`}
                                                        description={
                                                            getReservationDetails
                                                                ?.module
                                                                ?.type ===
                                                            'activity'
                                                                ? calDescriptionActivity
                                                                : getReservationDetails
                                                                      ?.module
                                                                      ?.type ===
                                                                  'vehicle'
                                                                ? calDescriptionVehicle
                                                                : getReservationDetails
                                                                      ?.module
                                                                      ?.type ===
                                                                  'commercial'
                                                                ? calDescriptionCommercial
                                                                : calDescriptionNonVehicle
                                                        }
                                                        // organizer={calOrganiser}
                                                        options={[
                                                            'iCal|Download',
                                                            'Google',
                                                            'Outlook.com',
                                                            'Microsoft365',
                                                            // "Yahoo",
                                                        ]}
                                                        location={fullAddress}
                                                        startDate={
                                                            calendarStart
                                                        }
                                                        endDate={calendarEnd}
                                                        startTime={
                                                            getReservationDetails
                                                                ?.bookingInfo
                                                                ?.checkinTime
                                                        }
                                                        endTime={
                                                            getReservationDetails
                                                                ?.bookingInfo
                                                                ?.checkoutTime
                                                        }
                                                        timeZone='Australia/Sydney'
                                                        hideCheckmark
                                                        size='2'
                                                        listStyle='modal'
                                                        lightMode='bodyScheme'
                                                        styleLight='--btn-background: #fff; --btn-text: #5cb5b3; '
                                                        styleDark='--btn-background: #D0342C;'
                                                    ></AddToCalendarButton>
                                                )}
                                            </Grid>
                                        )}
                                        <Grid
                                            sx={{
                                                width: isMobile
                                                    ? '100%'
                                                    : '50%',
                                                marginLeft: isMobile
                                                    ? '-15px'
                                                    : '11px',
                                            }}
                                        >
                                            <List>
                                                <ListItem
                                                    sx={{
                                                        paddingBottom: '10px',
                                                        textAlign: isMobile
                                                            ? 'left'
                                                            : 'right',
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingTop:
                                                                        '0px',
                                                                    // paddingBottom: '30px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    textTransform:
                                                                        'uppercase',
                                                                }}
                                                            >
                                                                Reservation
                                                                REFERENCE:{' '}
                                                                {
                                                                    getReservationDetails?.id
                                                                }
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            >
                                                                Created{' '}
                                                                {createdAtDate}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>{' '}
                                        </Grid>
                                        {!isMobile ? null : (
                                            <Grid
                                                sx={{
                                                    width: '100%',
                                                    marginLeft: isMobile
                                                        ? '-12px'
                                                        : '0px',
                                                }}
                                            >
                                                {getReservationDetails?.status ===
                                                    'completed' ||
                                                getReservationDetails?.status ===
                                                    'cancelled' ||
                                                getReservationDetails?.status ===
                                                    'declined' ? null : (
                                                    <AddToCalendarButton
                                                        name={`${
                                                            loggedInUser?.role.includes(
                                                                'owner'
                                                            )
                                                                ? `${
                                                                      getReservationDetails
                                                                          ?.guest
                                                                          ?.name
                                                                          ? getReservationDetails
                                                                                ?.guest
                                                                                ?.name
                                                                          : getReservationDetails
                                                                                ?.owner
                                                                                ?.name
                                                                  } - ${
                                                                      getReservationDetails
                                                                          ?.module
                                                                          ?.name
                                                                  }`
                                                                : ` ${getReservationDetails?.module?.name} REF: #${getReservationDetails?.id}`
                                                        }`}
                                                        label='ADD TO CALENDAR'
                                                        iCalFileName={`${
                                                            loggedInUser?.role.includes(
                                                                'owner'
                                                            )
                                                                ? `${
                                                                      getReservationDetails
                                                                          ?.guest
                                                                          ?.name
                                                                          ? getReservationDetails
                                                                                ?.guest
                                                                                ?.name
                                                                          : getReservationDetails
                                                                                ?.owner
                                                                                ?.name
                                                                  } - ${
                                                                      getReservationDetails
                                                                          ?.module
                                                                          ?.name
                                                                  }-${
                                                                      getReservationDetails?.status
                                                                  } reservation REF: #${
                                                                      getReservationDetails?.id
                                                                  }`
                                                                : `${getReservationDetails?.module?.name}-${getReservationDetails?.status} Reservation REF: #${getReservationDetails?.id}`
                                                        }`}
                                                        description={
                                                            getReservationDetails
                                                                ?.module
                                                                ?.type ===
                                                            'activity'
                                                                ? calDescriptionActivity
                                                                : getReservationDetails
                                                                      ?.module
                                                                      ?.type ===
                                                                  'vehicle'
                                                                ? calDescriptionVehicle
                                                                : calDescriptionNonVehicle
                                                        }
                                                        // organizer={calOrganiser}
                                                        options={[
                                                            'iCal|Download',
                                                            'Google',
                                                            'Outlook.com',
                                                            'Microsoft365',
                                                            // "Yahoo",
                                                        ]}
                                                        location={fullAddress}
                                                        startDate={
                                                            calendarStart
                                                        }
                                                        endDate={calendarEnd}
                                                        startTime={
                                                            getReservationDetails
                                                                ?.bookingInfo
                                                                ?.checkinTime
                                                        }
                                                        endTime={
                                                            getReservationDetails
                                                                ?.bookingInfo
                                                                ?.checkoutTime
                                                        }
                                                        timeZone='Australia/Sydney'
                                                        hideCheckmark
                                                        size='2'
                                                        listStyle='modal'
                                                        lightMode='bodyScheme'
                                                        styleLight='--btn-background: #fff; --btn-text: #5cb5b3; '
                                                        styleDark='--btn-background: #D0342C;'
                                                    ></AddToCalendarButton>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>

                                    {isMobile ? (
                                        <>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Date:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {startDate} - {endDate}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {getIndividualAsset?.type ===
                                                        'commercial'
                                                            ? ' Access From:'
                                                            : 'Checkin'}
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {getReservationDetails?.bookerType ===
                                                            'owner' &&
                                                        getReservationDetails?.onBehalf ===
                                                            false
                                                            ? '-'
                                                            : getReservationDetails
                                                                  ?.bookingInfo
                                                                  ?.checkinTime}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {getIndividualAsset?.type ===
                                                        'commercial'
                                                            ? ' Access To:'
                                                            : 'Checkout'}
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {getReservationDetails?.bookerType ===
                                                            'owner' &&
                                                        getReservationDetails?.onBehalf ===
                                                            false
                                                            ? '-'
                                                            : getReservationDetails
                                                                  ?.bookingInfo
                                                                  ?.checkoutTime}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Type:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {getReservationDetails?.module?.type
                                                            ?.charAt(0)
                                                            ?.toUpperCase() +
                                                            getReservationDetails?.module?.type.slice(
                                                                1
                                                            )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Status:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {getReservationDetails?.status
                                                            ?.charAt(0)
                                                            ?.toUpperCase() +
                                                            getReservationDetails?.status.slice(
                                                                1
                                                            )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <TableContainer component={Paper}>
                                            <Table aria-label='simple table'>
                                                <TableHead
                                                    sx={{
                                                        backgroundColor:
                                                            '#f6f8f9',
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell>
                                                            {' '}
                                                            <Typography
                                                                color='#787878'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    fontWeight: 550,
                                                                    textTransform:
                                                                        'uppercase',
                                                                }}
                                                            >
                                                                Date
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <Typography
                                                                color='#787878'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    fontWeight: 550,
                                                                    textTransform:
                                                                        'uppercase',
                                                                }}
                                                            >
                                                                {getIndividualAsset?.type ===
                                                                'commercial'
                                                                    ? ' Access From'
                                                                    : 'Checkin'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <Typography
                                                                color='#787878'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    fontWeight: 550,
                                                                    textTransform:
                                                                        'uppercase',
                                                                }}
                                                            >
                                                                {getIndividualAsset?.type ===
                                                                'commercial'
                                                                    ? ' Access To'
                                                                    : 'Checkout'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <Typography
                                                                color='#787878'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    fontWeight: 550,
                                                                    textTransform:
                                                                        'uppercase',
                                                                }}
                                                            >
                                                                Type
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <Typography
                                                                color='#787878'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    fontWeight: 550,
                                                                    textTransform:
                                                                        'uppercase',
                                                                }}
                                                            >
                                                                Status
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow
                                                        sx={{
                                                            '&:last-child td, &:last-child th':
                                                                { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell
                                                            component='th'
                                                            scope='row'
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                {startDate} -{' '}
                                                                {endDate}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                {getReservationDetails?.bookerType ===
                                                                    'owner' &&
                                                                getReservationDetails?.onBehalf ===
                                                                    false
                                                                    ? '-'
                                                                    : getReservationDetails
                                                                          ?.bookingInfo
                                                                          ?.checkinTime}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                {getReservationDetails?.bookerType ===
                                                                    'owner' &&
                                                                getReservationDetails?.onBehalf ===
                                                                    false
                                                                    ? '-'
                                                                    : getReservationDetails
                                                                          ?.bookingInfo
                                                                          ?.checkoutTime}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                {capitalizeName(
                                                                    getReservationDetails
                                                                        ?.module
                                                                        ?.type
                                                                )}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    // getReservationDetails?.status ===
                                                                    // 'completed'
                                                                    //     ? '#5cb5b3'
                                                                    //     : getReservationDetails?.status ===
                                                                    //       'cancelled'
                                                                    //     ? '#FF0000'
                                                                    //     : getReservationDetails?.status ===
                                                                    //       'approved'
                                                                    //     ? '#FFBF00'
                                                                    //     : getReservationDetails?.status ===
                                                                    //       'declined'
                                                                    //     ? '#ff4d00'
                                                                    //     : '#0096FF',
                                                                }}
                                                            >
                                                                {getReservationDetails?.status
                                                                    ?.charAt(0)
                                                                    ?.toUpperCase() +
                                                                    getReservationDetails?.status?.slice(
                                                                        1
                                                                    )}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </Grid>
                            </Grid>
                            {/* Accordion GRID */}
                            <Grid sx={{ marginTop: '30px' }}>
                                <Accordion
                                    expanded={expandedPanel1}
                                    onChange={() =>
                                        setExpandedPanel1(!expandedPanel1)
                                    }
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1a-content'
                                        id='panel1a-header'
                                        sx={{
                                            backgroundColor:
                                                expandedPanel1 === false
                                                    ? '#f6f8f9'
                                                    : '',
                                        }}
                                    >
                                        <Typography
                                            component='h3'
                                            variant='h6'
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontWeight: 550,
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            Reservation Details
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <>
                                            <Grid>
                                                <Divider
                                                    sx={{
                                                        marginTop: '0px',
                                                    }}
                                                />
                                                {getReservationDetails?.recurringId !==
                                                null ? (
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                Recurring
                                                                Reference:
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                <Link
                                                                    style={{
                                                                        color: '#787878',
                                                                    }}
                                                                    target='_self'
                                                                    onClick={
                                                                        openRecurringReservation
                                                                    }
                                                                    to={`/reservations/ai-assistant/${getReservationDetails?.recurringId}`}
                                                                >
                                                                    #
                                                                    {
                                                                        getReservationDetails?.recurringId
                                                                    }
                                                                </Link>{' '}
                                                                - This
                                                                Reservation was
                                                                reserved via
                                                                7DAY AI
                                                                Assistant and is
                                                                a part of a
                                                                recurring
                                                                reservation
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ) : null}
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        paddingTop:
                                                            getReservationDetails?.recurringId
                                                                ? '0px'
                                                                : '10px',
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '30%'
                                                                : '20%',
                                                        }}
                                                    >
                                                        {' '}
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            Experience:
                                                        </Typography>{' '}
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '70%'
                                                                : '80%',
                                                        }}
                                                    >
                                                        {' '}
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            {
                                                                getReservationDetails
                                                                    ?.module
                                                                    ?.name
                                                            }{' '}
                                                            - hosted by{' '}
                                                            {
                                                                getReservationDetails
                                                                    ?.owner
                                                                    ?.name
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid sx={{ display: 'flex' }}>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '30%'
                                                                : '20%',
                                                        }}
                                                    >
                                                        {' '}
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            {getReservationDetails
                                                                ?.module
                                                                ?.type ===
                                                            'vehicle'
                                                                ? 'Pickup Location:'
                                                                : 'Location:'}
                                                        </Typography>{' '}
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width: isMobile
                                                                ? '70%'
                                                                : '80%',
                                                        }}
                                                    >
                                                        {' '}
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: !getReservationDetails
                                                                    ?.module
                                                                    ?.isAvailable
                                                                    ? 'red'
                                                                    : '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            {!getReservationDetails
                                                                ?.module
                                                                ?.isAvailable ? (
                                                                `This experience is no longer listed, please contact the host`
                                                            ) : (
                                                                <Link
                                                                    style={{
                                                                        color: '#787878',
                                                                    }}
                                                                    target='_blank'
                                                                    to={`https://www.google.com/maps/place/${fullAddress}`}
                                                                >
                                                                    {' '}
                                                                    {
                                                                        fullAddress
                                                                    }
                                                                </Link>
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                {getIndividualAsset?.type ===
                                                    'vehicle' && (
                                                    <Grid>
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Dropoff
                                                                    Location:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    <Link
                                                                        style={{
                                                                            color: '#787878',
                                                                        }}
                                                                        target='_blank'
                                                                        to={
                                                                            getIndividualAsset
                                                                                ?.details
                                                                                ?.dropoffLocationSameAsPickup
                                                                                ? `https://www.google.com/maps/place/${fullAddress}`
                                                                                : `https://www.google.com/maps/place/${fullAddressDropoff}`
                                                                        }
                                                                    >
                                                                        {' '}
                                                                        {getIndividualAsset
                                                                            ?.details
                                                                            ?.dropoffLocationSameAsPickup
                                                                            ? 'Same as above'
                                                                            : fullAddressDropoff}
                                                                    </Link>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        {(getReservationDetails?.onBehalf ||
                                                            getReservationDetails?.bookerType !==
                                                                'owner') && (
                                                            <DriverReservationDetails
                                                                drivers={
                                                                    getReservationDetails
                                                                        ?.bookingInfo
                                                                        ?.drivers
                                                                }
                                                            />
                                                        )}
                                                    </Grid>
                                                )}
                                                {getReservationDetails?.bookerType.includes(
                                                    'owner'
                                                ) ? (
                                                    <>
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Company:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {getReservationDetails
                                                                        ?.guest
                                                                        ?.name
                                                                        ? getReservationDetails
                                                                              ?.guest
                                                                              ?.name
                                                                        : getReservationDetails
                                                                              ?.bookingInfo
                                                                              ?.visitType
                                                                        ? `Reserved on behalf of ${getReservationDetails?.owner?.name} `
                                                                        : `Blocked by ${getReservationDetails?.owner?.name}`}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Reserved By:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                <Box
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    gap='10px'
                                                                    pb='10px'
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.firstName
                                                                        }{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.lastName
                                                                        }{' '}
                                                                        -{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.email
                                                                        }{' '}
                                                                        (
                                                                        {
                                                                            getReservationDetails
                                                                                ?.owner
                                                                                .name
                                                                        }
                                                                        )
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        {getReservationDetails?.onBehalf ===
                                                        false ? (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Reservation
                                                                        Reason:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {getReservationDetails?.reason
                                                                            .replace(
                                                                                /([A-Z])/g,
                                                                                ' $1'
                                                                            )
                                                                            .replace(
                                                                                /^./,
                                                                                function (
                                                                                    str: string
                                                                                ) {
                                                                                    return str.toUpperCase();
                                                                                }
                                                                            )}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <>
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            Type
                                                                            of
                                                                            visit:
                                                                        </Typography>{' '}
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {getReservationDetails?.bookingInfo?.visitType
                                                                                ?.charAt(
                                                                                    0
                                                                                )
                                                                                ?.toUpperCase() +
                                                                                getReservationDetails?.bookingInfo?.visitType.slice(
                                                                                    1
                                                                                )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                {getReservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                'activity' ? (
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                No
                                                                                Guests:
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    getReservationDetails
                                                                                        ?.bookingInfo
                                                                                        ?.guests
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                ) : getReservationDetails
                                                                      ?.module
                                                                      ?.type ===
                                                                  'vehicle' ? null : getReservationDetails
                                                                      ?.module
                                                                      ?.type ===
                                                                  'commercial' ? (
                                                                    <>
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            {getReservationDetails
                                                                                ?.bookingInfo
                                                                                ?.desks !==
                                                                                0 && (
                                                                                <>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            No.
                                                                                            Dedicated
                                                                                            Desks:
                                                                                        </Typography>{' '}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                getReservationDetails
                                                                                                    ?.bookingInfo
                                                                                                    ?.desks
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            )}
                                                                            {getReservationDetails
                                                                                ?.bookingInfo
                                                                                ?.privateOffices !==
                                                                                0 && (
                                                                                <>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {getIndividualAsset
                                                                                                ?.details
                                                                                                ?.subType ===
                                                                                            'house'
                                                                                                ? `No. Rooms:`
                                                                                                : `No. Private Offices:`}
                                                                                        </Typography>{' '}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                getReservationDetails
                                                                                                    ?.bookingInfo
                                                                                                    ?.privateOffices
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            )}
                                                                        </Grid>

                                                                        {getReservationDetails
                                                                            ?.bookingInfo
                                                                            ?.meetingRooms !==
                                                                            0 && (
                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        No.
                                                                                        Meeting
                                                                                        Rooms:{' '}
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            getReservationDetails
                                                                                                ?.bookingInfo
                                                                                                ?.meetingRooms
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                        {getReservationDetails
                                                                            ?.bookingInfo
                                                                            ?.entireVenue && (
                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        Type
                                                                                        of
                                                                                        Reservation:{' '}
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        Entire
                                                                                        Venue
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    No.
                                                                                    Adults:
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            ?.adults
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    No.
                                                                                    Children:{' '}
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            ?.children
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {startDate ===
                                                                        endDate ? null : (
                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        No.
                                                                                        Overnight
                                                                                        Guests:{' '}
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            getReservationDetails
                                                                                                ?.bookingInfo
                                                                                                ?.overnightGuests
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {getReservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                    'activity' ||
                                                                getReservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                    'vehicle' ? null : (
                                                                    <>
                                                                        {getIndividualAsset
                                                                            ?.details
                                                                            ?.catering && (
                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        Catering
                                                                                        Details:{' '}
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            .requiresCatering ===
                                                                                        true
                                                                                            ? getReservationDetails
                                                                                                  ?.bookingInfo
                                                                                                  ?.cateringNotes
                                                                                            : 'No catering requested'}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                        {getIndividualAsset
                                                                            ?.details
                                                                            ?.alcohol && (
                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        Alcohol
                                                                                        Details
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            .requiresBeverages ===
                                                                                        true
                                                                                            ? getReservationDetails
                                                                                                  ?.bookingInfo
                                                                                                  ?.beverageNotes
                                                                                            : 'No beverages requested'}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {getReservationDetails
                                                                    ?.module
                                                                    ?.type !==
                                                                'property' ? null : (
                                                                    <>
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    Massage
                                                                                    Details:{' '}
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {getReservationDetails
                                                                                        ?.bookingInfo
                                                                                        ?.requiresMassages ===
                                                                                    true
                                                                                        ? getReservationDetails
                                                                                              ?.bookingInfo
                                                                                              ?.massageNotes
                                                                                        : hasMassages ===
                                                                                          false
                                                                                        ? 'This property does not offer massages'
                                                                                        : 'No massages requested'}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Notes:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {getReservationDetails?.bookerType ===
                                                                        'owner' &&
                                                                    getReservationDetails?.onBehalf ===
                                                                        false
                                                                        ? getReservationDetails?.notes
                                                                        : getReservationDetails
                                                                              ?.bookingInfo
                                                                              ?.notes}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>{' '}
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'flex-end',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    justifyContent:
                                                                        'flex-end',
                                                                }}
                                                                display={
                                                                    isMobile
                                                                        ? 'none'
                                                                        : isLaptop
                                                                        ? 'none'
                                                                        : 'block'
                                                                }
                                                            >
                                                                {' '}
                                                                <ShareReservation
                                                                    getReservationDetails={
                                                                        getReservationDetails
                                                                    }
                                                                    getExperienceDetails={
                                                                        getIndividualAsset
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Company:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {getReservationDetails
                                                                        ?.guest
                                                                        ?.name
                                                                        ? getReservationDetails
                                                                              ?.guest
                                                                              ?.name
                                                                        : getReservationDetails
                                                                              ?.owner
                                                                              ?.name}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Primary
                                                                    Guest:{' '}
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                <Box
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    gap='10px'
                                                                    pb='10px'
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.firstName
                                                                        }{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.lastName
                                                                        }{' '}
                                                                        -{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.email
                                                                        }
                                                                    </Typography>
                                                                    {loggedInUser?.role?.includes(
                                                                        'owner'
                                                                    ) &&
                                                                        getReservationDetails
                                                                            ?.booker
                                                                            ?.profilePicture &&
                                                                        (getIndividualAsset?.usesNirovision ||
                                                                            getIndividualAsset
                                                                                ?.details
                                                                                ?.dropoffUsesNirovision) && (
                                                                            <a
                                                                                href={`${process.env.REACT_APP_BASE_URL}/users/picture/${getReservationDetails?.booker?.profilePicture?.id}`}
                                                                            >
                                                                                <CustomButton
                                                                                    size='small'
                                                                                    color='green'
                                                                                    variant='outlined'
                                                                                    startIcon={
                                                                                        <FileDownload />
                                                                                    }
                                                                                >
                                                                                    Profile
                                                                                    Picture
                                                                                </CustomButton>
                                                                            </a>
                                                                        )}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        {getReservationDetails?.staffAttending &&
                                                            getIndividualAsset?.visitType !==
                                                                'personal' &&
                                                            getIndividualAsset?.type !==
                                                                'commercial' &&
                                                            getIndividualAsset?.type !==
                                                                'vehicle' && (
                                                                <Grid display='flex'>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            Staff
                                                                            Attending:
                                                                        </Typography>{' '}
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                        pb='10px'
                                                                    >
                                                                        {getReservationDetails
                                                                            ?.staffAttending
                                                                            ?.length >
                                                                        0 ? (
                                                                            getReservationDetails?.staffAttending.map(
                                                                                (
                                                                                    user: User
                                                                                ) => (
                                                                                    <Typography
                                                                                        fontSize='14px'
                                                                                        color='#787878'
                                                                                    >
                                                                                        <li>
                                                                                            {`${user.firstName} ${user.lastName} - ${user.email}`}
                                                                                        </li>
                                                                                    </Typography>
                                                                                )
                                                                            )
                                                                        ) : (
                                                                            <Typography
                                                                                fontSize='14px'
                                                                                color='#787878'
                                                                            >
                                                                                No
                                                                                staff
                                                                                selected
                                                                            </Typography>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        {getReservationDetails?.createdBy &&
                                                        getReservationDetails
                                                            ?.createdBy
                                                            .email !==
                                                            getReservationDetails
                                                                ?.booker
                                                                .email ? (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Reservation
                                                                        made by:{' '}
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            getReservationDetails
                                                                                .createdBy
                                                                                .firstName
                                                                        }{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                .createdBy
                                                                                .lastName
                                                                        }{' '}
                                                                        -{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                .createdBy
                                                                                .email
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ) : null}
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Type of
                                                                    visit:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {getReservationDetails?.bookingInfo?.visitType
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        ?.toUpperCase() +
                                                                        getReservationDetails?.bookingInfo?.visitType.slice(
                                                                            1
                                                                        )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        {getReservationDetails
                                                            ?.module?.type ===
                                                        'activity' ? (
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        No
                                                                        Guests:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            getReservationDetails
                                                                                ?.bookingInfo
                                                                                ?.guests
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ) : getReservationDetails
                                                              ?.module?.type ===
                                                          'vehicle' ? null : getReservationDetails
                                                              ?.module?.type ===
                                                          'commercial' ? (
                                                            <>
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    {getReservationDetails
                                                                        ?.bookingInfo
                                                                        ?.desks !==
                                                                        0 && (
                                                                        <>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    No.
                                                                                    Dedicated
                                                                                    Desks:
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            ?.desks
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                    {getReservationDetails
                                                                        ?.bookingInfo
                                                                        ?.privateOffices !==
                                                                        0 && (
                                                                        <>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {getIndividualAsset
                                                                                        ?.details
                                                                                        ?.subType ===
                                                                                    'house'
                                                                                        ? `No. Rooms:`
                                                                                        : `No. Private Offices:`}
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            ?.privateOffices
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                </Grid>

                                                                {getReservationDetails
                                                                    ?.bookingInfo
                                                                    ?.meetingRooms !==
                                                                    0 && (
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                No.
                                                                                Meeting
                                                                                Rooms:{' '}
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    getReservationDetails
                                                                                        ?.bookingInfo
                                                                                        ?.meetingRooms
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {getReservationDetails
                                                                    ?.bookingInfo
                                                                    ?.entireVenue && (
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Type
                                                                                of
                                                                                Reservation:{' '}
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Entire
                                                                                Venue
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            No.
                                                                            Adults:
                                                                        </Typography>{' '}
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.bookingInfo
                                                                                    ?.adults
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            No.
                                                                            Children:{' '}
                                                                        </Typography>{' '}
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.bookingInfo
                                                                                    ?.children
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                {startDate ===
                                                                    endDate ||
                                                                getIndividualAsset
                                                                    ?.details
                                                                    ?.subType ===
                                                                    'commercial' ||
                                                                getIndividualAsset
                                                                    ?.details
                                                                    ?.subType ===
                                                                    'office space' ? null : (
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                No.
                                                                                Overnight
                                                                                Guests:{' '}
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    getReservationDetails
                                                                                        ?.bookingInfo
                                                                                        ?.overnightGuests
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                        {getReservationDetails
                                                            ?.module?.type ===
                                                            'activity' ||
                                                        getReservationDetails
                                                            ?.module?.type ===
                                                            'vehicle' ? null : (
                                                            <>
                                                                {getIndividualAsset
                                                                    ?.details
                                                                    ?.catering && (
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Catering
                                                                                Details:{' '}
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {getReservationDetails
                                                                                    ?.bookingInfo
                                                                                    .requiresCatering ===
                                                                                true
                                                                                    ? getReservationDetails
                                                                                          ?.bookingInfo
                                                                                          ?.cateringNotes
                                                                                    : 'No catering requested'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {getIndividualAsset
                                                                    ?.details
                                                                    ?.alcohol && (
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Alcohol
                                                                                Details:{' '}
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {getReservationDetails
                                                                                    ?.bookingInfo
                                                                                    .requiresBeverages ===
                                                                                true
                                                                                    ? getReservationDetails
                                                                                          ?.bookingInfo
                                                                                          ?.beverageNotes
                                                                                    : 'No beverages requested'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                        {getReservationDetails
                                                            ?.module?.type !==
                                                        'property' ? null : (
                                                            <>
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            Massage
                                                                            Details:{' '}
                                                                        </Typography>{' '}
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {getReservationDetails
                                                                                ?.bookingInfo
                                                                                ?.requiresMassages ===
                                                                            true
                                                                                ? getReservationDetails
                                                                                      ?.bookingInfo
                                                                                      ?.massageNotes
                                                                                : hasMassages ===
                                                                                  false
                                                                                ? 'This property does not offer massages'
                                                                                : 'No massages requested'}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )}

                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Notes:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {getReservationDetails?.bookerType ===
                                                                        'owner' &&
                                                                    getReservationDetails?.onBehalf ===
                                                                        false
                                                                        ? getReservationDetails?.notes
                                                                        : getReservationDetails
                                                                              ?.bookingInfo
                                                                              ?.notes}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'flex-end',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    justifyContent:
                                                                        'flex-end',
                                                                }}
                                                                display={
                                                                    isMobile
                                                                        ? 'none'
                                                                        : isLaptop
                                                                        ? 'none'
                                                                        : 'block'
                                                                }
                                                            >
                                                                {' '}
                                                                <ShareReservation
                                                                    getReservationDetails={
                                                                        getReservationDetails
                                                                    }
                                                                    getExperienceDetails={
                                                                        getIndividualAsset
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}{' '}
                                            </Grid>
                                        </>
                                    </AccordionDetails>
                                </Accordion>
                                {getReservationDetails?.onBehalf ===
                                false ? null : (
                                    <Accordion
                                        expanded={expandedPanel2}
                                        onChange={() =>
                                            setExpandedPanel2(!expandedPanel2)
                                        }
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel2a-content'
                                            id='panel2a-header'
                                            sx={{
                                                backgroundColor:
                                                    expandedPanel2 === false
                                                        ? '#f6f8f9'
                                                        : '',
                                            }}
                                        >
                                            <Typography
                                                component='h3'
                                                variant='h6'
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 550,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {getIndividualAsset?.type ===
                                                'property'
                                                    ? 'Property Information'
                                                    : 'Experience Information'}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                <Grid>
                                                    {todaysDate >=
                                                        moment(
                                                            dayBeforeStartDate
                                                        ).format(
                                                            'DD/MM/YYYY'
                                                        ) &&
                                                    todaysDate <= endDate &&
                                                    getReservationDetails?.status ===
                                                        'approved' &&
                                                    getReservationDetails?.onBehalf !==
                                                        false ? (
                                                        <Grid>
                                                            {' '}
                                                            <Divider />
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    paddingTop:
                                                                        '10px',
                                                                }}
                                                            ></Grid>
                                                            <>
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        paddingTop:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: !getReservationDetails
                                                                                    ?.module
                                                                                    ?.isAvailable
                                                                                    ? 'red'
                                                                                    : '#787878',
                                                                                fontWeight:
                                                                                    !getReservationDetails
                                                                                        ?.module
                                                                                        ?.isAvailable
                                                                                        ? 'normal'
                                                                                        : 'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {!getReservationDetails
                                                                                ?.module
                                                                                ?.isAvailable ? (
                                                                                `This experience is no longer listed, please contact the host`
                                                                            ) : (
                                                                                <>
                                                                                    {getIndividualAsset?.type ===
                                                                                    'commercial'
                                                                                        ? `Access Details:`
                                                                                        : `Checkin Details`}
                                                                                </>
                                                                            )}
                                                                        </Typography>{' '}
                                                                    </Grid>

                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                getIndividualAsset
                                                                                    ?.details
                                                                                    .checkinDetails
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                            {getIndividualAsset?.type ===
                                                                'property' ||
                                                            getIndividualAsset?.type ===
                                                                'boat' ? (
                                                                <>
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            paddingTop:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                WIFI
                                                                                Details:
                                                                            </Typography>{' '}
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    getIndividualAsset
                                                                                        ?.details
                                                                                        .wifiDetails
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Max
                                                                                Guests:
                                                                            </Typography>{' '}
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    getIndividualAsset
                                                                                        ?.details
                                                                                        .maxGuests
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {getIndividualAsset
                                                                        ?.details
                                                                        ?.subType !==
                                                                        'commercial' &&
                                                                        getIndividualAsset
                                                                            ?.details
                                                                            ?.subType !==
                                                                            'office space' && (
                                                                            <>
                                                                                <Grid
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            No
                                                                                            Beds:
                                                                                        </Typography>{' '}
                                                                                    </Grid>

                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                getIndividualAsset
                                                                                                    ?.details
                                                                                                    .beds
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            Sleeps:
                                                                                        </Typography>{' '}
                                                                                    </Grid>

                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                getIndividualAsset
                                                                                                    ?.details
                                                                                                    .overnightGuests
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                </>
                                                            ) : null}
                                                        </Grid>
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingBottom:
                                                                    '10px',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            {getReservationDetails?.status ===
                                                            'completed' ? (
                                                                <>
                                                                    *Experience
                                                                    information
                                                                    is no longer
                                                                    available
                                                                    after the
                                                                    experience
                                                                    has been
                                                                    completed.
                                                                </>
                                                            ) : getReservationDetails?.status ===
                                                                  'cancelled' ||
                                                              getReservationDetails?.status ===
                                                                  'declined' ? (
                                                                <>
                                                                    *Experience
                                                                    information
                                                                    is not
                                                                    available
                                                                    for
                                                                    cancelled or
                                                                    declined
                                                                    reservations.
                                                                </>
                                                            ) : (
                                                                <>
                                                                    *
                                                                    {getIndividualAsset?.type ===
                                                                    'property'
                                                                        ? 'Property information'
                                                                        : 'Experience information'}{' '}
                                                                    {''}will be
                                                                    shown (1)
                                                                    day before
                                                                    the approved
                                                                    reservation.{' '}
                                                                </>
                                                            )}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </>
                                        </AccordionDetails>
                                    </Accordion>
                                )}

                                {getReservationDetails?.charges?.length > 0 && (
                                    <Accordion
                                        expanded={expandedPanel3}
                                        onChange={() =>
                                            setExpandedPanel3(!expandedPanel3)
                                        }
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel2a-content'
                                            id='panel2a-header'
                                            sx={{
                                                backgroundColor:
                                                    expandedPanel3 === false
                                                        ? '#f6f8f9'
                                                        : '',
                                            }}
                                        >
                                            <Typography
                                                component='h3'
                                                variant='h6'
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 550,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Pricing & Fees
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {' '}
                                            <BookingCharges
                                                charges={
                                                    getReservationDetails?.charges
                                                }
                                                bookingTotal={
                                                    getReservationDetails?.bookingTotal
                                                }
                                                invoice={
                                                    getReservationDetails?.invoice
                                                }
                                                moduleType={
                                                    getReservationDetails
                                                        ?.module.type
                                                }
                                                module={getIndividualAsset}
                                                overnightGuestCount={
                                                    getReservationDetails
                                                        ?.bookingInfo
                                                        ?.overnightGuests
                                                }
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                )}

                                {getReservationDetails?.invoice && (
                                    <Accordion
                                        expanded={expandedPanel4}
                                        onChange={() =>
                                            setExpandedPanel4(!expandedPanel4)
                                        }
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel2a-content'
                                            id='panel2a-header'
                                            sx={{
                                                backgroundColor:
                                                    expandedPanel4 === false
                                                        ? '#f6f8f9'
                                                        : '',
                                            }}
                                        >
                                            <Typography
                                                component='h3'
                                                variant='h6'
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 550,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Payment History
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <PaymentHistory
                                                reservationDetails={
                                                    getReservationDetails
                                                }
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                )}

                                {getReservationDetails?.createdAt !== null ? (
                                    <Accordion
                                        expanded={expandedPanel5}
                                        onChange={() =>
                                            setExpandedPanel5(!expandedPanel5)
                                        }
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                            sx={{
                                                backgroundColor:
                                                    expandedPanel5 === false
                                                        ? '#f6f8f9'
                                                        : '',
                                            }}
                                        >
                                            <Typography
                                                component='h3'
                                                variant='h6'
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 550,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Reservation History
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                <Grid>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '0px',
                                                        }}
                                                    />
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                Created:
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                {createdAtDate}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {getReservationDetails?.editedAt !==
                                                    null ? (
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Last Edited:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {editedAt} -{' '}
                                                                    {
                                                                        getReservationDetails
                                                                            ?.editedBy
                                                                            ?.firstName
                                                                    }{' '}
                                                                    {
                                                                        getReservationDetails
                                                                            ?.editedBy
                                                                            ?.lastName
                                                                    }{' '}
                                                                    -{' '}
                                                                    {
                                                                        getReservationDetails
                                                                            ?.editedBy
                                                                            ?.email
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}
                                                    {getReservationDetails?.declinedAt !==
                                                    null ? (
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Declined:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {getReservationDetails
                                                                        ?.declinedBy
                                                                        ?.firstName ? (
                                                                        <>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                    textDecoration:
                                                                                        getReservationDetails
                                                                                            ?.approvedBy
                                                                                            ?.firstName
                                                                                            ? 'line-through'
                                                                                            : 'none',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    declinedDate
                                                                                }{' '}
                                                                                -{' '}
                                                                                {
                                                                                    getReservationDetails
                                                                                        ?.declinedBy
                                                                                        ?.firstName
                                                                                }{' '}
                                                                                {
                                                                                    getReservationDetails
                                                                                        ?.declinedBy
                                                                                        ?.lastName
                                                                                }{' '}
                                                                                (
                                                                                {
                                                                                    getReservationDetails
                                                                                        ?.owner
                                                                                        ?.name
                                                                                }

                                                                                )
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                    textDecoration:
                                                                                        getReservationDetails
                                                                                            ?.approvedBy
                                                                                            ?.firstName
                                                                                            ? 'line-through'
                                                                                            : 'none',
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    Declined
                                                                                    Reason:{' '}
                                                                                </span>
                                                                                {
                                                                                    getReservationDetails?.declineReason
                                                                                }{' '}
                                                                            </Typography>
                                                                            {/* If decline reason is other, display comment */}
                                                                            {getReservationDetails?.declineReason ===
                                                                                'Other' && (
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                        textDecoration:
                                                                                            getReservationDetails
                                                                                                ?.approvedBy
                                                                                                ?.firstName
                                                                                                ? 'line-through'
                                                                                                : 'none',
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Declined
                                                                                        Comment:{' '}
                                                                                    </span>
                                                                                    {
                                                                                        getReservationDetails?.declineComment
                                                                                    }{' '}
                                                                                </Typography>
                                                                            )}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingY:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                    textDecoration:
                                                                                        'none',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                {getReservationDetails
                                                                                    ?.approvedBy
                                                                                    ?.firstName
                                                                                    ? 'Decline reversed'
                                                                                    : ''}
                                                                            </Typography>
                                                                        </>
                                                                    ) : null}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                Approved:
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                {getReservationDetails
                                                                    ?.approvedBy
                                                                    ?.firstName
                                                                    ? `${approvedDate} - ${getReservationDetails?.approvedBy?.firstName} ${getReservationDetails?.approvedBy?.lastName} (${getReservationDetails?.owner.name})`
                                                                    : '-'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {getReservationDetails?.status ===
                                                    'completed' ? (
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Completed:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {
                                                                        completedDate
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}
                                                    {getReservationDetails?.cancelledAt !==
                                                    null ? (
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '30%'
                                                                        : '20%',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: '#787878',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    Cancelled:
                                                                </Typography>{' '}
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    width: isMobile
                                                                        ? '70%'
                                                                        : '80%',
                                                                }}
                                                            >
                                                                {getReservationDetails
                                                                    ?.cancelledBy
                                                                    ?.firstName ? (
                                                                    <>
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                cancelledDate
                                                                            }{' '}
                                                                            -{' '}
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.cancelledBy
                                                                                    ?.firstName
                                                                            }{' '}
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.cancelledBy
                                                                                    ?.lastName
                                                                            }{' '}
                                                                            (
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.cancelledBy
                                                                                    ?.companyName
                                                                            }
                                                                            )
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                Cancellation
                                                                                Reason:{' '}
                                                                            </span>
                                                                            {
                                                                                getReservationDetails?.cancellationReason
                                                                            }
                                                                        </Typography>{' '}
                                                                    </>
                                                                ) : null}
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </>
                                            {getReservationDetails?.policies
                                                ?.length > 0 && (
                                                <>
                                                    <Grid display='flex'>
                                                        <Grid
                                                            width={
                                                                isMobile
                                                                    ? '30%'
                                                                    : '20%'
                                                            }
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                Policies:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            width={
                                                                isMobile
                                                                    ? '70%'
                                                                    : '80%'
                                                            }
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                This reservation
                                                                has the
                                                                following
                                                                policies:
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {getReservationDetails?.policies?.map(
                                                        (
                                                            policy: ReservationPolicy
                                                        ) => (
                                                            <Grid
                                                                key={
                                                                    policy.policyId
                                                                }
                                                                display='flex'
                                                                justifyContent='flex-end'
                                                            >
                                                                <Grid
                                                                    width={
                                                                        isMobile
                                                                            ? '70%'
                                                                            : '80%'
                                                                    }
                                                                >
                                                                    <Typography
                                                                        fontSize='14px'
                                                                        fontFamily='Open Sans'
                                                                        color='#787878'
                                                                    >
                                                                        <li>
                                                                            {
                                                                                policy.name
                                                                            }
                                                                            :{' '}
                                                                            {policy.checked ? (
                                                                                <>
                                                                                    {`${policy.checkedBy?.firstName} ${policy.checkedBy?.lastName}`}{' '}
                                                                                    consented
                                                                                    at{' '}
                                                                                    {moment(
                                                                                        policy.checkedAt
                                                                                    ).format(
                                                                                        'DD/MM/YYYY, h:mm a'
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                'User did not consent'
                                                                            )}
                                                                        </li>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    )}
                                                </>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                ) : null}
                                {getReservationDetails?.module?.type ===
                                    'commercial' &&
                                loggedInUser?.role === 'owner_admin' &&
                                !getReservationDetails?.approvedAt ? (
                                    <Accordion
                                        expanded={expandedPanel5}
                                        onChange={() =>
                                            setExpandedPanel5(!expandedPanel5)
                                        }
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                            sx={{
                                                backgroundColor:
                                                    expandedPanel5 === false
                                                        ? '#f6f8f9'
                                                        : '',
                                            }}
                                        >
                                            <Typography
                                                component='h3'
                                                variant='h6'
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 550,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Availability
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                <Grid>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '0px',
                                                        }}
                                                    />
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                Dedicated Desks:
                                                            </Typography>{' '}
                                                            {availability?.desks <
                                                                getReservationDetails
                                                                    ?.bookingInfo
                                                                    ?.desks && (
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: 'red',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    You don't
                                                                    have enough
                                                                    desks
                                                                    available to
                                                                    approve this
                                                                    booking.
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                {
                                                                    availability?.desks
                                                                }
                                                                /
                                                                {
                                                                    getIndividualAsset
                                                                        ?.details
                                                                        ?.desks
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                Private Offices:
                                                            </Typography>{' '}
                                                            {availability?.privateOffices <
                                                                getReservationDetails
                                                                    ?.bookingInfo
                                                                    ?.privateOffices && (
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: 'red',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    You don't
                                                                    have enough
                                                                    private
                                                                    offices
                                                                    available to
                                                                    approve this
                                                                    booking.
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                {
                                                                    availability?.privateOffices
                                                                }
                                                                /
                                                                {
                                                                    getIndividualAsset
                                                                        ?.details
                                                                        ?.privateOffices
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                Meeting Rooms:
                                                            </Typography>{' '}
                                                            {availability?.meetingRooms <
                                                                getReservationDetails
                                                                    ?.bookingInfo
                                                                    ?.meetingRooms && (
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                        color: 'red',
                                                                        paddingBottom:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    You don't
                                                                    have enough
                                                                    meeting
                                                                    rooms
                                                                    available to
                                                                    approve this
                                                                    booking.
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                {
                                                                    availability?.meetingRooms
                                                                }
                                                                /
                                                                {
                                                                    getIndividualAsset
                                                                        ?.details
                                                                        ?.meetingRooms
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : null}
                                {getReservationDetails?.bookerType ===
                                    'guest' &&
                                (loggedInUser?.role.includes('owner') ||
                                    loggedInUser?.role === 'admin') ? (
                                    <Accordion
                                        expanded={expandedPanel6}
                                        onChange={() =>
                                            setExpandedPanel6(!expandedPanel6)
                                        }
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                            sx={{
                                                backgroundColor:
                                                    expandedPanel6 === false
                                                        ? '#f6f8f9'
                                                        : '',
                                            }}
                                        >
                                            <Typography
                                                component='h3'
                                                variant='h6'
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 550,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {
                                                    getReservationDetails?.owner
                                                        .name
                                                }{' '}
                                                Notes{' '}
                                                <VisibilityOffIcon
                                                    sx={{ opacity: 0.5 }}
                                                    fontSize='small'
                                                />
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        paddingTop: '10px',
                                                    }}
                                                >
                                                    <Grid
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <CustomTextField
                                                            placeholder=''
                                                            fullWidth
                                                            color='neutral'
                                                            variant='outlined'
                                                            onChange={
                                                                handleOwnerNotes
                                                            }
                                                            defaultValue={
                                                                getReservationDetails?.ownerNotes
                                                            }
                                                            sx={{
                                                                backgroundColor:
                                                                    '#f6f8f9',
                                                            }}
                                                            inputProps={{
                                                                color: '#787878',
                                                                border: 'none',
                                                                style: {
                                                                    height: '250px',
                                                                    color: '#787878',
                                                                    // backgroundColor: '#f6f8f9',
                                                                    border: 'none',
                                                                    // width: isMobile
                                                                    //     ? '350px'
                                                                    //     : isIpad
                                                                    //     ? '200px'
                                                                    //     : '40px',
                                                                },
                                                            }}
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'flex-end',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <CustomButton
                                                            variant='contained'
                                                            color='neutral'
                                                            // autoFocus
                                                            disabled={
                                                                ownerNotes ===
                                                                ''
                                                            }
                                                            onClick={
                                                                saveOwnerNotes
                                                            }
                                                        >
                                                            <Typography>
                                                                Save Notes
                                                            </Typography>
                                                        </CustomButton>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : null}
                                {loggedInUser?.role.includes('owner') ||
                                loggedInUser?.role === 'admin' ? (
                                    <ReservationAttachments
                                        bookingId={getReservationDetails?.id}
                                        attachments={
                                            getReservationDetails?.attachments
                                        }
                                    />
                                ) : null}
                            </Grid>
                            {/* 
                                In-app messaging
                            */}
                            {!(
                                loggedInUser?.role.includes('owner') &&
                                !getReservationDetails?.owner?.messagingUsers?.includes(
                                    loggedInUser.sub
                                )
                            ) && (
                                <ContactForm
                                    reservation={getReservationDetails}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: location?.state
                                ? 'space-between'
                                : 'flex-end',
                            alignItems: 'flex-start',
                            height: 'auto',
                            paddingTop: '50px',
                            paddingBottom: '50px',
                        }}
                    >
                        {location?.state && (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {location?.state?.component ===
                                'notifications' ? null : (
                                    <CustomButton
                                        onClick={onClickGoBack}
                                        color='green'
                                        sx={{
                                            marginLeft: !isMobile
                                                ? '-10px'
                                                : '-22px',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                            // alignItems: 'flex-end'
                                        }}
                                    >
                                        <ArrowBackIcon />
                                        {isMobile ? null : (
                                            <Typography
                                                sx={{
                                                    paddingLeft: '5px',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                Back
                                                {location?.state?.component ===
                                                'dashboard'
                                                    ? ' to list'
                                                    : location?.state
                                                          ?.component ===
                                                      'reports'
                                                    ? ' to report'
                                                    : location?.state
                                                          ?.component ===
                                                      undefined
                                                    ? null
                                                    : ` to ${location?.state?.component}`}
                                            </Typography>
                                        )}
                                    </CustomButton>
                                )}
                            </Grid>
                        )}

                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: isIpad
                                    ? 'column-reverse'
                                    : 'row',
                                justifyContent: 'flex-end',
                                gap: '5px',
                                alignItems: isIpad ? 'flex-end' : 'flex-start',
                            }}
                        >
                            {loggedInUser?.viewOnly && (
                                <Typography
                                    sx={{
                                        color: 'red',
                                        fontSize: '10px',
                                        maxWidth: '250px',
                                        textAlign: 'right',
                                    }}
                                >
                                    You do not have permission to edit this
                                    reservation. Please contact your
                                    administrator.
                                </Typography>
                            )}{' '}
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    gap: '10px',
                                }}
                            >
                                {getReservationDetails?.bookerType.includes(
                                    'guest'
                                ) &&
                                    loggedInUser?.role?.includes('guest') &&
                                    (getReservationDetails?.status ===
                                        'pending' ||
                                        getReservationDetails?.status ===
                                            'approved') &&
                                    getReservationDetails?.module
                                        ?.isAvailable === true && (
                                        <CustomButton
                                            variant='contained'
                                            color='neutral'
                                            disabled={loggedInUser?.viewOnly}
                                            onClick={editReservation}
                                        >
                                            <Typography>Edit</Typography>
                                        </CustomButton>
                                    )}
                                {(getReservationDetails?.status ===
                                    'approved' ||
                                    getReservationDetails?.status ===
                                        'pending' ||
                                    getReservationDetails?.status ===
                                        'completed') &&
                                    loggedInUser?.role.includes('owner') && (
                                        <CustomButton
                                            variant='contained'
                                            color='neutral'
                                            disabled={loggedInUser?.viewOnly}
                                            // autoFocus
                                            onClick={editReservation}
                                        >
                                            <Typography>Edit</Typography>
                                        </CustomButton>
                                    )}
                                {loggedInUser?.role === 'owner_admin' &&
                                (getReservationDetails?.status === 'pending' ||
                                    getReservationDetails?.status ===
                                        'declined') ? (
                                    <>
                                        {' '}
                                        <CustomButton
                                            variant='contained'
                                            color='green'
                                            disabled={
                                                disableApprove ||
                                                loggedInUser?.viewOnly
                                            }
                                            // autoFocus
                                            onClick={() => onClickApprove()}
                                        >
                                            <Typography>Approve </Typography>
                                        </CustomButton>
                                        {getReservationDetails?.status ===
                                        'declined' ? null : (
                                            <CustomButton
                                                variant='contained'
                                                color='warning'
                                                // autoFocus
                                                onClick={
                                                    onClickOpenDeclineModal
                                                }
                                                disabled={
                                                    getReservationDetails?.status ===
                                                        'declined' ||
                                                    loggedInUser?.viewOnly
                                                }
                                            >
                                                <Typography>
                                                    Decline{' '}
                                                </Typography>
                                            </CustomButton>
                                        )}
                                    </>
                                ) : null}
                                {loggedInUser?.role.includes('owner') &&
                                    getReservationDetails?.status !==
                                        'pending' &&
                                    getReservationDetails?.status !==
                                        'declined' &&
                                    getReservationDetails?.status !==
                                        'cancelled' && (
                                        <CustomButton
                                            variant='contained'
                                            color='warning'
                                            disabled={loggedInUser?.viewOnly}
                                            // autoFocus
                                            onClick={onClickOpenModal}
                                        >
                                            <Typography>Cancel</Typography>
                                        </CustomButton>
                                    )}
                                {(getReservationDetails?.status ===
                                    'approved' ||
                                    getReservationDetails?.status ===
                                        'pending') &&
                                    loggedInUser?.role.includes('guest') &&
                                    loggedInUser?.viewOnly !== true && (
                                        <CustomButton
                                            variant='contained'
                                            color='warning'
                                            // autoFocus
                                            onClick={onClickOpenModal}
                                            disabled={loggedInUser?.viewOnly}
                                        >
                                            <Typography>
                                                Cancel
                                             
                                            </Typography>
                                        </CustomButton>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <CancelReservationModal
                        open={open}
                        onClose={handleCloseModal}
                        onChange={handleChangeModal}
                        message={'cancel this reservation'}
                        component={'reservation'}
                        id={getReservationDetails?.id}
                    />
                    <DeclineReservationModal
                        open={openDeclineModal}
                        onClose={handleCloseDeclineModal}
                        onChange={handleChangeDeclineModal}
                        message={'decline this reservation'}
                        component={'reservation'}
                        id={getReservationDetails?.id}
                    />
                </Grid>
            )}
        </Grid>
    );
}
