import React, { useState } from 'react';
import { ReservationPolicy as Policy } from '../../../types/policy';
import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    ListItem,
    ListItemSecondaryAction,
    Stack,
    Typography,
} from '@mui/material';
import { capitalizeName } from '../../../../shared/utils';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import ReservationPolicyModal from '../../Policies/PolicyModal';
import { useDispatch } from 'react-redux';
import { toggleConsent } from '../../../../app/Slices/ReservationPoliciesSlice';
import { CustomCheckbox } from '../../../../layout/styledcomponents/CustomCheckbox';
import FormCheckbox from '../../../shared/FormCheckbox';
import { Control, useFormContext } from 'react-hook-form';
import { get } from 'lodash';

interface Props {
    policy: Policy;
    control: Control<any>;
    index: number,
}

const ReservationPolicy = ({ policy, control, index }: Props) => {
    const { formState, setValue } = useFormContext();

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleConsent = () => {
        setValue(`policies[${index}].checkedAt`, new Date().toISOString());
    };

    const checkedError = get(formState.errors, `policies[${index}].checked`);

    return (
        <>
            <ListItem key={index} disablePadding sx={{ padding: 0 }}>
                <FormControl>
                    <Box display='flex' alignItems='center' sx={{ margin: 0 }}>
                        <FormCheckbox 
                            name={`policies[${index}].checked`}
                            control={control}
                            onClick={handleConsent}
                            sx={{
                                padding: 0,  // Removes any padding inside the checkbox
                                margin: 0,   // Removes any margin on the checkbox
                            }}
                        />
                        <Typography fontFamily='Open Sans' color='#787878'  sx={{ marginLeft: 1 }}>
                            {capitalizeName(policy.name)}
                      
                        </Typography>
                    </Box>
                    {checkedError && (
                        <FormHelperText error  sx={{ marginLeft: 0 }}>
                            {checkedError.message as string}
                        </FormHelperText>
                    )}
                </FormControl>
                <ListItemSecondaryAction sx={{ right: 0 }}>
                    <CustomButton
                        color='green'
                        onClick={() => setModalOpen(true)}
                    >
                        See details
                    </CustomButton>
                </ListItemSecondaryAction>
            </ListItem>
            <ReservationPolicyModal
                policy={policy}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            />
        </>
    );
};

export default ReservationPolicy;
