import { Box, IconButton, styled } from '@mui/material';
import { UUID } from 'crypto';
import React from 'react';
import { Slide } from 'react-slideshow-image';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';

interface Photo {
    id: UUID;
    src: string;
}

interface ExperiencePhotosProps {
    photos?: Photo[];
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: 'white',
    margin: theme.spacing(1),
}));

const css = `
    .slide {
        border-radius: 8px;
    }
`;

const ExperiencePhotos = ({ photos }: ExperiencePhotosProps) => {
    return (
        <Box>
            <style>{css}</style>
            <Slide
                cssClass='slide'
                autoplay={false}
                easing='ease'
                transitionDuration={300}
                nextArrow={
                    <CustomIconButton size='small'>
                        <NavigateNext fontSize='small' />
                    </CustomIconButton>
                }
                prevArrow={
                    <CustomIconButton size='small'>
                        <NavigateBefore fontSize='small' />
                    </CustomIconButton>
                }
            >
                {photos?.map((photo: Photo) => (
                    <img
                        key={photo.id}
                        src={photo.src}
                        width='100%'
                        height='100%'
                        style={{ objectFit: 'cover' }}
                    />
                ))}
            </Slide>
        </Box>
    );
};

export default ExperiencePhotos;
