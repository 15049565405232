import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Divider,
    Grid,
    List,
    ListItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useAddIndividual7DayAdminMutation,
    useAddIndividualGuestUsersMutation,
    useAddIndividualOwnerUsersMutation,
    useGetTeamListQuery,
    useGetUserListQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../../layout/styledcomponents/filters/Dropdown';
import UserRole from '../../../../shared/AuthRoles';
import { useExitPrompt } from '../../../../shared/LeavingPageWarning';
import { validatEmail, validatePhone } from '../../../../shared/utils';
import toast from '../../../shared/Toast';

export interface Teams {
    id: number;
    name: string;
}

export default function NewUserForm() {
    const [url, setUrl] = useState('');
    const { data: getUsers } = useGetUserListQuery(url);
    const [addNewOwnerUser] = useAddIndividualOwnerUsersMutation();
    const [addNewGuestUser] = useAddIndividualGuestUsersMutation();
    const [addNew7DayAdmin] = useAddIndividual7DayAdminMutation();
    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
    const loggedInUser = UserRole();
    const [isDuplicateEmail, setDuplicateEmail] = useState(false);
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [contactFirst, setContactFirst] = useState('');
    const [contactLast, setContactLast] = useState('');
    const [access, setAccess] = useState(
        loggedInUser?.role === 'admin' ? 'admin' : ''
    );
    const [status, setStatus] = useState('Enabled');
    const [emailAddress, setEmailAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [team, setTeam] = useState<any>(null);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);
    //Get List of Teams for dropdown selection
    const [teamList, setTeamList] = useState<any>([]);
    const [teamId, setTeamId] = useState({
        value: null,
        label: '',
    });
    const { data: getTeams } = useGetTeamListQuery(loggedInUser?.company);
    const teams: Teams[] = getTeams ?? [];

    useEffect(() => {
        let allTeams: any = teams?.map((val: any) => {
            return val?.owner?.name
                ? {
                      value: val?.id,
                      label:
                          val?.name?.charAt(0)?.toUpperCase() +
                          val?.name.slice(1),
                  }
                : {
                      value: val?.id,
                      label:
                          val?.name?.charAt(0)?.toUpperCase() +
                          val?.name.slice(1),
                  };
        });

        let filterOutDuplicates = allTeams?.filter(
            (value: any, index: any, self: any) =>
                index ===
                self.findIndex(
                    (t: any) =>
                        t?.label === value?.label && t?.label === value?.label
                )
        );

        setTeamList(filterOutDuplicates);
    }, [getTeams]);

    const handleTeamId = (value: any) => {
        if (value === '') {
            navigate('/teams');
            return;
        } else {
            let obj = teamList.find((o: any) => o.value === value);
            setTeamId({
                value: value,
                label:
                    obj?.label?.charAt(0)?.toLowerCase() + obj?.label.slice(1),
            });
            setTeam({
                id: value,
            });
        }
    };

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setUrl(`/owners/${loggedInUser?.company}/users`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setUrl(`/guests/${loggedInUser?.company}/users`);
        } else {
            setUrl(`/7-day/users`);
        }
    }, [loggedInUser]);

    const formDetailsOwner = {
        email: emailAddress,
        firstName: contactFirst,
        lastName: contactLast,
        phone: phone,
        isActive: status === 'Enabled' ? true : false,
        role: access === 'admin' ? 'owner_admin' : 'owner_user',
        ...(access === 'admin'
            ? {}
            : access === 'viewOnlyUser'
            ? { viewOnly: true }
            : { viewOnly: false }),
    };

    const formDetailsGuest = {
        email: emailAddress,
        firstName: contactFirst,
        lastName: contactLast,
        phone: phone,
        isActive: status === 'Enabled' ? true : false,
        department: team,
        role:
            access === 'admin'
                ? 'guest_admin'
                : access === 'teamAdmin'
                ? 'guest_department_admin'
                : 'guest_user',
        ...(access === 'admin'
            ? {}
            : access === 'viewOnlyUser'
            ? { viewOnly: true }
            : { viewOnly: false }),
    };

    const formDetails7DayAdmin = {
        email: emailAddress,
        firstName: contactFirst,
        lastName: contactLast,
        phone: phone,
        isActive: status === 'Enabled' ? true : false,
        // role: access === 'admin' ? 'guest_admin' : 'guest_user'
    };

    const onClickGoBack = () => {
        navigate(`/users`);
    };

    const errors: any = [];

    if (contactFirst.length === 0) {
        errors.push('Name cannot be empty');
    }
    if (contactLast.length === 0) {
        errors.push('Surname cannot be empty');
    }
    if (phone.length === 0) {
        errors.push('Phone cannot be empty');
    }
    if (access.length === 0) {
        errors.push('Access cannot be empty');
    }
    if (status.length === 0) {
        errors.push('Status cannot be empty');
    }
    if (emailAddress.length === 0) {
        errors.push('Email Address cannot be empty');
    }
    if (access === 'teamAdmin' && !team) {
        errors.push('Team cannot be empty');
    }

    useEffect(() => {
        return () => {
            setShowExitPrompt(false);
        };
    }, []);

    const handleFirstName = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setContactFirst(event.target.value);

        setShowExitPrompt(true);
    };

    const handleLastName = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setContactLast(event.target.value);
        setShowExitPrompt(true);
    };
    const handleAccess = (value: any) => {
        setAccess(value);
        setShowExitPrompt(true);
    };

    const handleStatus = (value: any) => {
        setStatus(value);
        setShowExitPrompt(true);
    };

    //Retrieve users and compare account.email with the new user email
    const mapOverUsers = getUsers?.map(
        (account: { email: any }) => account?.email
    );

    const handleEmailAddress = (event: any) => {
        // setEmailAddress(event.target.value);
        setShowExitPrompt(true);
        if (validatEmail(event?.target?.value) === false) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
        //Find Duplicate User
        const findDuplicate = mapOverUsers?.find(
            (i: any) => i === event?.target?.value
        );
        if (
            findDuplicate !== undefined &&
            findDuplicate !== getUsers?.account?.email
        ) {
            setDuplicateEmail(true);
            setEmailAddress('');
        } else if (findDuplicate === getUsers?.account?.email) {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value?.toLowerCase());
        } else {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value?.toLowerCase());
        }
    };
    const handlePhone = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPhone(event.target.value);
        setShowExitPrompt(true);
        if (validatePhone(event.target.value) === false) {
            setIsValidPhone(true);
        } else {
            setIsValidPhone(false);
        }
    };

    const navigate = useNavigate();

    const saveNewOwnerUser = () => {
        const requestBody = formDetailsOwner;
        let requestData = [loggedInUser?.company, requestBody];

        addNewOwnerUser(requestData).then((data: any) => {
            if (data) {
                if (data?.error) {
                    if (data?.error?.data?.error.includes('duplicate')) {
                        toast.error(
                            `Users email ${formDetailsGuest.email} already exists under another company profile`
                        );
                    } else if (
                        data?.error?.data?.error.includes(
                            'users.invalid_domain'
                        )
                    ) {
                        toast.error(`${data?.error?.data?.message}`);
                    } else {
                        toast.error(
                            `Failed to create user: ${formDetailsGuest?.firstName}`
                        );
                    }
                } else {
                    toast.success(
                        `New User: ${formDetailsGuest?.firstName} has been created`
                    );
                    navigate(
                        `/users/${formDetailsGuest?.firstName}/${data?.data.id}`,
                        {
                            state: {
                                name: 'newlyAddedUser',
                            },
                        }
                    );
                }
            } else {
                if (data?.error?.data?.error.includes('duplicate')) {
                    toast.error(
                        `Users email ${formDetailsGuest.email} exists under another company profile`
                    );
                } else {
                    toast.error(
                        `Failed to create user: ${formDetailsGuest?.firstName}`
                    );
                }
            }
        });
    };

    const saveNewGuestUser = () => {
        const requestBody = formDetailsGuest;
        let requestData = [loggedInUser?.company, requestBody];
        addNewGuestUser(requestData).then((data: any) => {
            if (data) {
                if (data?.error) {
                    if (data?.error?.data?.error.includes('duplicate')) {
                        toast.error(
                            `Users email ${formDetailsGuest.email} exists under another company profile`
                        );
                    } else if (
                        data?.error?.data?.error.includes(
                            'users.invalid_domain'
                        )
                    ) {
                        toast.error(`${data?.error?.data?.message}`);
                    } else {
                        toast.error(
                            `Failed to create user: ${formDetailsGuest?.firstName}`
                        );
                    }
                } else {
                    toast.success(
                        `New User: ${formDetailsGuest?.firstName} has been created`
                    );

                    navigate(
                        `/users/${formDetailsGuest?.firstName}/${data?.data.id}`,
                        {
                            state: {
                                name: 'newlyAddedUser',
                            },
                        }
                    );
                }
            } else {
                if (data?.error?.data?.error.includes('duplicate')) {
                    toast.error(
                        `Users email ${formDetailsGuest.email} exists under another company profile`
                    );
                } else {
                    toast.error(
                        `Failed to create user: ${formDetailsGuest?.firstName}`
                    );
                }
            }
        });
    };

    const saveNew7DayAdmin = () => {
        const requestBody = formDetails7DayAdmin;

        addNew7DayAdmin(requestBody).then((data: any) => {
            if (data) {
                if (data?.error) {
                    if (data?.error?.data?.error.includes('duplicate')) {
                        toast.error(
                            `Users email ${formDetailsGuest.email} exists under another company profile`
                        );
                    } else {
                        toast.error(
                            `Failed to create user: ${formDetailsGuest?.firstName}`
                        );
                    }
                } else {
                    toast.success(
                        `New User: ${formDetails7DayAdmin?.firstName} has been created`
                    );

                    navigate(
                        `/users/${formDetailsGuest?.firstName}/${data?.data.id}`,
                        {
                            state: {
                                name: 'newlyAddedUser',
                            },
                        }
                    );
                }
            } else {
                if (data?.error?.data?.error.includes('duplicate')) {
                    toast.error(
                        `Users email ${formDetailsGuest.email} exists under another company profile`
                    );
                } else {
                    toast.error(
                        `Failed to create user: ${formDetailsGuest?.firstName}`
                    );
                }
            }
        });
    };

    return (
        <Grid sx={{ width: '100%', height: '100%' }}>
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: isIpad ? '0px' : '30px',
                    height: 'auto',
                    // backgroundColor: 'blue'
                }}
            >
                <Typography
                    component='h4'
                    // variant='h6'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        fontWeight: 900,
                        paddingTop: isMobile || isIpad ? '30px' : '0px',
                        paddingBottom: '30px',
                    }}
                >
                    User Details
                </Typography>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            First Name
                            {contactFirst === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}{' '}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            placeholder='John'
                            value={contactFirst}
                            onChange={handleFirstName}
                            fullWidth
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Surname
                            {contactLast === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}{' '}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            value={contactLast}
                            placeholder='Smith'
                            onChange={handleLastName}
                            fullWidth
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        {' '}
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Phone
                            {phone === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            placeholder='0414789456'
                            type='tel'
                            value={phone}
                            onChange={handlePhone}
                            fullWidth
                            color={isValidPhone ? 'red' : 'green'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    {loggedInUser?.role.includes('guest') ? (
                        <>
                            <Grid
                                sx={{
                                    width: '20%',
                                    height: isMobile || isIpad ? '' : '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <>
                                    {' '}
                                    <Grid
                                        sx={{
                                            marginRight: '5px',
                                            paddingTop:
                                                isMobile || isIpad
                                                    ? '5px'
                                                    : '0px',
                                        }}
                                    >
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Team
                                            {access === 'teamAdmin' && !team ? (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    *
                                                </span>
                                            ) : null}{' '}
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    // color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    <List>
                                                        By selecting* the users
                                                        team, they will appear
                                                        in the 'Team Managers'
                                                        profile. Team Managers
                                                        will have access to view
                                                        and manage users that
                                                        belong to their team as
                                                        well as view and edit
                                                        any reservations that
                                                        belong to them.
                                                    </List>
                                                    <List>
                                                        * Only a company admin
                                                        can change a users Team.
                                                    </List>
                                                </Typography>
                                            }
                                            placement='right'
                                        >
                                            <HelpOutlineIcon
                                                sx={{
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                </>
                            </Grid>

                            <Grid
                                sx={{
                                    width:
                                        isMobile || isIpad ? '100%' : '29.8%',
                                    height:
                                        isMobile || isIpad ? '60px' : '50px',
                                }}
                            >
                                {loggedInUser?.role ===
                                'guest_department_admin' ? (
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        This user will be assigned the same Team
                                        as you.
                                    </Typography>
                                ) : (
                                    <DropdownFilter
                                        id={'TeamList'}
                                        options={[
                                            {
                                                value: '',
                                                label: '+ Add Team',
                                            },
                                        ].concat(
                                            teamList !== undefined
                                                ? teamList
                                                : []
                                        )}
                                        handleChange={handleTeamId}
                                        selectedValue={
                                            <Typography
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                {teamId?.label
                                                    ?.charAt(0)
                                                    ?.toUpperCase() +
                                                    teamId?.label.slice(1)}
                                            </Typography>
                                        }
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                )}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                sx={{
                                    width: '20%',
                                    height: isMobile || isIpad ? '0px' : '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            ></Grid>{' '}
                            <Grid
                                sx={{
                                    width:
                                        isMobile || isIpad ? '100%' : '29.8%',
                                    height: isMobile || isIpad ? '0px' : '50px',
                                }}
                            ></Grid>
                        </>
                    )}
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ marginRight: '5px' }}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Access
                                {access === '' ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}{' '}
                            </Typography>
                        </Grid>

                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        // color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                {' '}
                                                Company Admin: Add/Edit/View
                                                Listings, Users and Reservations
                                                for the entire company.
                                            </ListItem>
                                            <ListItem>
                                                {' '}
                                                Team Admin: Add/Edit/View
                                                Listings, Users and Reservations
                                                for their Team only.
                                            </ListItem>
                                            <ListItem>
                                                {' '}
                                                User: Add/Edit/View Reservations
                                                for themselves & view listings
                                            </ListItem>
                                            {loggedInUser?.role.includes(
                                                'guest'
                                            ) ? (
                                                <ListItem>
                                                    View Only User: View
                                                    listings & view reservations
                                                    made for them
                                                </ListItem>
                                            ) : null}
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <DropdownFilter
                            id={'userAccess'}
                            options={
                                loggedInUser?.role === 'admin'
                                    ? [{ value: 'admin', label: 'Admin' }]
                                    : loggedInUser.role.includes('guest')
                                    ? loggedInUser?.role ===
                                      'guest_department_admin'
                                        ? [
                                              {
                                                  value: 'user',
                                                  label: 'User',
                                                  isDefault: true,
                                              },
                                              {
                                                  value: 'viewOnlyUser',
                                                  label: 'View Only User',
                                              },
                                          ]
                                        : [
                                              {
                                                  value: 'admin',
                                                  label: 'Company Admin',
                                              },
                                              {
                                                  value: 'teamAdmin',
                                                  label: 'Team Admin',
                                              },
                                              {
                                                  value: 'user',
                                                  label: 'User',
                                                  isDefault: true,
                                              },
                                              {
                                                  value: 'viewOnlyUser',
                                                  label: 'View Only User',
                                              },
                                          ]
                                    : [
                                          { value: 'admin', label: 'Admin' },
                                          {
                                              value: 'user',
                                              label: 'User',
                                              isDefault: true,
                                          },
                                          {
                                              value: 'viewOnlyUser',
                                              label: 'View Only User',
                                          },
                                      ]
                            }
                            handleChange={handleAccess}
                            selectedValue={
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    {access === 'viewOnlyUser'
                                        ? 'View Only User'
                                        : access === 'teamAdmin'
                                        ? 'Team Admin'
                                        : access}
                                </Typography>
                            }
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                            // disable={loggedInUser?.role ==='admin'}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ marginRight: '5px' }}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Status
                                {status === '' ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}{' '}
                            </Typography>
                        </Grid>

                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        // color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                {' '}
                                                Enabled: Users can access the
                                                site and perform actions based
                                                on their level of access
                                            </ListItem>
                                            <ListItem>
                                                {' '}
                                                Disabled: Users will not be able
                                                to access the site
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '29.8%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <DropdownFilter
                            id={'userStatus'}
                            options={[
                                { value: 'Enabled', label: 'Enabled' },
                                {
                                    value: 'Disabled',
                                    label: 'Disabled',
                                    isDefault: true,
                                },
                            ]}
                            handleChange={handleStatus}
                            selectedValue={
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    {status}
                                </Typography>
                            }
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                        {/* <CustomTextField
                            placeholder='Active'
                            onChange={handleStatus}
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        /> */}
                    </Grid>
                </Grid>
            </Grid>
            {isMobile || isIpad ? null : <Divider sx={{ marginTop: '10px' }} />}

            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                }}
            >
                <Typography
                    component='h4'
                    // variant='h6'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        fontWeight: 900,
                        paddingTop: isMobile
                            ? '10px'
                            : isIpad
                            ? '10px'
                            : '30px',
                        height: '10%',
                    }}
                >
                    Users Login Details
                </Typography>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        width: '100%',
                        paddingTop: '30px',
                    }}
                >
                    <Grid sx={{ width: '20%', height: '50px' }}>
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Email{' '}
                            {emailAddress === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    {isValidEmail === true && isMobile ? (
                        <Typography
                            sx={{
                                fontSize: '10px',
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            <CloseIcon
                                sx={{
                                    fontSize: '12px',
                                    paddingRight: '5px',
                                    color: 'red',
                                }}
                            />{' '}
                            Invalid email
                        </Typography>
                    ) : null}
                    <Grid
                        sx={{
                            width: '30%',
                            height: '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            error={isDuplicateEmail}
                            label={
                                isDuplicateEmail === true
                                    ? 'Error: User already exists'
                                    : ''
                            }
                            // placeholder='user@example.com'
                            value={!isDuplicateEmail ? emailAddress : null}
                            // fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleEmailAddress}
                            color={isValidEmail ? 'green' : 'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? '350px'
                                        : isIpad
                                        ? '200px'
                                        : '400px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid sx={{ width: '20%', height: '50px' }}></Grid>
                    <Grid sx={{ width: '30%', height: '50px' }}></Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                        height: isMobile ? '0px' : isIpad ? '180px' : '200px',
                    }}
                >
                    <>
                        {' '}
                        <Grid
                            sx={{
                                width: isMobile ? '100%' : '20%',
                                height: '50px',
                            }}
                        ></Grid>
                        <Grid
                            sx={{
                                width: '30%',
                                height: '50px',
                                marginRight:
                                    !isMobile || !isIpad ? '50px' : '0px',
                            }}
                        >
                            {isValidEmail === true && !isMobile ? (
                                <Typography
                                    sx={{
                                        fontSize: '10px',
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    <List>
                                        <ListItem>
                                            <CloseIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'red',
                                                }}
                                            />{' '}
                                            Invalid email
                                        </ListItem>{' '}
                                    </List>{' '}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid
                            sx={{
                                width: '20%',
                                height: '50px',
                            }}
                        ></Grid>
                    </>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        height: 'auto',
                        paddingTop: '50px',
                        paddingBottom: '50px',
                    }}
                >
                    <Grid
                        sx={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {' '}
                        <CustomButton
                            onClick={onClickGoBack}
                            color='green'
                            sx={{
                                marginLeft: !isMobile ? '-8px' : '-18px',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5cb5b3',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <ArrowBackIcon />{' '}
                            {isMobile ? null : (
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                        paddingLeft: '5px',
                                    }}
                                >
                                    Back to users
                                </Typography>
                            )}
                        </CustomButton>
                    </Grid>

                    <Grid
                        sx={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CustomButton
                            variant='contained'
                            color='green'
                            // fullWidth={isMobile||isIpad}
                            onClick={
                                loggedInUser?.role === 'owner_company' ||
                                loggedInUser?.role === 'owner_admin'
                                    ? saveNewOwnerUser
                                    : loggedInUser?.role === 'guest_company' ||
                                      loggedInUser?.role === 'guest_admin' ||
                                      loggedInUser?.role ===
                                          'guest_department_admin'
                                    ? saveNewGuestUser
                                    : loggedInUser?.role === 'admin'
                                    ? saveNew7DayAdmin
                                    : null
                            }
                            disabled={
                                isValidEmail ||
                                isValidPhone ||
                                isDuplicateEmail ||
                                errors.length > 0
                            }
                            sx={{
                                top: isMobile || isIpad ? '10%' : '50%',
                            }}
                        >
                            Save
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
