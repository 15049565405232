import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ThreadHeaderProps, useChannelStateContext } from 'stream-chat-react';
import { Clear } from '@mui/icons-material';

const ThreadHeader = ({ closeThread }: ThreadHeaderProps) => {
    const { channel } = useChannelStateContext();

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            px={2}
            py={2}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    width: '100%',
                }}
            >
                <div>
                    <Typography
                        variant='h6'
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '18px',
                            color: 'text.primary',
                        }}
                    >
                        Thread
                    </Typography>
                    <Typography
                        sx={{ fontSize: '14px', color: 'text.secondary' }}
                    >
                        {channel?.data?.name}
                    </Typography>
                </div>
                <IconButton onClick={closeThread}>
                    <Clear />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ThreadHeader;
