import {
    Accordion,
    AccordionSummary,
    Badge,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PolicyIcon from '@mui/icons-material/Policy';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import UserRole from '../../../shared/AuthRoles';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
    useGetTeamListQuery,
    useGetUserListQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import { Message } from '@mui/icons-material';
import { CreditCard } from '@mui/icons-material';

export default function AdminCenterList() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();
    const loggedInUser = UserRole();
    const [url, setUrl] = useState('');
    const { data: getUsers } = useGetUserListQuery(url);
    const { data: getTeams } = useGetTeamListQuery(loggedInUser?.company);
    const handleChange = (url: string) => (event: any) => {
        if (event?.type) {
            navigate(url);
            ReactGA.send({
                hitType: 'pageview',
                page: url,
                title: url,
            });
            return;
        }
    };

    useEffect(() => {
        if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setUrl(`/guests/${loggedInUser?.company}/users`);
        } else {
            return;
        }
    }, [loggedInUser]);

    const settingsList =
        loggedInUser?.role === 'owner_admin' ||
        loggedInUser?.role === 'owner_company'
            ? [
                  {
                      settingName: 'Booking Caps',
                      settingDescription:
                          'Set limits on how many times a user or their organization can book your experience within a timeframe you choose, giving you full control over availability!',
                      settingUrl: '/bookingCaps',
                      settingIcon: <CalendarMonthIcon />,
                  },
                  {
                      settingName: 'Fee Types',
                      settingDescription:
                          'Create custom fee types and apply them to your experience, ensuring users are informed of any additional costs upfront!',
                      settingUrl: '/fees',
                      settingIcon: <AttachMoneyIcon />,
                  },
                  {
                      settingName: 'Your Policies',
                      settingDescription:
                          'Create a custom policy for your experience, making your terms clear and easy for users to understand!',
                      settingUrl: '/policies',
                      settingIcon: <PolicyIcon />,
                  },
                  {
                      settingName: 'In-app Messaging',
                      settingDescription:
                          'In-app messaging makes communication effortless, streamlining bookings, inquiries, and updates. Easily assign support users for a seamless experience!',
                      settingUrl: '/messaging',
                      settingIcon: <Message />,
                  },
                  {
                      settingName: 'Reservation Earnings',
                      settingDescription:
                          'Get a clear overview of your earnings from all your listed experiences, right at your fingertips!',
                      settingUrl: '/earnings',
                      settingIcon: <AttachMoneyIcon />,
                  },

              ]
            : loggedInUser?.role === 'admin'
            ? [
                  {
                      settingName: '7DAY App Fees',
                      settingDescription:
                          'Add, edit and manage 7DAY App Fees to Hosts and Guests',
                      settingUrl: '/7dayfees',
                      settingIcon: <AttachMoneyIcon />,
                  },
                  {
                      settingName: 'Bills and Invoices Configurations',
                      settingDescription:
                          ' Manage Guest Invoices and Host Bills Settings',
                      settingUrl: '/bills-invoices',
                      settingIcon: <AttachMoneyIcon />,
                  },
                  {
                      settingName: 'Users',
                      settingDescription:
                          'Add, edit and manage users from your organisation',
                      settingUrl: '/users',
                      settingIcon: <PeopleAltIcon />,
                  },
              ]
            : [
                  {
                      settingName: 'Teams',
                      settingDescription:
                          'Add, edit and manage teams to better control who has access to view/edit users and user reservations within your organisation',
                      settingUrl: '/teams',
                      settingIcon: <AccountTreeIcon />,
                  },
                  {
                      settingName: 'Users',
                      settingDescription:
                          'Add, edit and manage users from your organisation',
                      settingUrl: '/users',
                      settingIcon: <PeopleAltIcon />,
                  },
                  {
                      settingName: 'Billing',
                      settingDescription:
                          'Add, edit and manage billing account, and view invoices.',
                      settingUrl: '/billing',
                      settingIcon: <CreditCard />,
                  },
              ];

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid
                sx={{
                    textAlign: isMobile ? 'left' : 'right',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'left' : 'right',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                    }}
                ></Grid>
            </Grid>
            <Grid>
                <Grid
                    sx={{
                        height: isMobile ? '100%' : '100px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {/* {getUsers?.length === 1 || getTeams?.length === 0 ? (
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: 'red',
                            }}
                        >
                            *Please finish setting up your account.
                        </Typography>
                    ) : null} */}
                </Grid>

                {settingsList.map((item: any) => (
                    <Accordion
                        expanded={false}
                        onChange={handleChange(item?.settingUrl)}
                        key={item?.settingName}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowRightIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            // sx={{
                            //     backgroundColor:
                            //         expanded === item.panel ? '#f6f8f9' : '',
                            // }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                <ListItem>
                                    <ListItemAvatar>
                                        {(item?.settingName === 'Users' &&
                                            getUsers?.length === 1) ||
                                        (item?.settingName === 'Teams' &&
                                            getTeams?.length === 0) ? (
                                            <Badge
                                                badgeContent={'!'}
                                                color='error'
                                            >
                                                <Avatar>
                                                    {item?.settingIcon}
                                                </Avatar>
                                            </Badge>
                                        ) : (
                                            <Avatar>{item?.settingIcon}</Avatar>
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item?.settingName}
                                        secondary={item?.settingDescription}
                                    />
                                </ListItem>
                            </Typography>
                        </AccordionSummary>
                        {/* <AccordionDetails>{item.settingDescription}</AccordionDetails> */}
                    </Accordion>
                ))}
                {/* </List> */}
            </Grid>
        </Grid>
    );
}
