import { KeyboardArrowDown } from '@mui/icons-material';
import { Autocomplete, TextField, AutocompleteProps } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface FormAutocompleteProps<T>
    extends Omit<
        AutocompleteProps<T, boolean | undefined, true, boolean | undefined>,
        'renderInput'
    > {
    name: string;
    options: Array<any>;
    control: Control<any>;
    label?: string;
    customError?: string;
    placeholder?: string;
    shrinkLabel?: boolean;
    getOptionDisabled?: (option: any) => boolean;
    filterOptions?: (options: Array<any>, state: object) => Array<any>;
    startAdornment?: React.JSX.Element;
}

/**
 * For use within RHF FormProvider
 */
const FormAutocomplete = <T,>({
    name,
    control,
    options,
    label,
    placeholder,
    customError,
    startAdornment,
    shrinkLabel,
    ...props
}: FormAutocompleteProps<T>) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { onChange, value, ref },
                fieldState: { error },
            }) => (
                <Autocomplete
                    size='small'
                    options={options}
                    onChange={(_, data) => onChange(data?.value)}
                    disableClearable
                    value={
                        options.find((option) => option.value === value) ?? null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputRef={ref}
                            label={label}
                            error={!!error || !!customError}
                            helperText={(error && error.message) || customError}
                            placeholder={placeholder}
                            InputLabelProps={{ shrink: shrinkLabel }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment,
                            }}
                        />
                    )}
                    popupIcon={<KeyboardArrowDown />}
                    {...props}
                />
            )}
        />
    );
};

export default FormAutocomplete;
