import { Box, Button, Checkbox, Stack, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton'
import { AddCircle } from '@mui/icons-material'
import ExperiencePoliciesForm from './ExperiencePoliciesForm'
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton'

import { useFormContext, useFieldArray, useWatch } from 'react-hook-form'
import { FormValues } from '../../../types/experience'

interface Props {
    experienceType: string
}

const ExperiencePolicies = ({ experienceType }: Props) => {
    const isMobile = useMediaQuery('(max-width: 770px)');

    const { control, setValue } = useFormContext<FormValues>();
    const { fields, append, remove } = useFieldArray<FormValues, 'policies'>({
        control,
        name: 'policies',
    })

    const policies = useWatch({ control, name: 'policies' });
    const hasPolicies = useWatch({ control, name: 'hasPolicies' });

    const handleAddPolicy = () => {
        const policy = {
            policyId: undefined,
            requiresConsent: false,
        };
        append(policy);
    };

    const handleCheck = () => {
        setValue('hasPolicies', !hasPolicies);

        // remove unfinished policies to prevent validation errors
        policies.forEach((policy, index) => {
            if (!policy.policyId)
                remove(index);
        })
    }

    return (
        <Stack>
            <Typography
                component='h4'
                color='textPrimary'
                fontWeight='bold'
                pb='20px'
            >
                Policies
                {hasPolicies && (
                    <span style={{ color: 'red', fontWeight: 500 }}>*</span>
                )}
            </Typography>
            <Box display='flex' gap={1}>
                <Checkbox 
                    checked={hasPolicies}
                    onChange={handleCheck}
                    sx={{p: 0}} 
                />
                <Typography
                    color='#787878'
                    fontFamily='Open Sans'
                >
                    This Experience has Policies
                </Typography>
            </Box>
            {hasPolicies && (
                <Stack gap={2}>
                    <Box display='flex' justifyContent='flex-end'>
                  
                            <Button
                               component='label'
                                variant='outlined'
                                onClick={handleAddPolicy}
                                fullWidth={isMobile}
                            >
                              Add Policy
                            </Button>
                     
                        
                 
                    </Box>
                    {fields.map((policy, index) => (
                        <ExperiencePoliciesForm 
                            key={index}
                            index={index}
                            policy={policy}
                            remove={remove}
                            experienceType={experienceType}
                        />
                    ))}
                </Stack>
            )}
        </Stack>
    )
}

export default ExperiencePolicies