import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetIndividualFeeQuery } from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../shared/AuthRoles';
import FeeForm from '../../components/Fees/FeeForm';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const EditFeePage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const [url, setUrl] = useState('');
    const { data: getIndividualFee, isFetching } =
        useGetIndividualFeeQuery(url);
    const urlRef = window.location.href.split('/')[4];
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'feesPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    useEffect(() => {
        if (urlRef !== undefined) {
            if (
                loggedInUser?.role === 'owner_admin' ||
                loggedInUser?.role === 'owner_company' ||
                loggedInUser?.role === 'owner_user'
            ) {
                setUrl(`/owners/${loggedInUser?.company}/fees/${urlRef}`);
            } else if (
                loggedInUser?.role === 'guest_admin' ||
                loggedInUser?.role === 'guest_company'
            ) {
                setUrl(`/guests/${loggedInUser?.company}/users/${urlRef}`);
            } else {
                setUrl(`/7-day/users/${urlRef}`);
            }
        }
    }, [loggedInUser?.company]);

    const handleBackButton = () => {
        navigate(`/fees`);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <FeeForm fee={getIndividualFee} />
                    <CustomButton
                        onClick={handleBackButton}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                }}
                            >
                                Back to list
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
            )}
        </Container>
    );
};

export default EditFeePage;
