import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Box,
    Container,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import BillingReport from '../../components/Billing/Reports/BillingReport';

const GuestInvoicesReportPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const navigate = useNavigate();

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Box width='100%'>
                <Stack direction='row' alignItems='center' gap={1} pb='30px'>
                    <Typography
                        variant='h5'
                        color='#787878'
                        sx={{
                            textDecoration: 'underline rgb(92, 181, 179)',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        GUEST INVOICES
                    </Typography>
                </Stack>
                <BillingReport />

                <Grid
                    sx={{
                        justifyContent: 'flex-start',
                    }}
                >
                    {' '}
                    <CustomButton
                        onClick={() => navigate(`/reports`)}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back to Reports Menu
                        </Typography>
                    </CustomButton>
                </Grid>
            </Box>
        </Container>
    );
};

export default GuestInvoicesReportPage;
