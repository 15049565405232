import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetIndividualTeamQuery } from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../shared/AuthRoles';
import ActiveUserList from '../../components/Teams/ActiveUserList';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import TeamsForm from '../../components/Teams/TeamsForm';

const EditTeamsPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    const [url, setUrl] = useState('');
    const { data: getIndividualTeam, isFetching } =
        useGetIndividualTeamQuery(url);
    const urlRef = window.location.href.split('/')[4];

    useEffect(() => {
        if (urlRef !== undefined) {
            setUrl(`/departments/${urlRef}`);
        }
    }, [loggedInUser?.company]);

    const handleBackButton = () => {
        navigate(`/teams`);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <TeamsForm team={getIndividualTeam} />
                    <ActiveUserList team={getIndividualTeam} />
                    <CustomButton
                        onClick={handleBackButton}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                }}
                            >
                                Back to list
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
            )}
        </Container>
    );
};

export default EditTeamsPage;
